import { useEffect, useState } from "react";
import SearchFilter from "../ConnectComponents/SearchFilter";
import NoConnections from "./NoConnections";
import ReactPaginate from "react-paginate";
import { makeGetCall, makeNextPageCall } from "../../../utils/Requests";
import { apiConstants } from "../../../utils/Constants";
import { scrollToTop, showToast } from "../../../utils";
import ConnectUserCard from "../ConnectComponents/ConnectUserCard";
import { CONNECTION_TYPE } from "../ConnectConstants";
import ConnectMapContainer from "../ConnectComponents/ConnectMapContainer";
import useCheckDeviceScreen from "../../../hooks/useCheckScreen";
import NoSearchedFilter from "../ConnectComponents/NoSearchedFilter";
import { getGeolocation } from "../../../constants/helperConnectUser";

const ConnectConnections = () => {
  const [search, setSearch] = useState("");
  const [connectedConnectionsList, setConnectedConnectionsList] = useState([]);
  const [totalConnections, setTotalConnections] = useState(0);
  const [showMap, setShowMap] = useState(false);
  const [limit] = useState<number>(12);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [serachData, setSearchData] = useState<any>();
  const [isSearchMode, setIsSearchMode] = useState(false);
  const [count, setCount] = useState(0);
  const [users, setUsers] = useState<any>([]);
  const { isMobile } = useCheckDeviceScreen();
  const [apiDetails, setApiDetails] = useState({
    prevUrl: "",
    nextUrl: "",
    pageCount: 1,
  });
  const [userCurrentLocation, setUserCurrentLocation] = useState({
    lat: 0,
    long: 0,
  });
  const [connectid, setConnectid] = useState<any>(null);
  const [offset, setOffset] = useState<number>(0);

  const handleClearClick = () => {
    setSearch("");
    setIsLoading(true);
    setIsSearchMode(false);
  };

  const handleSuggestionSearch = (value: any) => {
    setSearch(value);
  };

  const handleSearchedSeeAllClick = () => {
    setIsLoading(true);
    getConnectedConnections();
    setIsSearchMode(true);
  };
  const handleViewToggle = () => {
    setShowMap((prev) => !prev);
  };

  const handlePageClick = (event: any) => {
    setIsLoading(true);
    setCurrentPage(event.selected);
    setOffset(event.selected * limit);
    if (apiDetails.nextUrl) {
      makeNextPageCall({ url: apiDetails.nextUrl })
        .then((res) => {
          if (res.next || res.previous) {
            setApiDetails({
              prevUrl: res.previous ?? "",
              nextUrl: res.next ?? "",
              pageCount: res.count ?? 1,
            });
          }
          setConnectedConnectionsList(res.results);
          setIsLoading(false);
        })
        .catch((err) => {
          showToast(err, "error");
        });
    } else if (apiDetails.prevUrl) {
      makeNextPageCall({ url: apiDetails.prevUrl })
        .then((res) => {
          if (res.next || res.previous) {
            setApiDetails({
              prevUrl: res.previous ?? "",
              nextUrl: res.next ?? "",
              pageCount: res.count ?? 1,
            });
          }
          setConnectedConnectionsList(res.results);
          setIsLoading(false);
        })
        .catch((err) => {
          showToast(err, "error");
        });
    }
    scrollToTop();
  };

  const getSearchedConnectedConnections = (
    url = apiConstants.get_my_connections,
    limit = 8
  ) => {
    const params = { limit, offset };
    makeGetCall({
      url,
      params: { ...params, search: search },
    })
      .then((res) => {
        const data = res.results;
        if (data) {
          setSearchData(data);
        }
        setIsLoading(false);
      })
      .catch((err: any) => {
        showToast(err, "error");
      });
  };
  const getConnectedConnections = async (url = apiConstants.get_my_connections) => {

    const userLocationData:any = await getGeolocation();
    let params = {
      limit,
      offset,
    };
    
    if(userLocationData?.currentLat && userLocationData?.currentLng ){
      params["point"] = `${userLocationData?.currentLng},${userLocationData?.currentLat}` ;
    }

   await makeGetCall({
      url,
      params: search ? { ...params, search: search } : params,
    })
      .then((res) => {
        const data = res.results;
        if (data) {
          setConnectedConnectionsList(data);
        }
        if (res.next || res.previous) {
          setApiDetails({
            prevUrl: res.previous ?? "",
            nextUrl: res.next ?? "",
            pageCount: res.count ?? 1,
          });
        }
        if(isSearchMode){
          setCount(res?.count);
        } 
        if(!isSearchMode){
          setTotalConnections(res?.count);
        }
        setIsLoading(false);
      })
      .catch((err: any) => {
        showToast(err, "error");
      });
  };

  useEffect(() => {
    getConnectedConnections();
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserCurrentLocation({
            lat: position.coords.latitude,
            long: position.coords.longitude,
          });
        },
        (err) => {
          showToast(
            "We kindly ask for your location to enhance your app experience; however, we regret to inform you that without this information, we are unable to proceed further.",
            "error"
          );
        }
      );
    } else {
      showToast("Geolocation is not available in your browser.", "error");
    }
  }, []);

  useEffect(() => {
    if (search !== "") {
      setIsSearchMode(true);
      getSearchedConnectedConnections();
    }
    if (search === "") {
      setIsSearchMode(false);
      getConnectedConnections();
    }
  }, [search]);

  return (
    <div>
      {!isMobile &&
       <div className="connect-page-heading">{`My connections(${totalConnections})`}
      </div>
      }
      {(connectedConnectionsList?.length !== 0 || isSearchMode) &&
        <SearchFilter
        showMap={showMap}
        handleViewToggle={handleViewToggle}
        handleSearchChange={handleSuggestionSearch}
        serachData={serachData}
        handleSeeAll={handleSearchedSeeAllClick}
        handleClearSearch={handleClearClick}
      />
      }
      {isSearchMode && <div style={{fontSize:"14px", fontFamily:"Poppins", lineHeight:"24px", fontWeight:"500", margin:"21px 0px 12px 0px"}}>{`Search results (${count})`}</div>}
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            marginTop: "200px",
          }}
        >
          <div className="lds-ring">
            <div style={{ textAlign: "center" }}>
              <img
                style={{ width: "50px" }}
                src="/static/spinner.svg"
                alt="loader"
              />
            </div>

            <div className="text__container">
              <span>Please wait... Loading</span>
            </div>
          </div>
        </div>
      ) : !showMap ? (
        connectedConnectionsList?.length === 0 ? (
          isSearchMode ? <NoSearchedFilter searchTerm={search} /> : <NoConnections />
        ) : (
          <div>
            <div className="content-title"></div>
            <div className="content-style">
              {" "}
              {connectedConnectionsList.map((connection) => (
                <ConnectUserCard
                  connectionData={connection}
                  key={connection.id}
                  mode={CONNECTION_TYPE.CONNECTION}
                  isScrollable={false}
                />
              ))}
            </div>
            {connectedConnectionsList?.length > 10 && (
              <div className="paginate-style">
                <div className="pages-inner-container">
                  <div className="pagination-box">
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel={
                        <>
                          <div
                            className={
                              Math.ceil(apiDetails.pageCount / limit) ===
                              currentPage + 1
                                ? "previous-button disabled"
                                : "previous-button"
                            }
                          >
                            <img src="/static/u_angle-left-b(1).svg" alt="" />
                            {!isMobile ? "Next " : ""}
                          </div>
                        </>
                      }
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={!isMobile ? 5 : 2}
                      forcePage={currentPage}
                      pageCount={Math.ceil(apiDetails.pageCount / limit)}
                      previousLabel={
                        <>
                          <div className="previous-button">
                          {!isMobile ? "Previous " : ""}
                            <img src="/static/u_angle-left-b.svg" alt="" />
                          </div>
                        </>
                      }
                      renderOnZeroPageCount={null}
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakClassName="page-item"
                      breakLinkClassName="page-link"
                      containerClassName="pagination"
                      activeClassName="active"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        )
      ) : (
        <div className="inner-contet">
          <div className="content-title"></div>
          <ConnectMapContainer
            eventMap={connectedConnectionsList}
            latitude={userCurrentLocation.lat}
            longitude={userCurrentLocation.long}
            mode={CONNECTION_TYPE.CONNECTION}
          />
        </div>
      )}
    </div>
  );
};

export default ConnectConnections;
