// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.interest_container_full {
  padding: 0px 98px; }
  @media screen and (max-width: 600px) {
    .interest_container_full {
      padding: 0px 20px; } }
  .interest_container_full .heading_listing_interest {
    font-family: Poppins;
    font-size: 24px;
    font-weight: 600;
    line-height: 36px;
    text-align: center;
    color: #25272D;
    margin: 40px 0px; }
    @media screen and (max-width: 600px) {
      .interest_container_full .heading_listing_interest {
        margin: 20px 0px;
        font-size: 20px;
        line-height: 24px; } }
`, "",{"version":3,"sources":["webpack://./src/components/ListingComponents/SelectInterestComponent/style.scss"],"names":[],"mappings":"AAAA;EACI,iBAAiB,EAAA;EAEjB;IAHJ;MAIQ,iBAAiB,EAAA,EAkBxB;EAtBD;IAQQ,oBAAoB;IACpB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;IAClB,cAAc;IACd,gBAAgB,EAAA;IAEhB;MAhBR;QAiBY,gBAAgB;QAChB,eAAe;QACf,iBAAiB,EAAA,EAExB","sourcesContent":[".interest_container_full {\n    padding: 0px 98px;\n\n    @media screen and (max-width:600px) {\n        padding: 0px 20px;\n    }\n\n    .heading_listing_interest {\n        font-family: Poppins;\n        font-size: 24px;\n        font-weight: 600;\n        line-height: 36px;\n        text-align: center;\n        color: #25272D;\n        margin: 40px 0px;\n\n        @media screen and (max-width:600px) {\n            margin: 20px 0px;\n            font-size: 20px;\n            line-height: 24px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
