import { Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "../components/Dashboard/Dashboard";
import Faq from "../components/Dashboard/Faq";
import PasswordManagement from "../components/Dashboard/PasswordManagement";
import PolicyPage from "../components/Dashboard/PolicyPage";
import Qualifications from "../components/Dashboard/Qualifications";
import TrainingExperience from "../components/Dashboard/TrainingExperience";
import TrainingExperienceNew from "../components/Profile/Dashboard/TrainingExperienceNew";
import Policies from "../components/Legal/Policies";
import BusinessInterests from "../components/Profile/BusinessInterests";
import DocumentUpload from "../components/Profile/DocumentUpload";
import ManageExpertises from "../components/Profile/ManageExpertises";
import ComingSoon from "../global/ComingSoon";
import CreateListingPage from "../pages/CreateListingPage";
import EventsPage from "../pages/EventsPage";
import EventsSearchPage from "../pages/EventsSearchPage";

import FavouritePage from "../pages/FavouritePage";
import ForgotPasswordPage from "../pages/ForgotPasswordPage";
import Login from "../pages/Login";
import ManageProfilePage from "../pages/ManageProfilePage";
import PaymentMethodPage from "../pages/PaymentMethodPage";
import PersonalDetailPage from "../pages/PersonalDetailPage";
import Register from "../pages/Register";
import ViewListingPage from "../pages/ViewListingPage";
import { ROUTE_CONSTANTS } from "../utils/RouteConstants";
import AuthRoutes from "./AuthRoutes";
import IntermediateRoutes from "./IntermediateRoutes";
import UnAuthRoutes from "./UnAuthRoutes";
import ListingDetail from "../components/Dashboard/ListingDetail";
import EditListingPage from "../pages/EditListingPage";
import DraftsPage from "../pages/DraftsPage";
import ProfileMenuPage from "../pages/ProfileMenuPage";
// import Landing from "../components/Landing";
import BookingEvent from "../components/Dashboard/BookingEvent";
import PromotionPage from "../pages/PromotionPage";
import PromotionDetail from "../components/Promotion/PromotionDetail";
import Earnings from "../components/Dashboard/Earnings";
import EarnBookingPage from "../pages/EarnBookingPage";
import Welcome from "../pages/Welcome";
// import ListingBlockPage from "../pages/ListingBlockPage";
import PurchaseListing from "../components/Dashboard/PurchaseListing";
import ReviewScreen from "../components/Dashboard/Review";
import TeamMembership from "../components/Dashboard/TeamMembership";
import Subscription from "../components/Dashboard/Subscription/Subscription";
import ConnectPage from "../pages/ConnectPage";
import ChatPage from "../pages/ChatPage";
import MyBookings from "../components/Dashboard/MyBookings";
import Profile from "../components/UserProfile/Profile";
import MyListingsPage from "../pages/MyListingPage";
import NotificationPage from "../pages/Notification";
import StripeSuccess from "../components/Stripe/StripeSuccess";
import StripeFailed from "../components/Stripe/StripeFailed";
import JoinPage from "../pages/JoinPage";
import BookingDetails from "../components/Dashboard/BookingDetails";
import EventBookingPage from "../pages/EventBookingPage";
import ListingDetailTwo from "../components/Dashboard/ListingDetailTwo";
import ListingDetailGallery from "../components/Dashboard/ListingDetailGallery";

import PromotionDetails from "../components/Promotion/PromotionDetails";
import LandingPageNew from "../pages/LandingPage";
import Interest from "../components/Profile/Interest";
import InterestNew from "../components/Profile/Dashboard/IntersetsNew";
import BankAccountStripe from "../components/Profile/Dashboard/BankAccountStripe";
import FavouriteNew from "../components/Profile/Dashboard/FavouriteNew";
import ConnectConnections from "../components/Connect/ConnectConnections";
import ConnectRequests from "../components/Connect/ConnectRequests";
import ConnectSuggestions from "../components/Connect/ConnectSuggestions";
import ConnectFavourites from "../components/Connect/ConnectFavourites";
import ConnectFlagged from "../components/Connect/ConnectFlagged";
import ConnectZone from "../components/Connect/ConnectZone";
import Connect from "../components/Connect";
import BusinessDetailPage from "../pages/BusinessDetailPage";
import MyTeamListing from "../components/Profile/Dashboard/MyTeamListing";
import TeamListingDetails from "../components/Profile/Dashboard/TeamListingDetails";
import ConnectUserProfile from "../pages/ConnectUserProfile/ConnectUserProfile";
import PaymentClose from "../pages/PaymentClose";

const RouteStack = (props: any) => {
  return (
    <Routes>
      <Route>
      <Route path={ROUTE_CONSTANTS.policies} element={<PolicyPage />} />

        {/* <Route path={ROUTE_CONSTANTS.about_us} element={<Landing />} /> */}
        <Route path={ROUTE_CONSTANTS.about_us} element={<LandingPageNew />} />
        <Route
          path={ROUTE_CONSTANTS.dashboard}
          element={<Navigate to={ROUTE_CONSTANTS.events} />}
        />
        <Route path={ROUTE_CONSTANTS.landing} element={<EventsPage />} />
        <Route path={ROUTE_CONSTANTS.search} element={<EventsSearchPage />} />
      </Route>
      <Route element={<UnAuthRoutes />}>
        <Route path={ROUTE_CONSTANTS.register} element={<Register />} />
        {/* <Route path={ROUTE_CONSTANTS.welcome} element={<Welcome />} /> */}
        <Route
          path={ROUTE_CONSTANTS.forgot_password}
          element={<ForgotPasswordPage />}
        />
        <Route path={ROUTE_CONSTANTS.login} element={<Login />} />
        {/* <Route path={ROUTE_CONSTANTS.policies} element={<Policies />} /> */}

        {/* <Route path={ROUTE_CONSTANTS.events} element={<EventsPage />} /> */}
      </Route>
      <Route element={<AuthRoutes />}>
        <Route
          path={ROUTE_CONSTANTS.personal_details}
          element={<PersonalDetailPage />}
        />
        <Route
          path={ROUTE_CONSTANTS.business_details}
          element={<BusinessDetailPage />}
        />
        <Route
          path={ROUTE_CONSTANTS.profile_menu}
          element={<ProfileMenuPage />}
        />
        <Route path={`/user/:id`} element={<Profile />} />

        <Route path={ROUTE_CONSTANTS.intrest} element={<InterestNew />} />
        <Route path={ROUTE_CONSTANTS.my_earning} element={<Earnings />} />
        {/* <Route path={ROUTE_CONSTANTS.favourites} element={<FavouritePage />} /> */}
        <Route path={ROUTE_CONSTANTS.favourites} element={<FavouriteNew />} />
        <Route
          path={ROUTE_CONSTANTS.experience}
          element={<TrainingExperienceNew />}
        />
        {/* <Route
          path={ROUTE_CONSTANTS.qualification}
          element={<Qualifications />}
        /> */}
        <Route
          path={ROUTE_CONSTANTS.password_management}
          element={<PasswordManagement />}
        />
        <Route path={ROUTE_CONSTANTS.subscription} element={<Subscription />} />
        {/* <Route path={ROUTE_CONSTANTS.subscription} element={<Subscription2 />} /> */}
        {/* <Route path={ROUTE_CONSTANTS.policies} element={<PolicyPage />} /> */}
        <Route path={ROUTE_CONSTANTS.faq} element={<Faq />} />
        <Route path={ROUTE_CONSTANTS.contact} element={<ComingSoon />} />
        <Route path={ROUTE_CONSTANTS.delete_account} element={<ComingSoon />} />
        <Route path={"/"} element={<Dashboard />} />
        <Route path={ROUTE_CONSTANTS.login} element={<Login />} />
        <Route
          path={ROUTE_CONSTANTS.payment_methods}
          element={<PaymentMethodPage />}
        />
        <Route
          path={ROUTE_CONSTANTS.create_listing}
          element={<CreateListingPage />}
        />

        <Route
          path={ROUTE_CONSTANTS.view_listing}
          element={<ViewListingPage />}
        />
        <Route
          path={ROUTE_CONSTANTS.stripeconnect_success}
          element={<StripeSuccess />}
        />
        <Route
          path={ROUTE_CONSTANTS.stripeconnect_failed}
          element={<StripeFailed />}
        />

        <Route path={ROUTE_CONSTANTS.promotion} element={<PromotionPage />} />
        <Route
          path={`${ROUTE_CONSTANTS.promotion}/:id`}
          element={<PromotionDetails />}
        />
        <Route
          path={`${ROUTE_CONSTANTS.edit_listing}/:id`}
          element={<EditListingPage />}
        />
        <Route path={ROUTE_CONSTANTS.drafts} element={<DraftsPage />} />
        <Route
          path={`${ROUTE_CONSTANTS.bookingEvent}/:event_id/:booking_id`}
          element={<BookingEvent />}
        />
        <Route
          path={`${ROUTE_CONSTANTS.earn_booking}/:listing_id`}
          element={<EarnBookingPage />}
        />
        {/* <Route
          path={`${ROUTE_CONSTANTS.listing_block}/`}
          element={<ListingBlockPage />}
        /> */}
        {/* <Route path={`${ROUTE_CONSTANTS.joining}/`} element={<JoinPage />} /> */}
        <Route
          path={ROUTE_CONSTANTS.notifications}
          element={<NotificationPage />}
        />
      </Route>
      <Route element={<IntermediateRoutes />}>
        <Route
          path={ROUTE_CONSTANTS.manage_profile}
          element={<ManageProfilePage />}
        />
        <Route
          path={ROUTE_CONSTANTS.business_intrest}
          element={<BusinessInterests />}
        />
        {/* <Route path={ROUTE_CONSTANTS.business_intrest} element={<Interest />} /> */}
        <Route
          path={ROUTE_CONSTANTS.manage_expertise}
          element={<ManageExpertises />}
        />
        <Route
          path={ROUTE_CONSTANTS.upload_document}
          element={<DocumentUpload />}
        />
        <Route path={ROUTE_CONSTANTS.login} element={<Login />} />
        <Route path={ROUTE_CONSTANTS.register} element={<Register />} />
        <Route path={ROUTE_CONSTANTS.welcome} element={<Welcome />} />
      </Route>

      <Route path="*" element={<ComingSoon />} />
      {/* <Route path={ROUTE_CONSTANTS.policies} element={<PolicyPage />} /> */}
      <Route
        path={ROUTE_CONSTANTS.purchase_listing}
        element={<PurchaseListing />}
      />
      {/* <Route
        path={ROUTE_CONSTANTS.team_membership}
        element={<TeamMembership />}
      /> */}
      <Route
        path={ROUTE_CONSTANTS.team_membership}
        element={<MyTeamListing />}
      />
      <Route element={<AuthRoutes />}>
      <Route element={<Connect />}>
        {/* <Route path={ROUTE_CONSTANTS.connect} element={<Connect />} /> */}
        <Route path={ROUTE_CONSTANTS.connect} element={<ConnectZone />} />
        <Route path="/connect/connections" element={<ConnectConnections />} />
        <Route path="/connect/requests" element={<ConnectRequests />} />
        <Route path="/connect/flagged" element={<ConnectFlagged />} />
        <Route path="/connect/suggestions" element={<ConnectSuggestions />} />
        <Route path="/connect/favourite" element={<ConnectFavourites />} />
      </Route>
      </Route>
      <Route path={ROUTE_CONSTANTS.chat} element={<ChatPage />} />
      <Route path={ROUTE_CONSTANTS.my_booking} element={<MyBookings />} />
      <Route
        path={ROUTE_CONSTANTS.booking_details}
        element={<BookingDetails />}
      />
      <Route
        path={ROUTE_CONSTANTS.team_listing_details}
        element={<TeamListingDetails />}
      />
      <Route
        path={ROUTE_CONSTANTS.events}
        element={<Navigate to={ROUTE_CONSTANTS.landing} />}
      />
      <Route
        path={`${ROUTE_CONSTANTS.events_booking}/:id`}
        element={<EventBookingPage />}
      />
      <Route path={"events/:id"} element={<ListingDetail />} />
      <Route
        path={ROUTE_CONSTANTS.my_created_listing}
        element={<MyListingsPage />}
      />
      <Route path={"events/:id/review"} element={<ReviewScreen />} />
      <Route path="/listing-two/:id" element={<ListingDetailTwo />} />
      <Route path="/listing-gallery/:id" element={<ListingDetailGallery />} />

      <Route
        path={ROUTE_CONSTANTS.bank_accounts}
        element={<BankAccountStripe />}
      />
      <Route path="/our-mates/:id" element={<ConnectUserProfile />} />
      <Route path="/payment-status" element={<PaymentClose />} />
    </Routes>
  );
};

export default RouteStack;
