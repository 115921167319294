import { Outlet } from "react-router-dom";
import { getItemFromStore } from "../utils";
import AuthRoutes from "./AuthRoutes";
import UnAuthRoutes from "./UnAuthRoutes";

const IntermediateRoutes = () => {
  const isAuthenticated = getItemFromStore("access_token") ? true : false;

  const isProfileComplete = getItemFromStore("isProfileComplete")
    ? true
    : false;
  const isAuthAndProfileComplete = isAuthenticated && isProfileComplete;
  const isAuthAndNotProfileComplete = isAuthenticated && !isProfileComplete;

  return isAuthAndNotProfileComplete ? (
    <>
      <Outlet />
    </>
  ) : isAuthAndProfileComplete ? (
    <>
      <AuthRoutes />
    </>
  ) : (
    <UnAuthRoutes />
  );
};

export default IntermediateRoutes;
