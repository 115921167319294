/* eslint-disable react-hooks/exhaustive-deps */
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Button from "../../global/Button";
import {
  getItemFromStore,
  handleVideoClick,
  isUserAuthenticated,
  showToast,
} from "../../utils";
import endpoints from "../../utils/endpoints";
import { makeGetCall, makePatchCall, makePostCall } from "../../utils/Requests";
import GlobalHeader from "./GlobalHeader";
import GoogleMapContainer from "../../global/GoogleMapContainer";
import ApiLoader from "../../global/ApiLoader";
import moment from "moment";

import EventDateModal from "./EventDateModal";
import {
  ExclusiveMembericon,
  FlagIcon,
  GenderNeuter,
  IFeeIcon,
  LocationGreyIcon,
  PeopleCommunityIconH,
  RightArrowStickIcon,
  SessionPackageIcon,
  SpClockIcon,
  SpDateIcon,
} from "../../utils/Icons/svgs";
import ScheduleDatesPopup from "./Popups/ScheduleDatesPopup";
import BookingErrorPopup from "./Popups/BookingErrorPopup";
import CancelBookingPopup from "./Popups/CancelBookingPopup";
import CancelReasonPopup from "./Popups/CancelReasonPopup";
import MemberExclusivePopup from "./Popups/MemberExclusivePopup";
import { LISTING_TYPE } from "../../types";
import FlagListingPopup from "./Popups/FlagListingPopup";
import { ROUTE_CONSTANTS } from "../../utils/RouteConstants";
import { useOutsideClick } from "../../hooks";
import CopyLinkButton from "../CopyLinkButton";
import OpenMapContainer from "./OpenMapContainer";
import EventDateListing from "./EventDateListing";

const ListingDetail = () => {
  const [isDropOpen, setIsDropOpen] = useState(false);
  const [detailsData, setDetailsData] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectdate, setSelectDate] = useState<any>();
  const [showDateModal, setShowDateModal] = useState<boolean>(false);
  const [showAllDateModal, setAllShowDateModal] = useState<boolean>(false);
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const [second, setSecond] = useState(false);
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [ageError, setAgeError] = useState(null);
  const [selDates, setSelDates] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const [showBookingError, setShowBookingError] = useState(false);
  const [showCancelPopup, setShowCancelPopup] = useState(false);
  const [showCancelReason, setShowCancelReason] = useState(false);
  const [cancelEvents, setCancelEvents] = useState<any>();
  const [reviewCount, setReviewCount] = useState(0);
  const [rating, setRating] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [showExclusivePopup, setShowExclusivePopup] = useState(false);
  const [showFlagListingPopup, setShowFlagListingPopup] = useState(false);

  const [showCancelByUser, setShowCancelByUser] = useState(false);
  const [showCancelByAdmin, setShowCancelByAdmin] = useState(null);
  const [showRemoveByAdmin, setShowRemoveByAdmin] = useState(null);
  const [showInactivePopup, setShowInactivePopup] = useState(false);
  const [allCancelPopup, setAllCancelPopup] = useState(false);
  const [isShareOpen, setIsShareOpen] = useState(false);
  const [showCancelListingPopup, setShowCancelListingPopup] = useState(false);
  const ref = useRef(null);
  const [openMap, setOpenMap] = useState(false);
  const [eventCenter, setEventCenter] = useState<any>();

  let userAge = null;
  let userProfile = null;
  let cancelBookingEvents = [];
  const handleTeamBooking = () => {
    const data = {
      booking_data: {},
    };

    makePostCall({ url: endpoints.listings_intermediate, apiPayload: data })
      .then((res) => {
        if (res.status.code === 200) {
          const booking_id = res?.data?.id;
          navigate(`/booking/${id}/${booking_id}`);
        }
      })
      .catch((err) => console.error(err));
  };

  const handleBookingError = () => {
    if (!isUserAuthenticated()) {
      showToast("You must log in first", "error");
      navigate("/login");
      return;
    } else {
      userProfile = getItemFromStore("userProfile");
      userAge = moment().diff(moment(userProfile?.dob, "YYYY-MM-DD"), "years");
      const reqAge = userAge;
      const reqGender = userProfile?.gender;

      if (
        detailsData?.listingType === LISTING_TYPE.SPECIAL &&
        detailsData?.canBookSpecialListing === false
      ) {
        showToast(
          "You are unable to book this special listing event as you have been removed by the admin from booking this listing",
          "error"
        );
      }

      if (
        userProfile?.userType === "Guest" &&
        detailsData?.listingType === "SPECIAL"
      ) {
        setShowExclusivePopup(true);
        return;
      }

      if (detailsData?.createdBy?.id === getItemFromStore("userProfile")?.id) {
        setAllShowDateModal(true);
      } else {
        navigate(`/events-booking/${id}`);
      }
    }
  };

  const cancelBookingApi = () => {
    makeGetCall({
      url: endpoints.mylisting_new + `${id}/cancel-bookings/`,
    })
      .then((res) => {
        setCancelEvents(res?.data);
        setShowCancelByUser(res?.data?.cancelledByUser);
      })
      .catch((err) => {
        console.error(err, "error");
      });
  };
  const handleShowAllCancellationPopup = () => {
    const apiPayload: any = {};
    apiPayload.listing = id;
    apiPayload.to_display = false;

    makePostCall({ url: endpoints.listings_popup, apiPayload: apiPayload })
      .then((res) => {
        if (res.status.code === 200) {
        }
      })
      .catch((err) => console.error(err));
  };

  const handleCancel = () => {
    cancelBookingApi();
    setShowCancelPopup(true);
  };

  const goBack = () => {
    navigate(-1);
  };
  useEffect(() => {
    navigate('/listing-two/' + id)
  }, [])

  // useEffect(() => {
  //   setIsLoading(true);
  //   makeGetCall({
  //     url: endpoints.mylisting_new + `${id}`,
  //   })
  //     .then((res) => {
  //       setDetailsData(res.data);
  //       setShowCancelByUser(res?.data?.cancelledByUser);
  //       if (res.data?.events?.length === 1) {
  //         setSelectDate(1);
  //       }
  //       setIsLoading(false);
  //     })
  //     .catch((err) => {
  //       setIsLoading(false);
  //       showToast(err, "error");
  //     });

  //   //review count api

  //   makeGetCall({
  //     url: endpoints.mylisting_new + `${id}/reviews/`,
  //   })
  //     .then((res) => {
  //       if (res.status.code === 200) {
  //         setReviewCount(res?.count);
  //         setRating(res?.average);
  //       }
  //     })
  //     .catch((err) => {
  //       console.error("error", err);
  //     });
  // }, [id, refresh]);

  // useEffect(() => {
  //   cancelBookingApi();
  // }, []);

  // useEffect(() => {
  //   if (
  //     showCancelByUser &&
  //     detailsData?.listingType !== "SPECIAL" &&
  //     showCancelByAdmin === null &&
  //     showRemoveByAdmin === null
  //   ) {
  //     setAllCancelPopup(true);
  //   } else if (
  //     showCancelByAdmin === "L" &&
  //     detailsData?.listingType !== "SPECIAL"
  //   ) {
  //     setAllCancelPopup(true);
  //   } else if (
  //     detailsData?.listingType === "SPECIAL" &&
  //     showRemoveByAdmin === "L"
  //   ) {
  //     setAllCancelPopup(true);
  //   }
  // }, [detailsData?.cancelledByUser]);

  // useEffect(() => {
  //   if (allCancelPopup) {
  //     handleShowAllCancellationPopup();
  //   }
  // }, [allCancelPopup]);

  // useEffect(() => {
  //   setShowCancelByUser(detailsData.cancelledByUser);
  //   setShowCancelByAdmin(detailsData.inActiveBy);
  //   setShowRemoveByAdmin(detailsData.inActiveBy);
  // }, [detailsData]);

  let center;
  if (
    detailsData?.scheduleDetails?.address?.location?.split("POINT ").length > 1
  ) {
    center = {
      lng: Number(
        detailsData?.scheduleDetails?.address?.location
          ?.split("POINT ")?.[1]
          ?.split(" ")?.[0]
          ?.substring(1)
      ),
      lat: Number(
        detailsData?.scheduleDetails?.address?.location
          ?.split("POINT ")?.[1]
          ?.split(" ")?.[1]
          .slice(0, -1)
      ),
    };
  } else {
    center = {
      lng: Number(
        detailsData?.scheduleDetails?.address?.location
          ?.split("POINT")?.[1]
          ?.split(" ")?.[0]
          ?.substring(1)
      ),
      lat: Number(
        detailsData?.scheduleDetails?.address?.location
          ?.split("POINT")?.[1]
          ?.split(" ")?.[1]
          .slice(0, -1)
      ),
    };
  }

  if (detailsData?.listingType === LISTING_TYPE.M) {
    if (
      detailsData?.scheduleDetails?.[0]?.address?.location?.split("POINT ")
        .length > 1
    ) {
      center = {
        lng: Number(
          detailsData?.scheduleDetails?.[0]?.address?.location
            ?.split("POINT ")?.[1]
            ?.split(" ")?.[0]
            ?.substring(1)
        ),
        lat: Number(
          detailsData?.scheduleDetails?.[0]?.address?.location
            ?.split("POINT ")?.[1]
            ?.split(" ")?.[1]
            .slice(0, -1)
        ),
      };
    } else {
      center = {
        lng: Number(
          detailsData?.scheduleDetails?.[0]?.address?.location
            ?.split("POINT")?.[1]
            ?.split(" ")?.[0]
            ?.substring(1)
        ),
        lat: Number(
          detailsData?.scheduleDetails?.[0]?.address?.location
            ?.split("POINT")?.[1]
            ?.split(" ")?.[1]
            .slice(0, -1)
        ),
      };
    }
  }

  if (detailsData?.listingType === LISTING_TYPE.SPECIAL) {
    if (
      detailsData?.events?.[0]?.address?.location?.split("POINT ").length > 1
    ) {
      center = {
        lng: Number(
          detailsData?.events?.[0]?.address?.location
            ?.split("POINT ")?.[1]
            ?.split(" ")?.[0]
            ?.substring(1)
        ),
        lat: Number(
          detailsData?.events?.[0]?.address?.location
            ?.split("POINT ")?.[1]
            ?.split(" ")?.[1]
            .slice(0, -1)
        ),
      };
    } else {
      center = {
        lng: Number(
          detailsData?.events?.[0]?.address?.location
            ?.split("POINT")?.[1]
            ?.split(" ")?.[0]
            ?.substring(1)
        ),
        lat: Number(
          detailsData?.events?.[0]?.address?.location
            ?.split("POINT")?.[1]
            ?.split(" ")?.[1]
            .slice(0, -1)
        ),
      };
    }
  }

  function isDisabled() {
    if (detailsData?.listingType !== LISTING_TYPE.S) {
      if (detailsData?.listingType !== LISTING_TYPE.SPECIAL) {
        if (
          detailsData?.teamSlotsAvailable === 0 ||
          detailsData?.teamSlotsAvailable === null
        ) {
          return true;
        }
      }
    }
    return false;
  }
  const timing = {
    startTime: moment(
      detailsData?.scheduleDetails?.startTime?.substring(0, 5),
      ["HH:mm"]
    ).format("hh:mmA"),
    endTime: moment(detailsData?.scheduleDetails?.endTime?.substring(0, 5), [
      "HH:mm",
    ]).format("hh:mmA"),
  };

  const isImage = /\.(jpg|jpeg|gif|png|svg|webp|avif|heic)$/.test(
    detailsData?.media?.toLowerCase()
  );
  const isVideo = /\.(mp4|mov|avi)$/.test(detailsData?.media?.toLowerCase());

  const isProfileImage = /\.(jpg|jpeg|gif|png|svg|webp|avif|heic)$/.test(
    detailsData?.createdBy?.profilePicture?.toLowerCase()
  );
  const isProfileVideo = /\.(mp4|mov|avi)$/.test(
    detailsData?.createdBy?.profilePicture?.toLowerCase()
  );

  const makeInactiveList = () => {
    if (id) {
      makePatchCall({
        url: endpoints.listing + `${id}/mark-inactive/`,
      })
        .then((res) => {
          if (res.status.code === 200) {
            showToast(res.status?.message, "success");
            setShowInactivePopup(false);
            navigate(ROUTE_CONSTANTS.view_listing);
          } else {
            showToast(res.status?.message, "error");
          }
        })
        .catch((err) => {
          showToast(err, "error");
        });
    }
  };

  useOutsideClick(ref, () => {
    setIsDropOpen(false);
    setIsShareOpen(false);
  });

  const handleWindowClosed = () => {
    if (!detailsData?.isCancellationAllowed) {
      return true;
    } else {
      if (!detailsData?.hasCancellableEvent) {
        return true;
      }
    }

    return false;
  };

  useEffect(() => {
    // Effect to mute/unmute video based on fullscreen state change
    const handleFullScreenChange = () => {
      const isFullScreen = !!document.fullscreenElement;
      const video = document.getElementById(
        `listing_video${detailsData?.id}`
      ) as HTMLVideoElement | null;

      if (video) {
        video.muted = !isFullScreen;
      }
    };

    document.addEventListener("fullscreenchange", handleFullScreenChange);
    return () => {
      document.removeEventListener("fullscreenchange", handleFullScreenChange);
    };
  }, [detailsData]);

  const handleFlagClick = () => {
    showToast("You must log in first", "error");
    navigate("/login");
  };

  function checkIsProfileCompleted() {
    const userDetails = getItemFromStore("userProfile");
    if (userDetails) {
      if (!userDetails?.name) {
        navigate(ROUTE_CONSTANTS.manage_profile);
      }
    }
    return true;
  }

  return (
    <>
      <GlobalHeader />
      {isLoading ? (
        <div className="page_loader">
          <ApiLoader />
        </div>
      ) : (
        <div className="dyevent-container">
          <div className="dyevent-main-container position-relative">
            <div className="eimage-container">
              {detailsData?.media ? (
                <>
                  {isImage && (
                    <img
                      style={{ width: "100%" }}
                      src={detailsData?.media}
                      alt=""
                    />
                  )}
                  {isVideo && (
                    <video
                      style={{
                        borderRadius: "12px",
                        width: "100%",
                        cursor: "pointer",
                      }}
                      src={detailsData?.media}
                      className="event_img"
                      autoPlay
                      playsInline
                      loop
                      muted
                      onClick={(e) => {
                        e.stopPropagation();
                        handleVideoClick(`listing_video${detailsData?.id}`);
                      }}
                      id={`listing_video${detailsData?.id}`}
                    >
                      <source src={detailsData?.media} />
                    </video>
                  )}
                </>
              ) : (
                <img
                  style={{ width: "100%" }}
                  src="/static/expertise2.png"
                  alt=""
                />
              )}
              <div
                onClick={goBack}
                className="back_arrow d-md-none cursor-pointer"
                style={{ zIndex: "1" }}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="rgba(0, 0, 0, 0.32)"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.7327 19.7905C11.0326 20.0762 11.5074 20.0646 11.7931 19.7647C12.0787 19.4648 12.0672 18.99 11.7673 18.7043L5.516 12.7498L20.25 12.7498C20.6642 12.7498 21 12.4141 21 11.9998C21 11.5856 20.6642 11.2498 20.25 11.2498L5.51564 11.2498L11.7673 5.29502C12.0672 5.00933 12.0787 4.5346 11.7931 4.23467C11.5074 3.93475 11.0326 3.9232 10.7327 4.20889L3.31379 11.2756C3.14474 11.4366 3.04477 11.642 3.01386 11.8556C3.00477 11.9023 3 11.9505 3 11.9998C3 12.0495 3.00483 12.0981 3.01405 12.1451C3.04518 12.3582 3.14508 12.5631 3.31379 12.7238L10.7327 19.7905Z"
                    fill="white"
                  />
                </svg>
              </div>
              {detailsData?.listingType === "SPECIAL" && (
                <div className="e-member-only d-none d-md-flex">
                  <div className="icon">
                    <ExclusiveMembericon color="#fff" />
                  </div>
                  <p>Exclusive for Members</p>
                </div>
              )}
              {detailsData?.createdBy?.id ===
                getItemFromStore("userProfile")?.id ? (
                <div style={{ marginTop: "20px", width: "100%" }}>
                  <Button
                    btnTxt="See all bookings"
                    className={"backtologin"}
                    onClick={() => navigate(`/earn-booking/${id}`)}
                  />
                </div>
              ) : (
                <Fragment>
                  {!detailsData?.isListingBookedByMe ? (
                    <Fragment>
                      <div className="all-button d-none d-md-block">
                        <div className="all-button-top">
                          {detailsData?.listingType === LISTING_TYPE.C ? (
                            <div>
                              <div
                                className="event_btn_price "
                                style={{ display: "block", textAlign: "start" }}
                              >
                                <span
                                  style={{ textAlign: "start" }}
                                  className="event_price "
                                >
                                  ${detailsData?.price}
                                </span>
                              </div>
                              <span className="" style={{ fontSize: "12px" }}>
                                per/month
                              </span>
                            </div>
                          ) : (
                            <div
                              className="event_btn_price"
                              style={{ width: "30%" }}
                            >
                              <span className="event_price">
                                ${detailsData?.price ? detailsData?.price : "0"}
                              </span>

                              {(detailsData?.listingType === "S" ||
                                detailsData?.listingType === "SPECIAL") && (
                                  <span className="event_class">
                                    {detailsData?.price
                                      ? "/class"
                                      : "(No Charges)"}
                                  </span>
                                )}
                              {detailsData?.listingType === LISTING_TYPE.M && (
                                <span className="event_class">
                                  {detailsData?.price
                                    ? "/package"
                                    : "(No Charges)"}
                                </span>
                              )}
                            </div>
                          )}

                          <Button
                            btnTxt={`${isDisabled()
                              ? "Booking Full"
                              : detailsData?.listingType === "C"
                                ? "Join Team"
                                : "Book Now"
                              }`}
                            className={"backtologin"}
                            onClick={() => {
                              handleBookingError();
                            }}
                            disabled={isDisabled()}
                          />
                        </div>
                        <hr
                          className="my-4"
                          style={{ border: "2px solid #F1F1F1" }}
                        ></hr>
                        {detailsData?.isCancellationAllowed ? (
                          <div className="c-cancellation-policy">
                            <p className="c-first">Cancellation Policy</p>
                            <p className="c-second">
                              You can cancel your booking up until{" "}
                              {"{" +
                                detailsData?.cancellationAllowedTillHours +
                                "}"}{" "}
                              hours of your event
                            </p>
                          </div>
                        ) : (
                          <div className="c-cancellation-policy">
                            <p className="c-first">Cancellation Policy</p>
                            <p className="c-second">
                              No cancellation allowed after booking
                            </p>
                          </div>
                        )}

                        {detailsData?.listingType === LISTING_TYPE.C && (
                          <>
                            <div className="c-cancellation-policy">
                              <p className="c-first">
                                Recurring payment
                                <img
                                  src="/static/greyinfo.svg"
                                  alt=""
                                  width="20px"
                                  height="20px"
                                  style={{
                                    marginLeft: "12px",
                                    minWidth: "12px",
                                  }}
                                />
                              </p>
                            </div>
                            <div className="info-box-reacc-payment">
                              <div className="info-box-cancel">
                                <span>
                                  Due to the recurring nature of the payment,
                                  the specified amount will be automatically
                                  deducted from your account according to the
                                  chosen payment term. To cease this recurring
                                  payment, please navigate to the "My Team
                                  Listings" section within your profile.
                                </span>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </Fragment>
                  ) : (
                    <div className="cancel_booking_buttons_wrapper d-none d-md-flex">
                      <div className="c_b_info">
                        <div className="c_b_icon d-flex">
                          <IFeeIcon width="16" height="16" />
                        </div>
                        <div className="c_b_txt">
                          You’re all set! You’ve booked this event. For any
                          further bookings or cancellations please tap below.
                        </div>
                      </div>

                      <Button
                        btnTxt={`${detailsData?.teamSlotsAvailable === 0
                          ? "Booking Full"
                          : detailsData?.listingType !== LISTING_TYPE.C
                            ? "Book Another Events"
                            : "Reserve More Spots"
                          }`}
                        className={"backtologin"}
                        onClick={() => {
                          handleBookingError();
                        }}
                        disabled={isDisabled()}
                      />

                      {detailsData?.listingType !== LISTING_TYPE.C && (
                        <Button
                          btnTxt={`${handleWindowClosed()
                            ? "Cancel window closed"
                            : " Cancel Booked Events"
                            }`}
                          className={"c_b_cancel"}
                          onClick={() => {
                            // handleBookingError();
                            // setShowCancelPopup(true);
                            handleCancel();
                          }}
                          disabled={handleWindowClosed()}
                        />
                      )}

                      {detailsData?.listingType !== LISTING_TYPE.C ? (
                        <hr
                          className="my-4"
                          style={{ border: "2px solid #F1F1F1" }}
                        ></hr>
                      ) : null}
                      {detailsData?.isCancellationAllowed ? (
                        <div className="c-cancellation-policy">
                          <p className="c-first">Cancellation Policy</p>
                          <p className="c-second">
                            You can cancel your booking up until{" "}
                            {"{" +
                              detailsData?.cancellationAllowedTillHours +
                              "}"}{" "}
                            hours of your event
                          </p>
                        </div>
                      ) : detailsData?.listingType !== LISTING_TYPE.C ? (
                        <div className="c-cancellation-policy">
                          <p className="c-first">Cancellation Policy</p>
                          <p className="c-second">
                            No cancellation allowed after booking
                          </p>
                        </div>
                      ) : null}
                      {detailsData?.listingType === LISTING_TYPE.C && (
                        <>
                          <div className="c-cancellation-policy">
                            <p className="c-first">
                              Recurring payment
                              <img
                                src="/static/greyinfo.svg"
                                alt=""
                                width="20px"
                                height="20px"
                                style={{
                                  marginLeft: "12px",
                                  minWidth: "12px",
                                }}
                              />
                            </p>
                          </div>
                          <div
                            className="info-box-reacc-payment"
                            style={{ marginTop: "0" }}
                          >
                            <div className="info-box-cancel">
                              <span>
                                Due to the recurring nature of the payment, the
                                specified amount will be automatically deducted
                                from your account according to the chosen
                                payment term. To cease this recurring payment,
                                please navigate to the "My Team Listings"
                                section within your profile.
                              </span>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  )}
                </Fragment>
              )}
            </div>
            <div className="e-info-container">
              <div className="dropdown-container">
                <div className="drop-cont">
                  <div className="dropdown-btn">
                    {detailsData?.createdBy?.id ===
                      getItemFromStore("userProfile")?.id ? (
                      <div
                        className="d-flex"
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "8px",
                        }}
                      >
                        <div onClick={() => setIsShareOpen(!isShareOpen)}>
                          <img alt="" src="/static/share.svg" />
                        </div>
                        <div onClick={() => setIsDropOpen(!isDropOpen)}>
                          <img
                            alt=""
                            className="py-2"
                            src="/static/three-grey-dots.svg"
                          />
                        </div>
                      </div>
                    ) : null}

                    {detailsData?.createdBy?.id !==
                      getItemFromStore("userProfile")?.id ? (
                      <div
                        className="d-flex"
                        style={{
                          alignItems: "center",
                          justifyContent: "center",
                          gap: "8px",
                        }}
                      >
                        <div onClick={() => setIsShareOpen(!isShareOpen)}>
                          <img alt="" src="/static/share.svg" />
                        </div>
                        <div
                          onClick={() => {
                            isUserAuthenticated() && checkIsProfileCompleted()
                              ? setShowFlagListingPopup(true)
                              : handleFlagClick();
                          }}
                        >
                          <FlagIcon />
                        </div>
                      </div>
                    ) : null}
                  </div>
                  {isDropOpen &&
                    detailsData?.createdBy?.id ===
                    getItemFromStore("userProfile")?.id ? (
                    <div
                      ref={ref}
                      className="drop-menu"
                      style={{
                        height: "auto",
                        top: "33px",
                        width: "12rem",
                      }}
                    >
                      <div className="drop-menu-content">
                        {detailsData?.isListingEditable ? (
                          <div
                            className="menu-item mb-0"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              navigate(`/edit-listing/${id}`);
                              setIsDropOpen(false);
                            }}
                          >
                            <img src="/static/Edit.svg" alt="" />
                            <p className="mb-0">Edit Listing</p>
                          </div>
                        ) : null}
                        {showCancelByAdmin !== null ? (
                          ""
                        ) : (
                          <div
                            className="menu-item mt-2 mb-0"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setShowInactivePopup(true);
                              setIsDropOpen(false);
                              // navigate(ROUTE_CONSTANTS.my_created_listing);
                            }}
                          >
                            <img src="/static/Communication.svg" alt="" />
                            <p className="mb-0">Inactive Listing</p>
                          </div>
                        )}
                      </div>
                    </div>
                  ) : null}
                  {isShareOpen ? (
                    <div
                      ref={ref}
                      className="drop-menu"
                      style={{
                        height: "auto",
                        top: "33px",
                        width: "12rem",
                        padding: "12px",
                        gap: "8px",
                      }}
                    >
                      <CopyLinkButton url={detailsData.dynamicLink} />
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="mobile-buttons-group d-flex d-md-none">
                <div
                  className={`listing-btn d-flex ${detailsData?.listingType === "P" ? "color_black" : ""
                    } `}
                >
                  {(detailsData?.listingType === "S" ||
                    detailsData?.listingType === "SPECIAL") && (
                      <img src="/static/Sport-white.svg" alt="" />
                    )}
                  {detailsData?.listingType === "P" && (
                    <SessionPackageIcon
                      color="white"
                      width="20px"
                      height="20px"
                    />
                  )}
                  {detailsData?.listingType === "C" && <PeopleCommunityIconH />}
                  <p className="list-btn-text mb-0 ">
                    {detailsData?.listingType === "S" ||
                      detailsData?.listingType === "SPECIAL"
                      ? "Session"
                      : detailsData?.listingType === "P"
                        ? "Package"
                        : detailsData?.listingType === "C"
                          ? "Team"
                          : ""}
                  </p>
                </div>
                {detailsData?.scheduleDetails?.startTime && (
                  <div className="time-btn d-flex">
                    <img
                      src="/static/Clock-black.svg"
                      width={16}
                      height={16}
                      alt=""
                    />{" "}
                    <p className="time-btn-text mb-0">
                      {timing?.startTime}-{timing?.endTime}
                    </p>
                  </div>
                )}
              </div>
              <div className="info-container">
                <div className="i-heading-container">
                  <p className="info-title">{detailsData?.title}</p>

                  <div className="d-md-none">
                    {detailsData.isListingBookedByMe && (
                      <Link
                        to={`${ROUTE_CONSTANTS.chat}?id=${detailsData?.id
                          }&name=${detailsData?.title}${detailsData?.chatRoom
                            ? "&showChat=" + detailsData?.chatRoom
                            : ""
                          }`}
                      >
                        <img src="/static/Chat.svg" alt="" />
                      </Link>
                    )}
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="buttons-group">
                    <div
                      className={`listing-btn d-none d-md-flex ${detailsData?.listingType === "P" ? "color_black" : ""
                        }`}
                    >
                      {(detailsData?.listingType === "S" ||
                        detailsData?.listingType === "SPECIAL") && (
                          <img src="/static/Sport-white.svg" alt="" />
                        )}
                      {detailsData?.listingType === LISTING_TYPE.M && (
                        <SessionPackageIcon
                          color="white"
                          width="20px"
                          height="20px"
                        />
                      )}
                      {detailsData?.listingType === LISTING_TYPE.C && (
                        <PeopleCommunityIconH />
                      )}

                      <p className="list-btn-text mb-0 ">
                        {detailsData?.listingType === "S" ||
                          detailsData?.listingType === "SPECIAL"
                          ? "Session"
                          : detailsData?.listingType === "P"
                            ? "Package"
                            : detailsData?.listingType === "C"
                              ? "Team"
                              : ""}
                      </p>
                    </div>

                    {detailsData?.difficultyLevel?.map((d) => (
                      <>
                        {d === "I" ? (
                          <div className="inter-btn">
                            <img src="/static/ribbon-star.svg" alt="" />{" "}
                            <p className="inter-btn-text mb-0">Intermediate</p>
                          </div>
                        ) : d === "B" ? (
                          <div className="inter-btn">
                            <img src="/static/ribbon-gray.svg" alt="" />
                            <p className="inter-btn-text mb-0">Beginner</p>
                          </div>
                        ) : (
                          <div className="inter-btn">
                            <img src="/static/trophy_grey.svg" alt="" />
                            <p className="inter-btn-text mb-0">Advance</p>
                          </div>
                        )}
                      </>
                    ))}
                    {detailsData?.gender?.map((g) => (
                      <>
                        {g === "M" ? (
                          <div className="inter-btn">
                            <GenderNeuter />{" "}
                            <p className="inter-btn-text mb-0">Male</p>
                          </div>
                        ) : g === "F" ? (
                          <div className="inter-btn">
                            <GenderNeuter />
                            <p className="inter-btn-text mb-0">Female</p>
                          </div>
                        ) : (
                          <div className="inter-btn">
                            <GenderNeuter />
                            <p className="inter-btn-text mb-0">
                              Prefer not to say
                            </p>
                          </div>
                        )}
                      </>
                    ))}

                    {detailsData?.minAge && detailsData?.maxAge ? (
                      <div className="inter-btn">
                        <p className="inter-btn-text mb-0">
                          Age: {detailsData?.minAge} to {detailsData?.maxAge}
                        </p>
                      </div>
                    ) : (
                      <div className="inter-btn">
                        <p className="inter-btn-text mb-0">All Ages</p>
                      </div>
                    )}
                    {detailsData?.listingType === "S" ||
                      detailsData?.listingType === "SPECIAL" ? null : (
                      <div className="avatar-participants-container  d-flex">
                        <div className="avatar-container d-flex">
                          <div className="avatar-item">
                            <img src="/static/dummyimg.svg" alt="" />
                          </div>
                          <div className="avatar-item">
                            <img src="/static/dummyimg.svg" alt="" />
                          </div>
                          <div className="avatar-item">
                            <img src="/static/dummyimg.svg" alt="" />
                          </div>
                        </div>
                        <p className="avatar-info mb-0">
                          {detailsData?.teamSlotsAvailable
                            ? detailsData?.teamSlotsAvailable
                            : 0}{" "}
                          Left out of{" "}
                          <span>{detailsData?.maxParticipants} spots</span>
                        </p>
                      </div>
                    )}

                    {detailsData?.createdBy?.id ===
                      getItemFromStore("userProfile")?.id ? (
                      <div
                        style={{
                          marginLeft: "8px",
                          marginTop: "-2px",
                          cursor: "pointer",
                        }}
                        onClick={() => navigate(`/earn-booking/${id}`)}
                      >
                        <img src="/static/check-booking-btn.svg" alt="" />{" "}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="d-none d-md-block">
                    {detailsData.isListingBookedByMe && (
                      <Link
                        to={`${ROUTE_CONSTANTS.chat}?id=${detailsData?.id
                          }&name=${detailsData?.title}${detailsData?.chatRoom
                            ? "&showChat=" + detailsData?.chatRoom
                            : ""
                          }`}
                      >
                        <img src="/static/Chat.svg" alt="" />
                      </Link>
                    )}
                  </div>
                </div>
              </div>

              {detailsData?.listingType === "SPECIAL" && (
                <div className="e-member-only d-md-none">
                  <div className="icon">
                    <ExclusiveMembericon color="#fff" />
                  </div>
                  <p>Exclusive for Members</p>
                </div>
              )}

              <div className="dates-container">
                {detailsData?.listingType === LISTING_TYPE.C ? (
                  ""
                ) : (
                  <div className="dates-button mb-0">
                    <p className="dates-btn-txt d-flex">
                      <p className="mb-0">
                        {detailsData?.listingType === LISTING_TYPE.M
                          ? `Sessions Included (${detailsData?.events?.length} Sessions)`
                          : "Available Dates"}
                      </p>
                    </p>
                    <p
                      className="dates-btn-txt  d-flex align-items-center"
                      onClick={() => handleBookingError()}
                      style={{ cursor: "pointer" }}
                    >
                      View all
                      <span>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.2673 4.20953C12.9674 3.92384 12.4926 3.93539 12.2069 4.23531C11.9213 4.53524 11.9328 5.00997 12.2327 5.29566L18.484 11.2502L3.75 11.2502C3.33579 11.2502 3 11.5859 3 12.0002C3 12.4144 3.33579 12.7502 3.75 12.7502L18.4844 12.7502L12.2327 18.705C11.9328 18.9907 11.9213 19.4654 12.2069 19.7653C12.4926 20.0653 12.9674 20.0768 13.2673 19.7911L20.6862 12.7244C20.8553 12.5634 20.9552 12.358 20.9861 12.1444C20.9952 12.0977 21 12.0495 21 12.0002C21 11.9505 20.9952 11.9019 20.9859 11.8549C20.9548 11.6418 20.8549 11.4369 20.6862 11.2762L13.2673 4.20953Z"
                            fill="#25272D"
                          />
                        </svg>
                      </span>
                    </p>
                  </div>
                )}
                <div className="dates-info-container align-items-start">
                  {(detailsData?.listingType === "S" ||
                    detailsData?.listingType === "SPECIAL") &&
                    (detailsData?.events?.length > 1 ? (
                      detailsData?.events?.slice(0, 3).map((item, i) => {
                        return (
                          <div
                            className={`dates-info  ${selectdate === i + 1 ? "active-date" : ""
                              } `}
                            key={i}
                            style={{ cursor: "pointer" }}
                            onClick={() => setSelectDate(i + 1)}
                          >
                            <div className="dates-info-up-txt mb-1 mx-auto">
                              {moment(item?.date).format("ddd")},{" "}
                              {moment(item?.date).date()}{" "}
                              {moment(item?.date).format("MMMM")}
                            </div>
                            <div className="dates mx-auto">
                              <span className="date-hl ">
                                <svg
                                  width="17"
                                  height="16"
                                  viewBox="0 0 17 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M8.49961 1.6001C12.0342 1.6001 14.8996 4.46548 14.8996 8.0001C14.8996 11.5347 12.0342 14.4001 8.49961 14.4001C4.96499 14.4001 2.09961 11.5347 2.09961 8.0001C2.09961 4.46548 4.96499 1.6001 8.49961 1.6001ZM8.09961 4.0001C7.8787 4.0001 7.69961 4.17918 7.69961 4.4001V8.4001L7.70605 8.472C7.73992 8.6586 7.90324 8.8001 8.09961 8.8001L10.4996 8.8001L10.5715 8.79365C10.7581 8.75978 10.8996 8.59647 10.8996 8.4001C10.8996 8.17918 10.7205 8.0001 10.4996 8.0001L8.49961 8.0001V4.4001L8.49317 4.3282C8.4593 4.1416 8.29598 4.0001 8.09961 4.0001Z"
                                    fill="#8F939A"
                                  />
                                </svg>
                              </span>
                              <p className="date-txt mb-0">
                                {moment(item?.startTime?.substring(0, 5), [
                                  "HH:mm",
                                ]).format("hh:mmA")}{" "}
                                -{" "}
                                {moment(item?.endTime?.substring(0, 5), [
                                  "HH:mm",
                                ]).format("hh:mmA")}
                              </p>
                            </div>
                            {selectdate === i + 1 &&
                              detailsData?.maxParticipants -
                              (detailsData?.maxParticipants -
                                item?.slotsAvailable) ===
                              0 ? (
                              <p className="booking_full mb-0 mx-auto">
                                {detailsData?.maxParticipants -
                                  item?.slotsAvailable}
                                /{detailsData?.maxParticipants} spots *Booking
                                Full*
                              </p>
                            ) : null}
                          </div>
                        );
                      })
                    ) : (
                      <div className="dates-info active-date ">
                        <div className="dates-info-up-txt mb-1 center mx-auto ">
                          {moment(detailsData?.events?.[0]?.date).format("ddd")}
                          , {moment(detailsData?.events?.[0]?.date).date()}{" "}
                          {moment(detailsData?.events?.[0]?.date).format(
                            "MMMM"
                          )}
                        </div>
                        <div className="dates mx-auto">
                          <span className="date-hl ">
                            <svg
                              width="17"
                              height="16"
                              viewBox="0 0 17 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8.49961 1.6001C12.0342 1.6001 14.8996 4.46548 14.8996 8.0001C14.8996 11.5347 12.0342 14.4001 8.49961 14.4001C4.96499 14.4001 2.09961 11.5347 2.09961 8.0001C2.09961 4.46548 4.96499 1.6001 8.49961 1.6001ZM8.09961 4.0001C7.8787 4.0001 7.69961 4.17918 7.69961 4.4001V8.4001L7.70605 8.472C7.73992 8.6586 7.90324 8.8001 8.09961 8.8001L10.4996 8.8001L10.5715 8.79365C10.7581 8.75978 10.8996 8.59647 10.8996 8.4001C10.8996 8.17918 10.7205 8.0001 10.4996 8.0001L8.49961 8.0001V4.4001L8.49317 4.3282C8.4593 4.1416 8.29598 4.0001 8.09961 4.0001Z"
                                fill="#8F939A"
                              />
                            </svg>
                          </span>
                          <p className="date-txt mb-0">
                            {moment(
                              detailsData?.events?.[0]?.startTime?.substring(
                                0,
                                5
                              ),
                              ["HH:mm"]
                            ).format("hh:mmA")}{" "}
                            -{" "}
                            {moment(
                              detailsData?.events?.[0]?.endTime?.substring(
                                0,
                                5
                              ),
                              ["HH:mm"]
                            ).format("hh:mmA")}
                          </p>
                        </div>
                        {selectdate &&
                          detailsData?.maxParticipants -
                          (detailsData?.maxParticipants -
                            detailsData?.events?.slotsAvailable) ===
                          0 ? (
                          <p className="booking_full mb-0">
                            {detailsData?.events?.slotsAvailable}/
                            {detailsData?.maxParticipants} spots *Booking Full*
                          </p>
                        ) : null}
                      </div>
                    ))}

                  {detailsData?.listingType === "P" &&
                    detailsData?.events?.slice(0, 2).map((item, i) => {
                      return (
                        <>
                          <div className="session_p">
                            <div className="sp_title">{item?.title}</div>
                            <div className="sp_address">
                              <div className="d-flex">
                                <LocationGreyIcon />
                              </div>
                              <p className="mb-0">{item?.address?.address}</p>
                            </div>
                            <div className="sp_date_time">
                              <div>
                                <SpDateIcon /> {moment(item?.date).date()}{" "}
                                {moment(item?.date).format("MMM")},{" "}
                                {moment(item?.date).year()}{" "}
                              </div>
                              <div>
                                <SpClockIcon />
                                {moment(item?.startTime?.substring(0, 5), [
                                  "HH:mm",
                                ]).format("hh:mmA")}{" "}
                                -{" "}
                                {moment(item?.endTime?.substring(0, 5), [
                                  "HH:mm",
                                ]).format("hh:mmA")}
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                </div>
              </div>
              {detailsData?.specialInstruction?.length > 0 && (
                <>
                  <p className="l-special">Special Instructions</p>
                  <div className="l-instructions-wrapper">
                    {detailsData?.specialInstruction?.map((item, index) => {
                      return (
                        <div className="l-instruction" key={item + index}>
                          {item}
                        </div>
                      );
                    })}
                  </div>
                </>
              )}
              <div className="review-container">
                <p className="review ">Reviews ({reviewCount})</p>
                <Link to={`review`} className="event-link-title">
                  <p className="view-all mb-0">
                    <p className="mb-0">View all</p>
                    <RightArrowStickIcon />
                  </p>
                </Link>
              </div>

              <div className="user-container">
                <div className="user-image-container">
                  <Link to={`/our-mates/${detailsData?.createdBy?.id}`}>
                    {isProfileImage ? (
                      <img
                        src={
                          detailsData?.createdBy?.profilePicture
                            ? detailsData?.createdBy?.profilePicture
                            : "/static/dummyimg.svg"
                        }
                        alt=""
                      />
                    ) : isProfileVideo ? (
                      <video
                        src={detailsData?.createdBy?.profilePicture}
                        autoPlay
                        playsInline
                        muted
                        loop
                      >
                        <source src={detailsData?.createdBy?.profilePicture} />{" "}
                      </video>
                    ) : (
                      <img src={"/static/dummyimg.svg"} alt="" />
                    )}
                  </Link>
                </div>
                <div className="user-info-container">
                  <p className="user-name-txt mb-0">
                    {detailsData?.listingType === "SPECIAL"
                      ? "Fitness Mates"
                      : detailsData?.createdBy?.name}
                  </p>
                  <div className="user-rating-container">
                    <p className="mb-0">⭐ {rating} stars </p>
                    <span className="user-rate-hl"> ({reviewCount})</span>
                  </div>
                </div>
              </div>

              <div className="desc-container">
                <p className="desc">{detailsData?.description} </p>
              </div>
              {detailsData?.interests?.length > 0 ? (
                <div className="interest-container">
                  <p className="int-txt">Interest Categories</p>
                  <div className="interest-buttons-group">
                    {detailsData?.interests?.map((item, i) => {
                      return (
                        <div key={i} className="interest-btn">
                          <p className="int-txt mb-0">{item?.title}</p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : null}
              <div className="map-container">
                <p className="map-txt">Getting there</p>

                <GoogleMapContainer center={center} />
              </div>
              {!detailsData?.isListingBookedByMe ? (
                <Fragment>
                  <div className="all-button d-md-none ">
                    {detailsData?.isCancellationAllowed ? (
                      <div className="c-cancellation-policy">
                        <p className="c-first">Cancellation Policy</p>
                        <p className="c-second">
                          You can cancel your booking up until{" "}
                          {"{" +
                            detailsData?.cancellationAllowedTillHours +
                            "}"}{" "}
                          hours of your event
                        </p>
                      </div>
                    ) : (
                      <div className="c-cancellation-policy">
                        <p className="c-first">Cancellation Policy</p>
                        <p className="c-second">
                          No cancellation allowed after booking
                        </p>
                      </div>
                    )}
                    <div className="all-button-top">
                      {detailsData?.listingType === "C" ? (
                        <div style={{ width: "40%" }}>
                          <div
                            className="event_btn_price "
                            style={{ display: "block", textAlign: "start" }}
                          >
                            <span
                              style={{ textAlign: "start" }}
                              className="event_price "
                            >
                              ${detailsData?.price}
                            </span>
                          </div>
                          <span className="" style={{ fontSize: "12px" }}>
                            per/month
                          </span>
                        </div>
                      ) : (
                        <div
                          className="event_btn_price"
                          style={{ width: "30%" }}
                        >
                          <span className="event_price">
                            ${detailsData?.price ? detailsData?.price : "0"}
                          </span>

                          {(detailsData?.listingType === "S" ||
                            detailsData?.listingType === "SPECIAL") && (
                              <span className="event_class">
                                {detailsData?.price ? "/class" : "(No Charges)"}
                              </span>
                            )}
                          {detailsData?.listingType === "P" && (
                            <span className="event_class">
                              {detailsData?.price ? "/package" : "(No Charges)"}
                            </span>
                          )}
                        </div>
                      )}

                      <Button
                        btnTxt={`${isDisabled()
                          ? "Booking Full"
                          : detailsData?.listingType === "C"
                            ? "Join Team"
                            : "Book Now"
                          }`}
                        className={"backtologin"}
                        onClick={() => {
                          handleBookingError();
                        }}
                        disabled={isDisabled()}
                      />
                    </div>
                  </div>
                </Fragment>
              ) : (
                <div className="cancel_booking_buttons_wrapper  d-md-none">
                  {detailsData?.isCancellationAllowed ? (
                    <div className="c-cancellation-policy">
                      <p className="c-first">Cancellation Policy</p>
                      <p className="c-second">
                        You can cancel your booking up until{" "}
                        {"{" + detailsData?.cancellationAllowedTillHours + "}"}{" "}
                        hours of your event
                      </p>
                    </div>
                  ) : (
                    <div className="c-cancellation-policy">
                      <p className="c-first">Cancellation Policy</p>
                      <p className="c-second">
                        No cancellation allowed after booking
                      </p>
                    </div>
                  )}
                  <div className="c_b_info">
                    <div className="c_b_icon d-flex">
                      <IFeeIcon width="16" height="16" />
                    </div>
                    <div className="c_b_txt">
                      You’re all set! You’ve booked this event. For any further
                      bookings or cancellations please tap below.
                    </div>
                  </div>

                  <Button
                    btnTxt={`${detailsData?.teamSlotsAvailable === 0
                      ? "Booking Full"
                      : detailsData?.listingType !== LISTING_TYPE.C
                        ? "Book Another Events"
                        : "Reserve More Spots"
                      }`}
                    className={"backtologin"}
                    onClick={() => {
                      handleBookingError();
                    }}
                    disabled={isDisabled()}
                  />
                  {detailsData?.listingType !== LISTING_TYPE.C && (
                    <Button
                      btnTxt={`${handleWindowClosed()
                        ? "Cancel window closed"
                        : " Cancel Booked Events"
                        }`}
                      className={"c_b_cancel"}
                      onClick={() => {
                        handleCancel();
                      }}
                      disabled={handleWindowClosed()}
                    />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {showDateModal && (
        <EventDateModal
          second={second}
          show={showDateModal}
          onHide={() => {
            setShowDateModal(false);
            if (second) {
              setSecond(false);
            }
          }}
          eventsArray={detailsData?.events}
          maxParticipants={detailsData?.maxParticipants}
          setShowScheduleModal={setShowScheduleModal}
          selectedEvents={selectedEvents}
          detailsData={detailsData}
          setSelDates={setSelDates}
          selDates={selDates}
          isDisabled={isDisabled}
          setOpenMap={setOpenMap}
          setEventCenter={setEventCenter}
        />
      )}

      {showAllDateModal && (
        <EventDateListing
          second={second}
          show={showAllDateModal}
          onHide={() => {
            setAllShowDateModal(false);
            if (second) {
              setSecond(false);
            }
          }}
          eventsArray={detailsData?.events}
          maxParticipants={detailsData?.maxParticipants}
          selectedEvents={selectedEvents}
          detailsData={detailsData}
        />
      )}

      {showExclusivePopup && (
        <MemberExclusivePopup
          show={showExclusivePopup}
          onHide={() => {
            setShowExclusivePopup(false);
          }}
        />
      )}

      {showScheduleModal && (
        <ScheduleDatesPopup
          show={showScheduleModal}
          onHide={() => {
            setShowScheduleModal(false);
          }}
          detailsData={detailsData}
          setSecond={setSecond}
          setSelectedEvents={setSelectedEvents}
          setShowDateModal={setShowDateModal}
          setSelDates={setSelDates}
          selDates={selDates}
        />
      )}

      {/* <SecondEventPopup show={second} onHide={() => setSecond(false)} eventsArray={detailsData?.events} maxParticipants={detailsData?.maxParticipants} selectedEvents={selectedEvents} /> */}

      <CancelBookingPopup
        show={showCancelPopup}
        detailsData={detailsData}
        onHide={() => {
          setShowCancelPopup(false);
        }}
        setShowCancelReason={setShowCancelReason}
        listing_id={id}
        cancelBookingEvents={cancelBookingEvents}
        cancelEvents={cancelEvents}
      />

      <CancelReasonPopup
        show={showCancelReason}
        onHide={() => {
          setShowCancelReason(false);
        }}
        listing_id={id}
        cancelBookingEvents={cancelBookingEvents}
        detailsData={detailsData}
        cancelEvents={cancelEvents}
        setRefresh={setRefresh}
      />
      {showBookingError && (
        <BookingErrorPopup
          show={showBookingError}
          onHide={() => {
            setShowBookingError(false);
            setAgeError(null);
          }}
          gender={detailsData?.gender}
          ageError={ageError}
          setAgeError={setAgeError}
        />
      )}

      {showFlagListingPopup && (
        <FlagListingPopup
          show={showFlagListingPopup}
          onHide={() => {
            setShowFlagListingPopup(false);
          }}
          listing_id={id}
          type={detailsData?.listingType || "S"}
        />
      )}

      {openMap ? (
        <div className="fixed-sidebar">
          <div
            className="sidebar-box connect-search-user "
            style={{ padding: "0" }}
          >
            <div
              style={{
                position: "absolute",
                top: "20px",
                zIndex: "20",
                paddingLeft: "20px",
                display: "inline-flex",
                gap: "12px",
                alignItems: "center",
                fontSize: "20px",
              }}
            >
              <img
                src="/static/cross-black.svg"
                onClick={() => {
                  setOpenMap(false);
                }}
                className="close-btn"
                alt=""
              />
              <span>Map View</span>
            </div>
            <div
              className="inner-contet"
              style={{
                marginTop: "0",
                maxWidth: "877px",
                position: "relative",
              }}
            >
              <OpenMapContainer eventCenter={eventCenter} />
            </div>
          </div>
        </div>
      ) : null}

      {showCancelByUser &&
        detailsData?.listingType !== "SPECIAL" &&
        detailsData?.isPopupToBeDisplayed === null &&
        showCancelByAdmin === null &&
        showRemoveByAdmin === null ? (
        <div className="modal-cancel-event show">
          <div className="modal-section">
            <div className="modal-top-part">
              <h5>Event Cancelled</h5>
              <div className="info-box-wrap">
                <div className="info_box" style={{ boxShadow: "none" }}>
                  <img src="/static/greyinfo.svg" alt="" /> The event has been
                  cancelled, and the refund for the cancelled event will be
                  processed within 7-14 working days. You can check the refund
                  status from cancelled events section under my bookings page.
                </div>
              </div>
            </div>
            <Button
              btnTxt="See all cancelled events"
              className={"backtologin"}
              onClick={() =>
                navigate(ROUTE_CONSTANTS.my_booking + "?activeTab=Cancelled")
              }
            />
            <Button
              btnTxt="Go back"
              className={"goback backtologin"}
              onClick={() => setShowCancelByUser(false)}
            />
            <div className="terms">
              <span>Please read the </span>
              <span
                className="yellow-text"
                onClick={() => navigate(ROUTE_CONSTANTS.policies)}
                style={{ cursor: "pointer" }}
              >
                t&C for cancellation terms{" "}
              </span>
            </div>
          </div>
        </div>
      ) : null}

      {showCancelByAdmin === "L" &&
        detailsData?.listingType !== "SPECIAL" &&
        showCancelListingPopup === false &&
        detailsData?.isPopupToBeDisplayed === null ? (
        <div className="modal-cancel-event show">
          <div className="modal-section">
            <div className="modal-top-part">
              <h5>Event Cancelled</h5>
              <div className="info-box-wrap">
                <div className="info_box" style={{ boxShadow: "none" }}>
                  <img src="/static/greyinfo.svg" alt="" /> The event has been
                  cancelled, and the refund for the cancelled event will be
                  processed within 7-14 working days. You can check the refund
                  status from cancelled events section under my bookings page.
                </div>
              </div>
            </div>
            <Button
              btnTxt="See all cancelled events"
              className={"backtologin"}
              onClick={() =>
                navigate(ROUTE_CONSTANTS.my_booking + "?activeTab=Cancelled")
              }
            />
            <Button
              btnTxt={`${detailsData?.createdBy?.id ===
                getItemFromStore("userProfile")?.id
                ? "ok"
                : "Go back"
                }`}
              className={"goback backtologin"}
              onClick={() => {
                if (
                  detailsData?.createdBy?.id ===
                  getItemFromStore("userProfile")?.id
                ) {
                  setShowCancelListingPopup(true);
                } else {
                  navigate(ROUTE_CONSTANTS.my_booking);
                }
              }}
            />
            <div className="terms">
              <span>Please read the </span>
              <span
                className="yellow-text"
                onClick={() => navigate(ROUTE_CONSTANTS.policies)}
                style={{ cursor: "pointer" }}
              >
                t&C for cancellation terms{" "}
              </span>
            </div>
          </div>
        </div>
      ) : null}

      {showCancelByAdmin === "A" &&
        detailsData?.listingType !== "SPECIAL" &&
        detailsData?.isPopupToBeDisplayed === null ? (
        <div className="modal-cancel-event show">
          <div className="modal-section">
            <div className="modal-top-part">
              <h5>Event Cancelled by admin</h5>
              <div className="info-box-wrap">
                <div className="info_box" style={{ boxShadow: "none" }}>
                  <img src="/static/greyinfo.svg" alt="" /> The event has been
                  cancelled by the admin, and the refund for the cancelled event
                  will be processed within 7-14 working days. You can check the
                  refund status from cancelled events section under my bookings
                  page.
                </div>
              </div>
            </div>

            <Button
              btnTxt="See all cancelled events"
              className={"backtologin"}
              onClick={() =>
                navigate(ROUTE_CONSTANTS.my_booking + "?activeTab=Cancelled")
              }
            />
            <Button
              btnTxt="Go back"
              className={"goback backtologin"}
              onClick={() => {
                if (
                  detailsData?.createdBy?.id ===
                  getItemFromStore("userProfile")?.id
                ) {
                  setShowCancelListingPopup(true);
                } else {
                  navigate(ROUTE_CONSTANTS.my_booking);
                }
              }}
            />
            <div className="terms">
              <span>Please read the </span>
              <span
                className="yellow-text"
                onClick={() => navigate(ROUTE_CONSTANTS.policies)}
                style={{ cursor: "pointer" }}
              >
                t&C for cancellation terms{" "}
              </span>
            </div>
          </div>
        </div>
      ) : null}
      {detailsData?.listingType === "SPECIAL" &&
        detailsData?.canBookSpecialListing === false &&
        detailsData?.isPopupToBeDisplayed === null ? (
        <div className="modal-cancel-event show">
          <div className="modal-section">
            <div className="modal-top-part">
              <h5>Admin removed you</h5>
              <div className="info-box-wrap">
                <div className="info_box" style={{ boxShadow: "none" }}>
                  <img src="/static/greyinfo.svg" alt="" />
                  It seems like the admin has removed you, so you won't be part
                  of the event any more. The refund for the cancelled event will
                  be processed within 7-14 working days. To track the refund
                  status, please check the cancelled events section on the "My
                  Bookings" page.
                </div>
              </div>
            </div>

            <Button
              btnTxt="See all cancelled events"
              className={"backtologin"}
              onClick={() =>
                navigate(ROUTE_CONSTANTS.my_booking + "?activeTab=Cancelled")
              }
            />
            <Button
              btnTxt="Go back"
              className={"goback backtologin"}
              onClick={() => {
                if (
                  detailsData?.createdBy?.id ===
                  getItemFromStore("userProfile")?.id
                ) {
                  navigate(ROUTE_CONSTANTS.view_listing);
                } else {
                  navigate(ROUTE_CONSTANTS.my_booking);
                }
              }}
            />
            <div className="terms">
              <span>Please read the </span>
              <span
                className="yellow-text"
                onClick={() => navigate(ROUTE_CONSTANTS.policies)}
                style={{ cursor: "pointer" }}
              >
                t&C for cancellation terms{" "}
              </span>
            </div>
          </div>
        </div>
      ) : null}

      {showInactivePopup ? (
        <div className="modal-cancel-event show">
          <div className="modal-section" style={{ gap: "20px" }}>
            <div className="modal-top-part">
              <button className="close">
                <img
                  src="/static/dismiss-cross-round-black.svg"
                  alt=""
                  onClick={() => setShowInactivePopup(false)}
                />
              </button>

              <h5>Are You Sure</h5>
              <p>
                Once the listing is inactivated we won’t be able to recover the
                information.
              </p>
            </div>

            <Button
              btnTxt="Yes, Inactive"
              className={"backtologin"}
              onClick={() => makeInactiveList()}
            />
            <Button
              btnTxt="Go back"
              className={"goback backtologin"}
              onClick={() => setShowInactivePopup(false)}
            />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ListingDetail;
