// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.meeting_link_container {
  margin: 12px 0px 24px 0px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%; }
  .meeting_link_container .header_text {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left; }
  .meeting_link_container .button_container {
    width: 100%;
    display: flex;
    gap: 12px; }
    .meeting_link_container .button_container .button_wrap {
      min-width: 90px;
      text-align: center;
      padding: 7px 12px;
      gap: 6px;
      border-radius: 16px;
      border: 1px solid #8f939a;
      font-family: Poppins;
      font-size: 12px;
      font-weight: 500;
      line-height: 16px;
      color: #25272d;
      cursor: pointer; }
      .meeting_link_container .button_container .button_wrap:hover, .meeting_link_container .button_container .button_wrap.selected {
        border: 1px solid #ee7830;
        color: #ffffff;
        background: #ee7830; }
  .meeting_link_container .meeting_link_form_wrap {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 12px; }
    .meeting_link_container .meeting_link_form_wrap .form_group {
      margin-bottom: 0; }
  .meeting_link_container .heading_2 {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: #000000; }
`, "",{"version":3,"sources":["webpack://./src/components/ListingComponents/MeetingLink/style.scss"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,aAAa;EACb,sBAAsB;EACtB,SAAS;EACT,WAAW,EAAA;EALb;IAQI,oBAAoB;IACpB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB,EAAA;EAZpB;IAgBI,WAAW;IACX,aAAa;IACb,SAAS,EAAA;IAlBb;MAqBM,eAAe;MACf,kBAAkB;MAClB,iBAAiB;MACjB,QAAQ;MACR,mBAAmB;MACnB,yBAAyB;MACzB,oBAAoB;MACpB,eAAe;MACf,gBAAgB;MAChB,iBAAiB;MACjB,cAAc;MACd,eAAe,EAAA;MAhCrB;QAoCQ,yBAAyB;QACzB,cAAc;QACd,mBAAmB,EAAA;EAtC3B;IAgDI,aAAa;IACb,sBAAsB;IACtB,WAAW;IACX,SAAS,EAAA;IAnDb;MA6CM,gBAAgB,EAAA;EA7CtB;IAuDI,oBAAoB;IACpB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB;IAChB,cAAc,EAAA","sourcesContent":[".meeting_link_container {\n  margin: 12px 0px 24px 0px;\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n  width: 100%;\n\n  .header_text {\n    font-family: Poppins;\n    font-size: 14px;\n    font-weight: 500;\n    line-height: 20px;\n    text-align: left;\n  }\n\n  .button_container {\n    width: 100%;\n    display: flex;\n    gap: 12px;\n\n    .button_wrap {\n      min-width: 90px;\n      text-align: center;\n      padding: 7px 12px;\n      gap: 6px;\n      border-radius: 16px;\n      border: 1px solid #8f939a;\n      font-family: Poppins;\n      font-size: 12px;\n      font-weight: 500;\n      line-height: 16px;\n      color: #25272d;\n      cursor: pointer;\n\n      &:hover,\n      &.selected {\n        border: 1px solid #ee7830;\n        color: #ffffff;\n        background: #ee7830;\n      }\n    }\n  }\n\n  .meeting_link_form_wrap {\n    .form_group {\n      margin-bottom: 0;\n    }\n\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n    gap: 12px;\n  }\n\n  .heading_2 {\n    font-family: Poppins;\n    font-size: 12px;\n    font-weight: 400;\n    line-height: 16px;\n    text-align: left;\n    color: #000000;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
