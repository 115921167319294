// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader_wrapper {
  width: 100%;
  height: 100%;
  min-width: 100dvw;
  min-height: 100dvh;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white; }
  .loader_wrapper .inner_wrap {
    display: flex; }
    .loader_wrapper .inner_wrap .spinner_img {
      width: 40px;
      height: 40px; }
`, "",{"version":3,"sources":["webpack://./src/components/LoadingSplash/style.scss"],"names":[],"mappings":"AAAA;EAEI,WAAW;EACX,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,UAAU;EACV,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,iBAA8B,EAAA;EAVlC;IAaQ,aAAa,EAAA;IAbrB;MAgBY,WAAW;MACX,YAAY,EAAA","sourcesContent":[".loader_wrapper {\n\n    width: 100%;\n    height: 100%;\n    min-width: 100dvw;\n    min-height: 100dvh;\n    z-index: 1;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background: rgb(255, 255, 255);\n\n    .inner_wrap {\n        display: flex;\n\n        .spinner_img {\n            width: 40px;\n            height: 40px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
