import Button from "./Button";
import NameAge from "./NameAge";
import { useContext, useState } from "react";
import {
  makeDeleteCall,
  makePatchCall,
  makePostCall,
} from "../../../utils/Requests";
import {
  CONNCECTION_STATUS_CHOICES,
  CONNECTIONS_STATUS,
} from "../ConnectConstants";
import { showToast } from "../../../utils";
import Favourites from "./Favourite";
import IconPlus from "../../../assets/img/connectIcons/plus-circle.svg";
import IconArrowDown from "../../../assets/img/connectIcons/arrow-down.svg";
// import { connectContext } from "./ConectWrapper";
import { useNavigate } from "react-router-dom";
import { gettingDistance } from "../../../constants/helperConnectUser";

const ShortUserCard = ({ connectionData, mode = "flag" }) => {
  const navigate = useNavigate();
  const [isBtnClicked, setIsBtnClicked] = useState({
    accepted: false,
    rejected: false,
  });
  // const { setIsProfileUpdated } = useContext(connectContext);
  const [isMarkedFavourite, setIsFavouriteClicked] = useState(false);
  const [buttonTitle, setButtonTitle] = useState(CONNECTIONS_STATUS.CONNECT);
  const [connectionStatus, setConnectionStatus] = useState(
    connectionData?.connectionStatus?.status
  );
  const [connectionDetail, setConnectionDetail] = useState<any>({});
  const [showRemoveConfirmationModal, setShowRemoveConfirmationModal] =
    useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [connectionStatusId, setConnectionStatusId] = useState("");

  const handleConnectClick = (connectId: any) => {
    makePostCall({
      url: `auth/${connectId}/connections/`,
    })
      .then((res) => {
        if (res.status.code === 200) {
          if (res.data?.status === CONNCECTION_STATUS_CHOICES.PENDING) {
            showToast(res.status.message, "success");
            setConnectionStatus(CONNECTIONS_STATUS.PENDING);
            setButtonTitle(CONNECTIONS_STATUS.PENDING);
          }
        } else {
          showToast(res.status.message || res.error.message, "error");
        }
      })
      .catch((err) => {
        showToast(err, "error");
      });
  };

  const handleRejectClick = (connectId: any) => {
    makeDeleteCall({
      url: `auth/connections/${connectId}/`,
    })
      .then((res) => {
        if (res.status.code === 200) {
          showToast(res.status.message, "success");
          setButtonTitle(CONNECTIONS_STATUS.CONNECT);
          setConnectionStatus(CONNECTIONS_STATUS.CONNECT);
          setIsBtnClicked((prev) => ({ ...prev, rejected: true }));
          // setIsProfileUpdated((prev) => !prev);
        } else {
          showToast(res.status.message || res.error.message, "error");
        }
      })
      .catch((err) => {
        showToast(err, "error");
      });
  };

  const handlePendingClick = (
    connectionId = connectionStatusId,
    isConfirmed = false
  ) => {
    if (!isConfirmed) {
      setConnectionStatusId(connectionId);
      setShowConfirmationModal(true);
    } else {
      setShowConfirmationModal(false);
      makeDeleteCall({
        url: `auth/connections/${connectionStatusId}/`,
      })
        .then((res) => {
          if (res.status.code === 200) {
            showToast(res.status.message, "success");
            setButtonTitle(CONNECTIONS_STATUS.CONNECT);
            setConnectionStatus(CONNECTIONS_STATUS.CONNECT);
          } else {
            showToast(res.status.message || res.error.message, "error");
          }
        })
        .catch((err) => {
          showToast(err, "error");
        });
    }
  };
  const handleRemoveClick = (connectId: any, isConfirmed = false) => {
    if (!isConfirmed) {
      setConnectionStatusId(connectId);
      setShowRemoveConfirmationModal(true);
    } else {
      setShowRemoveConfirmationModal(false);
      makeDeleteCall({
        url: `auth/connections/${connectionStatusId}/`,
      })
        .then((res) => {
          if (res.status.code === 200) {
            showToast("Connection Removed Successfully", "success");
            setButtonTitle(CONNECTIONS_STATUS.CONNECT);
            setConnectionStatus(CONNECTIONS_STATUS.CONNECT);
          } else {
            showToast(res.status.message || res.error.message, "error");
          }
        })
        .catch((err) => {
          showToast(err, "error");
        });
    }
  };
  const handleAcceptClick = (connectId: any) => {
    makePatchCall({
      url: `auth/connections/${connectId}/`,
      apiPayload: { status: "A" },
    })
      .then((res) => {
        if (res.status.code === 200) {
          showToast(res.status.message, "success");
          setIsBtnClicked((prev) => ({ ...prev, accepted: true }));
          setButtonTitle(CONNECTIONS_STATUS.CONNECTED);
          setConnectionStatus(CONNECTIONS_STATUS.CONNECTED);
          // setIsProfileUpdated((prev) => !prev);
        } else {
          showToast(res.status.message || res.error.message, "error");
        }
      })
      .catch((err) => {
        showToast(err, "error");
      });
  };
  return (
    <div
      className="short-card-wrapper"
      onClick={() => navigate(`/our-mates/${connectionData?.id}`)}
    >
      <div className="user-detail-wraper">
        <div className="user-img-wrapper">
          {connectionData?.profilePictureExtension?.includes("video") ? (
            <video
              autoPlay
              playsInline
              loop
              muted
              src={connectionData?.profilePicture}
              className="profile-img-style vid"
            >
              <source src={"" || ""} type="video/mp4" />
            </video>
          ) : (
            <img
              src={
                connectionData?.profilePicture
                  ? connectionData.profilePicture
                  : "/static/dummyimg.svg"
              }
              className="profile-img-style"
              alt="profile-img"
            />
          )}
        </div>
        <div className="name-interest-wraper">
          <NameAge
            connectionName={connectionData?.name}
            connectionAge={connectionData?.age}
          />
          <div className="text-style">
            <span>{`${
              connectionData?.interestsCount
                ? connectionData?.interestsCount
                : 0
            } mutual interests`}</span>
            <span>&#8226;</span>
            <span>
              {connectionData?.distance === null
                ? "Location not available"
                : gettingDistance(connectionData?.distance || 0)}
            </span>
          </div>
        </div>
      </div>

      {mode === "request" &&
        !isBtnClicked.accepted &&
        !isBtnClicked.rejected && (
          <div className="request-btn-wrapper" style={{ gap: "12px" }}>
            {/* <Favourites
              statusFav={connectionData.isMarkedFavourite}
              userId={connectionData.id}
              setIsFavouriteClicked={setIsFavouriteClicked}
              isAcceptRejct={true}
            /> */}

            <Button
              title="Reject"
              className="reject-btn-class"
              handleBtnClick={() =>
                handleRejectClick(
                  connectionData?.connectionStatus?.connectionId
                )
              }
            />

            <Button
              title="Accept"
              className="accept-btn-class"
              handleBtnClick={() =>
                handleAcceptClick(
                  connectionData?.connectionStatus?.connectionId
                )
              }
            />
          </div>
        )}

      {(isBtnClicked.accepted || isBtnClicked.rejected) && (
        <div
          className="request-btn-wrapper"
          style={{
            gap: "12px",
          }}
        >
          <Favourites
            statusFav={connectionData.isMarkedFavourite}
            userId={connectionData.id}
            setIsFavouriteClicked={setIsFavouriteClicked}
          />
          <Button
            className="connect-btn-class"
            title={buttonTitle}
            handleBtnClick={() => {
              setConnectionDetail(connectionData);
              if (
                isBtnClicked.rejected ||
                buttonTitle === CONNECTIONS_STATUS.CONNECT
              ) {
                handleConnectClick(connectionData.id);
              }
              if (
                isBtnClicked.accepted &&
                buttonTitle === CONNECTIONS_STATUS.CONNECTED
              ) {
                // setConnectionDetail(connectionData);
                handleRemoveClick(connectionData.connectionStatus.connectionId);
              }
              if (buttonTitle === CONNECTIONS_STATUS.PENDING) {
                handlePendingClick(
                  connectionData.connectionStatus.connectionId
                );
              }
            }}
            iconFront={
              buttonTitle === CONNECTIONS_STATUS.CONNECT ? IconPlus : false
            }
            iconBack={
              buttonTitle === CONNECTIONS_STATUS.CONNECTED
                ? IconArrowDown
                : false
            }
          />
        </div>
      )}
      {showConfirmationModal && (
        <div className="modal show">
          <div
            style={{
              maxWidth: "373px",
              maxHeight: "410px",
              width: "100%",
              // maxWidth: "619px",
              background: "#ffffff",
              borderRadius: "12px",
              paddingInline: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "27px",
              }}
            >
              <div>
                {connectionDetail?.profilePictureExtension?.includes(
                  "video"
                ) ? (
                  <video
                    autoPlay
                    playsInline
                    loop
                    muted
                    src={connectionDetail?.profilePicture}
                    // className="profile-img-style vid"
                    style={{
                      borderRadius: "100%",
                      objectFit: "cover",
                      border: "4px solid white",
                      height: "72px",
                      width: "72px",
                      boxShadow: "0px 4px 20px 0px #0000001f",
                    }}
                  >
                    <source src={"" || ""} type="video/mp4" />
                  </video>
                ) : (
                  <img
                    src={
                      connectionDetail?.profilePicture
                        ? connectionDetail.profilePicture
                        : "/static/dummyimg.svg"
                    }
                    // className="profile-img-style"
                    style={{
                      borderRadius: "100%",
                      objectFit: "cover",
                      border: "4px solid white",
                      height: "72px",
                      width: "72px",
                      boxShadow: "0px 4px 20px 0px #0000001f",
                    }}
                    alt="profile-img"
                  />
                )}
              </div>
            </div>
            <div
              style={{
                marginTop: "20px",
                textAlign: "center",
                fontSize: "20px",
                fontWeight: "700",
                lineHeight: "24px",
                fontFamily: "Poppins",
              }}
            >
              Request cancel !
            </div>
            <div
              style={{
                marginTop: "16px",
                textAlign: "center",
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "24px",
                color: "#6C6D6F",
                fontFamily: "Poppins",
                textWrap: "balance",
              }}
            >
              {`Are you sure you want to cancel this request for ${connectionDetail.name} ?`}
            </div>
            {/* <div
            style={{
              textAlign: "center",
              fontSize: "14px",
              fontWeight: "400px",
              lineHeight: "24px",
              color: "#6C6D6F",
              fontFamily: "Poppins",
            }}
          >
            from your connection?
          </div> */}
            {/* <div style={{ display: "flex", justifyContent: "space-between" }}> */}
            <div style={{ marginTop: "24px" }}>
              <Button
                className="confirm-btn-class"
                title="Confirm"
                handleBtnClick={() =>
                  handlePendingClick(connectionStatusId, true)
                }
              />
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "14px",
              }}
            >
              <button
                onClick={() => setShowConfirmationModal(false)}
                style={{
                  border: "none",
                  backgroundColor: "white",
                  fontSize: "18px",
                  fontWeight: "500",
                  paddingTop: "16px",
                  fontFamily: "Poppins",
                  lineHeight: "24px",
                  paddingBottom: "16px",
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
      {showRemoveConfirmationModal && (
        <div className="modal show">
          <div
            style={{
              maxWidth: "373px",
              maxHeight: "410px",
              width: "100%",
              // maxWidth: "619px",
              background: "#ffffff",
              borderRadius: "12px",
              paddingInline: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "27px",
              }}
            >
              <div>
                {connectionDetail?.profilePictureExtension?.includes(
                  "video"
                ) ? (
                  <video
                    autoPlay
                    playsInline
                    loop
                    muted
                    src={connectionDetail?.profilePicture}
                    // className="profile-img-style vid"
                    style={{
                      borderRadius: "100%",
                      objectFit: "cover",
                      border: "4px solid white",
                      height: "72px",
                      width: "72px",
                      boxShadow: "0px 4px 20px 0px #0000001f",
                    }}
                  >
                    <source src={"" || ""} type="video/mp4" />
                  </video>
                ) : (
                  <img
                    src={
                      connectionDetail?.profilePicture
                        ? connectionDetail.profilePicture
                        : "/static/dummyimg.svg"
                    }
                    // className="profile-img-style"
                    style={{
                      borderRadius: "100%",
                      objectFit: "cover",
                      border: "4px solid white",
                      height: "72px",
                      width: "72px",
                      boxShadow: "0px 4px 20px 0px #0000001f",
                    }}
                    alt="profile-img"
                  />
                )}
              </div>
            </div>
            <div
              style={{
                marginTop: "20px",
                textAlign: "center",
                fontSize: "20px",
                fontWeight: "700",
                lineHeight: "24px",
                fontFamily: "Poppins",
              }}
            >
              Remove connection
            </div>
            <div
              style={{
                marginTop: "16px",
                textAlign: "center",
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "24px",
                color: "#6C6D6F",
                fontFamily: "Poppins",
              }}
            >
              {`Are you sure you want to remove ${connectionDetail.name} from your connection?`}
            </div>
            {/* <div
              style={{
                textAlign: "center",
                fontSize: "14px",
                fontWeight: "400px",
                lineHeight: "24px",
                color: "#6C6D6F",
                fontFamily: "Poppins",
              }}
            >
              from your connection?
            </div> */}
            {/* <div style={{ display: "flex", justifyContent: "space-between" }}> */}
            <div style={{ marginTop: "24px" }}>
              <Button
                className="confirm-btn-class"
                title="Confirm"
                handleBtnClick={() =>
                  handleRemoveClick(connectionStatusId, true)
                }
              />
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "14px",
              }}
            >
              <button
                onClick={() => setShowRemoveConfirmationModal(false)}
                style={{
                  border: "none",
                  backgroundColor: "white",
                  fontSize: "18px",
                  fontWeight: "500",
                  paddingTop: "16px",
                  fontFamily: "Poppins",
                  lineHeight: "24px",
                  paddingBottom: "16px",
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ShortUserCard;
