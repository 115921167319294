import { Route, Routes } from "react-router-dom";
import ConnectZone from "./ConnectZone";
import ConnectFlagged from "./ConnectFlagged";
import ConnectRequests from "./ConnectRequests";
import ConnectConnections from "./ConnectConnections";
import ConnectWrapper from "./ConnectComponents/ConectWrapper";
import ConnectSuggestions from "./ConnectSuggestions";
import ConnectFavourites from "./ConnectFavourites";

const Connect = () => {
  return (
    <ConnectWrapper>
      <Routes>
        <Route path="/connect" element={<ConnectZone />} />
        <Route path="/connect/connections" element={<ConnectConnections />} />
        <Route path="/connect/requests" element={<ConnectRequests />} />
        <Route path="/connect/flagged" element={<ConnectFlagged />} />
        <Route path="/connect/suggestions" element={<ConnectSuggestions />} />
        <Route path="/connect/favourite" element={<ConnectFavourites />} />
      </Routes>
    </ConnectWrapper>
  );
};

export default Connect;
