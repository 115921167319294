const ComingBtn = ({
  onClick = () => {},
  text = "Download on App Store",
  className = "",
  icon,
  ...props
}) => {
  return (
    <button
      {...props}
      className={`comming-btn-wrap ${className}`}
      onClick={onClick}
    >
      <span>{icon}</span>
      {text}
      <span className="coming-soon">Coming Soon</span>
    </button>
  );
};

export default ComingBtn;
