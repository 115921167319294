import React, { useEffect, useState } from "react";
import CardView from "../../assets/img/icons/CardView";
import ListView from "../../assets/img/icons/ListView";
import Button from "../../global/Button";
import {
  getItemFromStore,
  removeLocalStorage,
  setLocalStorage,
  showToast,
} from "../../utils";
import useDebounce from "../../utils/useDebounce";
import { makeGetCall, makePatchCall } from "../../utils/Requests";
import apiConfig from "../../utils/endpoints";
import { STRING_CONSTANTS } from "../../utils/StringConstant";
import { ROUTE_CONSTANTS } from "../../utils/RouteConstants";
import { useNavigate } from "react-router-dom";
import AddInterestPopup from "./AddInterestPopup";
import LoadingLayer from "../ConnectUserProfile/LoaderLayer";

type Props = {
  [key: string]: any;
};

const Interest = (props: Props) => {
  const { handleInterestSelectContinue } = props;
  const userData = getItemFromStore("userData")?.interest || [];
  const [isLoading, setIsLoading] = useState<any>(false);
  const [addInterestPopup, setAddInterestPopup] = useState<any>(false);
  const [searchData, setSearchData] = useState("");
  const navigate = useNavigate();

  const debouncedSearchTerm = useDebounce(searchData, 500);
  const [selectedInterest, setSelectedInterest] = useState<any>(userData);

  const [isListView, setIsListView] = useState(false);
  const [filter, setFilter] = useState("A");
  const [interestList, setInterestList] = useState([]);

  const handleNext = () => {
    if (!selectedInterest?.length) {
      showToast(STRING_CONSTANTS.select_atleast_one_intrest, "error");
    } else {
      setIsLoading(true);
      let apiPayload: any = {
        interest: selectedInterest.map((item: any) => item.id),
      };
      // if (getItemFromStore("userData")?.isTrainer === false) {
      apiPayload.isProfileCompleted = true;
      // }
      makePatchCall({
        url: apiConfig.profile,
        apiPayload,
      })
        .then((res) => {
          if (res.status.code === 200) {
            setIsLoading(false);

            showToast(res.status.message, "success");
            if (getItemFromStore("userData")?.isTrainer) {
              handleInterestSelectContinue();
              setLocalStorage("userData", {
                ...res.data?.userDetail,
                ...res.data?.businessDetail,
                ...res.data?.trainerData,
              });
            } else {
              removeLocalStorage("userData");
              setLocalStorage("isProfileComplete", true);
              navigate(ROUTE_CONSTANTS.events);
            }
          } else {
            showToast(STRING_CONSTANTS.wrong_data_message, "error");
            setIsLoading(false);
          }
        })
        .catch((err) => {
          showToast(err, "error");
          setIsLoading(false);
        });
    }
  };

  const getInterestList = () => {
    setIsLoading(true);

    let params = { want_paginated: false };

    if (filter === "F") {
      params["featured"] = true;
    }

    if (searchData) {
      params["search"] = searchData;
    }

    makeGetCall({ url: apiConfig.interest_list, params })
      .then((res) => {
        setInterestList(res?.data);

        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        showToast(err, "error");
      });
  };

  useEffect(() => {
    getInterestList();
  }, [debouncedSearchTerm, filter]);

  const handleFilterData = (type = "all") => {
    setFilter(type);
  };

  const handleIntrest = (intrest: any) => {
    let index = selectedInterest.findIndex(
      (intr: any) => intr.id === intrest.id
    );
    if (index > -1) {
      selectedInterest.splice(index, 1);
    } else {
      selectedInterest.push(intrest);
    }

    setSelectedInterest([...selectedInterest]);
  };

  const callBack = () => {
    setFilter("A");
    setSearchData("");

    getInterestList();
  };
  const ListViewData = () => {
    return (
      <div className="interest_list_wrapper">
        {interestList?.map((val) => (
          <div
            className={`interest_list ${
              selectedInterest.some((intr: any) => intr.id === val.id)
                ? "selected"
                : ""
            }`}
            onClick={() => {
              handleIntrest(val);
            }}
          >
            <div>
              <img
                className="list_image"
                src={val?.icon || "/static/handball.svg"}
                alt=""
              />
            </div>
            <div className="list_title">{val?.title || ""}</div>
          </div>
        ))}
      </div>
    );
  };
  const CardViewData = () => {
    return (
      <div className="interest_data_wrapper">
        {interestList?.map((val) => (
          <div
            className={`interest_cards ${
              selectedInterest.some((intr: any) => intr.id === val.id)
                ? "selected"
                : ""
            }`}
            onClick={() => {
              handleIntrest(val);
            }}
          >
            <div>
              <img
                className="card_image"
                src={val?.image || "/static/dance.png"}
                alt=""
              />
            </div>
            <div className="card_title">{val?.title || ""}</div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="bi_wrapper">
      {isLoading && <LoadingLayer />}
      <div className="bi_container">
        <div className="search_header">
          <div className="search_input_container">
            <div className="search_input_wrapper">
              <div className="search_label">Search Interests</div>
              <input
                className="input_search_text"
                type="text"
                value={searchData}
                placeholder="Search your interests"
                onChange={(e) => {
                  setSearchData(e.target.value);
                }}
              />
              {searchData && (
                <img
                  src="/static/cross-circle-gray-dark.svg"
                  className="clear_data"
                  alt=""
                  onClick={() => setSearchData("")}
                />
              )}
            </div>
          </div>
          <div className="search_view">
            <CardView
              active={!isListView}
              className="cursor-pointer"
              onClick={() => setIsListView(false)}
            />
            <ListView
              active={isListView}
              className="cursor-pointer"
              onClick={() => setIsListView(true)}
            />
          </div>
        </div>
        <div className="filter_container">
          <Button
            btnTxt="All"
            onClick={() => {
              handleFilterData("A");
            }}
            className={`all_filter_btn ${filter !== "A" ? "inactive" : ""}`}
          />

          <Button
            btnTxt="Featured"
            onClick={() => {
              handleFilterData("F");
            }}
            className={`all_filter_btn ${filter !== "F" ? "inactive" : ""}`}
          />
        </div>
        <div className="body_interest">
          {interestList?.length < 1 && (
            <div style={{ textAlign: "center" }}>No interest found!</div>
          )}
          {isListView ? <ListViewData /> : <CardViewData />}
        </div>
        <div className="btn_footer">
          <Button
            btnTxt={
              selectedInterest?.length > 0
                ? `Continue with ${selectedInterest?.length} selected`
                : "Continue"
            }
            className={"btn_continue"}
            isLoading={isLoading}
            // disabled={Object.keys(selectedData).length < 1}
            onClick={handleNext}
          />

          <Button
            btnTxt="Can’t find interest?"
            className={"btn_new"}
            onClick={() => setAddInterestPopup(true)}
          />
        </div>
      </div>
      {addInterestPopup && (
        <AddInterestPopup
          onClose={() => setAddInterestPopup(false)}
          callBack={callBack}
        />
      )}
    </div>
  );
};

export default Interest;
