import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import InputField from "../../global/InputField";
import { apiConstants } from "../../utils/Constants";
import { loginForm } from "../../utils/FormConfig";
import { yupResolver } from "@hookform/resolvers/yup";
import { SignInValidations } from "./AuthValidation";
import { STRING_CONSTANTS } from "../../utils/StringConstant";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Button from "../../global/Button";
import { makePostCall } from "../../utils/Requests";
import endpoints from "../../utils/endpoints";
import {
  getItemFromStore,
  removeAllItemFromStore,
  setLocalStorage,
  showToast,
} from "../../utils";
import { ROUTE_CONSTANTS } from "../../utils/RouteConstants";

import SocialLogin from "./SocialLogin";
import FullLoader from "../../global/FullLoader";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Logo from "../../assets/img/icons/Logo";
const signupBg1 = require("../../assets/img/signup-bg-1.jpg");
const signupBg2 = require("../../assets/img/signup-bg-2.jpg");
const signupBg3 = require("../../assets/img/signup-bg-3.jpg");

const SignIn = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [passType, setPassType] = useState("password");
  const [socialLoading, setSocialLoading] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const sliderRef = useRef(null);

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: loginForm,
    resolver: yupResolver(SignInValidations),
    mode: "onSubmit",
  });

  if (
    process.env.REACT_APP_IS_PROD === "true" &&
    !window.location.pathname.includes([
      ROUTE_CONSTANTS.register,
      ROUTE_CONSTANTS.manage_profile,
      ROUTE_CONSTANTS.joining,
    ])
  )
    window.location.replace(ROUTE_CONSTANTS.register);
  useEffect(() => {
    // we are removing all the items from the localstorage because there is a goback functionality and the user account created at the first step so we are removing the user data from local storage if user click on back and come to login or sign up page so the can login there account here.

    // if (localStorage.getItem('isFirstTimeUser') && localStorage.getItem('isFirstTimeUser') == "yes") {
    //   localStorage.setItem('isFirstTimeUser', 'no')
    //   navigate('/register');
    // } else {
    removeAllItemFromStore();

    // }
  }, []);

  const handleLogin = () => {
    handleSubmit((data) => {
      setIsLoading(true);
      const decoData: any = { ...data, email: data.email.toLowerCase() };

      let formData: any = new FormData();
      formData.append("password", decoData.password);
      formData.append(
        "client_id",
        process.env.REACT_APP_IS_DEV === "true"
          ? process.env.REACT_APP_CLIENT_ID
          : process.env.REACT_APP_IS_LOCAL === "true"
            ? process.env.REACT_APP_LOCAL_CLIENT_ID
            : process.env.REACT_APP_STAGING_CLIENT_ID
      );
      formData.append(
        "client_secret",
        process.env.REACT_APP_IS_DEV === "true"
          ? process.env.REACT_APP_CLIENT_SECRET
          : process.env.REACT_APP_IS_LOCAL === "true"
            ? process.env.REACT_APP_LOCAL_CLIENT_SECRET
            : process.env.REACT_APP_STAGING_CLIENT_SECRET
      );
      formData.append("grant_type", "password");
      formData.append("username", decoData.email);

      makePostCall({
        url: endpoints.login,
        apiPayload: formData,
        content_type: "multipart/form-data",
      })
        .then((res) => {
          if (res.status.code === 200) {
            showToast(res.status.message, "success");

            setLocalStorage("access_token", res.token.accessToken);
            setLocalStorage(
              "isProfileComplete",
              res?.data?.isProfileCompleted || false
            );
            setLocalStorage("refresh_token", res.token.refreshToken);
            if (getItemFromStore("isProfileComplete"))
              navigate(ROUTE_CONSTANTS.events, { replace: true });
            else navigate(ROUTE_CONSTANTS.manage_profile);
          } else {
            showToast(res.status.message || res.error.message, "error");
          }
          setIsLoading(false);
        })
        .catch((err) => {
          showToast(err, "error");
          setIsLoading(false);
        });
    })();
  };
  useEffect(() => {
    sliderRef.current.slickGoTo(currentSlide);
  }, [currentSlide]);
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    beforeChange: (current, next) => {
      setCurrentSlide(next);
    },
  };
  return (
    <>
      <div className=" d-md-none logo_nav ">
        <Link to="/">
          <img src="/static/logo_small.svg" alt="" />
        </Link>
      </div>
      <div className="login constiner-fluid p-0 m-0 overflow-hidden">
        <div style={{ height: "100vh" }} className="row p-0 m-0">
          <div
            className="col1 d-none d-md-block col-5 px-0 overflow-hidden"
            style={{ height: "100vh" }}
          >
            <div className="img-slider" style={{ height: "100%" }}>
              <Slider {...settings} ref={sliderRef} style={{ height: "100%" }}>
                <div className="slider-item">
                  <img src={signupBg1} alt="" style={{ objectFit: "cover" }} />
                  <div className="overlay"></div>
                </div>
                <div className="slider-item">
                  <img src={signupBg2} alt="" style={{ objectFit: "cover" }} />
                  <div className="overlay"></div>
                </div>
                <div className="slider-item">
                  <img src={signupBg3} alt="" style={{ objectFit: "fill" }} />
                  <div className="overlay"></div>
                </div>
              </Slider>
            </div>
            <div className="txtb" style={{ width: "100%" }}>
              <div className="logo">
                <Logo fill="#FFFFFF" />
                <p style={{ color: "#FFFFFF" }}>
                  {currentSlide === 0
                    ? "Find your ideal fitness mates and activities, anywhere anytime."
                    : currentSlide === 1
                      ? "Connecting fitness and health enthusiasts to join classes and activities together"
                      : "Create or join a health or fitness activity that suits your needs today."}
                </p>
              </div>
              <div className="guest-button">
                <div className="slider-box">
                  <div className="slider-bg"></div>
                  {[0, 1, 2].map((index) => (
                    <div
                      key={index}
                      className={`slider ${currentSlide === index ? "active" : ""
                        }`}
                      onClick={() => setCurrentSlide(index)}
                      style={{ transform: `translateX(${index * 43}px)` }}
                    ></div>
                  ))}
                </div>
                {/* <NavLink
                  to={ROUTE_CONSTANTS.events}
                  style={{ color: "#FFFFFF" }}
                >
                  Continue as Guest
                </NavLink> */}
              </div>
            </div>
          </div>
          <div className="col2 col-12 col-md-7 mx-auto login-right-container overflow-y-auto vh-100">
            {socialLoading ? (
              <FullLoader loaderClass={"fullLoader"} />
            ) : (
              <form onSubmit={handleSubmit(handleLogin)}>
                <h1 className="l-onboarding-text">Welcome!</h1>
                <p className="l-onboarding-info">
                  Please sign in to access your account and connect with your
                  fitness community.
                </p>
                <InputField
                  type={"email"}
                  name={apiConstants.email}
                  placeholder={"Enter Email"}
                  inputLabel={"Email Address"}
                  control={control}
                  errors={errors}
                  setValue={setValue}
                />
                <div className="password">
                  <InputField
                    type={passType}
                    name={apiConstants.password}
                    placeholder={"Enter password"}
                    inputLabel={"Password"}
                    control={control}
                    errors={errors}
                    setValue={setValue}
                    addIconPadding
                  />
                  <img
                    src={
                      passType === "password"
                        ? "/static/closeeye.svg"
                        : "/static/openeye.svg"
                    }
                    alt=""
                    onClick={() =>
                      setPassType(passType === "password" ? "text" : "password")
                    }
                  />
                </div>

                <Link
                  to={ROUTE_CONSTANTS.forgot_password}
                  className="forgot_password"
                  style={{ width: "100%" }}
                >
                  {STRING_CONSTANTS.forgot_password}
                </Link>
                <Button
                  type="submit"
                  btnTxt="Login account"
                  isLoading={isLoading}
                />
                <div className="continue_container">
                  <div className="line"></div>
                  <div className="continue_with ">or continue with</div>
                  <div className="line"></div>
                </div>

                <SocialLogin setSocialLoading={setSocialLoading} />
                <div className="no_account ">
                  Don’t have an account?
                  <Link to={ROUTE_CONSTANTS.register}> Create account.</Link>
                </div>
                <div className="policies ">
                  <Link
                    to={ROUTE_CONSTANTS.policy}
                    target={"_blank"}
                    className="policies "
                  >
                    Terms and Privacy
                  </Link>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default SignIn;
