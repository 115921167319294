import axios, { AxiosError, AxiosResponse } from "axios";
import {
	getItemFromStore,
	isUserAuthenticated,
	removeAllItemFromStore,
	showToast,
} from ".";

import { errorMessage } from "./Constants";
import endpoints from "./endpoints";

//this method allow to update api base url
const updateBaseUrl = (protocol?: any) => {
	let baseURL = protocol || endpoints.API_BASE_URL;
	return baseURL;
};
const instance: any = axios.create({
	baseURL: updateBaseUrl(),
	timeout: 60000,
});

instance.interceptors.request.use(
	function (config: any) {
		const accessToken: any = getItemFromStore("access_token");
		config.port = 443;
		if (accessToken) {
			config.headers["Authorization"] = `Bearer ${getItemFromStore(
				"access_token"
			)}`;
		}
		return config;
	},
	function (error: AxiosError) {
		return Promise.reject(error);
	}
);

instance.interceptors.response.use(
	async function (response: AxiosResponse) {
		const data: any = response?.data;
		const statusCode = response?.status;
		const detail = data?.status?.detail;

		if (
			(statusCode === 401 && isUserAuthenticated()) ||
			(statusCode === 403 && isUserAuthenticated())
		) {
			removeAllItemFromStore();
			window.location.reload();
		}

		else if (statusCode === 403) {
			if (detail) {
				for (var key in detail) {
					if (detail.hasOwnProperty(key)) {
						if (!detail[0]) {
							showToast(detail[key][0], "error", detail[key][0]);
						} else {
							showToast(detail[0], "error", detail[0]);
						}
					}
				}
			} else {
				const message = data?.status?.message;
				showToast(message, "error", message);
			}
		}
		return data;
	},
	async function (error: AxiosError) {
		const response: any = error?.response;
		if (response) {
			const errorData = response?.data?.errors;
			const status: any = response?.status;
			if (
				(status === 401 && isUserAuthenticated()) ||
				(status === 403 && isUserAuthenticated())
			) {
				removeAllItemFromStore();
				window.location.reload();
			} else if (status === 400) {
				if (errorData) {
					for (const key in errorData) {
						if (errorData.hasOwnProperty(key)) {
							if (!errorData[0]) {
								showToast(errorData[key][0], "error", errorData[key][0]);
							} else {
								showToast(errorData[0], "error", errorData[0]);
							}
						}
					}
				}
			} else if (status >= 500) {
				showToast(
					errorMessage.server_error,
					"error",
					errorMessage.server_error
				);
			}
		}
		return Promise.reject(error);
	}
);

export { instance };
