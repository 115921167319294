/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import Button from "../../../global/Button";
import { dateFormatter, showToast } from "../../../utils";
import { apiConstants } from "../../../utils/Constants";
import { STRING_CONSTANTS } from "../../../utils/StringConstant";
import SingleTimePicker from "../../DatePicker/SingleTimePicker/index.tsx";
import SingleDatePicker from "../../DatePicker/SingleDatePicker/index.tsx";
import { compareDayjsTime } from "../../../constants/helperCreateListing";
import dayjs from "dayjs";
import { useState } from "react";

interface scheduleListingProps {
  control: any;
  watch?: any;
  handleSetValue?: any;
  getValues?: any;
  setMultipleScheduleLisiting?: any;
  trigger: any;
  errors: any;
  setScheduleDate: any;
  dirtyFields: any;
}
const ScheduleListingSidebar = (props: scheduleListingProps) => {
  const {
    control,
    watch,
    handleSetValue,
    getValues,
    setMultipleScheduleLisiting,
    trigger,
    errors,
    setScheduleDate,
    dirtyFields,
  } = props;

  const [showVisible, setShowVisible] = useState(false);

  function isTimeConflict(list, date, startTime, endTime) {
    return list.some((item) => {
      if (item?.date === date) {
        const existingStart = moment(`${item.date} ${item.start_time}`);
        const existingEnd = moment(`${item.date} ${item.end_time}`);
        const newStart = moment(`${date} ${startTime}`).add(1, "minute");
        const newEnd = moment(`${date} ${endTime}`).subtract(1, "minute");

        return (
          newStart.isBetween(existingStart, existingEnd, null, "[)") ||
          newEnd.isBetween(existingStart, existingEnd, null, "(]")
        );
      }
      return false;
    });
  }

  const handleScheduleListing = async () => {
    let startDate = watch(apiConstants.event_date);
    let endDate = watch(apiConstants.event_date);
    handleSetValue(apiConstants.event_start_date, new Date(startDate));
    handleSetValue(apiConstants.event_end_date, new Date(endDate));

    await trigger([
      apiConstants.event_end_date,
      apiConstants.event_start_date,
      apiConstants.event_start_time,
      apiConstants.event_end_time,
      apiConstants.event_date,
    ]);
    if (
      errors[apiConstants.event_start_date] ||
      errors[apiConstants.event_end_date] ||
      errors[apiConstants.event_start_time] ||
      errors[apiConstants.event_end_time] ||
      errors[apiConstants.event_date]
    ) {
      //If the fields has error update the errors and return
      setScheduleDate("");
      handleSetValue(apiConstants.has_schedule_date, false);
      setMultipleScheduleLisiting(true);
      return;
    } else {
      const eventList = watch(apiConstants.multipleEvents);
      const newDate = dayjs(watch(apiConstants.event_start_date)).format(
        "YYYY-MM-DD"
      );
      const newStartTime = dayjs(watch(apiConstants.event_start_time)).format(
        "HH:mm:ss.SSSSSS"
      );
      const newEndTime = dayjs(watch(apiConstants.event_end_time)).format(
        "HH:mm:ss.SSSSSS"
      );

      const hasConflict = isTimeConflict(
        eventList,
        newDate,
        newStartTime,
        newEndTime
      );

      if (hasConflict) {
        showToast(
          "An event with a conflicting date and time already exists.",
          "error"
        );
      } else {
        handleSetValue(apiConstants.has_schedule_date, true);
        setMultipleScheduleLisiting(false);
      }
    }
  };

  return (
    <div
      className={"modal show sidebar-modal"}
      style={{ visibility: showVisible ? "hidden" : "visible" }}
    >
      <div className="modal-section v-modal modal-section-sidebar">
        <div className="modal-sidebar-heading-box">
          <span
            onClick={() => {
              setMultipleScheduleLisiting(false);
            }}
            style={{ cursor: "pointer" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M8.73464 16.7917C9.03557 17.0763 9.51026 17.0631 9.79489 16.7621C10.0795 16.4612 10.0663 15.9865 9.76536 15.7019L4.52632 10.7468L17.25 10.7468C17.6642 10.7468 18 10.411 18 9.99678C18 9.58256 17.6642 9.24678 17.25 9.24678L4.52405 9.24678L9.76536 4.28952C10.0663 4.00489 10.0795 3.53021 9.79489 3.22927C9.51026 2.92834 9.03557 2.91512 8.73464 3.19974L2.31741 9.26918C2.15112 9.42647 2.05112 9.62658 2.01741 9.83537C2.00601 9.88736 2 9.94136 2 9.99678C2 10.0544 2.00649 10.1104 2.01878 10.1643C2.05377 10.3701 2.15331 10.567 2.31741 10.7222L8.73464 16.7917Z"
                fill="#25272D"
              />
            </svg>
          </span>
          <span className="">Schedule Event</span>
        </div>
        <div className="modal-top-part new-top-sidebar">
          <div className="all-mid-part p-0 pb-4 p-md-4">
            <SingleDatePicker
              name={apiConstants.event_date}
              watch={watch}
              handleSetValue={handleSetValue}
              label={"Event Date"}
              onOpen={() => setShowVisible(true)}
              onDone={() => setShowVisible(false)}
            />
            {/* <div className="form_group">
              <div className={"input_box"}>
                <span className="input_label">Event Date</span>
                <div className="one-time">
                  <MultiDateSelector
                    name={apiConstants.event_date}
                    control={control}
                    minDate={new Date()}
                    watch={watch}
                    errors={errors}
                  />
                </div>
              </div>
            </div> */}

            <h5
              style={{
                marginTop: "24px",
                marginBottom: "16px",
              }}
            >
              Select listing time
            </h5>
            <div className="two-row-input">
              <SingleTimePicker
                label={"Start Time"}
                name={apiConstants.event_start_time}
                watch={watch}
                handleSetValue={handleSetValue}
                onOpen={() => setShowVisible(true)}
                onDone={() => setShowVisible(false)}
              />
              <SingleTimePicker
                label={"End Time"}
                name={apiConstants.event_end_time}
                watch={watch}
                handleSetValue={handleSetValue}
                onOpen={() => setShowVisible(true)}
                onDone={() => setShowVisible(false)}
              />
              {/* <div className="form_group position-relative">
                <div className="input_box position-initial">
                  <span className="input_label">Start Time:</span>
                  <EventTimeSelector
                    name={apiConstants.event_start_time}
                    control={control}
                    errors={errors}
                  />
                </div>
              </div>
              <div className="form_group position-relative">
                <div className="input_box position-initial">
                  <span className="input_label">End Time:</span>
                  <EventTimeSelector
                    name={apiConstants.event_end_time}
                    control={control}
                    errors={errors}
                  />
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <div className="all-button p-0 px-md-4">
          <div className="all-button-top">
            <Button
              btnTxt="Cancel"
              className="first-parts"
              onClick={() => {
                handleSetValue(apiConstants.event_date, null);
                handleSetValue(apiConstants.event_start_time, null);
                handleSetValue(apiConstants.event_end_time, null);
                setMultipleScheduleLisiting(false);
              }}
            />
            <Button
              btnTxt="Schedule listing"
              className={"backtologin text-sm"}
              onClick={() => {
                if (!watch(apiConstants.event_date)) {
                  showToast("An event date is required to proceed.", "error");
                } else if (
                  !watch(apiConstants.event_end_time) ||
                  !watch(apiConstants.event_start_time)
                ) {
                  showToast(STRING_CONSTANTS.start_and_end_time_error, "error");
                } else if (
                  !compareDayjsTime(
                    watch(apiConstants.event_start_time),
                    watch(apiConstants.event_end_time)
                  )
                ) {
                  showToast(
                    STRING_CONSTANTS.start_and_end_date_error_eql,
                    "error"
                  );
                  return;
                } else {
                  handleScheduleListing();
                }
              }}
            />
          </div>
          <p>By cancelling, your schedule progress won’t be saved</p>
        </div>
      </div>
    </div>
  );
};

export default ScheduleListingSidebar;
