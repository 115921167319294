import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import GlobalHeader from "../Dashboard/GlobalHeader";
import Arrow from "../../assets/img/Arrow.svg";
import { makeGetCall, makePostCall } from "../../utils/Requests";
import endpoints from "../../utils/endpoints";
import ScheduleDatesPopup from "../Dashboard/Popups/ScheduleDatesPopup";
import EventDateBox from "../Dashboard/EventDateBox";
import { Accordion } from "react-bootstrap";
import Button from "../../global/Button";
import InputField from "../../global/InputField";
import { apiConstants } from "../../utils/Constants";
import { useForm } from "react-hook-form";
import { Form } from "react-bootstrap";
import PaymentStep from "./PaymentStep";
import { getItemFromStore, showToast } from "../../utils";
import moment from "moment";
import ApiLoader from "../../global/ApiLoader";
import { LISTING_TYPE } from "../../types";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  BasicDetailsValidation,
  BusinessInterestValidationTwo,
} from "../Profile/ProfileValidations";
import { STRING_CONSTANTS } from "../../utils/StringConstant";
import useCheckDeviceScreen from "../../hooks/useCheckScreen";
import MobileInput from "../../global/MobileInput";
import OpenMapContainer from "../Dashboard/OpenMapContainer";
import GlobalHeader2 from "../Dashboard/GlobalHeader2";

const EventBooking = () => {
  const { id } = useParams();
  const [detailsData, setDetailsData] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [refresh, setRefresh] = useState(false);
  const [second, setSecond] = useState(false);
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const [selDates, setSelDates] = useState([]);
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [showDateModal, setShowDateModal] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState(1);
  const [stepDone, setStepDone] = useState(1);
  const [activeTab, setActiveTab] = useState(1);
  const [isChecked, setIsChecked] = useState(true);
  const [selectedBookEvents, setSelectedBookEvents] = useState([]);
  const navigate = useNavigate();
  const [initialBookEvents, setIntitalBookEvents] = useState([]);
  const [attendessProfile, setAttendessProfile] = useState([]);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const [dropdownValues, setDropdownValues] = useState({});
  const [dropdownGender, setDropdownGender] = useState("");
  const [bookingForMyself, setBookingForMyselef] = useState("");
  const [packageCounter, setPackageCounter] = useState(null);
  const { isMobile } = useCheckDeviceScreen();
  const [openMap, setOpenMap] = useState(false);
  const [isEventFull, setIsEventFull] = useState(false);
  const [eventCenter, setEventCenter] = useState<any>();
  const [showLeavePopup, setShowLeavePopup] = useState(false);

  const isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  const [isListingError, setIsListingError] = useState<boolean>(false);
  const [isGenderLimitError, setIsGenderLimitError] = useState<boolean>(false);
  const [isAgeLimitError, setIsAgeLimitError] = useState<boolean>(false);

  const [isListingSelfError, setIsListingSelfError] = useState<boolean>(false);
  const [isGenderLimitSelfError, setIsGenderLimitSelfError] =
    useState<boolean>(false);
  const [isAgeLimitSelfError, setIsAgeLimitSelfError] =
    useState<boolean>(false);

  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    watch,
    trigger,
    formState: { errors, dirtyFields },
    register,
  } = useForm({
    // resolver: yupResolver(BusinessInterestValidationTwo),
    mode: "onSubmit",
  });

  let AllEventsData = [];
  if (initialBookEvents && detailsData?.events) {
    for (let k = 0; k < detailsData?.events?.length; k++) {
      for (let l = 0; l < initialBookEvents?.length; l++) {
        if (detailsData?.events[k]?.id === initialBookEvents[l]?.event) {
          AllEventsData.push({
            ...detailsData?.events[k],
            bookedSlots: initialBookEvents[l].slots,
          });
        }
      }
    }
  }

  useEffect(() => {
    localStorage.removeItem("user-" + id);
  }, []);
  useEffect(() => {
    setIsLoading(true);
    makeGetCall({
      url: endpoints.mylisting_new + `${id}`,
    })
      .then((res) => {
        setDetailsData(res.data);

        let eventSlots = res?.data?.events.filter(
          (item) => item.slotsAvailable !== 0
        );

        if (eventSlots && eventSlots?.length < 1) {
          setIsEventFull(true);
        }
        setIsLoading(false);
      })
      .catch((err) => {
        showToast(err, "error");
        setIsLoading(false);
      });
  }, [id, refresh]);
  // Update dropdown value

  const handleDropdownChangeNew = (value, id, index) => {
    setDropdownValues((prevState) => ({
      ...prevState,
      [id + index]: value,
    }));
  };

  const handleDropdown = (event, index, i) => {
    setValue(`attendees[${index}][${i}].gender`, event?.target?.value);
    trigger(`attendees[${index}][${i}].gender`);
  };

  const genderLine = (item) => {
    let showGenderLine = "genders.";

    if (item) {
      if (item.length === 1) {
        item.map((i, index) => {
          i === "M"
            ? (showGenderLine = "Male only.")
            : i === "F"
            ? (showGenderLine = "Female only.")
            : (showGenderLine = "genders.");
        });
      } else if (item.length === 2) {
        item[0] === "M"
          ? (showGenderLine = "Male & ")
          : item[0] === "F"
          ? (showGenderLine = "Female & ")
          : (showGenderLine = "Prefer not to say & ");
        item[1] === "M"
          ? (showGenderLine += "Male only.")
          : item[1] === "F"
          ? (showGenderLine += "Female only.")
          : (showGenderLine += "genders.");
      } else {
        showGenderLine = "genders.";
      }
    }

    return showGenderLine;
  };

  const handleBookingForMyself = (id, count, index) => {
    let userProfile = getItemFromStore("userProfile");
    setValue(`attendees[${index}][${0}].name`, userProfile.name);
    setValue(`attendees[${index}][${0}].email`, userProfile.email);
    if (userProfile.businessContactNumber) {
      setValue(
        `attendees[${index}][${0}].contact_number`,
        userProfile.businessContactNumber
      );
    } else if (userProfile.mobileNumber) {
      setValue(
        `attendees[${index}][${0}].contact_number`,
        userProfile.mobileNumber
      );
    }
    if (userProfile.dob) {
      setValue(
        `attendees[${index}][${0}].age`,
        moment().diff(moment(userProfile.dob, "YYYY-MM-DD"), "years")
      );
    }
    if (userProfile?.gender) {
      handleDropdownChangeNew(userProfile?.gender || null, id, 0);
      setValue(`attendees[${index}][${0}].gender`, userProfile?.gender || null);
    }
  };

  const handleOnlyMyDetails = () => {
    let userProfile = getItemFromStore("userProfile");

    setValue(apiConstants.name, userProfile.name);
    setValue(apiConstants.email, userProfile.email);
    // setValue(apiConstants.phone_number + id + i, userProfile.mobileNumber);

    if (userProfile.businessContactNumber) {
      setValue(apiConstants.contact_number, userProfile.businessContactNumber);
    } else if (userProfile.mobileNumber) {
      setValue(apiConstants.contact_number, userProfile.mobileNumber);
    }

    if (userProfile.dob) {
      setValue(
        "age",
        moment().diff(moment(userProfile.dob, "YYYY-MM-DD"), "years")
      );
    }
    if (userProfile?.gender) {
      setValue(`gender`, userProfile?.gender || null);
    }
  };

  const onError = () => {
    showToast(STRING_CONSTANTS.general_form_error_message, "error");
  };

  const renderAccordInner = (id, count, index) => {
    let renderElement = [];
    for (let i = 0; i < count; i++) {
      renderElement.push(
        <div className="bookiie-detail-wrap p-0" key={i}>
          <div className="bookiie-detail-title-box">
            <div className="bookie-detail-title">
              <span>Attendee {i + 1}</span>
              <span>
                <img src="/static/common/rightTick.svg" alt="" />
              </span>
            </div>
          </div>
          {/* style={{ display: "flex", gap: "16px", flexWrap: "wrap" }} */}
          <div
            className="row"
            // style={{ maxWidth: "720px" }}
          >
            <div className="col-12 col-md-6 mb-res">
              <InputField
                displayErrorOutlined={
                  !watch(`attendees[${index}][${i}].name`) && isListingError
                }
                type="text"
                name={`attendees[${index}][${i}].name`}
                control={control}
                errors={errors}
                value={setValue}
                inputLabel={"Name*"}
                placeholder={"Enter your name"}
                setValue={setValue}
                className="mb-0"
              />
            </div>

            <div className="col-12 col-md-3 d-none d-md-block">
              <div
                className="ep_input up_gender form_group mb-0"
                style={{
                  border:
                    ((!watch(`attendees[${index}][${i}].gender`) ||
                      watch(`attendees[${index}][${i}].gender`) === "Gender") &&
                      isListingError) ||
                    (isGenderLimitError &&
                      ["M", "F"]?.includes(
                        watch(`attendees[${index}][${i}].gender`)
                      ) &&
                      !detailsData?.gender?.includes(
                        watch(`attendees[${index}][${i}].gender`)
                      ))
                      ? "1px solid #FF3B30"
                      : "1px solid #dcdcdc",
                }}
              >
                <span className="ep_input_label">Gender*</span>
                <div className="d-flex option_cont">
                  <select
                    name="dropdown"
                    {...register(`attendees[${index}][${i}].gender`, {})}
                    onChange={(e) => {
                      handleDropdown(e, index, i);
                    }}
                    style={{
                      backgroundColor: "white",
                      border: "none",
                      color: "#25272d",
                      width: "100%",
                    }}
                  >
                    <option
                      value={null}
                      disabled
                      selected
                      style={{ color: "#8F939A" }}
                    >
                      Gender
                    </option>
                    <option value="M">Male</option>
                    <option value="F">Female</option>
                    <option value="N">Prefer not to say</option>
                  </select>
                </div>
              </div>
              {errors?.attendees?.[index][i]?.gender && (
                <div className="mb-2 text-danger">
                  {errors?.attendees?.[index]?.[i]?.gender?.message}
                </div>
              )}
            </div>
            <div className="col-12 col-md-3 d-none d-md-block">
              <MobileInput
                watch={watch}
                // style={{ width: "154px" }}
                // type="text"
                name={`attendees[${index}][${i}].age`}
                control={control}
                errors={errors}
                inputLabel={"Age*"}
                placeholder={"Age"}
                setValue={setValue}
                displayErrorOutlined={
                  (!watch(`attendees[${index}][${i}].age`) && isListingError) ||
                  (detailsData?.isAgeLimit &&
                    isAgeLimitError &&
                    (watch(`attendees[${index}][${i}].age`) <
                      detailsData?.minAge ||
                      watch(`attendees[${index}][${i}].age`) >
                        detailsData?.maxAge)) ||
                  (!detailsData?.isAgeLimit &&
                    isAgeLimitError &&
                    (watch(`attendees[${index}][${i}].age`) < 1 ||
                      watch(`attendees[${index}][${i}].age`) > 150))
                }
              />
            </div>

            <InputField
              // style={{ width: "154px" }}
              type="text"
              name={`attendees[${index}][${i}].event_id`}
              value={id}
              control={control}
              errors={errors}
              inputLabel={"Age*"}
              placeholder={"Age"}
              setValue={setValue(`attendees[${index}][${i}].event_id`, id)}
              className="d-none"
              // displayErrorOutlined
            />
          </div>
          {/* style={{ display: "flex", gap: "20px" }} */}
          <div className="row ">
            {/* style={{ width: "100%" }} */}
            <div className=" col-12 col-md-6 mb-res">
              <InputField
                style={{ width: "100%" }}
                type="text"
                name={`attendees[${index}][${i}].email`}
                control={control}
                errors={errors}
                inputLabel={"Email*"}
                placeholder={"Add your email address"}
                setValue={setValue}
                className="mb-0"
                displayErrorOutlined={
                  (!watch(`attendees[${index}][${i}].email`) ||
                    !watch(`attendees[${index}][${i}].email`)?.match(
                      isValidEmail
                    )) &&
                  isListingError
                }
              />
            </div>
            <div className="col-12 col-md-6 mb-res">
              <MobileInput
                placeholder={"Enter your Contact Number"}
                name={`attendees[${index}][${i}].contact_number`}
                watch={watch}
                setValue={setValue}
                control={control}
                className={" mb-0"}
                errors={errors}
              />
            </div>
            {detailsData.listingType === LISTING_TYPE.C && (
              <div className="col-12 col-md-12 mb-res">
                <InputField
                  type={"textarea"}
                  name={`attendees[${index}][${i}].description`}
                  control={control}
                  errors={errors}
                  inputLabel={"Description"}
                  className={"textarea-label"}
                  placeholder={"Tell us why are you interested?"}
                  maxlength="300"
                  setValue={setValue}
                  getValues={getValues}
                />
              </div>
            )}
          </div>
          {setValue(`attendees[${index}][${i}].priority`, i + 1)}

          {isMobile && (
            <div className="row ">
              <div className="col-6 pe-2 ">
                <div
                  className="ep_input up_gender form_group mb-0"
                  // style={{ width: "154px" }}
                  style={{
                    border:
                      ((!watch(`attendees[${index}][${i}].gender`) ||
                        watch(`attendees[${index}][${i}].gender`) ===
                          "Gender") &&
                        isListingError) ||
                      (isGenderLimitError &&
                        ["M", "F"]?.includes(
                          watch(`attendees[${index}][${i}].gender`)
                        ) &&
                        !detailsData?.gender?.includes(
                          watch(`attendees[${index}][${i}].gender`)
                        ))
                        ? "1px solid #FF3B30"
                        : "1px solid #dcdcdc",
                  }}
                >
                  <span className="ep_input_label">Gender*</span>
                  <div className="d-flex option_cont">
                    <select
                      name="dropdown"
                      {...register(`attendees[${index}][${i}].gender`, {})}
                      onChange={(e) => {
                        handleDropdown(e, index, i);
                      }}
                      style={{
                        backgroundColor: "white",
                        border: "none",
                        color: "#25272d",
                        width: "100%",
                      }}
                    >
                      <option
                        value={null}
                        disabled
                        selected
                        style={{ color: "#8F939A" }}
                      >
                        Gender
                      </option>
                      <option value="M">Male</option>
                      <option value="F">Female</option>
                      <option value="N">Prefer not to say</option>
                    </select>
                  </div>
                </div>
                {errors?.attendees?.[index][i]?.gender && (
                  <div className="mb-2 text-danger">
                    {errors?.attendees?.[index]?.[i]?.gender?.message}
                  </div>
                )}
              </div>
              <div className="col-6 ps-2">
                <MobileInput
                  watch={watch}
                  name={`attendees[${index}][${i}].age`}
                  control={control}
                  errors={errors}
                  inputLabel={"Age*"}
                  placeholder={"Age"}
                  setValue={setValue}
                  displayErrorOutlined={
                    (!watch(`attendees[${index}][${i}].age`) &&
                      isListingError) ||
                    (detailsData?.isAgeLimit &&
                      isAgeLimitError &&
                      (watch(`attendees[${index}][${i}].age`) <
                        detailsData?.minAge ||
                        watch(`attendees[${index}][${i}].age`) >
                          detailsData?.maxAge)) ||
                    (!detailsData?.isAgeLimit &&
                      isAgeLimitError &&
                      (watch(`attendees[${index}][${i}].age`) < 1 ||
                        watch(`attendees[${index}][${i}].age`) > 150))
                  }
                />
              </div>
            </div>
          )}
        </div>
      );
    }
    return renderElement;
  };

  const handleAutoFillEvents = () => {
    const data = getValues();

    const firstAttendee = data.attendees[0];
    AllEventsData.slice(1).map((val, index) => {
      for (let i = 0; i < val?.bookedSlots; i++) {
        if (firstAttendee?.[i]?.name) {
          setValue(
            `attendees[${index + 1}][${i}].name`,
            firstAttendee?.[i]?.name
          );
        }
        if (firstAttendee?.[i]?.email) {
          setValue(
            `attendees[${index + 1}][${i}].email`,
            firstAttendee?.[i]?.email
          );
        }
        if (firstAttendee?.[i]?.contact_number) {
          setValue(
            `attendees[${index + 1}][${i}].contact_number`,
            firstAttendee?.[i]?.contact_number
          );
        }
        if (firstAttendee?.[i]?.age) {
          setValue(
            `attendees[${index + 1}][${i}].age`,
            firstAttendee?.[i]?.age
          );
        }
        if (firstAttendee?.[i]?.gender) {
          setValue(
            `attendees[${index + 1}][${i}].gender`,
            firstAttendee?.[i]?.gender
          );
        }
      }
    });
  };

  const onSubmit = (dataValue: any) => {
    let attendees = [];

    if (activeTab === 1) {
      for (const attendee of dataValue?.attendees) {
        for (const val of attendee) {
          if (
            !val?.name ||
            !val?.age ||
            !val?.email ||
            !val?.gender ||
            val?.gender === "Gender"
          ) {
            setIsListingError(true);
            showToast(`Please fill all the attendee details.`, "error");
            return;
          } else if (!val?.email.match(isValidEmail)) {
            setIsListingError(true);
            showToast(`Invalid attendee email`, "error");
            return;
          } else if (
            detailsData?.isAgeLimit &&
            (val?.age < detailsData.minAge || val?.age > detailsData?.maxAge)
          ) {
            showToast(
              `This event is only for the age group ${detailsData?.minAge} - ${detailsData?.maxAge} years`,
              "error"
            );
            setIsListingError(true);
            setIsAgeLimitError(true);
            return;
          } else if (Number(val?.age) === 0 || Number(val?.age) > 150) {
            showToast(`Age should be between 1 - 150.`, "error");
            setIsListingError(true);
            setIsAgeLimitError(true);
            return;
          } else if (
            val?.gender === "M" &&
            !detailsData?.gender?.includes("M")
          ) {
            showToast(
              `This event is not for Male. Please try booking other events.`,
              "error"
            );
            setIsListingError(true);
            setIsGenderLimitError(true);
            return;
          } else if (
            val?.gender === "F" &&
            !detailsData?.gender?.includes("F")
          ) {
            showToast(
              `This event is not for Female. Please try booking other events.`,
              "error"
            );
            setIsListingError(true);
            setIsGenderLimitError(true);
            return;
          }
        }
      }
    } else if (activeTab === 2) {
      if (
        !dataValue?.name ||
        !dataValue?.age ||
        !dataValue?.gender ||
        !dataValue?.email ||
        dataValue?.gender === "Gender"
      ) {
        setIsListingSelfError(true);
        showToast(`Please fill the attendee details.`, "error");
        return;
      } else if (!dataValue?.email.match(isValidEmail)) {
        setIsListingSelfError(true);
        showToast(`Invalid attendee email`, "error");
        return;
      } else if (
        detailsData?.isAgeLimit &&
        (dataValue?.age < detailsData?.minAge ||
          dataValue?.age > detailsData?.maxAge)
      ) {
        showToast(
          `This event is only for the age group ${detailsData?.minAge} - ${detailsData?.maxAge} years`,
          "error"
        );
        setIsListingSelfError(true);
        setIsAgeLimitSelfError(true);
        return;
      } else if (Number(dataValue?.age) === 0 || Number(dataValue?.age) > 150) {
        showToast(`Age should be between 1 - 150.`, "error");
        setIsListingSelfError(true);
        setIsAgeLimitSelfError(true);
        return;
      } else if (
        dataValue?.gender === "M" &&
        !detailsData?.gender?.includes("M")
      ) {
        showToast(
          `This event is not for Male. Please try booking other events.`,
          "error"
        );
        setIsListingSelfError(true);
        setIsGenderLimitSelfError(true);
        return;
      } else if (
        dataValue?.gender === "F" &&
        !detailsData?.gender?.includes("F")
      ) {
        showToast(
          `This event is not for Female. Please try booking other events.`,
          "error"
        );
        setIsListingSelfError(true);
        setIsGenderLimitSelfError(true);
        return;
      }
    }

    if (activeTab === 1) {
      if (
        detailsData.listingType === LISTING_TYPE.S ||
        detailsData.listingType === LISTING_TYPE.SPECIAL
      ) {
        for (const attendee of dataValue?.attendees) {
          for (const val of attendee) {
            const gender = detailsData?.gender;

            if (!(val.name && val.email)) {
              return showToast(`please fill the attendee details`, "error");
            }

            // email validation
            if (val.email) {
              const isValidEmail =
                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i;
              if (!isValidEmail.test(val.email)) {
                return showToast(`Enter a valid email address`, "error");
              }
            }

            // gender validation

            if (val?.gender === "Gender" || val?.gender === null) {
              return showToast("Please select your gender", "error");
            } else if (
              gender?.length > 0 &&
              val?.gender === "M" &&
              !gender?.includes("M")
            ) {
              return showToast(
                `This event is not for Male. Please try booking other events.`,
                "error"
              );
            } else if (
              gender?.length > 0 &&
              val?.gender === "F" &&
              !gender?.includes("F")
            ) {
              return showToast(
                `This event is not for Female. Please try booking other events.`,
                "error"
              );
            }

            // age validation
            if (detailsData?.isAgeLimit) {
              if (!val.age) {
                return showToast(`please select your age`, "error");
              } else if (
                val.age < detailsData.minAge ||
                val.age > detailsData.maxAge
              ) {
                return showToast(
                  `This event is only for the age group ${detailsData.minAge} - ${detailsData.maxAge} years`,
                  "error"
                );
              }
            }

            attendees.push(val);
          }
        }
      } else if (
        detailsData.listingType === LISTING_TYPE.M ||
        detailsData.listingType === LISTING_TYPE.C
      ) {
        for (const attendee of dataValue?.attendees) {
          for (const val of attendee) {
            let newVal = val;
            delete newVal.event_id;
            const gender = detailsData?.gender;
            if (!(newVal.name && newVal.email)) {
              return showToast(`please fill the attendee details`, "error");
            }

            // email validation
            if (newVal.email) {
              const isValidEmail =
                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i;
              if (!isValidEmail.test(newVal.email)) {
                return showToast(`Enter a valid email address`, "error");
              }
            }

            if (newVal?.gender === "Gender" || newVal?.gender === null) {
              return showToast("Please select your gender", "error");
            } else if (
              gender?.length > 0 &&
              newVal?.gender === "M" &&
              !gender?.includes("M")
            ) {
              return showToast(
                `This event is not for Male. Please try booking other events.`,
                "error"
              );
            } else if (
              gender?.length > 0 &&
              newVal?.gender === "F" &&
              !gender?.includes("F")
            ) {
              return showToast(
                `This event is not for Male. Please try booking other events.`,
                "error"
              );
            }

            if (detailsData?.isAgeLimit) {
              if (!newVal.age) {
                return showToast(`please select your age`, "error");
              } else if (
                newVal.age < detailsData.minAge ||
                newVal.age > detailsData.maxAge
              ) {
                return showToast(
                  `This event is only for the age group ${detailsData.minAge} - ${detailsData.maxAge} years`,
                  "error"
                );
              }
            }
            attendees.push(newVal);
          }
        }
      }
    } else if (activeTab === 2) {
      if (
        detailsData.listingType === LISTING_TYPE.S ||
        detailsData.listingType === LISTING_TYPE.SPECIAL
      ) {
        let singleUserValue = dataValue;
        delete singleUserValue.attendees;
        for (const events of AllEventsData) {
          singleUserValue.event_id = events.id;
          const gender = detailsData?.gender;

          if (
            !(
              singleUserValue.name &&
              singleUserValue.email &&
              singleUserValue.age
            )
          ) {
            return showToast(`please fill the attendee details`, "error");
          }

          if (singleUserValue.age == 0) {
            return showToast(`Age should be more then zero`, "error");
          }
          if (singleUserValue.age > 150) {
            return showToast(`Age should be in valid limit`, "error");
          }
          // email validation
          if (singleUserValue.email) {
            const isValidEmail =
              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i;
            if (!isValidEmail.test(singleUserValue.email)) {
              return showToast(`Enter a valid email address`, "error");
            }
          }

          //gender validation
          if (
            singleUserValue?.gender === "Gender" ||
            singleUserValue?.gender === null
          ) {
            return showToast("Please select your gender", "error");
          } else if (
            gender?.length > 0 &&
            singleUserValue?.gender === "M" &&
            !gender?.includes("M")
          ) {
            return showToast(
              `This event is not for Male. Please try booking other events.`,
              "error"
            );
          } else if (
            gender?.length > 0 &&
            singleUserValue?.gender === "F" &&
            !gender?.includes("F")
          ) {
            return showToast(
              `This event is not for Male. Please try booking other events.`,
              "error"
            );
          }

          if (detailsData?.isAgeLimit) {
            if (!singleUserValue.age) {
              return showToast(`please select your age`, "error");
            } else if (
              singleUserValue.age < detailsData.minAge ||
              singleUserValue.age > detailsData.maxAge
            ) {
              return showToast(
                `This event is only for the age group ${detailsData.minAge} - ${detailsData.maxAge} years`,
                "error"
              );
            }
          }

          attendees.push({ ...singleUserValue });
        }
      } else if (
        detailsData.listingType === LISTING_TYPE.M ||
        detailsData.listingType === LISTING_TYPE.C
      ) {
        let singleUserValue = dataValue;
        delete singleUserValue.attendees;
        const gender = detailsData?.gender;
        if (
          !(
            singleUserValue.name &&
            singleUserValue.email &&
            singleUserValue.age
          )
        ) {
          return showToast(`please fill the attendee details`, "error");
        }

        if (singleUserValue.age == 0) {
          return showToast(`Age should be more then zero`, "error");
        }
        if (singleUserValue.age > 150) {
          return showToast(`Age should be in valid limit`, "error");
        }
        // email validation
        if (singleUserValue.email) {
          const isValidEmail =
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i;
          if (!isValidEmail.test(singleUserValue.email)) {
            return showToast(`Enter a valid email address`, "error");
          }
        }

        //gender validation
        if (
          singleUserValue?.gender === "Gender" ||
          singleUserValue?.gender === null
        ) {
          return showToast("Please select your gender", "error");
        } else if (
          gender?.length > 0 &&
          singleUserValue?.gender === "M" &&
          !gender?.includes("M")
        ) {
          return showToast(
            `This event is not for Male. Please try booking other events.`,
            "error"
          );
        } else if (
          gender?.length > 0 &&
          singleUserValue?.gender === "F" &&
          !gender?.includes("F")
        ) {
          return showToast(
            `This event is not for Male. Please try booking other events.`,
            "error"
          );
        }

        if (detailsData?.isAgeLimit) {
          if (!singleUserValue.age) {
            return showToast(`please select your age`, "error");
          } else if (
            singleUserValue.age < detailsData.minAge ||
            singleUserValue.age > detailsData.maxAge
          ) {
            return showToast(
              `This event is only for the age group ${detailsData.minAge} - ${detailsData.maxAge} years`,
              "error"
            );
          }
        }

        attendees.push({ ...singleUserValue });
      }
    }
    setAttendessProfile(attendees);

    const data = {
      booking_data: initialBookEvents,
      attendees: attendees,
    };

    if (attendees?.length < 1) {
      return showToast("please fill attendee details", "error");
    }

    makePostCall({
      url: endpoints.listings_intermediate,
      apiPayload: data,
    })
      .then((res) => {
        setIsLoading(false);
        const booking_id = res?.data?.id;
        navigate(`?booking_id=${booking_id}`);
      })
      .catch((err) => {
        setIsLoading(false);
      });
    setCurrentStep(currentStep + 1);
    setStepDone(stepDone + 1);
  };

  useEffect(() => {
    if (currentStep === 3) {
      const booking_id = queryParams.get("booking_id");
      makeGetCall({ url: endpoints.listings_intermediate + `${booking_id}` })
        .then((res) => {
          if (res.status.code === 200) {
            setSelectedBookEvents(res.data);
          }
        })
        .catch((err) => {
          showToast(err, "error");
        });
    }
  }, [currentStep]);

  const stepTitleBox = (step) => {
    if (step === 1) {
      return (
        <>
          <div className="page-title">Select Event Dates</div>
          <div className="page-sub-title">
            Please select the event dates that suits you.
          </div>
        </>
      );
    } else if (step === 2) {
      return (
        <>
          <div className="page-title">Attendee details</div>
          <div className="page-sub-title">
            Please fill the details of all the attendees
          </div>
        </>
      );
    } else if (step === 3) {
      return (
        <>
          <div className="page-title">Confirm & Pay</div>
          <div className="page-sub-title">
            Confirm all your details and make the payment
          </div>
        </>
      );
    } else return;
  };

  return (
    <>
      <GlobalHeader2 />
      <div className="page-event-booking">
        <div className="outer-box" style={{ marginTop: "20px" }}>
          <div className="content-box">
            <span
              className="back-arrow"
              onClick={() => {
                if (currentStep === 1) {
                  setShowLeavePopup(true);
                  // navigate(-1);
                }
                setCurrentStep(currentStep > 1 ? currentStep - 1 : 1);
                setStepDone(stepDone > 1 ? stepDone - 1 : 0);
              }}
            >
              <img src={Arrow} alt="" />
            </span>
            {stepTitleBox(currentStep)}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
                justifyContent: "center",
              }}
              className="step-show-res"
            >
              <div
                className="mobile-circle"
                style={{
                  width: "30px",
                  height: "30px",
                  borderRadius: "50%",
                  backgroundColor:
                    currentStep === 1
                      ? "#EE762F"
                      : stepDone >= 1
                      ? "#29CC6A"
                      : "#f3f3f3",
                  color: currentStep > 1 ? "#ffffff" : "#ffffff",
                  fontSize: "18px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                1
              </div>

              <div
                style={{ width: "116px", borderBottom: "2px dashed #000000" }}
              />

              <div
                className="mobile-circle"
                style={{
                  width: "30px",
                  height: "30px",
                  borderRadius: "50%",
                  fontSize: "18px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor:
                    currentStep === 2
                      ? "#EE762F"
                      : stepDone >= 2
                      ? "#29CC6A"
                      : "#f3f3f3",
                  color: currentStep >= 2 ? "#ffffff" : "#25272D",
                }}
              >
                2
              </div>
              <div
                style={{ width: "116px", borderBottom: "2px dashed #000000" }}
              />

              <div
                className="mobile-circle"
                style={{
                  width: "30px",
                  height: "30px",
                  borderRadius: "50%",
                  fontSize: "18px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  backgroundColor:
                    currentStep === 3
                      ? "#EE762F"
                      : stepDone >= 3
                      ? "#29CC6A"
                      : "#f3f3f3",
                  color: currentStep >= 3 ? "#ffffff" : "#25272D",
                }}
              >
                3
              </div>
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "52px",
              }}
            >
              <div
                style={{
                  width: "40px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "start",
                  gap: "4px",
                  marginTop: "2px",
                }}
                className={
                  currentStep === 1
                    ? "step-text step-text-bold"
                    : "step-text step-text-one"
                }
              >
                {detailsData?.listingType === LISTING_TYPE.M ? "Add" : "Date"}

                {stepDone > 1 && (
                  <span>
                    <img alt="" src="/static/check-small.svg" />
                  </span>
                )}
              </div>
              <div
                style={{
                  maxWidth: "175px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "4px",
                }}
                className={
                  currentStep === 2
                    ? "step-text step-text-two-bold"
                    : "step-text step-text-two"
                }
              >
                Attendee Details
                {stepDone > 2 && (
                  <span>
                    <img alt="" src="/static/check-small.svg" />
                  </span>
                )}
              </div>
              <div
                style={{
                  width: "50px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "4px",
                }}
                className={
                  currentStep === 3
                    ? "step-text step-text-three-bold"
                    : "step-text step-text-three"
                }
              >
                {detailsData?.listingType === LISTING_TYPE.C
                  ? "Confirm"
                  : "Pay"}
                {stepDone > 3 && (
                  <span>
                    <img alt="" src="/static/check-small.svg" />
                  </span>
                )}
              </div>
            </div>
            {isLoading ? (
              <div className="mt-5">
                <ApiLoader />
              </div>
            ) : (
              <div
                className={`${currentStep === 1 ? "d-block" : "d-none"}`}
                style={{ maxWidth: "800px", margin: "0 auto" }}
              >
                <EventDateBox
                  second={second}
                  show={true}
                  onHide={() => {
                    if (second) {
                      setSecond(false);
                    }
                  }}
                  eventsArray={detailsData?.events}
                  maxParticipants={detailsData?.maxParticipants}
                  setShowScheduleModal={setShowScheduleModal}
                  selectedEvents={selectedEvents}
                  detailsData={detailsData}
                  setSelDates={setSelDates}
                  selDates={selDates}
                  handleNextStep={() => {
                    setCurrentStep(currentStep + 1);
                    setStepDone(stepDone + 1);
                  }}
                  setIntitalBookEvents={setIntitalBookEvents}
                  initialBookEvents={initialBookEvents}
                  setParentPackageCounter={setPackageCounter}
                  setOpenMap={setOpenMap}
                  setEventCenter={setEventCenter}
                  setRefresh={setRefresh}
                  isEventFull={isEventFull}
                />
              </div>
            )}
            {currentStep === 2 ? (
              <div
                style={{
                  maxWidth: "720px",
                  margin: "0 auto 32px",
                }}
              >
                <div className="hor-sep"></div>
                <div className="info-box-wrapper-res">
                  <div className="info-box">
                    <img alt="" src="/static/info-sm.svg" />
                    The event welcomes participants of{" "}
                    <span className="orange">
                      {detailsData?.isAgeLimit
                        ? `aged ${detailsData?.minAge}-${detailsData?.maxAge}`
                        : "all ages"}{" "}
                    </span>
                    and{" "}
                    <span className="orange">
                      {detailsData?.gender?.length < 3
                        ? genderLine(detailsData?.gender)
                        : "genders."}
                    </span>
                    <span>
                      {
                        " Please review the details thoroughly. Note that our cancellation policy is in effect."
                      }
                    </span>
                  </div>
                  <div className="detail-question">
                    Do you want the event details to be shared with all the
                    attendee?
                  </div>
                  <div className="detail-button-box">
                    <div
                      className={
                        activeTab === 1 ? "tab-button-active" : "tab-button"
                      }
                      onClick={() => setActiveTab(1)}
                      style={{ cursor: "pointer" }}
                    >
                      Yes{!isMobile ? ", enter details of all attendee" : ""}
                    </div>
                    <div
                      className={
                        activeTab === 2 ? "tab-button-active" : "tab-button"
                      }
                      onClick={() => {
                        handleOnlyMyDetails();
                        setActiveTab(2);
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      No{!isMobile ? ", only my details will work" : ""}
                    </div>
                  </div>
                </div>
                {activeTab === 1 ? (
                  detailsData?.listingType === LISTING_TYPE.S ||
                  detailsData?.listingType === LISTING_TYPE.SPECIAL ? (
                    <div
                      style={{ marginTop: "32px" }}
                      className="attendee-detail-accrodian"
                    >
                      {AllEventsData?.slice(0, 1).map((events, index) => {
                        return (
                          <Accordion
                            defaultActiveKey="0"
                            className="event-booking-accordion"
                          >
                            <Accordion.Item
                              eventKey="0"
                              style={{ border: "none" }}
                            >
                              <Accordion.Header>
                                {moment(events.date).format(
                                  "dddd, Do MMMM YYYY"
                                )}
                              </Accordion.Header>
                              <Accordion.Body
                                style={{
                                  border: "none",
                                  paddingTop: "0",
                                  position: "relative",
                                }}
                              >
                                <div
                                  className="myself-box"
                                  style={{ position: "absolute" }}
                                  onClick={() => {
                                    setBookingForMyselef(events.id);
                                    handleBookingForMyself(
                                      events.id,
                                      events.bookedSlots,
                                      index
                                    );
                                  }}
                                >
                                  <img src="/static/myself-book.svg" alt="" />
                                  <span>Booking for myself</span>
                                </div>
                                {renderAccordInner(
                                  events.id,
                                  events.bookedSlots,
                                  index
                                )}
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        );
                      })}
                      {isMobile && false ? null : AllEventsData.length > 1 ? (
                        <div
                          className="save-attendies"
                          style={{ cursor: "pointer", zIndex: "100" }}
                          onClick={() => {
                            // alert('hi');
                            handleAutoFillEvents();
                          }}
                        >
                          Save attendees and auto fill for other events
                        </div>
                      ) : null}
                      {AllEventsData?.slice(1).map((events, index) => {
                        return (
                          <Accordion className="event-booking-accordion">
                            <Accordion.Item
                              eventKey="0"
                              style={{ border: "none" }}
                            >
                              <Accordion.Header>
                                {moment(events.date).format(
                                  "dddd, Do MMMM YYYY"
                                )}
                              </Accordion.Header>
                              <Accordion.Body
                                style={{
                                  border: "none",
                                  paddingTop: "0",
                                  position: "relative",
                                }}
                              >
                                <div
                                  className="myself-box"
                                  style={{ position: "absolute" }}
                                  onClick={() => {
                                    setBookingForMyselef(events.id);
                                    handleBookingForMyself(
                                      events.id,
                                      events.bookedSlots,
                                      index + 1
                                    );
                                  }}
                                >
                                  <img src="/static/myself-book.svg" alt="" />
                                  <span>Booking for myself</span>
                                </div>
                                {renderAccordInner(
                                  events.id,
                                  events.bookedSlots,
                                  index + 1
                                )}
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        );
                      })}
                    </div>
                  ) : (
                    <div
                      style={{ marginTop: "32px" }}
                      className="attendee-detail-accrodian attendee-detail-accrodian-res position-relative"
                    >
                      <div
                        className="myself-box"
                        style={{ position: "absolute" }}
                        onClick={() => {
                          // setBookingForMyselef(events.id);
                          handleBookingForMyself(
                            detailsData.id,
                            packageCounter,
                            0
                          );
                        }}
                      >
                        <img src="/static/myself-book.svg" alt="" />
                        <span>Booking for myself</span>
                      </div>
                      {renderAccordInner(detailsData.id, packageCounter, 0)}
                    </div>
                  )
                ) : (
                  <div className="bookiie-detail-wrap bookiie-detail-wrap-res p-0">
                    <div
                      className="bookie-detail-title"
                      style={{ maxWidth: "720px" }}
                    >
                      Bookie Detail
                    </div>
                    <div className="row">
                      <div className="col-12 col-md-6 mb-res">
                        <InputField
                          // style={{ width: "100%" }}
                          type="text"
                          name={apiConstants.name}
                          control={control}
                          inputLabel={"Name*"}
                          placeholder={"Enter your name"}
                          setValue={setValue}
                          className="mb-0"
                          displayErrorOutlined={
                            !watch(`name`) && isListingSelfError
                          }
                        />
                      </div>

                      <div className="col-12 col-md-3 d-none d-md-block">
                        <div
                          className="ep_input up_gender form_group mb-0"
                          style={{
                            border:
                              ((!watch(`gender`) ||
                                watch(`gender`) === "Gender") &&
                                isListingSelfError) ||
                              (isGenderLimitSelfError &&
                                ["M", "F"]?.includes(watch(`gender`)) &&
                                !detailsData?.gender?.includes(watch(`gender`)))
                                ? "1px solid #FF3B30"
                                : "1px solid #dcdcdc",
                          }}
                        >
                          <span className="ep_input_label">Gender*</span>
                          <div className="d-flex option_cont">
                            <select
                              {...register(`gender`, {
                                required: "please select a gender",
                              })}
                              style={{
                                backgroundColor: "white",
                                border: "none",
                                color: "#25272d",
                                width: "100%",
                              }}
                            >
                              <option
                                value={null}
                                disabled
                                selected
                                style={{ color: "#8F939A" }}
                              >
                                Gender
                              </option>
                              <option value="M">Male</option>
                              <option value="F">Female</option>
                              <option value="N">Prefer not to say</option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <div className="col-12 col-md-3 d-none d-md-block">
                        <MobileInput
                          watch={watch}
                          // type="text"
                          name={`age`}
                          control={control}
                          errors={errors}
                          inputLabel={"Age*"}
                          placeholder={"Age"}
                          setValue={setValue}
                          displayErrorOutlined={
                            (!watch("age") && isListingSelfError) ||
                            (detailsData?.isAgeLimit &&
                              isAgeLimitSelfError &&
                              (watch("age") < detailsData?.minAge ||
                                watch("age") > detailsData?.maxAge)) ||
                            (!detailsData?.isAgeLimit &&
                              isAgeLimitSelfError &&
                              (watch("age") < 1 || watch("age") > 150))
                          }
                        />
                      </div>
                    </div>
                    <div className="row">
                      <div className=" col-12 col-md-6 mb-res">
                        <InputField
                          // style={{ width: "100%" }}
                          type="text"
                          name={apiConstants.email}
                          control={control}
                          inputLabel={"Email*"}
                          placeholder={"Add your email address"}
                          setValue={setValue}
                          className="mb-0"
                          displayErrorOutlined={
                            (!watch(`email`) ||
                              !watch(`email`)?.match(isValidEmail)) &&
                            isListingSelfError
                          }
                        />
                      </div>
                      <div className=" col-12 col-md-6 mb-res">
                        <MobileInput
                          placeholder={"Enter your Contact Number"}
                          name={apiConstants.contact_number}
                          watch={watch}
                          setValue={setValue}
                          control={control}
                          className={"mb-0"}
                          errors={errors}
                        />
                      </div>
                      {detailsData.listingType === LISTING_TYPE.C && (
                        <div className="col-12 col-md-12 mb-res">
                          <InputField
                            type={"textarea"}
                            name={apiConstants.description}
                            control={control}
                            errors={errors}
                            inputLabel={"Description"}
                            className={"textarea-label"}
                            placeholder={"Tell us why are you interested?"}
                            maxlength="300"
                            setValue={setValue}
                            getValues={getValues}
                          />
                        </div>
                      )}
                    </div>
                    {isMobile && (
                      <div className="row ">
                        <div className="col-6 pe-2 ">
                          <div
                            className="ep_input up_gender form_group mb-0"
                            // style={{ width: "154px" }}
                            style={{
                              border:
                                ((!watch(`gender`) ||
                                  watch(`gender`) === "Gender") &&
                                  isListingSelfError) ||
                                (isGenderLimitSelfError &&
                                  ["M", "F"]?.includes(watch(`gender`)) &&
                                  !detailsData?.gender?.includes(
                                    watch(`gender`)
                                  ))
                                  ? "1px solid #FF3B30"
                                  : "1px solid #dcdcdc",
                            }}
                          >
                            <span className="ep_input_label">Gender*</span>
                            <div className="d-flex option_cont">
                              <select
                                {...register(`gender`, {
                                  required: "please select a gender",
                                })}
                                style={{
                                  backgroundColor: "white",
                                  border: "none",
                                  color: "#25272d",
                                  width: "100%",
                                }}
                              >
                                <option
                                  value={null}
                                  disabled
                                  selected
                                  style={{ color: "#8F939A" }}
                                >
                                  Gender
                                </option>
                                <option value="M">Male</option>
                                <option value="F">Female</option>
                                <option value="N">Prefer not to say</option>
                              </select>
                            </div>
                          </div>
                        </div>
                        <div className="col-6 ps-2">
                          <MobileInput
                            watch={watch}
                            // type="text"
                            name={`age`}
                            control={control}
                            errors={errors}
                            inputLabel={"Age*"}
                            placeholder={"Age"}
                            setValue={setValue}
                            displayErrorOutlined={
                              (!watch("age") && isListingSelfError) ||
                              (detailsData?.isAgeLimit &&
                                isAgeLimitSelfError &&
                                (watch("age") < detailsData?.minAge ||
                                  watch("age") > detailsData?.maxAge)) ||
                              (!detailsData?.isAgeLimit &&
                                isAgeLimitSelfError &&
                                (watch("age") < 1 || watch("age") > 150))
                            }
                          />
                        </div>
                      </div>
                    )}

                    <div
                      className="checkbox-ticket mt-0"
                      style={{ marginBottom: "24px" }}
                    >
                      <Form.Check
                        className={`checked_cont ${isChecked ? "active" : ""}`}
                        type="checkbox"
                        bsPrefix="checked_cont"
                        checked={isChecked}
                        onChange={() => setIsChecked(!isChecked)}
                      ></Form.Check>
                      <div style={{ textAlign: "start" }}>
                        All the tickets and event information will be shared
                        only with the user information mentioned above.
                      </div>
                    </div>
                  </div>
                )}

                <div className="btn_footer_listing_booking">
                  <Button
                    style={{
                      maxWidth: "350px",
                      margin: "0px",
                    }}
                    btnTxt="Save & Continue"
                    className={"backtologin"}
                    isLoading={isLoading}
                    disabled={!isChecked}
                    onClick={() => {
                      if (currentStep === 2) {
                        handleSubmit(onSubmit, onError)();
                      } else {
                        setCurrentStep(currentStep + 1);
                        setStepDone(stepDone + 1);
                      }
                    }}
                  />
                </div>
              </div>
            ) : currentStep === 3 ? (
              <div className="mx-auto">
                <PaymentStep
                  attendees={attendessProfile}
                  setCurrentStep={setCurrentStep}
                  parentPackageCounter={packageCounter}
                />
              </div>
            ) : null}

            {showScheduleModal && (
              <ScheduleDatesPopup
                show={showScheduleModal}
                onHide={() => {
                  setShowScheduleModal(false);
                }}
                detailsData={detailsData}
                setSecond={setSecond}
                setSelectedEvents={setSelectedEvents}
                setShowDateModal={setShowDateModal}
                setSelDates={setSelDates}
                setRefresh={setRefresh}
                selDates={selDates}
              />
            )}

            {openMap ? (
              <div className="fixed-sidebar">
                <div
                  className="sidebar-box connect-search-user "
                  style={{ padding: "0", position: "relative" }}
                >
                  <div
                    style={{
                      position: "absolute",
                      top: "20px",
                      left: "0px",
                      zIndex: "20",
                      paddingLeft: "20px",
                      display: "inline-flex",
                      gap: "12px",
                      alignItems: "center",
                      fontSize: "20px",
                    }}
                  >
                    <img
                      src="/static/cross-black.svg"
                      onClick={() => {
                        setOpenMap(false);
                      }}
                      className="close-btn"
                      alt=""
                    />
                    <span>Map View</span>
                  </div>
                  <div
                    className="inner-contet"
                    style={{
                      marginTop: "0",
                      maxWidth: "877px",
                      position: "relative",
                    }}
                  >
                    <OpenMapContainer eventCenter={eventCenter} />
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      {showLeavePopup ? (
        <div className={"modal show"}>
          <div
            className="modal-section log_out_modal csv-modal-wrap"
            style={{ maxWidth: "398px" }}
          >
            <div className="close-btn-csv close-button-leave-popup">
              <img
                src="/static/cross-black.svg"
                onClick={() => setShowLeavePopup(false)}
                className="close-btn"
                alt=""
              />
            </div>
            <div className="modal-top-part" style={{ paddingTop: "24px" }}>
              <h5>Are you sure you want to leave this page?</h5>
              <p>
                Please note that if you leave this page, all the data you have
                filled in during the booking process will be lost and cannot be
                retrieved.
              </p>
            </div>
            <div className="button_option">
              <Button
                btnTxt="Yes, leave"
                className={"backtologin"}
                onClick={() => {
                  navigate("/listing-two/" + id);
                }}
              />
              <Button
                btnTxt="Cancel"
                className={"backtologin notnow"}
                onClick={() => setShowLeavePopup(false)}
              />
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default EventBooking;
