/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Component } from "react";
import { googleKey } from "../utils/Constants";
import axios from "axios";
import InputField from "./InputField";
import { apiConstants } from "../utils/Constants";
import { showToast } from "../utils";

class GooglePlacesSearchBar extends Component<Readonly<any>, any> {
  autocompleteInput: any = React.createRef();
  autocomplete: any = null;
  mapContainer: any = React.createRef();
  marker: any = null;
  map: any = null;

  constructor(props: Readonly<any>) {
    super(props);
    this.state = { showPopup: false };
    this.handlePlaceChanged = this.handlePlaceChanged.bind(this);
    this.getCurrentLocation = this.getCurrentLocation.bind(this);
  }

  componentDidMount() {
    if (googleKey) {
      // Search address field should be for AU region only
      const options = {
        componentRestrictions: { country: "au" },
      };
      this.autocomplete = new google.maps.places.Autocomplete(
        this.autocompleteInput.current,
        options
      );
      this.autocomplete.addListener("place_changed", this.handlePlaceChanged);
    }

    this.map = new google.maps.Map(this.mapContainer.current, {
      zoom: 15, // Or any default zoom level you prefer
      center: {
        lat: this.props.lat || -25.363,
        lng: this.props.lng || 131.044,
      }, // Default center, can be any lat-lng
      draggable: false,
      fullscreenControl: false,
      mapTypeControl: false,
      zoomControl: false,
      streetViewControl: false,
    });
    if (this.props.lat && this.props.lng) {
      this.marker = new google.maps.Marker({
        map: this.map,
        position: {
          lat: this.props.lat,
          lng: this.props.lng,
        },
      });
    }
  }
  componentDidUpdate(prevProps) {
    // Check if the lat prop has changed
    if (this.props.lat !== prevProps.lat || this.props.lng !== prevProps.lng) {
      this.map = new google.maps.Map(this.mapContainer.current, {
        zoom: 15, // Or any default zoom level you prefer
        center: {
          lat: this.props.lat,
          lng: this.props.lng,
        }, // Default center, can be any lat-lng
        draggable: false,
        fullscreenControl: false,
        mapTypeControl: false,
        zoomControl: false,
        streetViewControl: false,
      });

      //  create a new marker
      this.marker = new google.maps.Marker({
        map: this.map,
        position: {
          lat: this.props.lat,
          lng: this.props.lng,
        },
      });
    }
  }

  componentWillUnmount() {
    if (this.autocomplete) {
      google.maps.event.clearInstanceListeners(this.autocomplete);
    }
  }

  handlePlaceChanged() {
    const place = this.autocomplete.getPlace();
    if (place && place.geometry) {
      let postalCode = 2000,
        region = "";
      const address_components = place.address_components;
      // eslint-disable-next-line array-callback-return
      address_components.map((component: any) => {
        if (component.types.includes("postal_code")) {
          postalCode = component.short_name || component.long_name;
        }
        if (component.types.includes("administrative_area_level_1")) {
          region = component.short_name || component.long_name;
        }
        if (component.types.includes("country")) {
          region = component.long_name || component.short_name;
        }
      });
      // Update the map's center to the selected place's location
      this.map.setCenter(place.geometry.location);
      this.map.setZoom(15); // Optional: Set a preferred zoom level

      // If a marker already exists, set its position to the new location
      if (this.marker) {
        this.marker.setPosition(place.geometry.location);
      } else {
        // Otherwise, create a new marker
        this.marker = new google.maps.Marker({
          map: this.map,
          position: place.geometry.location,
        });
      }

      this.props.onChangeLocation(
        place.geometry.location.lat(),
        place.geometry.location.lng(),
        `${place.name} ${place.formatted_address}`,
        address_components
      );
    }
  }

  getCurrentLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          // Center the map to the current location

          this.map.setCenter({ lat, lng });
          // If a marker already exists, set its position to the current location
          if (this.marker) {
            this.marker.setPosition({ lat, lng });
          } else {
            // Otherwise, create a new marker for the current location
            this.marker = new google.maps.Marker({
              map: this.map,
              position: { lat, lng },
            });
          }

          axios
            .get(
              `https://geocode.maps.co/reverse?lat=${lat}&lon=${lng}&api_key=669e10aa0854a480228272pbhcf0d67`
            )
            .then((res) => {
              if (res.data.display_name) {
                this.setState({ address: res.data.display_name });

                let location = this.props.handleLocation(
                  lat,
                  lng,
                  res.data.display_name,
                  [
                    {
                      long_name: res.data?.address?.city,
                      short_name: res.data?.address?.city,
                      types: ["administrative_area_level_1", "political"],
                    },
                    {
                      long_name: res.data?.address?.state,
                      short_name: res.data?.address?.state,
                      types: ["administrative_area_level_1", "political"],
                    },
                    {
                      long_name: res.data?.address?.country,
                      short_name: res.data?.address?.country,
                      types: ["administrative_area_level_1", "political"],
                    },
                  ]
                );
                this.props.setValue(
                  apiConstants.address,
                  res.data.display_name
                );
                this.props.setValue(apiConstants.lat, lat);
                this.props.setValue(apiConstants.lng, lng);
                this.props.setValue(apiConstants.postCode, location.zipcode);
                this.props.setValue(apiConstants.state, location.state);
                this.props.setValue(apiConstants.city, location.locality);
                this.props.setValue(apiConstants.country, location.country);
                this.props.onChangeLocation(lat, lng, res.data.display_name, [
                  {
                    long_name: res.data?.address?.city,
                    short_name: res.data?.address?.city,
                    types: ["administrative_area_level_1", "political"],
                  },
                  {
                    long_name: res.data?.address?.state,
                    short_name: res.data?.address?.state,
                    types: ["administrative_area_level_1", "political"],
                  },
                  {
                    long_name: res.data?.address?.country,
                    short_name: res.data?.address?.country,
                    types: ["administrative_area_level_1", "political"],
                  },
                ]);
              }
            })
            .catch((err) => {});
        },
        (error) => {
          console.log("error: ", error);
          showToast(
            "User's current location is not available.",
            "error",
            "current_location"
          );
          console.error("Error fetching geolocation:", error.message);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }

  render() {
    return (
      <>
        <div className="form_group google_search_bar">
          <div className="input_box location_listing">
            <InputField
              reference={this.autocompleteInput}
              // id="autocomplete" (disable this because of check validation before submit)
              name={this.props.name}
              value={this.state.address || this.props.value || ""}
              control={this.props.control}
              type="text"
              inputLabel={this.props.label}
              errors={
                this.props.errors && !(this.state.address || this.props.value)
                  ? this.props.errors
                  : this.props.errors
              }
              className={this.props.className}
              placeholder={this.props.placeholder}
              setValue={this.props.setValue}
              style={{ flexGrow: 1 }}
            />
            <img
              src={"/static/locationbtnselect.svg"}
              alt=""
              className="loc_sel_icon"
              onClick={this.getCurrentLocation.bind(this)}
            />
            {/* <img
              src="/static/currentlocation.svg"
              alt=""
              className="current_location_icon"
              onClick={this.getCurrentLocation.bind(this)}
            /> */}
          </div>
          <div
            className="google_map_view"
            ref={this.mapContainer}
            style={{
              width: "100%",
              height: "200px",
              display: this.props.hideMap ? "none" : "block",
            }}
          ></div>
        </div>
        {/* {this.state.showPopup && <LocationDeniedPopup onClose={() => { this.setState({ showPopup: false }); }} />} */}
      </>
    );
  }
}

export default GooglePlacesSearchBar;
