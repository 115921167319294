// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.media_wrapper {
  position: relative; }
  .media_wrapper .media_preview {
    width: 40px;
    height: 40px;
    position: absolute;
    top: calc(50% - 20px);
    left: calc(50% - 20px);
    cursor: pointer;
    z-index: 2; }
  .media_wrapper .media_loading_wrapper {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3; }
    .media_wrapper .media_loading_wrapper.not_supported {
      color: #fff;
      width: 100%; }
    .media_wrapper .media_loading_wrapper .media_loading {
      width: 20px;
      height: 20px;
      z-index: 3; }
`, "",{"version":3,"sources":["webpack://./src/components/ConnectUserProfile/MediaContainer/style.scss"],"names":[],"mappings":"AAAA;EACI,kBAAkB,EAAA;EADtB;IAOQ,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,qBAAqB;IACrB,sBAAsB;IACtB,eAAe;IACf,UAAU,EAAA;EAblB;IAmBQ,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,UAAU,EAAA;IAzBlB;MA4BY,WAAW;MACX,WAAW,EAAA;IA7BvB;MAiCY,WAAW;MACX,YAAY;MACZ,UAAU,EAAA","sourcesContent":[".media_wrapper {\n    position: relative;\n\n    .event_img {}\n\n\n    .media_preview {\n        width: 40px;\n        height: 40px;\n        position: absolute;\n        top: calc(50% - 20px);\n        left: calc(50% - 20px);\n        cursor: pointer;\n        z-index: 2;\n    }\n\n\n\n    .media_loading_wrapper {\n        position: absolute;\n        top: 0;\n        left: 0;\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        z-index: 3;\n\n        &.not_supported {\n            color: #fff;\n            width: 100%;\n        }\n\n        .media_loading {\n            width: 20px;\n            height: 20px;\n            z-index: 3;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
