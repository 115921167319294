import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { USER_TYPE } from "../../types";

import {
  dateFormatter,
  getItemFromStore,
  isObjEmpty,
  scrollToTop,
  setLocalStorage,
  showToast,
} from "../../utils";
import { apiConstants } from "../../utils/Constants";
import endpoints from "../../utils/endpoints";
import { makeGetCall, makePatchCall } from "../../utils/Requests";
import { ROUTE_CONSTANTS } from "../../utils/RouteConstants";
import { STRING_CONSTANTS } from "../../utils/StringConstant";
import BasicDetails from "./BasicDetails";
import {
  BasicDetailsValidation,
  BasicDetailsValidationGuest,
} from "./ProfileValidations";
import ApiLoader from "../../global/ApiLoader";
import GlobalHeader2 from "../Dashboard/GlobalHeader2";
import "./profile.scss";
import UserDetails from "./ManageProfileComponent/UserDetails";
import Interest from "./Interest";
import Trainer from "./Trainer";

const ManageProfile = () => {
  const location = useLocation();

  const userData = getItemFromStore("userData");
  const [userDataLoading, setUserDataLoading] = useState(false);

  //new state
  const [currentStep, setCurrentStep] = useState(1);
  const [coverMedia, setCoverMedia] = useState(null);
  const [profileMedia, setProfileMedia] = useState(null);

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    formState: { errors, dirtyFields },
    reset,
  } = useForm({
    resolver: yupResolver(
      getItemFromStore("userData")?.userType === USER_TYPE.GUEST
        ? BasicDetailsValidationGuest
        : BasicDetailsValidation
    ),
    mode: "onSubmit",
  });
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const attribute = queryParams.get("step");
    if (attribute && attribute == "interest") {
      setCurrentStep(2);
    }
  }, []);
  useEffect(() => {
    setUserDataLoading(true);
    makeGetCall({ url: endpoints.profile })
      .then((res) => {
        if (res.status.code === 200) {
          let data = {
            ...res.data?.userDetail,
            ...res.data?.businessDetail?.[0],
            ...res.data?.trainerData?.[0],
          };
          setLocalStorage("userData", data);
        } else {
          showToast(res.status.messgae, "error");
        }
        setUserDataLoading(false);
      })
      .catch((err) => {
        navigate(ROUTE_CONSTANTS.login);
        showToast(err, "error");
        setUserDataLoading(false);
      });
  }, []);

  useEffect(() => {
    if (userData && !isObjEmpty(userData)) {
      const decoUserData = {
        ...userData,

        businessContactNumber: userData?.businessContactNumber?.slice(3),
      };
      if (userData.dob) {
        decoUserData.dob = moment(userData.dob, "YYYY-MM-DD").toDate();
      }
      reset(decoUserData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  const onSubmit = (data: any) => {
    const formData = new FormData();

    if (getValues("dobDate") && getValues("dobMonth") && getValues("dobYear")) {
      const mergeDob = `${getValues("dobDate")} ${getValues(
        "dobMonth"
      )} ${getValues("dobYear")}`;
      const dateDob = moment(mergeDob, "DD MMMM YYYY").format(
        "ddd MMM DD YYYY 00:00:00 [GMT]ZZ [(India Standard Time)]"
      );
      formData.append(apiConstants.dob, dateFormatter(dateDob, "YYYY-MM-DD"));
    }

    Object.keys(dirtyFields).forEach((item: any) => {
      switch (item) {
        case "businessContactNumber":
          if (getValues(apiConstants.businessContactNumber).length > 3) {
            formData.append(
              apiConstants.businessContactNumber,
              `+61${data.businessContactNumber}`
            );
          }
          break;

        case "abn":
          formData.append(
            apiConstants.abn,
            getValues(apiConstants.abn).replaceAll(" ", "")
          );
          if (watch(apiConstants?.abnDetails)) {
            const abnDetails = watch(apiConstants.abnDetails) as any;
            formData.append("businessContactName", abnDetails?.EntityName);
            formData.append(
              "businessAddress",
              `${abnDetails?.AddressState} ${abnDetails?.AddressPostcode}`
            );
          }
          break;

        case "profilePicture":
          const fileData: any = getValues(apiConstants.profileImage);
          formData.append("profilePictureExtension", fileData?.type);
          formData.append(apiConstants.profileImage, fileData);
          break;

        case "coverPhotoVideo":
          formData.append(
            "cover_photo_video",
            getValues(apiConstants.coverPhoto)
          );
          formData.append(
            "cover_extension",
            getValues(apiConstants.coverExtension)
          );
          break;

        case "address":
          if (getValues(apiConstants.address)) {
            formData.append(
              apiConstants.address,
              getValues(apiConstants.address) || null
            );
            formData.append("latitude", getValues(apiConstants.lat) || null);
            formData.append("longitude", getValues(apiConstants.lng) || null);
            formData.append(
              "location",
              getValues(apiConstants.lng)
                ? `POINT (${getValues(apiConstants.lng)} ${getValues(
                    apiConstants.lat
                  )})`
                : null
            );
            formData.append(
              apiConstants.postCode,
              getValues(apiConstants.postCode)
            );
            formData.append(
              apiConstants.state,
              getValues(apiConstants.state) || null
            );
            formData.append(
              apiConstants.city,
              getValues(apiConstants.city) || null
            );
            formData.append(
              apiConstants.country,
              getValues(apiConstants.country) || null
            );
          }

          if (getValues(apiConstants.tradingName)) {
            formData.append(
              "trading_name",
              getValues(apiConstants.tradingName) || null
            );
          }
          break;

        default:
          if (getValues(item) !== null) formData.append(item, getValues(item));
          break;
      }
    });

    setIsLoading(true);
    makePatchCall({
      url: endpoints.profile,
      apiPayload: formData,
      content_type: "multipart/form-data",
    })
      .then((res) => {
        if (res?.status?.code === 200) {
          if (process.env.REACT_APP_IS_PROD === "true") {
            setCurrentStep(2);
            // navigate(ROUTE_CONSTANTS.joining + "?toast=true");
          } else {
            setCurrentStep(2);
            // navigate(ROUTE_CONSTANTS.business_intrest + "?toast=true");
            scrollToTop();
            setLocalStorage("userData", {
              ...res.data?.userDetail,
              ...res.data?.businessDetail,
              ...res.data?.trainerData,
            });
          }
        } else {
          showToast(res.status.message, "error");
        }
        setIsLoading(false);
      })
      .catch((err) => {
        showToast(err, "error");
        setIsLoading(false);
      });

    // if (
    //   getItemFromStore("userType") === USER_TYPE.MEMBER &&
    //   process.env.REACT_APP_IS_PROD !== "true"
    // ) {
    //   makeGetCall({
    //     url: `auth/${getValues(apiConstants.abn)}/validate-abn/`,
    //   })
    //     .then((res) => {
    //       if (res.status.code === 200) {
    //         updateBasicDetails();
    //       } else {
    //         showToast(STRING_CONSTANTS.wrong_data_message, "error");
    //       }
    //     })
    //     .catch((err) => {
    //       showToast(err, "error");
    //     });
    // } else {
    //   updateBasicDetails();
    // }
  };

  const onError = () => {
    showToast(STRING_CONSTANTS.general_form_error_message, "error");
  };

  const handleNext = (path?: string, dobError?: boolean) => {
    if (currentStep === 1) {
      if (!watch(apiConstants.name)?.trim()) {
        showToast("Please enter your full name", "error");
      } else if (watch("dobDate") || watch("dobMonth") || watch("dobYear")) {
        const mergeDob = `${getValues("dobDate")} ${getValues(
          "dobMonth"
        )} ${getValues("dobYear")}`;
        const dateDob = moment(mergeDob, "DD MMMM YYYY").format(
          "ddd MMM DD YYYY 00:00:00 [GMT]ZZ [(India Standard Time)]"
        );

        if (!watch("dobDate") || !watch("dobMonth") || !watch("dobYear")) {
          showToast("Please enter your complete date of birth", "error");
          return;
        } else if (moment(dateDob).isAfter(moment())) {
          showToast(
            "The date of birth cannot be in the future. Please enter a valid date of birth",
            "error"
          );
          return;
        } else {
          handleSubmit(onSubmit, onError)();
        }
      } else {
        handleSubmit(onSubmit, onError)();
      }
    } else {
      handleSubmit(onSubmit, onError)();
    }
  };

  const handleInterestSelectContinue = () => {
    if (watch(apiConstants.isTrainer)) {
      setCurrentStep(3);
    } else {
      navigate(ROUTE_CONSTANTS?.events);
    }
  };

  //new functions

  const handleProgressWidth = (step: number) => {
    switch (step) {
      case 1:
        return "33%";
      case 2:
        return "66%";
      case 3:
        return "100%";
      default:
        return "33%";
    }
  };

  const ProgressBar = () => {
    return (
      <div className="mp_progress_container">
        <div className="mp_progress">
          <div
            className="mp_current_progress"
            style={{ width: handleProgressWidth(currentStep) }}
          />
        </div>
      </div>
    );
  };

  const manageProfileSteps = (step: number) => {
    switch (step) {
      case 2:
        return (
          <Interest
            handleInterestSelectContinue={handleInterestSelectContinue}
          />
        );
      case 3:
        return (
          <Trainer
            handleInterestSelectContinue={handleInterestSelectContinue}
          />
        );
      default:
        return (
          <UserDetails
            coverMedia={coverMedia}
            setCoverMedia={setCoverMedia}
            profileMedia={profileMedia}
            setProfileMedia={setProfileMedia}
            handleNext={handleNext}
            control={control}
            errors={errors}
            isLoading={isLoading}
            setValue={setValue}
            getValues={getValues}
            watch={watch}
          />
        );
    }
  };

  const ProfileHeader = ({ step = 1 }) => {
    switch (step) {
      case 2:
        return (
          <>
            <div className="mp_heading">Your business interests</div>
            <div className="mp_description">
              Select at-least one interest to continue.
            </div>
          </>
        );

      case 3:
        return (
          <>
            <div className="mp_heading">Make your journey count</div>
            <div className="mp_description">
              Let us know about your expertise as a trainer and in your chosen
              interests!
            </div>
          </>
        );

      default:
        return (
          <>
            <div className="mp_heading">
              {STRING_CONSTANTS.basic_details_title}
            </div>
            <div className="mp_description">
              Add your business details to help your customers easily recognize
              and connect with your brand.
            </div>
          </>
        );
    }
  };

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    // Scroll to the top
    window.scrollTo(0, 0);

    // Update the URL parameters based on the current step
    let stepParam = "";
    switch (currentStep) {
      case 1:
        stepParam = "profile";
        break;
      case 2:
        stepParam = "interest";
        break;
      case 3:
        stepParam = "trainer";
        break;
      default:
        stepParam = "";
    }

    // Set the new URL search parameter
    if (stepParam) {
      setSearchParams({ step: stepParam });
    } else {
      searchParams.delete("step");
      setSearchParams(searchParams);
    }
  }, [currentStep, setSearchParams, searchParams]);

  const handleBack = () => {
    if (currentStep > 1) {
      setCurrentStep(currentStep - 1);
    } else {
      navigate(ROUTE_CONSTANTS?.events);
    }
  };

  return (
    <>
      {userDataLoading ? (
        <ApiLoader />
      ) : (
        <>
          <GlobalHeader2 />
          <div className="manage_profile_wrapper">
            <div className="mp_header">
              <ProfileHeader step={currentStep} />
              {/* <img
                className="back_btn"
                src="/static/arrow-back.svg"
                alt=""
                onClick={handleBack}
              /> */}
            </div>

            <div className="mp_progress_wrapper">
              <ProgressBar />
            </div>
            <>{manageProfileSteps(currentStep)}</>
          </div>
          {/* <div className="container-fluid p-0">
            <div className="manage_profile">
              <div className="profile_box">
                <div className="title">
                  {STRING_CONSTANTS.basic_details_title}
                </div>
                <div className="sub_title">
                  {getItemFromStore("userType") === USER_TYPE.GUEST
                    ? STRING_CONSTANTS.enter_details
                    : STRING_CONSTANTS.basic_details_desc}
                </div>
                <div className="progressbar">
                  <span
                    style={{
                      maxWidth:
                        getItemFromStore("userType") === USER_TYPE.GUEST
                          ? "50%"
                          : "30",
                    }}
                  ></span>
                </div>
                <BasicDetails
                  handleNext={handleNext}
                  control={control}
                  errors={errors}
                  isLoading={isLoading}
                  setValue={setValue}
                  getValues={getValues}
                  watch={watch}
                  setFileType={setFileType}
                  fileType={fileType}
                />
              </div>
            </div>
          </div> */}
        </>
      )}
    </>
  );
};

export default ManageProfile;
