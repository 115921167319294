import React from "react";
import "./style.scss";
import HorizontalLine from "../HorizontalLine/HorizontalLine";
import moment from "moment";
import { apiConstants } from "../../../utils/Constants";

type Props = {
  [key: string]: any;
};

const EventCardSimple = ({
  item,
  handleEventEditClick,
  onDeleteEvent,
  index,
  watch,
}: Props) => {
  return (
    <div className="simple_card_wrap">
      <div className="header_card">
        <div className="heading_1">{item.title}</div>
        <div className="action_card">
          <img
            src="/static/createListingIcon/editPencil.svg"
            alt=""
            onClick={() => handleEventEditClick(item)}
          />
          <img
            src="/static/createListingIcon/deleteTheme.svg"
            alt=""
            onClick={() => onDeleteEvent(index)}
          />
        </div>
      </div>

      {item?.address?.address && (
        <div className="body_1">
          <img src="/static/createListingIcon/location.svg" alt="" />
          <div>{item?.address?.address}</div>
        </div>
      )}
      <div className="d-flex gap-4 align-items-center">
        <div className="body_1">
          <img src="/static/createListingIcon/calendar.svg" alt="" />
          <div>{moment(item?.date, "YYYY-MM-DD").format("DD MMM, YYYY")}</div>
        </div>
        <div className="body_1">
          <img src="/static/createListingIcon/clockOutlineGrey.svg" alt="" />
          <div>{`${moment(item?.start_time, "HH:mm").format(
            "hh:mm A"
          )} - ${moment(item?.end_time, "HH:mm").format("hh:mm A")}`}</div>
        </div>
      </div>
      {watch(apiConstants.listing_event_type) === "V" &&
        watch(apiConstants.multiple_virtual_links_allowed) === true &&
        watch(apiConstants.virtual_links_single) && (
          <div className="body_1">
            <img src="/static/createListingIcon/linkGrey.svg" alt="" />
            <div>{watch(apiConstants.virtual_links_single) || "-"}</div>
          </div>
        )}

      {watch(apiConstants.listing_event_type) === "V" &&
        watch(apiConstants.multiple_virtual_links_allowed) === false &&
        item?.virtual_links_package && (
          <div className="body_1">
            <img src="/static/createListingIcon/linkGrey.svg" alt="" />
            <div>{item?.virtual_links_package || "-"}</div>
          </div>
        )}
    </div>
  );
};

const EventCardSection = (props: Props) => {
  const {
    data = [],
    handleEventEditClick,
    onDeleteEvent,
    setShowEventListingsSidebar,
    viewAll = true,
    totalData = 0,
    watch,
  } = props;
  return (
    <div className="event_card_container">
      {data?.map((item, index) => (
        <>
          <EventCardSimple
            key={item.title + index}
            item={item}
            handleEventEditClick={handleEventEditClick}
            onDeleteEvent={onDeleteEvent}
            index={index}
            watch={watch}
          />
          {data?.length - 1 !== index && <HorizontalLine />}
        </>
      ))}

      {viewAll && totalData > 2 && (
        <div className="view_all">
          <div
            className="viw_btn"
            onClick={() => setShowEventListingsSidebar(true)}
          >
            <div>
              <span style={{ textDecoration: "underline" }}>
                View All Events
              </span>{" "}
              <span style={{ textDecoration: "none" }}>{`(${totalData})`}</span>
            </div>
            <img src="/static/createListingIcon/forwardArrowBack.svg" alt="" />
          </div>
        </div>
      )}
    </div>
  );
};

export default EventCardSection;
