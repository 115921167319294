import { useEffect, useState } from "react";
import SearchFilter from "../ConnectComponents/SearchFilter";
import NoFavourites from "./NoFavourite";
import { makeGetCall, makeNextPageCall } from "../../../utils/Requests";
import { apiConstants } from "../../../utils/Constants";
import { scrollToTop, showToast } from "../../../utils";
import ConnectUserCard from "../ConnectComponents/ConnectUserCard";
import ReactPaginate from "react-paginate";
import { CONNECTION_TYPE } from "../ConnectConstants";
import ConnectMapContainer from "../ConnectComponents/ConnectMapContainer";
import useCheckDeviceScreen from "../../../hooks/useCheckScreen";
import NoSearchedFilter from "../ConnectComponents/NoSearchedFilter";

const ConnectFavourites = () => {
  const [search, setSearch] = useState("");
  const [showMap, setShowMap] = useState(false);
  const [serachData, setSearchData] = useState<any>();
  const [isSearchMode, setIsSearchMode] = useState(false);
  const [count, setCount] = useState(0);
  const [users, setUsers] = useState<any>([]);
  const [totalFavourites, setTotalFavourites] = useState(0);
  const [favouriteConnectionsList, setFavouriteConnectionsList] = useState([]);
  const [apiDetails, setApiDetails] = useState({
    prevUrl: "",
    nextUrl: "",
    pageCount: 1,
  });
  const [userCurrentLocation, setUserCurrentLocation] = useState({
    lat: 0,
    long: 0,
  });
  const [limit] = useState<number>(12);
  const [connectid, setConnectid] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [offset, setOffset] = useState<number>(0);
  const { isMobile } = useCheckDeviceScreen();

  const handleClearClick = () => {
    setSearch("");
    setIsLoading(true);
    setIsSearchMode(false);
  };

  const handleSuggestionSearch = (value: any) => {
    setSearch(value);
  };

  const handleSearchedSeeAllClick = () => {
    setIsSearchMode(true);
    setIsLoading(true);
    getFavouriteConnections();
  };
  const handleViewToggle = () => {
    setShowMap((prev) => !prev);
  };

  const handleUnfavouriteClick = (id: any) => {
    if (favouriteConnectionsList?.length > 0) {
      const filteredList = favouriteConnectionsList?.filter((item) => {
        if (item.id !== id) {
          return item;
        }
      });
      if (filteredList) {
        setFavouriteConnectionsList(filteredList);
        setTotalFavourites((prev) => prev - 1);
        if (isSearchMode) {
          setCount((prev) => prev - 1);
        }
      }
    }
  };
  const handlePageClick = (event: any) => {
    setIsLoading(true);
    setCurrentPage(event.selected);
    setOffset(event.selected * limit);
    if (apiDetails.nextUrl) {
      makeNextPageCall({ url: apiDetails.nextUrl })
        .then((res) => {
          if (res.next || res.previous) {
            setApiDetails({
              prevUrl: res.previous ?? "",
              nextUrl: res.next ?? "",
              pageCount: res.count ?? 1,
            });
          }
          setFavouriteConnectionsList(res.results);
          setIsLoading(false);
        })
        .catch((err) => {
          showToast(err, "error");
        });
    } else if (apiDetails.prevUrl) {
      makeNextPageCall({ url: apiDetails.prevUrl })
        .then((res) => {
          if (res.next || res.previous) {
            setApiDetails({
              prevUrl: res.previous ?? "",
              nextUrl: res.next ?? "",
              pageCount: res.count ?? 1,
            });
          }
          setFavouriteConnectionsList(res.results);
          setIsLoading(false);
        })
        .catch((err) => {
          showToast(err, "error");
        });
    }
    scrollToTop();
  };

  const getFavouriteConnections = (url = apiConstants.get_my_favourites) => {
    const params = { limit, offset };
    const mapParams = search ? { search: search } : {};
    makeGetCall({
      url,
      params: showMap
        ? mapParams
        : search
        ? { search: search, ...params }
        : params,
    })
      .then((res) => {
        if (res.status.code === 200) {
          const data = res.results;

          if (!isSearchMode) {
            setTotalFavourites(res.count);
          } else {
            setCount(res.count);
          }
          if (data) {
            setFavouriteConnectionsList(data);
          }
          if (res.next || res.previous) {
            setApiDetails({
              prevUrl: res.previous ?? "",
              nextUrl: res.next ?? "",
              pageCount: res.count ?? 1,
            });
          }

          setIsLoading(false);
        }
      })
      .catch((err: any) => {
        showToast(err, "error");
      });
  };

  const getSearchedSuggestion = () => {
    const params = {
      point:`${userCurrentLocation.long},${userCurrentLocation.lat}`,
      /* point:
        userCurrentLocation.lat && userCurrentLocation.long
          ? `${userCurrentLocation.lat},${userCurrentLocation.long}`
          : "77.7952727,26.8611005", */
      limit: 8,
      search: search,
    };
    makeGetCall({
      url: apiConstants.get_my_favourites,
      params,
    })
      .then((res) => {
        if (res.status.code === 200) {
          const data = res.results;
          setSearchData(data);
        }
      })
      .catch((err: any) => {
        showToast(err, "error");
      });
  };

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserCurrentLocation({
            lat: position.coords.latitude,
            long: position.coords.longitude,
          });
          getFavouriteConnections();
        },
        (err) => {
          showToast(
            "We kindly ask for your location to enhance your app experience; however, we regret to inform you that without this information, we are unable to proceed further.",
            "error"
          );
        }
      );
    } else {
      showToast("Geolocation is not available in your browser.", "error");
    }
  }, []);

  useEffect(() => {
    if (search !== "") {
      setIsSearchMode(true);
      getSearchedSuggestion();
    }
    if (search === "") {
      setIsSearchMode(false);
      getFavouriteConnections();
    }
  }, [search]);

  return (
    <div style={{ width: "100%" }}>
      {!isMobile && (
        <div className="connect-page-heading">{`Favourites(${totalFavourites})`}</div>
      )}
      {(favouriteConnectionsList.length !== 0 || isSearchMode) && (
        <SearchFilter
          showMap={showMap}
          handleViewToggle={handleViewToggle}
          handleSearchChange={handleSuggestionSearch}
          serachData={serachData}
          handleSeeAll={handleSearchedSeeAllClick}
          handleClearSearch={handleClearClick}
        />
      )}
      {isSearchMode && (
        <div
          style={{
            fontSize: "14px",
            fontFamily: "Poppins",
            lineHeight: "24px",
            fontWeight: "500",
            margin: "21px 0px 12px 0px",
          }}
        >{`Search results (${count})`}</div>
      )}
      {isLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            marginTop: "200px",
          }}
        >
          <div className="lds-ring">
            <div style={{ textAlign: "center" }}>
              <img
                style={{ width: "50px" }}
                src="/static/spinner.svg"
                alt="loader"
              />
            </div>

            <div className="text__container">
              <span>Please wait... Loading</span>
            </div>
          </div>
        </div>
      ) : !showMap ? (
        favouriteConnectionsList.length === 0 ? (
          isSearchMode ? (
            <NoSearchedFilter searchTerm={search} />
          ) : (
            <NoFavourites />
          )
        ) : (
          <div>
            <div className="content-title"></div>
            <div className="content-style">
              {favouriteConnectionsList.map((connection) => (
                <ConnectUserCard
                  connectionData={connection}
                  key={connection.id}
                  mode={CONNECTION_TYPE.FAVOURITES}
                  isScrollable={false}
                  handleUnfavouriteClick={handleUnfavouriteClick}
                />
              ))}
            </div>
            {totalFavourites > 10 && (
              <div className="paginate-style">
                <div className="pages-inner-container">
                  <div className="pagination-box">
                    <ReactPaginate
                      breakLabel="..."
                      nextLabel={
                        <>
                          <div
                            className={
                              Math.ceil(apiDetails.pageCount / limit) ===
                              currentPage + 1
                                ? "previous-button disabled"
                                : "previous-button"
                            }
                          >
                            <img src="/static/u_angle-left-b(1).svg" alt="" />
                            {!isMobile ? "Next " : ""}
                          </div>
                        </>
                      }
                      onPageChange={handlePageClick}
                      pageRangeDisplayed={!isMobile ? 5 : 2}
                      forcePage={currentPage}
                      pageCount={Math.ceil(apiDetails.pageCount / limit)}
                      previousLabel={
                        <>
                          <div className="previous-button">
                            {!isMobile ? "Previous " : ""}
                            <img src="/static/u_angle-left-b.svg" alt="" />
                          </div>
                        </>
                      }
                      renderOnZeroPageCount={null}
                      pageClassName="page-item"
                      pageLinkClassName="page-link"
                      previousClassName="page-item"
                      previousLinkClassName="page-link"
                      nextClassName="page-item"
                      nextLinkClassName="page-link"
                      breakClassName="page-item"
                      breakLinkClassName="page-link"
                      containerClassName="pagination"
                      activeClassName="active"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        )
      ) : (
        <div className="inner-contet">
          <div className="content-title"></div>
          <ConnectMapContainer
            eventMap={favouriteConnectionsList}
            latitude={userCurrentLocation.lat}
            longitude={userCurrentLocation.long}
            mode={CONNECTION_TYPE.FAVOURITES}
          />
        </div>
      )}
    </div>
  );
};

export default ConnectFavourites;
