import React, { useEffect } from "react";
import { STRING_CONSTANTS } from "../../../utils/StringConstant";
import { useNavigate, useSearchParams } from "react-router-dom";
import ListingCard from "../../Dashboard/CreateListing.tsx/ListingCard";
import "./style.scss";
import { CrossIcon, ErrorInfo } from "../../../utils/Icons/svgs";
import { showToast } from "../../../utils";

type Props = {
  [key: string]: any;
};

const SelectListingType = (props: Props) => {
  const {
    handleListingType,
    draftList = [],
    listingTypes,
    isGuest = false,
    isVisibleInfo = true,
    setIsVisibleInfo,
    isGuestLimit = true,
  } = props;
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const updateQueryParams = (val) => {
    setSearchParams({ type: val });
  };

  return (
    <>
      <div className="select_listing_container">
        <div className="wrap_select_listing">
          {isGuest && isVisibleInfo && (
            <div className="infoguestlisting">
              <div className="dl-email-verified_listing">
                <div>
                  <ErrorInfo />
                </div>
                <div>
                  <p className=" dl-title mb-0">
                    Being a guest user, you can only create 1 listing per week.
                  </p>
                  <p
                    className="dl-subtitle mb-0 cursor-pointer"
                    onClick={() =>
                      navigate("/subscription?SubscriptionType=true")
                    }
                  >
                    Click here to upgrade as a member.
                  </p>
                </div>
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    setIsVisibleInfo(false);
                  }}
                >
                  <CrossIcon width="16" height="16" fill="white" />
                </div>
              </div>
            </div>
          )}

          <div className="heading_header">
            {STRING_CONSTANTS.select_listing_type}
          </div>

          <div
            className="card_listing_type_wrap"
            style={{
              justifyContent: isGuest && isVisibleInfo ? "center" : "stretch",
            }}
          >
            {listingTypes.map((item, index) => {
              return (
                <div
                  className="card_inner"
                  onClick={() => {
                    if (!isGuestLimit) {
                      showToast(
                        "Your weekly limit to  create a listing has been reached!",
                        "error"
                      );
                    } else {
                      handleListingType(item);
                      updateQueryParams(item?.type);
                    }
                  }}
                  key={index}
                >
                  <div className="card-body">
                    <img src={item.icon} alt="" />
                    <div className="card_title">{item.title}</div>
                    <div className="card_desc">{item.desc}</div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="or-divider-wrap">
            <div className="divide-line" />
            <div> OR</div>
            <div className="divide-line" />
          </div>
          <button
            className={"draft_btn_add"}
            disabled={draftList?.length < 1}
            onClick={() => navigate("/drafts")}
          >
            Continue from draft
          </button>
        </div>
      </div>
    </>
  );
};

export default SelectListingType;
