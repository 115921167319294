import React from "react";
import DatePicker from "react-datepicker";
import { Controller, useController } from "react-hook-form";

interface EventTimeSelectorProps {
  name: string;
  label?: string;
  style?: React.CSSProperties;
  className?: string;
  errors?: object | any;
  id?: string;
  control?: any;
  minDate?: any;
  maxDate?: any;
  showMonthYearPicker?: boolean;
  dateFormat?: string;
  dobError?: boolean;
}
const EventTimeSelector = (props: EventTimeSelectorProps) => {
  const { name, control, errors } = props;
  const {
    field: { onChange, value },
  } = useController({
    name,
    control,
  });

  return (
    <Controller
      control={control}
      name={name}
      render={() => {
        return (
          <>
            <DatePicker
              selected={value || null}
              onChange={onChange}
              showTimeSelect
              showTimeSelectOnly
              timeIntervals={30}
              // timeCaption="Time"
              dateFormat="h:mm aa"
              icon={
                <img
                  src="/static/createListingIcon/down.svg"
                  alt=""
                  className="arrowIcon"
                  width={"24px"}
                  height={"24px"}
                />
              }
              showIcon
              toggleCalendarOnIconClick
            />
            {errors && errors[name] ? (
              <span className="text-danger">{errors[name]?.message}</span>
            ) : null}
          </>
        );
      }}
    />
  );
};

export default EventTimeSelector;
