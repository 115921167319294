import LocationIcon from "../../assets/img/icons/LocationIcon";
import TimeIcon from "../../assets/img/icons/TimeIcon";
import IconTeam from "../../assets/img/createListingIcon/icon-team.svg";
import IconPackage from "../../assets/img/createListingIcon/icon-package.svg";
import IconPhysicalEvent from "../../assets/img/createListingIcon/physical-orange.svg";
import IconVirtualEvent from "../../assets/img/createListingIcon/virtual-blue.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import IconSession from "../../assets/img/createListingIcon/icon-session.png";
import moment from "moment";
import { AddToCalendarButton } from "add-to-calendar-button-react";
import { Link } from "react-router-dom";
import { showToast } from "../../utils";

const MySchedulingCard = ({ item, setSideList, setMapCenter }) => {
  const handligSideAndCenter = () => {
    setSideList(true);
    setMapCenter({ lat: Number(item?.latitude), lng: Number(item?.longitude) });
  };

  return (
    <>
      {item && (
        <div className="my-schedule-wrap">
          <div className="left-content">
            <div style={{ minWidth: "50px" }}>
              <div className="day">
                {item.date === moment().format("YYYY-MM-DD") ? (
                  <span>Today</span>
                ) : (
                  moment(item.date).format("dddd").substring(0, 3)
                )}
              </div>
              <div className="date me-3">{item.date.split("-")[2]}</div>
            </div>
            <div className="vertical-divider" />
            <div>
              <div
                style={{ display: "flex", gap: "16px", alignItems: "center" }}
              >
                <Link
                  to={`/listing-two/${item.event.listing.id}`}
                  style={{ textDecoration: "none" }}
                >
                  <div className="title">{item.title}</div>
                </Link>
                <div
                  className="d-none d-md-flex"
                  style={{
                    backgroundColor:
                      item?.listingType === "P" ? "#F3F3F3" : "#EE7830",
                    padding: "2px 16px 2px 12px",
                    borderRadius: "8px",
                    color: item?.listingType === "P" ? "#EE7830" : "#FFFFFF",
                    fontSize: "14px",
                    fontWeight: "500",
                    fontFamily: "Poppins",
                    display: "flex",
                    gap: "4px",
                  }}
                >
                  {item?.listingType === "S" ||
                  item?.listingType === "SPECIAL" ? (
                    <img src={IconSession} alt="session" />
                  ) : item?.listingType === "P" ? (
                    <img src={IconPackage} alt="package" />
                  ) : (
                    <img src={IconTeam} alt="team" />
                  )}
                  {item?.listingType === "S" ||
                  item?.listingType === "SPECIAL" ? (
                    <span>Session</span>
                  ) : item?.listingType === "P" ? (
                    <span>Package</span>
                  ) : (
                    <span>Team</span>
                  )}
                </div>
              </div>
              <div className="d-md-none sub-content-wrap">
                {/* <div className="d-flex">
                  <div
                    className="map-link"
                    style={{
                      textDecoration: "none",
                      marginLeft: "4px",
                      color:
                        item.listingEventType === "V" ? "#0099FF" : "#EE762F",
                    }}
                  >
                    <span>
                      {item.listingEventType === "V" ? "Virtual" : "Physical"}
                    </span>
                    <img
                      style={{
                        marginLeft: "4px",
                        height: "16px",
                        width: "16px",
                      }}
                      src={
                        item.listingEventType === "V"
                          ? IconVirtualEvent
                          : IconPhysicalEvent
                      }
                    />
                  </div>
                </div> */}
                <div
                  className="d-flex d-md-none"
                  style={{
                    backgroundColor:
                      item?.listingType === "P" ? "#F3F3F3" : "#EE7830",
                    padding: "2px 8px 2px 8px",
                    borderRadius: "8px",
                    color: item?.listingType === "P" ? "#EE7830" : "#FFFFFF",
                    fontSize: "12px",
                    fontWeight: "500",
                    lineHeight: "16px",
                    fontFamily: "Poppins",
                    display: "flex",
                    gap: "4px",
                    alignItems: "center",
                  }}
                >
                  {item?.listingType === "S" ||
                  item?.listingType === "SPECIAL" ? (
                    <img
                      src={IconSession}
                      alt="session"
                      height="13px"
                      width="13px"
                    />
                  ) : item?.listingType === "P" ? (
                    <img
                      src={IconPackage}
                      alt="package"
                      height="13px"
                      width="13px"
                    />
                  ) : (
                    <img src={IconTeam} alt="team" height="13px" width="13px" />
                  )}
                  {item?.listingType === "S" ||
                  item?.listingType === "SPECIAL" ? (
                    <span>Session</span>
                  ) : item?.listingType === "P" ? (
                    <span>Package</span>
                  ) : (
                    <span>Team</span>
                  )}
                </div>
                <div
                  style={{
                    borderRadius: "12px",
                    padding: "4px 10px",
                    backgroundColor:
                      item.listingEventType === "V" ? "#E6F5FF" : "#FEEFE7",
                    color: "#25272D",
                    fontFamily: "Poppins",
                    fontSize: "12px",
                    fontWeight: "400",
                  }}
                >
                  <img
                    src={
                      item.listingEventType === "V"
                        ? IconVirtualEvent
                        : IconPhysicalEvent
                    }
                    alt="icon"
                  />
                  <span style={{marginLeft:"6px"}}>
                    {item.listingEventType === "V" ? "Virtual" : "Physical"}{" "}
                  </span>
                </div>
                <div className="content-items">
                  <span>
                    <TimeIcon color="#25272D" />
                  </span>
                  <span className="sub-title" style={{ color: "#25272D" }}>
                    {moment(item?.startTime?.substring(0, 5), ["HH:mm"]).format(
                      "hh:mmA"
                    )}{" "}
                    -{" "}
                    {moment(item?.endTime?.substring(0, 5), ["HH:mm"]).format(
                      "hh:mmA"
                    )}
                  </span>
                </div>
                {item.listingEventType !== "V" ? (
                  <div className="content-items">
                    <span>
                      <LocationIcon />
                    </span>
                    <div className="t-sub-title-box">
                      <span className="sub-title" style={{ color: "#25272D" }}>
                        {item.address}
                      </span>
                    </div>
                    <span className="map-link" onClick={handligSideAndCenter}>
                      Open maps
                    </span>
                  </div>
                ) : (
                  <div
                    className="content-items"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div
                      className="open-maps-box-booking-detail"
                      style={{ cursor: "pointer" }}
                    >
                      <img alt="" src="/static/meet.svg" />
                      <span
                        className="map-link"
                        style={{
                          color: "#25272D",
                          marginLeft: "4px",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          window.open(item.virtualLink);
                        }}
                      >
                        Meet Link
                      </span>
                      <CopyToClipboard
                        onClick={(e) => e.stopPropagation()}
                        onCopy={() => showToast("Copied!!", "success")}
                        text={item.virtualLink}
                        style={{ marginLeft: "7px" }}
                      >
                        <img
                          alt=""
                          src="/static/copyblue.svg"
                          className="copy-btn"
                        />
                      </CopyToClipboard>
                    </div>
                  </div>
                )}
              </div>
              <div className="d-none d-md-flex sub-content-wrap">
                <div className="d-flex">
                  <span className="sub-title" style={{ color: "#25272D" }}>
                    Event Type:
                  </span>
                  <div
                    className="map-link"
                    style={{
                      textDecoration: "none",
                      marginLeft: "4px",
                      color:
                        item.listingEventType === "V" ? "#0099FF" : "#EE762F",
                    }}
                  >
                    <span>
                      {item.listingEventType === "V" ? "Virtual" : "Physical"}
                    </span>
                    <img
                      style={{
                        marginLeft: "4px",
                        height: "16px",
                        width: "16px",
                      }}
                      src={
                        item.listingEventType === "V"
                          ? IconVirtualEvent
                          : IconPhysicalEvent
                      }
                    />
                  </div>
                </div>

                {item.listingEventType !== "V" ? (
                  <div className="content-items">
                    <span>
                      <LocationIcon />
                    </span>
                    <div className="t-sub-title-box">
                      <span className="sub-title" style={{ color: "#25272D" }}>
                        {item.address}
                      </span>
                    </div>
                    <span className="map-link" onClick={handligSideAndCenter}>
                      Open maps
                    </span>
                  </div>
                ) : (
                  <div
                    className="content-items"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div
                      className="open-maps-box-booking-detail"
                      style={{ cursor: "pointer" }}
                    >
                      <img alt="" src="/static/meet.svg" />
                      <span
                        className="map-link"
                        style={{
                          color: "#25272D",
                          marginLeft: "4px",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          window.open(item.virtualLink);
                        }}
                      >
                        Meet Link
                      </span>
                      <CopyToClipboard
                        onClick={(e) => e.stopPropagation()}
                        onCopy={() => showToast("Copied!!", "success")}
                        text={item.virtualLink}
                        style={{ marginLeft: "7px" }}
                      >
                        <img
                          alt=""
                          src="/static/copyblue.svg"
                          className="copy-btn"
                        />
                      </CopyToClipboard>
                    </div>
                  </div>
                )}
                <div className="content-items">
                  <span>
                    <TimeIcon color="#25272D" />
                  </span>
                  <span className="sub-title" style={{ color: "#25272D" }}>
                    {moment(item?.startTime?.substring(0, 5), ["HH:mm"]).format(
                      "hh:mmA"
                    )}{" "}
                    -{" "}
                    {moment(item?.endTime?.substring(0, 5), ["HH:mm"]).format(
                      "hh:mmA"
                    )}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div
            className="cal-btn d-none d-md-flex"
            style={{ display: "flex", gap: "34px" }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="avatar-participants-container d-none d-md-flex">
              <div className="avatar-container">
                {item?.event?.bookersImage?.slice(0, 3)?.map((item:any) => {
                  return (
                    <Link to={`/our-mates/${item.id}`} key={item.id}>
                      {["mp4", "mov", "avi"].includes(
                        item?.profilePicture
                          ?.split(".")
                          .splice(-1)[0]
                          .toLowerCase()
                      ) ? (
                        <>
                          <div className="avatar-item">
                            <video
                              src={item?.profilePicture}
                              autoPlay
                              playsInline
                              loop
                              muted
                              style={{
                                cursor: "pointer",
                                width: "20px",
                                height: "20px",
                                objectFit: "cover",
                                verticalAlign: "middle",
                                borderRadius: "100%",
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              <source src={item?.profilePicture} />
                            </video>
                          </div>
                        </>
                      ) : (
                        <div key={item.profilePicture} className="avatar-item">
                          <img
                            src={
                              item.profilePicture ||
                              "/static/dummyimg.svg"
                            }
                            alt=""
                          />
                        </div>
                      )}
                    </Link>
                  );
                })}
              </div>
              <span className="t-participants">+ {item.participants}</span>
            </div>
            <AddToCalendarButton
              name={item?.title}
              startDate={item?.date}
              startTime={item?.startTime?.substring(0, 5)}
              endTime={item?.endTime?.substring(0, 5)}
              trigger="click"
              hideCheckmark={true}
              size="5|3|2"
              options={["Apple", "Google", "Outlook.com", "iCal"]}
              styleLight="--btn-background: #faebe3;--btn-background-hover:   #faebe3; --btn-text: #ee7830;--btn-text-hover: #ee7830; --font: Poppins ;"
              styleDark="--btn-background: #faebe3; --btn-text: #ee7830; --font: Georgia, 'Times New Roman', Times, serif;"
            ></AddToCalendarButton>

            {/* <OutlineButton icon={<CalendarAddIcon />} text="Add to Calendar" /> */}
          </div>
          <div className="d-md-none d-flex justify-content-between" style={{marginTop:"12px"}}>
            <div className="avatar-participants-container ">
              <div className="avatar-container">
                <div className="avatar-item">
                  <img src="/static/dummyimg.svg" alt="" />
                </div>
                <div className="avatar-item">
                  <img src="/static/dummyimg.svg" alt="" />
                </div>
                <div className="avatar-item">
                  <img src="/static/dummyimg.svg" alt="" />
                </div>
              </div>
              <span className="t-participants">+ {item.participants}</span>
            </div>
            <div
              className="cal-btn"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <AddToCalendarButton
                name={item?.title}
                startDate={item?.date}
                startTime={item?.startTime?.substring(0, 5)}
                endTime={item?.endTime?.substring(0, 5)}
                trigger="click"
                hideCheckmark={true}
                size="5|3|2"
                options={["Apple", "Google", "Outlook.com", "iCal"]}
                styleLight="--btn-background: #faebe3;--btn-background-hover:   #faebe3; --btn-text: #ee7830;--btn-text-hover: #ee7830; --font: Poppins ;"
                styleDark="--btn-background: #faebe3; --btn-text: #ee7830; --font: Georgia, 'Times New Roman', Times, serif;"
              ></AddToCalendarButton>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default MySchedulingCard;
