// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  min-width: 100vw;
  min-height: 100dvh;
  z-index: 10000;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.42);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px); }
  .loader_wrapper .inner_wrap {
    display: flex; }
    .loader_wrapper .inner_wrap .spinner_img {
      width: 40px;
      height: 40px; }
`, "",{"version":3,"sources":["webpack://./src/components/ConnectUserProfile/LoaderLayer/style.scss"],"names":[],"mappings":"AAAA;EACI,eAAe;EACf,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,kBAAkB;EAClB,cAAc;EACd,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,+BAA+B;EAC/B,kCAA0B;UAA1B,0BAA0B,EAAA;EAb9B;IAgBQ,aAAa,EAAA;IAhBrB;MAmBY,WAAW;MACX,YAAY,EAAA","sourcesContent":[".loader_wrapper {\n    position: fixed;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    min-width: 100vw;\n    min-height: 100dvh;\n    z-index: 10000;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    background: rgba(0, 0, 0, 0.42);\n    backdrop-filter: blur(4px);\n\n    .inner_wrap {\n        display: flex;\n\n        .spinner_img {\n            width: 40px;\n            height: 40px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
