// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.info_container {
  width: 100%;
  padding: 10px 12px 10px 12px;
  display: flex;
  align-items: flex-start;
  gap: 10px;
  border-radius: 10px;
  background: #F3F3F3; }
  .info_container .info_text {
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    text-align: left;
    color: #52545B; }
  .info_container img {
    width: 16px;
    height: 16px; }
`, "",{"version":3,"sources":["webpack://./src/components/ListingComponents/InfoCard/infoStyle.scss"],"names":[],"mappings":"AAAA;EACI,WAAW;EACX,4BAA4B;EAC5B,aAAa;EACb,uBAAuB;EACvB,SAAS;EACT,mBAAmB;EACnB,mBAAmB,EAAA;EAPvB;IAUQ,oBAAoB;IACpB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,gBAAgB;IAChB,cAAc,EAAA;EAftB;IAmBQ,WAAW;IACX,YAAY,EAAA","sourcesContent":[".info_container {\n    width: 100%;\n    padding: 10px 12px 10px 12px;\n    display: flex;\n    align-items: flex-start;\n    gap: 10px;\n    border-radius: 10px;\n    background: #F3F3F3;\n\n    .info_text {\n        font-family: Poppins;\n        font-size: 12px;\n        font-weight: 400;\n        line-height: 16px;\n        text-align: left;\n        color: #52545B;\n    }\n\n    img {\n        width: 16px;\n        height: 16px;\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
