/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ReactInputMask from "react-input-mask";
import InputField from "../../global/InputField";
import { showToast } from "../../utils";
import { apiConstants } from "../../utils/Constants";
import { ExpertiseDefaultValue } from "../../utils/FormConfig";
import { STRING_CONSTANTS } from "../../utils/StringConstant";

interface ExpCardProps {
  expdata?: any;
  item: any;
  updateExpById: any;
  userAge: any;
}
const ExpCard = (props: ExpCardProps) => {
  const { item, updateExpById, userAge } = props;
  const [open, setOpen] = useState(false);
  const {
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: ExpertiseDefaultValue,
    mode: "onChange",
  });
  useEffect(() => {
    setValue(apiConstants.notes, item?.interestInformation);
  }, [item]);

  useEffect(() => {
    if (window.location.pathname === "/experience") {
      if (item?.interestInformation) {
        setOpen(true);
      }
    }
  }, [item, window.location]);

  return (
    <>
      <div className="d-none d-md-block">
        <div style={{ display: "flex" }}>
          <img src={item?.image} alt="" className="expertise-img" />
          <div className="txtb">
            <h4>{item?.title} </h4>
            <div style={{ display: "flex", gap: "12px", position: "relative" }}>
              <span className="optional_txt">(optional)</span>
              <div className="form_group">
                <div className="input_box">
                  <span className="input_label traning-label">{"Years"}</span>
                  <ReactInputMask
                    mask="99"
                    maskChar={null}
                    onChange={(e: any) => {
                      if (!userAge) {
                        updateExpById({
                          id: item?.id,
                          value: e.target.value,
                          type: "year",
                        });
                      } else if (Number(e.target.value) < userAge) {
                        updateExpById({
                          id: item?.id,
                          value: e.target.value,
                          type: "year",
                        });
                      } else {
                        showToast(
                          `Expertise should not be more than ${userAge - 1
                          } years`,
                          "error",
                          "expertise-age"
                        );
                      }
                    }}
                    value={item?.years}
                  />
                </div>
              </div>
              <div className="form_group">
                <div className="input_box">
                  <span className="input_label traning-label">{"Month"}</span>
                  <ReactInputMask
                    mask="99"
                    maskChar={null}
                    onChange={(e: any) => {
                      if (
                        Number(e.target.value) >= 0 &&
                        Number(e.target.value) <= 11
                      ) {
                        updateExpById({
                          id: item?.id,
                          value: e.target.value,
                          type: "month",
                        });
                      } else {
                        showToast(
                          STRING_CONSTANTS.month_not_more_than_12,
                          "error"
                        );
                      }
                    }}
                    value={item?.months}
                  />
                </div>
              </div>
            </div>
            {!open ? (
              <div className="tell-us-more-box">
                <span className="tell-us-more" onClick={() => setOpen(!open)}>
                  Tell us more
                </span>
              </div>
            ) : null}

            {open ? (
              <div
                className="form_group"
                style={{ marginBottom: "12px", marginTop: "10px" }}
              >
                <div className="input_box">
                  <span className="textarea-label">Additional note</span>
                  <InputField
                    type={"textarea"}
                    name={apiConstants.notes}
                    className={"listing_desc"}
                    placeholder={"Tell others more about your expertise"}
                    control={control}
                    getValues={getValues}
                    maxlength={300}
                    errors={errors}
                    setValue={setValue}
                    handleNotes={(e: any) => {
                      updateExpById({
                        id: item?.id,
                        value: e.target.value,
                        type: "interestInformation",
                      });
                    }}
                  />
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="d-md-none d-flex">
        <div className="mobile-card">
          <div style={{ display: "flex" }}>
            <img src={item?.image} alt="" className="expertise-img" />
            <div className="txtb">
              <h4>{item?.title} </h4>
              <div
                style={{ display: "flex", gap: "12px", position: "relative" }}
              >
                <span className="optional_txt">(optional)</span>
                <div className="form_group">
                  <div className="input_box">
                    <span className="input_label traning-label">{"Years"}</span>
                    <ReactInputMask
                      mask="99"
                      maskChar={null}
                      onChange={(e: any) => {
                        if (!userAge) {
                          updateExpById({
                            id: item?.id,
                            value: e.target.value,
                            type: "year",
                          });
                        } else if (Number(e.target.value) < userAge) {
                          updateExpById({
                            id: item?.id,
                            value: e.target.value,
                            type: "year",
                          });
                        } else {
                          showToast(
                            `Expertise should not be more than ${userAge - 1
                            } years`,
                            "error",
                            "expertise-age-mob"
                          );
                        }
                      }}
                      value={item?.years}
                    />
                  </div>
                </div>
                <div className="form_group">
                  <div className="input_box">
                    <span className="input_label traning-label">{"Month"}</span>
                    <ReactInputMask
                      mask="99"
                      maskChar={null}
                      onChange={(e: any) => {
                        if (
                          Number(e.target.value) >= 0 &&
                          Number(e.target.value) <= 11
                        ) {
                          updateExpById({
                            id: item?.id,
                            value: e.target.value,
                            type: "month",
                          });
                        } else {
                          showToast(
                            STRING_CONSTANTS.month_not_more_than_12,
                            "error"
                          );
                        }
                      }}
                      value={item?.months}
                    />
                  </div>
                </div>
              </div>
              {!open ? (
                <div className="tell-us-more-box">
                  <span className="tell-us-more" onClick={() => setOpen(!open)}>
                    Tell us more
                  </span>
                </div>
              ) : null}
            </div>
          </div>
          {open ? (
            <div
              className="form_group"
              style={{ marginBottom: "0px", marginTop: "10px" }}
            >
              <div className="input_box">
                <span className="textarea-label">Additional note</span>
                <InputField
                  type={"textarea"}
                  name={apiConstants.notes}
                  className={"listing_desc"}
                  placeholder={"Tell others more about your expertise"}
                  control={control}
                  getValues={getValues}
                  maxlength={300}
                  errors={errors}
                  setValue={setValue}
                  handleNotes={(e: any) => {
                    updateExpById({
                      id: item?.id,
                      value: e.target.value,
                      type: "interestInformation",
                    });
                  }}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default ExpCard;
