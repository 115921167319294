import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store/store";
import * as Sentry from "@sentry/react";
import App from "./App";
import "./index.css";
import { APP_ENV } from "./utils/Constants";

Sentry.init({
  dsn: process.env.REACT_APP_DSN_LINK,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  environment: process.env.REACT_APP_SENTRY_ENV,
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [ "https://dev.fitnessmates.com.au/", "https://staging.fitnessmates.com.au/", "https://fitnessmates.com.au/" ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const loadNewRelicScript = () => {
  const script = document.createElement("script");
  
  // Check the environment and set the script source accordingly
  if (process.env.REACT_APP_NEW_RELIC_ENV === APP_ENV.STAGING) {
    script.src = `${process.env.PUBLIC_URL}/newrelic-staging.js`; // Staging New Relic
  }
   if(process.env.REACT_APP_NEW_RELIC_ENV === APP_ENV.PRODUCTION) {
    script.src = `${process.env.PUBLIC_URL}/newrelic-prod.js`;    // Production New Relic
  }

  // Append the script to the body
  document.body.appendChild(script);
};

// Load the New Relic script dynamically
loadNewRelicScript();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
