/* eslint-disable react-hooks/exhaustive-deps */
import { act, useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GENDER_PREFERENCE, LISTING_TYPE, USER_TYPE } from "../../../types";
import Modal from "react-bootstrap/Modal";
import { UseClickOutside } from "./UseClickOutside";
import {
  getEventsList,
  getItemFromStore,
  showToast,
  getIntrestListFeatured,
  getEventsListMap,
  getSearchList,
  scrollToTop,
  formatNumber,
} from "../../../utils";
import {
  EventListingType,
  ResponsiveEventListingType,
} from "../../../utils/Constants";
import ReactPaginate from "react-paginate";
import ReactSimplyCarousel from "react-simply-carousel";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import Button from "../../../global/Button";
import { ROUTE_CONSTANTS } from "../../../utils/RouteConstants";
import GlobalHeader2 from "../GlobalHeader2";
import GlobalFooter from "../GlobalFooter";
import { Pagination } from "react-bootstrap";
import useCheckDeviceScreen from "../../../hooks/useCheckScreen";
import MapContainer from "../../Connect/MapContainer";
import FilterPopup from "./FilterPopup";
import EventsMapContainer from "./EventsMapContainer";
import moment from "moment";
import img from "../../../assets/img/fitness.png";
import img2 from "../../../assets/img/star.png";
import Reactangleimg from "../../../assets/img/reactangle.svg";
import Ellipse from "../../../assets/img/ellipse.svg";
import Dummyimg from "../../../assets/img/dummyimg.svg";

import { ClassNames } from "@emotion/react";
import endpoints from "../../../utils/endpoints";
import {
  makeDeleteCall,
  makePatchCall,
  makePostCall,
} from "../../../utils/Requests";
import dayjs from "dayjs";
const Vector2 = require("../../../assets/img/Physical.svg").default;

const Events2 = () => {
  const ref = useRef<HTMLDivElement>(null);
  const searchRef = useRef<HTMLDivElement>(null);
  const selfDetails = getItemFromStore("userProfile");

  useOutsideAlerter(ref);
  useOutsideAlerterNew(searchRef);

  const location = useLocation();
  const navigate = useNavigate();
  const [event, setEvent] = useState<any>([]);
  const [eventMap, setEventMap] = useState<any>([]);
  const [showShare, setShowShare] = useState(false);
  const [detailsData, setDetailsData] = useState(null);
  const [initialCount, setInitialCount] = useState(0);
  const [temporaryCallId, setTemporaryCallId] = useState(null);

  const [nextUrl, setNextUrl] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [currentLat, setCurrentLat] = useState<any>(null);
  const [currentLng, setCurrentLng] = useState<any>(null);
  const [chipType, setChipType] = useState<any>("closest");
  const [distance, setDistance] = useState<any>(5000);
  const [isLocationAccessible, setIslocationAccessible] = useState<any>(null);
  const [selectedPage, setSelectedPage] = useState(1);
  const [showMap, setShowMap] = useState(false);
  const [pageCount, setPageCount] = useState(1);
  const [limit, setLimit] = useState(16);
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [desktopInterestList, setDesktopInterestList] = useState([]);
  const [interests, setInterests] = useState([]);
  const [search, setSearch] = useState("");
  const [address, setAddress] = useState("");
  const [extraLoader, setExtraLoader] = useState(false);
  const [isFilterActive, setIsFilterActive] = useState(false);
  const [lat, setLat] = useState("");
  const [long, setLong] = useState("");
  const [applyCount, setApplyCount] = useState(0);
  const [debouncedSearchValue, setDebouncedSearchValue] = useState("");

  const [gender, setGender] = useState("");
  const [minAge, setMinAge] = useState("");
  const [maxAge, setMaxAge] = useState("");
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [eventType, setEventType] = useState("P");

  const [activeTab, setActiveTab] = useState("tab1");
  const [searchEvents, setSearchEvents] = useState([]);
  const [searchUsers, setSearchUsers] = useState([]);

  const [suggstionmodel, setsuggstionmodel] = useState(false);
  const [activeoption, setactiveoption] = useState<number>(0);
  const [loadingtext, setLoadingtext] = useState(false); // Add loading state
  const [activescroll, setActivescroll] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const [isFav, setIsFav] = useState(false);

  useEffect(() => {
    if (currentLat && currentLng && selfDetails?.id) {
      makePatchCall({
        url: "auth/location/",
        apiPayload: {
          location: `${currentLng},${currentLat}`,
        },
      })
        .then((res) => {})
        .catch((err) => {
          showToast(err, "error");
        });
    }
  }, [currentLat, currentLng, selfDetails?.id]);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(detailsData?.dynamicLink);

      showToast("Text copied!", "success");
    } catch (err) {
      showToast("Failed to copy the text. Please try again.", "error");
    }
  };

  const getRequiredMediaUrl = (item: any) => {
    if (item?.uploadDocumentThumbnail) {
      return item?.uploadDocumentThumbnail;
    } else {
      return item?.uploadDocument;
    }
  };

  const encodedMessage = encodeURIComponent("Check out this link!");
  const encodedLink = encodeURIComponent(detailsData?.dynamicLink);

  const handleWhatsAppShare = () => {
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodedMessage}%20${encodedLink}`;
    window.open(whatsappUrl, "_blank");
  };

  const handleSmsShare = () => {
    const smsUrl = `sms:?body=${encodedMessage}%20${encodedLink}`;
    window.open(smsUrl, "_blank");
  };

  const handleMessengerShare = () => {
    const messengerUrl = `fb-messenger://share?link=${encodedLink}`;
    window.open(messengerUrl, "_blank");
  };

  const handleTwitterShare = () => {
    const twitterUrl = `https://twitter.com/intent/tweet?text=${encodedMessage}%20${encodedLink}`;
    window.open(twitterUrl, "_blank");
  };

  const handleFacebookShare = () => {
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodedLink}`;
    window.open(facebookUrl, "_blank");
  };

  const handleEmailShare = () => {
    const emailUrl = `mailto:?subject=${encodedMessage}&body=${encodedMessage}%20${encodedLink}`;
    window.open(emailUrl, "_blank");
  };
  const handleFocus = () => {
    setIsExpanded(true);
  };
  useEffect(() => {
    const delayInputTimeoutId = setTimeout(() => {
      setDebouncedSearchValue(search);
    }, 1500);
    return () => clearTimeout(delayInputTimeoutId);
  }, [search, 1500]);

  const [listingType, setListingType] = useState(
    location.search === "?type=C"
      ? LISTING_TYPE.C
      : location.search === "?type=C"
      ? LISTING_TYPE.C
      : LISTING_TYPE.S
  );

  const handleNotShowShare = () => {
    setShowShare(false);
  };

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsExpanded(false);
          handleClearClick();
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  function useOutsideAlerterNew(searchRef) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutsideNew(event) {
        // alert('hi')
        if (searchRef.current && !searchRef.current.contains(event.target)) {
          // alert('bye')
          setIsExpanded(false);
          handleClearClickNew();
        }
      }
      // Bind the event listener
      document.addEventListener("click", handleClickOutsideNew);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("click", handleClickOutsideNew);
      };
    }, [searchRef]);
  }
  const handlePageClick = (event) => {
    scrollToTop();
    let page = event.selected + 1;
    setCurrentPage(event.selected);
    setOffset(event.selected * limit);
  };

  // useEffect(() => {
  //   setChipType("closest");
  //   setInterests([]);
  //   setLimit(16);
  //   setOffset(0);
  //   setGender("");
  //   setDebouncedSearchValue("");
  //   setEventType("P");
  //   setMaxPrice("");
  //   setMinPrice("");
  //   setStartDate("");
  //   setEndDate("");
  //   setListingType(
  //     location.search === "?type=C"
  //       ? LISTING_TYPE.C
  //       : location.search === "?type=C"
  //         ? LISTING_TYPE.C
  //         : LISTING_TYPE.S
  //   );
  //   setShowMap(false);
  // }, [location]);

  const getListByLocation = (time_id) => {
    // setTimeout(() => {
    // Check if Geolocation API is available in the browser
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          clearTimeout(time_id);
          setCurrentLat(position.coords.latitude);
          setCurrentLng(position.coords.longitude);
          setIslocationAccessible(true);
        },
        (err) => {
          setIslocationAccessible(false);
          showToast(
            "Kindly enable your location to enhance your app experience, however we still show you the events.",
            "error"
          );
        },
        {
          enableHighAccuracy: false,
          timeout: 10000,
          maximumAge: 0,
        }
      );
    } else {
      setIslocationAccessible(false);
      showToast(
        "It looks like geolocation is not available in your browser.",
        "error"
      );
    }
    // }, 6000);
  };
  const getListWithoutLocation = () => {
    setIsLoading(true);

    let params = {
      listing_type: listingType,
      interests: interests.toString(),
      sort: chipType,
      distance_filter: false,
      limit: limit,
      offset: offset,
      listing_search: debouncedSearchValue,
      gender: gender,
      listing_event_type: eventType,
      min_price: minPrice,
      max_price: maxPrice,
    };
    if (
      listingType !== LISTING_TYPE.C &&
      currentLng &&
      currentLat &&
      chipType === "closest"
    ) {
      params["dist"] = distance;
      params["point"] = `${currentLng},${currentLat}`;
    }

    if (long && lat) {
      params["dist"] = distance;
      params["point"] = `${long},${lat}`;
    }
    if (minAge) {
      params["age"] = minAge + "," + maxAge;
      // params["max_age"] = maxAge || minAge;
    }
    if (startDate && endDate) {
      params["dates"] =
        dayjs(startDate).format("YYYY-MM-DD") +
        "," +
        dayjs(endDate).format("YYYY-MM-DD");
      //dates: moment(startDate).format("YYYY-MM-DD") + "," + moment(endDate).format("YYYY-MM-DD"),
    }
    if (showMap) {
      let temp_params = { ...params };
      temp_params["distance_filter"] = true;
      getEventsListMap(
        temp_params,
        setEventMap,
        setNextUrl,
        setIsLoading,
        setPageCount
      );
    } else {
      if (isLocationAccessible === true && currentLng && currentLat) {
        getEventsList(params, setEvent, setNextUrl, setIsLoading, setPageCount);
      } else {
        getEventsList(params, setEvent, setNextUrl, setIsLoading, setPageCount);
        // getListByLocation();
      }
    }
  };

  useEffect(() => {
    if (initialCount !== 0) {
      getListWithoutLocation();
    } else if (initialCount === 0) {
      let time_id = setTimeout(() => {
        // alert('inside function')
        getListWithoutLocation();
      }, 3000);
      getListByLocation(time_id);
      setInitialCount(1);
      // setTemporaryCallId(time_id)
    }
  }, [
    chipType,
    distance,
    currentLat,
    currentLng,
    isLocationAccessible,
    listingType,
    offset,
    interests,
    // debouncedSearchValue,
    applyCount,
    showMap,
    eventType,
  ]);

  useEffect(() => {
    // getListByLocation();
    // setInitialCount(1);
  }, []);

  useEffect(() => {
    getIntrestListFeatured(
      {
        featured: true,
        want_paginated: false,
      },
      setDesktopInterestList
    );
  }, []);

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={"next-arrow-btn-container"}
        style={{ ...style, display: "block" }}
        onClick={onClick}
      >
        <div className="next-arrow-btn">
          <img src="/static/chevron-black-left.svg" alt="" />
        </div>
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={"prev-arrow-btn-container"}
        style={{ ...style }}
        onClick={onClick}
      >
        <div className="prev-arrow-btn">
          <img src="/static/chevron-black-right.svg" alt="" />
        </div>
      </div>
    );
  }

  function MultipleItems(props) {
    const interestArray = [...props.item];

    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 2,
      centerMode: true,
      // centerMode:true,
      variableWidth: true,
      nextArrow: <SampleNextArrow />,
      prevArrow: <SamplePrevArrow />,
    };
    return (
      <div className="slider-container">
        <Slider {...settings}>
          {interestArray.map((item) => {
            return (
              <div
                key={item?.id}
                className="filter-item"
                onClick={() => {
                  let temp_interests = [...interests];
                  if (temp_interests.includes(item.id)) {
                    const index = temp_interests.indexOf(item.id);
                    if (index > -1) {
                      // only splice array when item is found
                      temp_interests.splice(index, 1); // 2nd parameter means remove one item only
                      setInterests([...temp_interests]);
                    }
                  } else {
                    temp_interests.push(item.id);
                    setInterests([...temp_interests]);
                  }
                }}
              >
                <img
                  // src={item.icon ? item.icon : item.image}
                  src={
                    interests.includes(item.id) ? item.colouredIcon : item.icon
                  }
                  style={{ width: "24px", height: "24px" }}
                />
                <p
                  style={{
                    fontSize: "12px",
                    fontWeight: "500",
                    color: "#52545B",
                  }}
                  className={
                    interests.includes(item.id) ? "active-text-new" : ""
                  }
                >
                  {item.title}
                </p>
              </div>
            );
          })}
        </Slider>
      </div>
    );
  }

  function EventImageCarousel({ item }: any) {
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);
    return (
      <div className="carousal-item-custom">
        {item?.photos.length > 1 ? (
          <ReactSimplyCarousel
            activeSlideIndex={activeSlideIndex}
            onRequestChange={setActiveSlideIndex}
            itemsToShow={1}
            itemsToScroll={1}
            dotsNav={{
              show: true,
              containerProps: {
                className: "dots-nav-container",
                style: {
                  position: "absolute",
                  bottom: "10%",
                  gap: "6px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                },
              },
              itemBtnProps: {
                style: {
                  height: 6,
                  width: 6,
                  borderRadius: "50%",
                  border: 0,
                  background: "rgba(255, 255, 255, 0.4)",
                },
              },
              activeItemBtnProps: {
                style: {
                  height: 7,
                  width: 7,
                  borderRadius: "60%",
                  border: 0,
                  background: "white",
                },
              },
            }}
            forwardBtnProps={{
              //here you can also pass className, or any other button element attributes
              className: "image-carousel-button",

              style: {
                background: "rgba(255, 255, 255, 0.4)",
                borderRadius: "50%",
                border: "none",
                cursor: "pointer",
                height: 24,
                width: 24,
                position: "absolute",
                zIndex: "0",
                top: "50%",
                right: "16px",
                translate: "0 -50%",
                display: "none",
                justifyContent: "center",
                alignItems: "center",
              },
              children: (
                <img
                  style={{ width: "16px", height: "16px" }}
                  alt=""
                  src="/static/chevron-black-right.svg"
                />
              ),
            }}
            backwardBtnProps={{
              //here you can also pass className, or any other button element attributes
              className: "image-carousel-button",
              style: {
                background: "rgba(255, 255, 255, 0.4)",
                borderRadius: "50%",
                border: "none",
                cursor: "pointer",
                height: 24,
                width: 24,
                position: "absolute",
                zIndex: "99",
                top: "50%",
                left: "16px",
                translate: "0 -50%",
                display: "none",
                justifyContent: "center",
                alignItems: "center",
              },
              children: (
                <img
                  style={{ width: "16px", height: "16px" }}
                  alt=""
                  src="/static/chevron-black-left.svg"
                />
              ),
            }}
            speed={400}
            easing="linear"
          >
            {/* here you can also pass any other element attributes. Also, you can use your custom components as slides */}

            {item?.photos.map((ph) =>
              /\.(mp4|mov|avi)$/.test(ph?.documentName?.toLowerCase()) ? (
                <>
                  <video
                    src={getRequiredMediaUrl(ph)}
                    className="event_img"
                    //preload="none"
                    //autoPlay
                    controls
                    disablePictureInPicture
                    controlsList="nodownload noplaybackrate nofullscreen"
                    // playsInline
                    // loop
                    muted
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      e.preventDefault();
                      scrollToTop();
                      window.open("/listing-two/" + item.id);
                    }}
                  >
                    <source src={getRequiredMediaUrl(ph)} />
                  </video>
                </>
              ) : (
                <img
                  src={getRequiredMediaUrl(ph) || "/static/expertise2.png"}
                  className="event_img"
                  //preload="none"
                  // autoPlay
                  controls
                  controlsList="nodownload"
                  // playsInline
                  // loop
                  muted
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToTop();
                    window.open("/listing-two/" + item.id);
                  }}
                />
              )
            )}
          </ReactSimplyCarousel>
        ) : item?.photos.length == 1 ? (
          /\.(mp4|mov|avi)$/.test(
            item?.photos[0].documentName?.toLowerCase()
          ) ? (
            <>
              <div className="individual-image-video-block">
                <div className="individual-image-video-block-2">
                  <video
                    disablePictureInPicture
                    // src={item?.photos[0].uploadDocument}
                    src={getRequiredMediaUrl(item?.photos[0])}
                    className="event_img"
                    //preload="none"
                    // autoPlay
                    controls
                    controlsList="nodownload noplaybackrate nofullscreen"
                    // playsInline
                    // loop
                    muted
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      e.preventDefault();
                      scrollToTop();
                      window.open("/listing-two/" + item.id);
                    }}
                  >
                    <source src={getRequiredMediaUrl(item?.photos[0])} />
                  </video>
                </div>
              </div>
            </>
          ) : (
            <div className="individual-image-video-block">
              <div className="individual-image-video-block-2">
                <img
                  src={
                    getRequiredMediaUrl(item?.photos[0]) ||
                    "/static/expertise2.png"
                  }
                  className="event_img"
                  alt=""
                  loading="lazy"
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToTop();
                    window.open("/listing-two/" + item.id);
                  }}
                />
              </div>
            </div>
          )
        ) : /\.(mp4|mov|avi)$/.test(item?.media?.toLowerCase()) ? (
          <>
            <div className="individual-image-video-block">
              <div className="individual-image-video-block-2">
                <video
                  disablePictureInPicture
                  src={item?.media}
                  className="event_img"
                  //preload="none"
                  // autoPlay
                  controls
                  controlsList="nodownload noplaybackrate nofullscreen"
                  // playsInline
                  // loop
                  muted
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToTop();
                    window.open("/listing-two/" + item.id);
                  }}
                >
                  <source src={item?.media} />
                </video>
              </div>
            </div>
          </>
        ) : (
          <div className="individual-image-video-block">
            <div className="individual-image-video-block-2">
              <img
                src={item?.media || "/static/expertise2.png"}
                className="event_img"
                alt=""
                loading="lazy"
                onClick={(e) => {
                  e.preventDefault();
                  scrollToTop();
                  window.open("/listing-two/" + item.id);
                }}
              />
            </div>
          </div>
        )}
      </div>
    );
  }
  const { isMobile } = useCheckDeviceScreen();

  const Inputhandlechamge = (e: any) => {
    setsuggstionmodel(true);
  };

  const handleClearClick = (e: any) => {
    setsuggstionmodel(false);
    setSearch("");
    setSearchEvents([]);
    setActiveTab("tab1");
  };

  const handleClearClickNew = (e: any) => {
    setsuggstionmodel(false);
    setSearch("");
    // setSearchEvents([]);
    setActiveTab("tab1");
  };

  useEffect(() => {
    if (!search) {
      setsuggstionmodel(false);
      setLoadingtext(false);
      setSearchEvents([]);
      setSearchUsers([]);
    } else {
      // Start loading
      setLoadingtext(true);

      getSearchList(
        {
          search: search,
          tab: activeTab === "tab1" ? "Events" : "Users",
        },
        (events) => {
          setSearchEvents(events);
          setLoadingtext(false);
        },
        (users) => {
          setSearchUsers(users);
          setLoadingtext(false);
        }
      );

      setsuggstionmodel(true);
    }
  }, [search, activeTab]);

  const handleLike = async (id) => {
    const apiPayload = {
      listing: id,
    };
    makePostCall({ url: endpoints.favourite_listing, apiPayload })
      .then((res) => {
        setEvent((prevEvents) =>
          prevEvents.map((event) =>
            event.id === id ? { ...event, isFavourite: true } : event
          )
        );
        showToast(res.status.message, "success");
        setExtraLoader(false);
      })
      .catch((error) => {
        const response = error?.response;
        if (
          response?.status == 400 &&
          response?.data?.errors?.nonFieldErrors?.length == 1
        ) {
          if (
            response.data.errors.nonFieldErrors[0] ===
            "The fields listing, marked_by must make a unique set."
          ) {
            showToast("You already marked favourite.", "success");
          }
        } else {
          showToast(error.message, "error");
        }
        setExtraLoader(false);
      });
  };

  const handleDislike = (id) => {
    const apiPayload = {
      listing: id,
    };
    makeDeleteCall({
      url: endpoints.favourite_listing,
      apiPayload,
      content_type: "multipart/form-data",
    })
      .then((res) => {
        setEvent((prevEvents) =>
          prevEvents.map((event) =>
            event.id === id ? { ...event, isFavourite: false } : event
          )
        );
        showToast(res.status.message, "success");
        setExtraLoader(false);
      })
      .catch((error) => {
        console.log("error", error);
        setExtraLoader(false);
      });
  };

  const debounce = (func: any, delay: any) => {
    let timerId: any;

    return function (...args) {
      if (timerId) {
        clearTimeout(timerId);
      }
      timerId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const debouncedToggleFavourite = useCallback(
    debounce((item: any) => {
      const isAuthenticated = getItemFromStore("access_token");

      if (isAuthenticated) {
        if (item.isFavourite) {
          handleDislike(item.id);
        } else {
          handleLike(item.id);
        }
      } else {
        navigate(`/login`);
      }
    }, 200), // Adjust debounce delay as needed
    [handleDislike, handleLike, navigate]
  );
  interface Tab {
    text: string;
  }

  const tabs: Tab[] = [
    // { text: "All Events" },
    { text: "Physical" },
    { text: "Virtual" },
    { text: "Filter & Sort" },
    // { text: "Show" },
  ];

  const handleClick = (index: number) => {
    setactiveoption(index);
  };

  return (
    <>
      <GlobalHeader2 />
      <div
        style={{
          paddingBottom:
            !showMap && Math.ceil(pageCount / limit) > 1 ? "0px" : "20px",
        }}
        className={`events-page background-grey ${suggstionmodel ? "" : ""}`}
      >
        <div className="selected-listing-type-container d-none d-md-flex">
          <div className="selected-listing-type-wrapper">
            <div className="listing-type-left">
              {EventListingType?.map((item: any, index: number) => {
                return (
                  <div
                    key={index}
                    onClick={() => {
                      setIsLoading(true);
                      setOffset(0);
                      setCurrentPage(0);
                      setListingType(item.type);
                    }}
                    style={{ pointerEvents: isLoading ? "none" : "auto" }}
                    className={`selected-type ${
                      listingType === item.type ? "active" : ""
                    }`}
                  >
                    <img
                      src={
                        listingType === item.type
                          ? item.inactiveImg
                          : item.activeImg
                      }
                      alt=""
                    />
                    <img src={item.inactiveImg} alt="" />
                    <p className="mb-0">{item.title}</p>
                  </div>
                );
              })}
            </div>
            <div
              ref={searchRef}
              className="listing-type-right-content danny-ref "
              style={{
                width: suggstionmodel ? "auto" : "382px",
                height: "48px",
              }}
            >
              <div className="listing-type-right">
                <img src="static/Search.svg" alt="" />
                <input
                  className="seracinput"
                  placeholder="Search for any Event or Individual"
                  style={{
                    fontFamily: "Poppins",
                    fontStyle: "italic",
                    fontSize: "12px",
                  }}
                  value={search}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      let activeTabVar =
                        activeTab === "tab1" ? "Events" : "Users";
                      navigate(
                        "/search?search=" + search + "&tab=" + activeTabVar
                      );
                    }
                  }}
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
                {search && (
                  <span onClick={handleClearClick} className="clearstyle">
                    Clear
                  </span>
                )}
              </div>
              {suggstionmodel && (
                <div className="automodel">
                  {/* Suggestion model */}
                  <div className="Tab-section">
                    <div className="tabcontent">
                      <div
                        style={{
                          flexBasis: 0,
                          flexGrow: 1,
                          textAlign: "center",
                        }}
                      >
                        <button
                          className={`eventsstyle ${
                            activeTab === "tab1" ? "active" : ""
                          }`}
                          onClick={() => setActiveTab("tab1")}
                        >
                          Event
                        </button>
                        <hr
                          className={`eventbottom ${
                            activeTab === "tab1" ? "active" : ""
                          }`}
                        />
                      </div>
                      <div
                        style={{
                          flexBasis: 0,
                          flexGrow: 1,
                          textAlign: "center",
                        }}
                      >
                        <button
                          className={`Peopleeventstyle ${
                            activeTab === "tab2" ? "active" : ""
                          }`}
                          onClick={() => setActiveTab("tab2")}
                        >
                          People
                        </button>
                        <hr
                          className={`peoplebottom ${
                            activeTab === "tab2" ? "active" : ""
                          }`}
                        />
                      </div>
                    </div>
                    <div>
                      <hr
                        className={`main-divider ${
                          activeTab === "tab1"
                            ? "eventborder"
                            : activeTab === "tab2"
                            ? "peopleborder"
                            : ""
                        }`}
                      />
                    </div>
                  </div>
                  <div>
                    {activeTab === "tab1" && (
                      <div className="conatinerview-scroll">
                        {loadingtext ? (
                          <div className="Noresultfound-section d-flex justify-content-center align-items-center">
                            <span className="Noresultfound">Loading...</span>
                          </div>
                        ) : searchEvents.length === 0 ? (
                          <div className="Noresultfound-section d-flex justify-content-center align-items-center">
                            <span className="Noresultfound">
                              No results found
                            </span>
                          </div>
                        ) : (
                          searchEvents.map((item, index) => (
                            <div
                              className="conatinerview mb-2"
                              key={index}
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                window.open("/listing-two/" + item.id)
                              }
                            >
                              <div
                                className="main-section"
                                style={{ height: "80px" }}
                              >
                                <div>
                                  <img
                                    className="eventimg"
                                    src={
                                      item.thumbnail
                                        ? item.thumbnail
                                        : item?.photos[0]
                                        ? item?.photos[0].uploadDocument
                                        : "/static/expertise2.png"
                                    }
                                    alt=""
                                  />
                                </div>
                                <div className="innterdata">
                                  <span className="eventname">
                                    {item.title}
                                  </span>
                                  <span className="createdat">
                                    Created by: {item?.createdBy?.name}
                                  </span>
                                  <span className="eventtype">
                                    Event Type:{" "}
                                    {item.listingEventType === "V"
                                      ? "Virtual"
                                      : "Physical"}
                                  </span>
                                </div>
                              </div>
                            </div>
                          ))
                        )}
                      </div>
                    )}
                    {activeTab === "tab2" && (
                      <div className="conatinerview-scroll">
                        {loadingtext ? (
                          <div className="Noresultfound-section d-flex justify-content-center align-items-center">
                            <span className="Noresultfound">Loading...</span>
                          </div>
                        ) : searchUsers.length === 0 ? (
                          <div className="Noresultfound-section d-flex justify-content-center align-items-center">
                            <span className="Noresultfound">
                              No results found
                            </span>
                          </div>
                        ) : (
                          searchUsers.map((item, index) => (
                            <div
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                window.open("/our-mates/" + item.id)
                              }
                              className="conatinerview mb-2"
                              key={index}
                            >
                              <div
                                className="main-section-people"
                                style={{ height: "80px" }}
                              >
                                <div>
                                  <img
                                    style={{
                                      borderRadius: "60px",
                                      width: "50px",
                                      height: "50px",
                                    }}
                                    className="peopleimg"
                                    src={
                                      item.profilePicture
                                        ? item.profilePicture
                                        : "/static/dummyimg.svg"
                                    }
                                    alt=""
                                  />
                                </div>
                                <div className="innterdata">
                                  <span className="eventnamepeople">
                                    {item.name}
                                  </span>
                                  <span className="createdat">
                                    {item.userType}
                                  </span>
                                </div>
                              </div>
                            </div>
                          ))
                        )}
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="filter-row-container d-none d-md-block">
          <div className="filter-row-wrapper ">
            <div className="filter-first ">
              {desktopInterestList.length > 0 ? (
                <MultipleItems item={desktopInterestList} />
              ) : (
                ""
              )}
            </div>
            <div
              className="filter-second"
              style={{
                gap: "0px",
                marginLeft: "1px",
                maxWidth: "181px",
                maxHeight: "48px",
              }}
            >
              <div
                className={`filter-sort-btn ${
                  eventType === "P" ? "orange-color" : "normal-color"
                }`}
                style={{
                  width: "100px",
                  // borderTopRightRadius: '0px',
                  // borderBottomRightRadius: '0px'
                }}
                onClick={() => {
                  if (!isLoading) {
                    setCurrentPage(0);
                    setOffset(0 * limit);
                    setEventType("P");
                  }
                }}
              >
                <div className="inner-info">
                  <p>Physical</p>
                </div>
              </div>
              <div
                style={{
                  width: "100px",
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                }}
                className={`filter-sort-btn  ${
                  eventType === "V"
                    ? "blue-color"
                    : "normal-color normal-color-v"
                }`}
                onClick={() => {
                  if (!isLoading) {
                    setCurrentPage(0);
                    setOffset(0 * limit);
                    setEventType("V");
                  }
                }}
              >
                <div className="inner-info">
                  <p>Virtual</p>
                </div>
              </div>
            </div>
            <div className="filter-second ">
              <div style={{ position: "relative" }}>
                <div
                  className={
                    !isFilterActive ? "filter-sort-btn" : "active-filter-sort"
                  }
                  style={{ marginRight: "6px" }}
                  onClick={() => {
                    setShowFilter(!showFilter);
                  }}
                >
                  <div className="inner-info">
                    <img src="/static/filter-sort.svg" alt="" />
                    <p>Filter & Sort</p>
                  </div>
                </div>
                {isFilterActive && (
                  <div className="filter-count-wrap">{applyCount}</div>
                )}
              </div>
              <div
                className={showMap ? "show-map-btn" : "show-map-btn-inactive"}
                onClick={() => {
                  setShowMap(!showMap);
                }}
              >
                {showMap ? (
                  <div className="inner-info">
                    <img src="/static/tabler_list.svg" alt="" />
                    <p>Show List</p>
                  </div>
                ) : (
                  <div className="inner-info">
                    <img src="/static/map-icon.svg" alt="" />
                    <p>Show Map</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {isMobile && (
          <>
            <div
              className={
                isExpanded
                  ? "heading-and-map-container-res"
                  : "heading-and-map-container"
              }
            >
              {!isExpanded ? (
                <>
                  <div className="heading">Event</div>
                </>
              ) : null}

              <div
                ref={ref}
                className={`res-search-and-sorting-container ${
                  isExpanded ? "expanded" : ""
                }`}
                // onClickOutside={() => setShowDialog(false)}
              >
                <div className="search-input">
                  <img
                    src="static/Search.svg"
                    alt=""
                    style={{ height: "14px", width: "14px" }}
                  />
                  <input
                    style={{ fontSize: "12px" }}
                    placeholder="Search"
                    value={search}
                    onFocus={() => setIsExpanded(true)}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        let activeTabVar =
                          activeTab === "tab1" ? "Events" : "Users";
                        navigate(
                          "/search?search=" + search + "&tab=" + activeTabVar
                        );
                      }
                    }}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                  />
                  {search && (
                    <span onClick={handleClearClick} className="clearstyle">
                      X
                    </span>
                  )}
                  {suggstionmodel && (
                    <div className="automodel">
                      {/* Suggestion model */}
                      <div className="Tab-section">
                        <div className="tabcontent">
                          <div>
                            <button
                              className={`eventsstyle ${
                                activeTab === "tab1" ? "active" : ""
                              }`}
                              onClick={() => setActiveTab("tab1")}
                            >
                              Event
                            </button>
                            <hr
                              className={`eventbottom ${
                                activeTab === "tab1" ? "active" : ""
                              }`}
                            />
                          </div>
                          <div>
                            <button
                              className={`Peopleeventstyle ${
                                activeTab === "tab2" ? "active" : ""
                              }`}
                              onClick={() => setActiveTab("tab2")}
                            >
                              People
                            </button>
                            <hr
                              className={`peoplebottom ${
                                activeTab === "tab2" ? "active" : ""
                              }`}
                            />
                          </div>
                        </div>
                        <div>
                          <hr
                            className={`main-divider ${
                              activeTab === "tab1"
                                ? "eventborder"
                                : activeTab === "tab2"
                                ? "peopleborder"
                                : ""
                            }`}
                          />
                        </div>
                      </div>
                      {activeTab === "tab1" && (
                        <div className="conatinerview-scroll-res">
                          {loadingtext ? (
                            <div className="Noresultfound-section d-flex justify-content-center align-items-center">
                              <span className="Noresultfound">Loading...</span>
                            </div>
                          ) : searchEvents.length === 0 ? (
                            <div className="Noresultfound-section d-flex justify-content-center align-items-center">
                              <span className="Noresultfound">
                                No results found
                              </span>
                            </div>
                          ) : (
                            searchEvents.map((item, index) => (
                              <div className="">
                                <div
                                  className="conatinerviewres mb-2"
                                  key={index}
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    window.open("/listing-two/" + item.id)
                                  }
                                >
                                  <div
                                    className="main-section"
                                    style={{ height: "80px" }}
                                  >
                                    <div>
                                      <img
                                        className="eventimg-responsive"
                                        src={
                                          item.thumbnail
                                            ? item.thumbnail
                                            : item?.photos[0]
                                            ? item?.photos[0].uploadDocument
                                            : "/static/expertise2.png"
                                        }
                                        alt=""
                                      />
                                    </div>
                                    <div className="innterdata">
                                      <span className="eventnameres">
                                        {item.title}
                                      </span>
                                      <span className="createdat">
                                        Created by: {item?.createdBy?.name}
                                      </span>
                                      <span className="eventtype">
                                        Event Type:{" "}
                                        {item.listingEventType === "V"
                                          ? "Virtual"
                                          : "Physical"}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))
                          )}
                        </div>
                      )}

                      {activeTab === "tab2" && (
                        <div className="conatinerview-scroll-res">
                          {loadingtext ? (
                            <div className="Noresultfound-section d-flex justify-content-center align-items-center">
                              <span className="Noresultfound">Loading...</span>
                            </div>
                          ) : searchEvents.length === 0 ? (
                            <div className="Noresultfound-section d-flex justify-content-center align-items-center">
                              <span className="Noresultfound">
                                No results found
                              </span>
                            </div>
                          ) : (
                            searchUsers.map((item, index) => (
                              <div
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  window.open("/our-mates/" + item.id)
                                }
                                className="conatinerview mb-3"
                                key={index}
                              >
                                <div
                                  className="main-section mb-2"
                                  style={{ height: "80px" }}
                                >
                                  <div>
                                    <img
                                      style={{
                                        borderRadius: "60px",
                                        width: "50px",
                                        height: "50px",
                                      }}
                                      className="peopleimg"
                                      src={
                                        item.profilePicture
                                          ? item.profilePicture
                                          : "/static/dummyimg.svg"
                                      }
                                      alt=""
                                    />
                                  </div>
                                  <div className="innterdata">
                                    <span className="eventname">
                                      {item.name}
                                    </span>
                                    <span className="createdat">
                                      {item.userType}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ))
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="res-listing-container ">
              {ResponsiveEventListingType?.map((item: any, index: number) => {
                return (
                  <div
                    key={index}
                    onClick={() => {
                      setIsLoading(true);
                      setOffset(0);
                      setCurrentPage(0);
                      setListingType(item.type);
                    }}
                    style={{ pointerEvents: isLoading ? "none" : "auto" }}
                    className={`res-selected-type ${
                      listingType === item.type ? "active" : ""
                    }`}
                  >
                    <img
                      src={
                        listingType === item.type
                          ? item.inactiveImg
                          : item.activeImg
                      }
                      alt=""
                    />
                    <p className="mb-0">{item.title}</p>
                  </div>
                );
              })}
            </div>

            {/* <div
              className="filter-sorting-btn"
              onClick={() => {
                setShowFilter(!showFilter);
              }}
            >
              <img src="static/filter-res.svg" alt="" />
            </div> */}
            <div className="res-filter-list-container">
              {desktopInterestList.map((item) => (
                <div
                  className="filter-item"
                  onClick={() => {
                    let temp_interests = [...interests];
                    if (temp_interests.includes(item.id)) {
                      const index = temp_interests.indexOf(item.id);
                      if (index > -1) {
                        // only splice array when item is found
                        temp_interests.splice(index, 1); // 2nd parameter means remove one item only
                        setInterests([...temp_interests]);
                      }
                    } else {
                      temp_interests.push(item.id);
                      setInterests([...temp_interests]);
                    }
                  }}
                >
                  <img
                    className="filter-item-img"
                    src={item.icon ? item.icon : item.image}
                  />
                  <p
                    className={
                      interests.includes(item.id)
                        ? "active-text"
                        : "filter-text"
                    }
                  >
                    {item.title}
                  </p>
                </div>
              ))}
            </div>
          </>
        )}

        {/* <div className="resposnive-tab d-flex gap-2 d-lg-none d-xl-none d-md-none">
          {tabs.map((tab, index) => (
            <div
              key={index}
              className={
                index === activeoption ? "active-class" : "normal-class"
              }
              onClick={() => {
                if (!isLoading) {
                  if (tab.text === "Physical") {
                    handleClick(index);
                    setCurrentPage(0);
                    setOffset(0 * limit);
                    setEventType("P");
                  }
                  if (tab.text === "Virtual") {
                    handleClick(index);
                    setCurrentPage(0);
                    setOffset(0 * limit);
                    setEventType("V");
                  }
                  if (tab.text === "Filter & Sort") {
                    setShowFilter(!showFilter);
                  }
                }
              }}
              style={{ cursor: "pointer" }}
            >
              <span
                className={
                  index === activeoption
                    ? "active-class-text"
                    : "normal-class-text"
                }
              >
                {tab.text}
              </span>
            </div>
          ))}
        </div> */}
        <div className="filter-row-container-responsive d-block d-sm-none">
          <div className="filter-row-wrapper-responsive pt-3 ">
            <div
              className="filter-second-res"
              style={{
                gap: "0px",
                marginLeft: "1px",
                maxWidth: "181px",
                maxHeight: "48px",
                width: "153px",
              }}
            >
              <div
                className={`filter-sort-btn-res ${
                  eventType === "P" ? "orange-color" : "normal-color"
                }`}
                style={{
                  width: "90px",
                  // borderTopRightRadius: '0px',
                  // borderBottomRightRadius: '0px'
                }}
                onClick={() => {
                  if (!isLoading) {
                    setCurrentPage(0);
                    setOffset(0 * limit);
                    setEventType("P");
                  }
                }}
              >
                <div className="inner-info">
                  <p>Physical</p>
                </div>
              </div>
              <div
                style={{
                  width: "90x",
                  borderTopLeftRadius: "0px",
                  borderBottomLeftRadius: "0px",
                }}
                className={`filter-sort-btn-res  ${
                  eventType === "V"
                    ? "blue-color"
                    : "normal-color normal-color-v-res"
                }`}
                onClick={() => {
                  if (!isLoading) {
                    setCurrentPage(0);
                    setOffset(0 * limit);
                    setEventType("V");
                  }
                }}
              >
                <div className="inner-info">
                  <p>Virtual</p>
                </div>
              </div>
            </div>
            <div className="filter-second-res">
              <div
                className={isFilterActive ? "active-sort-btn" : "sort-btn"}
                style={{ marginRight: "6px", width: "110px " }}
                onClick={() => {
                  setShowFilter(!showFilter);
                }}
              >
                <div className="inner-info-res position-relative ">
                  <img
                    src="/static/filter-sort.svg"
                    alt=""
                    style={{ width: "16px", height: "16px" }}
                  />
                  <p style={{ fontSize: "12px" }}>Filter & Sort</p>
                </div>
                {isFilterActive && (
                  <div className="total-filter-conatiner position-absolute">
                    <span className="total-filter-number">{applyCount}</span>
                  </div>
                )}
              </div>
              <div
                style={{ width: "66px" }}
                className={showMap ? "show-map-btn" : "show-map-btn-inactive"}
                onClick={() => {
                  setShowMap(!showMap);
                }}
              >
                {showMap ? (
                  <div className="inner-info">
                    {/* <img src="/static/map_with_grey.svg" alt="" /> */}
                    <img src="/static/map-icon.svg" alt="" />
                  </div>
                ) : (
                  <div className="inner-info">
                    <img src="/static/map-icon.svg" alt="" />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {isLoading ? (
          <div className="page_loader">
            <div
              className="lds-ring"
              style={{
                margin: "0 auto",
              }}
            >
              <div style={{ textAlign: "center" }}>
                <img
                  style={{ width: "50px" }}
                  src="/static/spinner.svg"
                  alt="loader"
                />
              </div>

              <div className="text__container">
                <span>Please wait... Loading</span>
              </div>
            </div>
          </div>
        ) : showMap ? (
          <div>
            <EventsMapContainer
              eventMap={eventMap}
              setDistance={setDistance}
              distance={distance}
              // latitude={currentLat}
              // longitude={currentLng}
            />
          </div>
        ) : (
          <>
            <div className="listing-container">
              <div className="listing-inner-wrapper">
                {event.length == 0 ? (
                  <div style={{ height: "400px", width: "100%" }}>
                    <p className="no-content" style={{ margin: "auto" }}>
                      No Events to Display
                    </p>
                  </div>
                ) : (
                  <div className="listings">
                    {event?.map((item: any, index: number) => {
                      return (
                        <div className="mob-diff">
                          <div className="event-link-title">
                            <div className="listing_box" key={index}>
                              <div className="crousel-container">
                                <div className="span_box">
                                  <span
                                    className="heart"
                                    onClick={() => {
                                      if (!extraLoader) {
                                        setExtraLoader(true);
                                        debouncedToggleFavourite(item);
                                      }
                                    }}
                                  >
                                    {item.isFavourite ? (
                                      <img src="/static/heartred.svg" alt="" />
                                    ) : (
                                      <img
                                        src="/static/card-heart.svg"
                                        alt=""
                                      />
                                    )}
                                  </span>
                                </div>

                                <div className="span_box">
                                  <span
                                    className="share"
                                    onClick={() => {
                                      setDetailsData(item);
                                      setShowShare(true);
                                    }}
                                  >
                                    <img
                                      src="/static/Card-Share-Icon.svg"
                                      alt=""
                                    />
                                  </span>
                                </div>
                                <div className="span_box">
                                  {item.listingEventType !== "V" ? (
                                    <>
                                      <img
                                        className="event_type"
                                        src="/static/event-type2.svg"
                                        alt=""
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <span className="event_type">
                                        <img
                                          src="/static/event-type.svg"
                                          alt=""
                                        />
                                      </span>
                                    </>
                                  )}
                                </div>
                                {item.listingEventType !== "V" ? (
                                  <div className="span_box">
                                    {item?.distance ? (
                                      <span className="distance">
                                        {formatNumber(
                                          item.distance?.toFixed(2)
                                        )}{" "}
                                        km away...
                                      </span>
                                    ) : null}
                                  </div>
                                ) : (
                                  ""
                                )}

                                <EventImageCarousel item={item} />
                                {/* {item?.listingType === LISTING_TYPE.SPECIAL && (
                                  <div className="special-box">
                                    <img
                                      src={img2}
                                      alt=""
                                      style={{
                                        height: "20px",
                                        width: "20px",
                                        marginRight: "auto",
                                      }}
                                    />
                                    <div className="exc-text">
                                      Exclusive for Members
                                    </div>
                                  </div>
                                )} */}

                                <div className="span_box">
                                  {item?.listingType === LISTING_TYPE.C ? (
                                    <span className="price">Join</span>
                                  ) : item?.price || item?.monthlyCost ? (
                                    <span className="price">
                                      {item?.price == "0.00" ? (
                                        "Free"
                                      ) : (
                                        <>
                                          {" "}
                                          AUD {item?.price || item?.monthlyCost}
                                        </>
                                      )}
                                    </span>
                                  ) : (
                                    <span className="price">Free</span>
                                  )}
                                </div>
                              </div>

                              <div
                                className="limited-event-info"
                                onClick={(e) => {
                                  e.preventDefault();
                                  scrollToTop();
                                  window.open("/listing-two/" + item.id);
                                }}
                              >
                                <div className="title item">
                                  <a className="event-link-title" href={`#`}>
                                    {item?.title}
                                  </a>
                                </div>
                                <div
                                  className="item"
                                  style={{
                                    fontSize: "12px",
                                    fontWeight: "400",
                                    minHeight: "16px",
                                  }}
                                >
                                  {item?.scheduleDetails?.address?.address ? (
                                    <>
                                      <img
                                        src="/static/ion_location-outline.svg"
                                        alt=""
                                      />
                                      <span style={{ paddingLeft: "4px" }}>
                                        {
                                          item?.scheduleDetails?.address
                                            ?.address
                                        }
                                      </span>
                                    </>
                                  ) : item?.scheduleDetails[0]?.address
                                      ?.address ? (
                                    <>
                                      <img
                                        src="/static/ion_location-outline.svg"
                                        alt=""
                                      />
                                      <span style={{ paddingLeft: "4px" }}>
                                        {
                                          item?.scheduleDetails[0]?.address
                                            ?.address
                                        }
                                      </span>
                                    </>
                                  ) : item?.events !== null &&
                                    item?.events[0]?.address?.address ? (
                                    <>
                                      <img
                                        src="/static/ion_location-outline.svg"
                                        alt=""
                                      />
                                      <span style={{ paddingLeft: "4px" }}>
                                        {item?.events[0]?.address?.address}
                                      </span>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                {item?.listingType === LISTING_TYPE.C ? null : (
                                  <div
                                    className="item"
                                    style={{
                                      fontSize: "12px",
                                      fontWeight: "400",
                                    }}
                                  >
                                    <img src="/static/calendar-2.svg" alt="" />
                                    <span style={{ paddingLeft: "4px" }}>
                                      {moment(item?.upcomingEventDate).format(
                                        "DD MMM, YYYY"
                                      ) + " "}
                                    </span>
                                    <img
                                      src="/static/EmptyDot.svg"
                                      style={{ marginBottom: "3px" }}
                                    />
                                    <span style={{ paddingLeft: "4px" }}>
                                      {moment(
                                        item?.events?.[0]?.startTime?.substring(
                                          0,
                                          5
                                        ),
                                        ["HH:mm"]
                                      ).format("hh:mmA")}{" "}
                                      -{" "}
                                      {moment(
                                        item?.events?.[0]?.endTime?.substring(
                                          0,
                                          5
                                        ),
                                        ["HH:mm"]
                                      ).format("hh:mmA")}
                                    </span>
                                  </div>
                                )}
                              </div>

                              {item?.listingType === LISTING_TYPE.SPECIAL ? (
                                <div
                                  className="name name-special"
                                  style={{
                                    padding: "0px",
                                    // borderTopLeftRadius: "0px",
                                    // borderTopRightRadius: "0px",
                                    marginTop: "14px",
                                  }}
                                >
                                  <img
                                    src={"/static/SpecialNew.svg"}
                                    alt=""
                                    style={{
                                      objectFit: "none",
                                      height: "100%",
                                      width: "60%",
                                      marginRight: "auto",
                                      marginTop: "6px",
                                      paddingTop: "0px",
                                    }}
                                  />
                                  {/* <span>Fitness Mates</span> */}
                                </div>
                              ) : (
                                <div
                                  className="name"
                                  style={{ cursor: "pointer" }}
                                  onClick={() => {
                                    const isAuthenticated = getItemFromStore(
                                      "access_token"
                                    )
                                      ? true
                                      : false;
                                    if (isAuthenticated) {
                                      navigate(
                                        `/our-mates/${item?.createdBy?.id}`
                                      );
                                    } else {
                                      navigate(`/login`);
                                    }
                                  }}
                                >
                                  <a className="event-link-title">
                                    {["mp4", "mov", "avi"].includes(
                                      item?.createdBy?.profilePicture
                                        ?.split(".")
                                        .splice(-1)[0]
                                        ?.toLowerCase()
                                    ) ? (
                                      <video
                                        disablePictureInPicture
                                        src={item?.createdBy?.profilePicture}
                                        //preload="none"
                                        className="event_img"
                                        autoPlay
                                        //controls
                                        // playsInline
                                        // loop
                                        muted
                                      >
                                        <source
                                          src={item?.createdBy?.profilePicture}
                                        />
                                      </video>
                                    ) : (
                                      <img
                                        style={{ border: "2px solid white" }}
                                        src={
                                          item?.createdBy?.profilePicture ||
                                          "/static/dummyimg.svg"
                                        }
                                        alt=""
                                        loading="lazy"
                                      />
                                    )}
                                  </a>
                                  <span>
                                    {"Event  by "}
                                    {item?.createdBy?.tradingName
                                      ? item?.createdBy?.tradingName
                                      : item?.createdBy?.name}
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            </div>
          </>
        )}
        {!showMap && Math.ceil(pageCount / limit) > 1 && (
          <div className="pages-container my-3">
            <div className="pages-inner-container">
              <div className="pagination-box">
                <ReactPaginate
                  breakLabel="..."
                  nextLabel={
                    <>
                      {/* <div className={(Math.ceil(pageCount / limit) === currentPage+1)?"previous-button disabled":"previous-button"}>Next  <img src="/static/u_angle-left-b(1).svg" alt="" /></div> */}

                      <div className="previous-button">
                        {" "}
                        <img src="/static/u_angle-left-b(1).svg" alt="" />{" "}
                        {!isMobile ? "Next " : ""}
                      </div>
                    </>
                  }
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={!isMobile ? 5 : 2}
                  forcePage={currentPage}
                  pageCount={Math.ceil(pageCount / limit)}
                  previousLabel={
                    <>
                      <div className="previous-button">
                        {!isMobile ? "Previous " : ""}
                        <img src="/static/u_angle-left-b.svg" alt="" />
                      </div>
                    </>
                  }
                  renderOnZeroPageCount={null}
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                  containerClassName="pagination"
                  activeClassName="active"
                />
              </div>
            </div>
          </div>
        )}
      </div>
      {/* {!showMap && */}
      <GlobalFooter />
      <div className="create-listing-btn">
        <Button
          btnImg="/static/plusadd.svg"
          btnTxt="Create Listing"
          className="add_listing_btn"
          onClick={() => {
            navigate(ROUTE_CONSTANTS.create_listing);
          }}
        />
      </div>
      {/* // } */}
      {showFilter && (
        <FilterPopup
          setCurrentPage={setCurrentPage}
          setAddress={setAddress}
          setLat={setLat}
          setLong={setLong}
          address={address}
          setApplyCount={() => {
            let temp_apply = applyCount;
            setApplyCount(temp_apply + 1);
          }}
          setInterests={setInterests}
          setMinAge={setMinAge}
          setMaxAge={setMaxAge}
          setGender={setGender}
          setMinPrice={setMinPrice}
          setMaxPrice={setMaxPrice}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          setEventType={setEventType}
          setChipType={setChipType}
          chipType={chipType}
          eventType={eventType}
          show={showFilter}
          minPrice={minPrice}
          maxPrice={maxPrice}
          startDate={startDate}
          endDate={endDate}
          minAge={minAge}
          maxAge={maxAge}
          gender={gender}
          onHide={() => setShowFilter(false)}
          setIsFilterActive={setIsFilterActive}
        />
      )}
      {detailsData ? (
        <Modal
          show={showShare}
          onHide={handleNotShowShare}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <div className="share_modal_layout">
              <img
                src="/static/close.svg"
                alt="Close"
                style={{ cursor: "pointer" }}
                onClick={handleNotShowShare}
                className="share_modal_close_btn"
              />
              <h4>Share Listing</h4>
              <div className="share_modal_details">
                {detailsData?.thumbnail ? (
                  <img
                    src={detailsData?.thumbnail || "/static/expertise2.png"}
                    className="event_img"
                    alt=""
                    style={{
                      width: "110px",
                      height: "86px",
                      borderRadius: "12px",
                    }}
                    loading="lazy"
                    onClick={(e) => {
                      e.preventDefault();
                      // scrollToTop();
                      // navigate(`listing-two/${detailsData.id}`);
                    }}
                  />
                ) : detailsData?.photos.length > 0 ? (
                  /\.(mp4|mov|avi)$/.test(
                    detailsData?.photos[0].documentName?.toLowerCase()
                  ) ? (
                    <>
                      <video
                        style={{
                          width: "110px",
                          height: "86px",
                          borderRadius: "12px",
                        }}
                        src={detailsData?.photos[0].uploadDocument}
                        className="event_img"
                        //preload="none"
                        // autoPlay
                        controls
                        controlsList="nodownload"
                        // playsInline
                        // loop
                        muted
                        onClick={(e) => {
                          e.preventDefault();
                          // scrollToTop();
                          // navigate(`listing-two/${detailsData.id}`);
                        }}
                      >
                        <source src={detailsData?.photos[0].uploadDocument} />
                      </video>
                    </>
                  ) : (
                    <img
                      src={
                        detailsData?.photos[0].uploadDocument ||
                        "/static/expertise2.png"
                      }
                      className="event_img"
                      alt=""
                      style={{
                        width: "110px",
                        height: "86px",
                        borderRadius: "12px",
                      }}
                      loading="lazy"
                      onClick={(e) => {
                        e.preventDefault();
                        // scrollToTop();
                        // navigate(`listing-two/${detailsData.id}`);
                      }}
                    />
                  )
                ) : (
                  <img
                    style={{
                      width: "110px",
                      height: "86px",
                      borderRadius: "12px",
                    }}
                    src="/static/expertise2.png"
                    alt=""
                  />
                )}

                <div className="title">
                  {detailsData.title && <h5>{detailsData.title}</h5>}
                  <p className="sub-title">
                    Address:{" "}
                    {detailsData?.scheduleDetails?.address?.address
                      ? detailsData?.scheduleDetails?.address?.address
                      : detailsData?.scheduleDetails[0]?.address?.address
                      ? detailsData?.scheduleDetails[0]?.address?.address
                      : ""}
                  </p>
                  <div className="d-sm-none">
                    {detailsData?.listingType === "S" ||
                    detailsData?.listingType === "SPECIAL" ? (
                      <button
                        className="session_btn_popup"
                        // style={{ padding: "10px" }}
                      >
                        <img src="/static/Sport-white.svg" alt="Sport Balls" />
                        Session
                      </button>
                    ) : detailsData?.listingType === "P" ? (
                      <button
                        className="package_btn_popup"
                        style={{ padding: "10px" }}
                      >
                        <img src="/static/box-white.svg" alt="Sport Balls" />
                        Package
                      </button>
                    ) : (
                      <button
                        className="team_btn_popup"
                        style={{ padding: "10px" }}
                      >
                        <img src="/static/people-white.svg" alt="Sport Balls" />
                        Team
                      </button>
                    )}
                  </div>
                </div>
                <div className="d-none d-sm-block">
                  {detailsData?.listingType === "S" ||
                  detailsData?.listingType === "SPECIAL" ? (
                    <button
                      className="session_btn_popup"
                      // style={{ padding: "10px" }}
                    >
                      <img src="/static/Sport-white.svg" alt="Sport Balls" />
                      Session
                    </button>
                  ) : detailsData?.listingType === "P" ? (
                    <button
                      className="package_btn_popup"
                      style={{ padding: "10px" }}
                    >
                      <img src="/static/box-white.svg" alt="Sport Balls" />
                      Package
                    </button>
                  ) : (
                    <button
                      className="team_btn_popup"
                      style={{ padding: "10px" }}
                    >
                      <img src="/static/people-white.svg" alt="Sport Balls" />
                      Team
                    </button>
                  )}
                </div>
              </div>
              <div className="share_options">
                <span className="flex-fill" onClick={() => handleCopy()}>
                  <img src="/static/copy.svg" alt="Copy icon" />
                  Copy Link
                </span>
                <span className="flex-fill" onClick={handleEmailShare}>
                  <img src="/static/mail.svg" alt="Mail icon" />
                  Email
                </span>
                <span className="flex-fill" onClick={handleSmsShare}>
                  <img src="/static/message.svg" alt="Message icon" />
                  Message
                </span>
                <span className="flex-fill" onClick={handleWhatsAppShare}>
                  <img src="/static/whatsapp.svg" alt="WhatsApp icon" />
                  WhatsApp
                </span>
                <span className="flex-fill" onClick={handleMessengerShare}>
                  <img src="/static/messenger.svg" alt="Messenger icon" />
                  Messenger
                </span>
                <span className="flex-fill" onClick={handleFacebookShare}>
                  <img src="/static/facebook.svg" alt="Facebook icon" />
                  Facebook
                </span>
                <span className="flex-fill" onClick={handleTwitterShare}>
                  <img src="/static/twitter.svg" alt="Twitter icon" />
                  Twitter
                </span>
              </div>
              <button
                className="mt-5 d-none back-btn"
                onClick={handleNotShowShare}
              >
                Back
              </button>
            </div>
          </Modal.Body>
        </Modal>
      ) : null}
    </>
  );
};

export default Events2;
