import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import ApiLoader from "../../global/ApiLoader";
import Button from "../../global/Button";
import { USER_TYPE } from "../../types";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  fetchMoreIntrestList,
  getIntrestList,
  getItemFromStore,
  removeLocalStorage,
  scrollToTop,
  setLocalStorage,
  showToast,
} from "../../utils";

import endpoints from "../../utils/endpoints";
import { businessInterestForm } from "../../utils/FormConfig";
import { makePatchCall } from "../../utils/Requests";
import { ROUTE_CONSTANTS } from "../../utils/RouteConstants";
import { STRING_CONSTANTS } from "../../utils/StringConstant";
import useDebounce from "../../utils/useDebounce";
import { BusinessInterestValidation } from "./ProfileValidations";
import useCheckDeviceScreen from "../../hooks/useCheckScreen";
import LogoHeader from "../Dashboard/LogoHeader";

export default function BusinessInterests() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { handleSubmit } = useForm({
    defaultValues: businessInterestForm,
    resolver: yupResolver(BusinessInterestValidation),
    mode: "onSubmit",
  });
  const navigate = useNavigate();
  const userData = getItemFromStore("userData")?.interest || [];
  const filterOption = [
    { label: "Most popular", value: "popularity" },
    { label: "Recently added", value: "recently_added" },
    { label: "Sort A to Z", value: "title" },
    { label: "Sort Z to A", value: "-title" },
  ];
  const [filter, setFilter] = useState("popularity");
  const [showFilter, setShowFilter] = useState(false);
  const [isLoading, setIsLoading] = useState<any>(false);
  const [submitIntrest, setSubmitIntrest] = useState(false);
  const [intrestList, setIntrestList] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce(searchTerm, 500);
  const [selectedInterest, setSelectedInterest] = useState<any>(userData);
  const [nextUrl, setNextUrl] = useState(null);

  const { isMobile } = useCheckDeviceScreen();
  const handleNext = () => {
    if (!selectedInterest?.length) {
      showToast(STRING_CONSTANTS.select_atleast_one_intrest, "error");
    } else {
      scrollToTop();
      setSubmitIntrest(false);
      let apiPayload: any = {
        interest: selectedInterest.map((item: any) => item.id),
      };
      if (getItemFromStore("userData")?.isTrainer === false) {
        apiPayload.isProfileCompleted = true;
      }
      makePatchCall({
        url: endpoints.profile,
        apiPayload,
      })
        .then((res) => {
          if (res.status.code === 200) {
            showToast(res.status.message, "success");
            if (getItemFromStore("userData")?.isTrainer) {
              navigate(ROUTE_CONSTANTS.manage_expertise);
              scrollToTop();
              setLocalStorage("userData", {
                ...res.data?.userDetail,
                ...res.data?.businessDetail,
                ...res.data?.trainerData,
              });
            } else {
              removeLocalStorage("userData");
              setLocalStorage("isProfileComplete", true);
              navigate(ROUTE_CONSTANTS.events);
              scrollToTop();
            }
          } else {
            showToast(STRING_CONSTANTS.wrong_data_message, "error");
          }
          setSubmitIntrest(false);
        })
        .catch((err) => {
          showToast(err, "error");
          setSubmitIntrest(false);
        });
    }
  };

  useEffect(() => {
    getIntrestList(
      { search: searchTerm || "", sort: filter || "" },
      setIntrestList,
      setNextUrl,
      setIsLoading
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm, filter]);

  const handleIntrest = (intrest: any) => {
    let index = selectedInterest.findIndex(
      (intr: any) => intr.id === intrest.id
    );
    if (index > -1) {
      selectedInterest.splice(index, 1);
    } else {
      selectedInterest.push(intrest);
    }

    setSelectedInterest([...selectedInterest]);
  };

  return (
    <div className="container-fluid p-0 ">
      <LogoHeader />
      <div className="manage_profile">
        <div className="profile_box  ">
          <div className="title ">
            {getItemFromStore("userData").userType === USER_TYPE.MEMBER
              ? STRING_CONSTANTS.your_buisness_intrest
              : STRING_CONSTANTS.your_intrest}

            <img
              src="/static/backarrow.svg"
              alt=""
              className="back_arrow"
              onClick={() =>
                navigate(ROUTE_CONSTANTS.manage_profile + "?step=profile")
              }
            />
          </div>
          <div className="sub_title">{STRING_CONSTANTS.intrest_desc}</div>
          <div className="progressbar">
            <span
              style={{
                maxWidth:
                  getItemFromStore("userType") === USER_TYPE.GUEST
                    ? "100%"
                    : getItemFromStore("userData")?.isTrainer
                      ? "50%"
                      : "100%",
              }}
            ></span>
          </div>

          <div className="search-interest input_box position-relative">
            {isMobile ? (
              ""
            ) : (
              <span className="input_label">Search Interests</span>
            )}

            <input
              className="input-with-image"
              type="text"
              value={searchTerm}
              placeholder="Search your interests"
              onChange={(e) => {
                setSearchTerm(e.target.value);
              }}
            />
            {isMobile ? (
              <div className="search-image-icon">
                <img src="/static/search-gray.svg" alt="" />
              </div>
            ) : (
              ""
            )}

            <div className="filter-outer ">
              <img
                src={
                  filter
                    ? "/static/filterbtnselect.svg"
                    : "/static/filterbtnunselect.svg"
                }
                alt=""
                className="filter-icon"
                onClick={() => setShowFilter(!showFilter)}
              />
              {showFilter ? (
                <ul className="filter-dropdown">
                  {filterOption.map((item, index) => {
                    return (
                      <li
                        className={filter === item.value ? "active" : ""}
                        key={index}
                        onClick={() => {
                          setFilter(filter === item.value ? "" : item.value);
                          setShowFilter(!showFilter);
                        }}
                      >
                        <div>
                          {filter === item.value ? (
                            <img src="/static/victory.svg" alt="" />
                          ) : (
                            <img src="/static/recently-added.svg" alt="" />
                          )}
                          <span>{item.label}</span>
                          {filter === item.value ? (
                            <img
                              src={"/static/orange-radio-btn.svg"}
                              alt=""
                              className="select_img"
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </li>
                    );
                  })}
                </ul>
              ) : null}
            </div>
          </div>

          <ul className="business_intrest ">
            {isLoading ? (
              <ApiLoader />
            ) : intrestList?.length ? (
              <div>
                <InfiniteScroll
                  className="infinite_scroll_styles"
                  dataLength={intrestList?.length}
                  next={() =>
                    fetchMoreIntrestList(
                      nextUrl,
                      intrestList,
                      setNextUrl,
                      setIntrestList,
                      setIsLoading
                    )
                  }
                  hasMore={nextUrl ? true : false}
                  loader={<ApiLoader />}
                >
                  {intrestList?.map((item: any, index: number) => {
                    return (
                      <li key={index}>
                        <label
                          htmlFor={item.title}
                          onClick={() => {
                            handleIntrest(item);
                          }}
                          className={
                            selectedInterest.some(
                              (intr: any) => intr.id === item.id
                            )
                              ? "active"
                              : ""
                          }
                        >
                          <img src={item?.image} alt="" />

                          <svg
                            width="20"
                            height="20"
                            viewBox="0 0 20 20"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0ZM13.2197 6.96967L8.75 11.4393L6.78033 9.46967C6.48744 9.17678 6.01256 9.17678 5.71967 9.46967C5.42678 9.76256 5.42678 10.2374 5.71967 10.5303L8.21967 13.0303C8.51256 13.3232 8.98744 13.3232 9.28033 13.0303L14.2803 8.03033C14.5732 7.73744 14.5732 7.26256 14.2803 6.96967C13.9874 6.67678 13.5126 6.67678 13.2197 6.96967Z"
                              fill="#F3F3F3"
                            />
                          </svg>

                          <h4>
                            <div>{item.title}</div>{" "}
                          </h4>
                        </label>
                      </li>
                    );
                  })}
                </InfiniteScroll>
              </div>
            ) : (
              <div className="no-data-found">
                <img src="/static/no-data-found.png" alt="" />
                <p>
                  {STRING_CONSTANTS.no_data_desc} <b>"{searchTerm}".</b>
                </p>
                <small>{STRING_CONSTANTS.no_data_small_desc}</small>
              </div>
            )}
          </ul>
          <div className="continue_btn">
            <Button
              btnTxt="Continue"
              isLoading={submitIntrest}
              onClick={handleNext}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
