// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.event_container_outer {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, minmax(320px, 1fr));
  gap: 16px; }
  @media screen and (max-width: 1850px) {
    .event_container_outer {
      grid-template-columns: repeat(3, minmax(320px, 1fr)); } }
  @media screen and (max-width: 1520px) {
    .event_container_outer {
      grid-template-columns: repeat(3, minmax(280px, 1fr)); } }
  @media screen and (max-width: 1400px) {
    .event_container_outer {
      grid-template-columns: repeat(3, minmax(250px, 1fr)); } }
  @media screen and (max-width: 1300px) {
    .event_container_outer {
      grid-template-columns: repeat(2, minmax(250px, 1fr)); } }
  @media screen and (max-width: 1024px) {
    .event_container_outer {
      grid-template-columns: repeat(3, minmax(250px, 1fr)); } }
  @media screen and (max-width: 800px) {
    .event_container_outer {
      grid-template-columns: repeat(2, minmax(250px, 0.5fr)); } }
  @media screen and (max-width: 560px) {
    .event_container_outer {
      grid-template-columns: repeat(1, minmax(250px, 1fr)); } }

.no_data_container {
  padding: 40px 0px;
  display: flex;
  width: 100%;
  justify-content: center; }
  @media screen and (max-width: 560px) {
    .no_data_container .no_data_img {
      width: 280px;
      height: 180px; } }
`, "",{"version":3,"sources":["webpack://./src/components/ConnectUserProfile/EventContainer/style.container.scss"],"names":[],"mappings":"AAAA;EACI,WAAW;EACX,aAAa;EACb,oDAAoD;EACpD,SAAS,EAAA;EAET;IANJ;MAOQ,oDAAoD,EAAA,EA2B3D;EAxBG;IAVJ;MAWQ,oDAAoD,EAAA,EAuB3D;EApBG;IAdJ;MAeQ,oDAAoD,EAAA,EAmB3D;EAhBG;IAlBJ;MAmBQ,oDAAoD,EAAA,EAe3D;EAZG;IAtBJ;MAuBQ,oDAAoD,EAAA,EAW3D;EARG;IA1BJ;MA2BQ,sDAAsD,EAAA,EAO7D;EAJG;IA9BJ;MA+BQ,oDAAoD,EAAA,EAG3D;;AAED;EACI,iBAAiB;EACjB,aAAa;EACb,WAAW;EACX,uBAAuB,EAAA;EAEvB;IANJ;MAQY,YAAY;MACZ,aAAa,EAAA,EAChB","sourcesContent":[".event_container_outer {\n    width: 100%;\n    display: grid;\n    grid-template-columns: repeat(4, minmax(320px, 1fr));\n    gap: 16px;\n\n    @media screen and (max-width:1850px) {\n        grid-template-columns: repeat(3, minmax(320px, 1fr));\n    }\n\n    @media screen and (max-width:1520px) {\n        grid-template-columns: repeat(3, minmax(280px, 1fr));\n    }\n\n    @media screen and (max-width:1400px) {\n        grid-template-columns: repeat(3, minmax(250px, 1fr));\n    }\n\n    @media screen and (max-width:1300px) {\n        grid-template-columns: repeat(2, minmax(250px, 1fr));\n    }\n\n    @media screen and (max-width:1024px) {\n        grid-template-columns: repeat(3, minmax(250px, 1fr));\n    }\n\n    @media screen and (max-width:800px) {\n        grid-template-columns: repeat(2, minmax(250px, 0.5fr));\n    }\n\n    @media screen and (max-width:560px) {\n        grid-template-columns: repeat(1, minmax(250px, 1fr));\n    }\n\n}\n\n.no_data_container {\n    padding: 40px 0px;\n    display: flex;\n    width: 100%;\n    justify-content: center;\n\n    @media screen and (max-width:560px) {\n        .no_data_img {\n            width: 280px;\n            height: 180px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
