/* eslint-disable react-hooks/exhaustive-deps */
import { Fragment, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Button from "../../../global/Button";
import InputField from "../../../global/InputField";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import CommonModal from "../../../global/CommonModal";
import DateSelecter from "../../../global/DateSelecter";
import MobileInput from "../../../global/MobileInput";
import {
  convertBytesToMB,
  dateFormatter,
  getItemFromStore,
  handleLocation,
  handleVideoClick,
  isObjEmpty,
  isSocialLogin,
  showToast,
} from "../../../utils";
import { apiConstants, genderOption } from "../../../utils/Constants";
import endpoints from "../../../utils/endpoints";
import { makeGetCall, makePatchCall } from "../../../utils/Requests";
import { STRING_CONSTANTS } from "../../../utils/StringConstant";
import { PersonalDetailsValidationGuest } from "../../Profile/ProfileValidations";
import { USER_TYPE } from "../../../types";
import GoogleLocationSearchBox from "../../../global/GoogleLocationSearchBox";
import useCheckDeviceScreen from "../../../hooks/useCheckScreen";
import ImageCropper from "../../../global/ImageCropper";
import DashboardWrapper from "../../Dashboard/DashboardWrapper";
import MediaContainer from "../ManageProfileComponent/MediaContainer";
import GooglePlacesSearchBar from "../../../global/GooglePlaceSelector";
import DobField from "../../ProfileSection/DobField";

const PersonalDetailsNew = () => {
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    formState: { errors, dirtyFields },
    reset,
  } = useForm({
    // resolver: yupResolver(PersonalDetailsValidationGuest),
    mode: "onSubmit",
  });
  const navigate = useNavigate();

  const [uploadedImg, setUploadedImg] = useState<string | null>(null);
  const [userUpdate, setUserUpdate] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [tempValues, setTempValues] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [fileType, setFileType] = useState("");
  const [coverFileType, setCoverFileType] = useState("");
  const [dobError, setDobError] = useState<any>(false);
  const [abnResponse, setAbnResponse] = useState<any>("");
  const [isUserProfileChange, setIsUserProfileChange] = useState(false);
  const { isMobile } = useCheckDeviceScreen();
  const [coverImageFile, setCoverImageFile] = useState(null);
  const inputRef = useRef(null);
  const coverRef = useRef(null);

  //cropper
  const [cropperOpen, setCropperOpen] = useState(false);
  const [imageFile, setImageFile] = useState("");
  const [croppedImage, setCroppedImage] = useState<any>("");

  useEffect(() => {
    if (croppedImage) {
      setValue(apiConstants.profileImage, croppedImage, { shouldDirty: true });
      setValue(apiConstants.profileImageExtension, croppedImage.type);
    }
  }, [croppedImage]);

  //cropper
  const [cropperCoverOpen, setCropperCoverOpen] = useState(false);
  const [imageCoverFile, setImageCoverFile] = useState("");
  const [croppedCoverImage, setCroppedCoverImage] = useState<any>("");

  useEffect(() => {
    if (croppedCoverImage) {
      setValue(apiConstants.coverPhoto, croppedCoverImage, {
        shouldDirty: true,
      });
      setValue(apiConstants.coverExtension, croppedCoverImage.type);
    }
  }, [croppedCoverImage]);

  useEffect(() => {
    const proImage: any = watch(apiConstants.profileImage);
    if (proImage?.name) {
      setUploadedImg(URL.createObjectURL(proImage));
    } else {
      if (proImage) {
        setUploadedImg(proImage);
      }
    }
  }, [watch(apiConstants.profileImage)]);

  const updateprofile = (formData: any) => {
    setIsLoading(true);
    makePatchCall({
      url: endpoints.profile,
      apiPayload: formData,
      content_type: "multipart/form-data",
    })
      .then((res) => {
        if (res.status.code === 200) {
          showToast(res.status.message, "success");
          setUserUpdate(true);
          setShowPasswordModal(false);
          setIsUserProfileChange(!isUserProfileChange);
        } else {
          showToast(
            res.status.message || STRING_CONSTANTS.wrong_data_message,
            "error"
          );
        }
        setIsLoading(false);
      })
      .catch((err) => {
        showToast(err, "error");
        setIsLoading(false);
      });
  };

  const formData = new FormData();
  const updatedValues: any = {};

  useEffect(() => {
    if (
      getItemFromStore("userProfile")?.userType === USER_TYPE.MEMBER &&
      watch(apiConstants.abn)?.split(" ")?.join("")?.length === 11
    ) {
      makeGetCall({
        url: `auth/${getValues(apiConstants.abn)}/validate-abn/`,
      })
        .then((res) => {
          if (res.status.code === 200) {
            setAbnResponse(res.data);
            if (res.data.AbnStatus === "Active") {
              setAbnResponse(res.data);
              setValue(apiConstants.abnDetails, res.data);
            } else {
              setAbnResponse(res.data.Message);
            }
          } else {
            setAbnResponse(STRING_CONSTANTS.invalid_abn);
          }
        })
        .catch((err) => {
          showToast(err, "error");
        });
    } else {
      setAbnResponse(null);
    }
  }, [watch(apiConstants.abn)]);

  const onError = () => {
    showToast(STRING_CONSTANTS.general_form_error_message, "error");
  };
  const onSubmit = (data: any) => {
    if (
      abnResponse !== STRING_CONSTANTS.invalid_abn &&
      abnResponse !== STRING_CONSTANTS.invalid_abn_msg
    ) {
      // eslint-disable-next-line array-callback-return
      Object.keys(dirtyFields).map((item: any) => {
        updatedValues[item] = getValues(item);
        if (
          item === "businessContactNumber" &&
          getValues(apiConstants.businessContactNumber).length > 3
        ) {
          formData.append(
            apiConstants.businessContactNumber,
            `+61${data.businessContactNumber}`
          );
        } else if (item === "abn") {
          formData.append(
            apiConstants.abn,
            getValues(apiConstants.abn).replaceAll(" ", "")
          );
          const abnDetails: any = watch(apiConstants.abnDetails);
          if (watch(apiConstants?.abnDetails)) {
            formData.append("businessContactName", abnDetails?.EntityName);
            formData.append(
              "businessAddress",
              `${abnDetails?.AddressState} ${abnDetails?.AddressPostcode}`
            );
          }
        } else if (item === "profilePicture") {
          const profileFile: any = getValues(apiConstants.profileImage);
          formData.append("profilePictureExtension", profileFile?.type);
          formData.append(apiConstants.profileImage, profileFile);
        } else if (item === "coverPhotoVideo") {
          const coverFile: any = getValues(apiConstants.coverPhoto);
          formData.append("coverExtension", coverFile?.type);
          formData.append(apiConstants.coverPhoto, coverFile);
        } else {
          if (getValues(item) !== null) formData.append(item, getValues(item));
        }
      });

      if (
        getValues("dobDate") &&
        getValues("dobMonth") &&
        getValues("dobYear")
      ) {
        const mergeDob = `${getValues("dobDate")} ${getValues(
          "dobMonth"
        )} ${getValues("dobYear")}`;
        const dateDob = moment(mergeDob, "DD MMMM YYYY").format(
          "ddd MMM DD YYYY 00:00:00 [GMT]ZZ [(India Standard Time)]"
        );
        formData.append(apiConstants.dob, dateFormatter(dateDob, "YYYY-MM-DD"));
      }
      if (Object.keys(dirtyFields).includes("email")) {
        setTempValues(updatedValues);
        setShowPasswordModal(true);
      } else {
        updateprofile(formData);
      }
    }
  };

  const handleNext = () => {
    if (watch("dobDate") || watch("dobMonth") || watch("dobYear")) {
      const mergeDob = `${getValues("dobDate")} ${getValues(
        "dobMonth"
      )} ${getValues("dobYear")}`;
      const dateDob = moment(mergeDob, "DD MMMM YYYY").format(
        "ddd MMM DD YYYY 00:00:00 [GMT]ZZ [(India Standard Time)]"
      );

      if (!watch("dobDate") || !watch("dobMonth") || !watch("dobYear")) {
        showToast("please enter complete date of birth", "error");
        return;
      } else if (moment(dateDob).isAfter(moment())) {
        showToast(
          "The date of birth cannot be in the future. Please enter a valid date of birth",
          "error"
        );
        return;
      } else {
        handleSubmit(onSubmit, onError)();
      }
    } else {
      handleSubmit(onSubmit, onError)();
    }
  };

  useEffect(() => {
    let vid: any = document.getElementById("myVideo");
    if (
      (uploadedImg && fileType?.includes("video")) ||
      (uploadedImg &&
        getItemFromStore("userProfile")?.profilePictureExtension?.includes(
          "video"
        ))
    ) {
      if (vid) {
        vid.src = uploadedImg;
      }
    } else {
      if (vid) {
        vid.src = "";
      }
      setFileType("");
    }
  }, [uploadedImg]);

  const handleUploadClick = (target = "cover") => {
    if (target === "profile") {
      inputRef.current.click();
    } else {
      coverRef.current.click();
    }
  };

  useEffect(() => {
    if (getItemFromStore("userProfile")) {
      if (
        getItemFromStore("userProfile")?.profilePictureExtension?.includes(
          "video"
        )
      ) {
        setFileType("video");
      } else {
        setFileType("image");
      }
    }
  }, []);

  const setFileValue = (target, file) => {
    if (target === "profile") {
      setValue(apiConstants.profileImage, file, { shouldDirty: true });
      setValue(apiConstants.profileImageExtension, file.type);
    } else {
      setValue(apiConstants.coverPhoto, file, { shouldDirty: true });
      setValue(apiConstants.coverExtension, file.type);
    }
  };

  const handleFileChange = async (event, target = "cover") => {
    const file = event?.target?.files[0];
    if (!file) {
      return;
    }
    const maxSize = 5 * 1024 * 1024; // 5 MB
    const allowedImageTypes = ["image/jpeg", "image/jpg", "image/png"];
    const allowedVideoTypes = ["video/mp4", "video/quicktime"]; // .MOV MIME type is video/quicktime
    const isValidImageType = allowedImageTypes.includes(file.type);
    const isValidVideoType = allowedVideoTypes.includes(file.type);
    const isValidType = isValidImageType || isValidVideoType;

    if (target === "profile" && !isValidImageType) {
      showToast(
        "You can only upload a JPG, JPEG, or PNG image for the profile photo.",
        "error"
      );
      return;
    }

    if (target === "cover" && !isValidType) {
      showToast(
        "You can only upload JPG, JPEG, PNG images, or MP4, MOV videos for the cover photo.",
        "error"
      );
      return;
    }

    if (file.size > maxSize) {
      showToast("You can only upload a maximum 5MB file size.", "error");
      return;
    }

    if (isValidVideoType && target === "cover") {
      const video = document.createElement("video");
      video.preload = "metadata";
      video.src = URL.createObjectURL(file);

      video.onloadedmetadata = function () {
        URL.revokeObjectURL(video.src);
        if (video.duration > 30) {
          showToast("Video cannot be longer than 30 seconds.", "error");
        } else {
          setFileValue(target, file);
        }
      };
    } else {
      if (file?.type?.startsWith("image")) {
        if (target === "cover") {
          const reader: any = new FileReader();
          reader.onloadend = function () {
            setImageCoverFile(reader?.result);
            setCropperCoverOpen(true);
          };
          reader.readAsDataURL(file);
          setImageCoverFile(file);
        } else {
          const reader: any = new FileReader();
          reader.onloadend = function () {
            setImageFile(reader?.result);
            setCropperOpen(true);
          };
          reader.readAsDataURL(file);
          setImageFile(file);
        }
      } else {
        setFileValue(target, file);
      }
    }
  };

  return (
    <DashboardWrapper
      watch={watch}
      userUpdate={userUpdate}
      reset={reset}
      showTab={true}
      isUserProfileChange={isUserProfileChange}
    >
      <div className="personal-details-wrapper">
        <div className="profile_box">
          <div className="title">
            <img
              src="/static/backarrow.svg"
              alt=""
              className="back_arrow d-md-none"
              onClick={() => navigate(-1)}
            />
            {STRING_CONSTANTS.update_profile}
          </div>
        </div>
        <div className="image-upload-box">
          <div className="cover-photo-box">
            <>
              <div className="cover_empty">
                <label
                // onClick={(e) => {
                //   e.stopPropagation();
                //   handleUploadClick("cover");
                // }}
                >
                  {watch(apiConstants.coverPhoto) ? (
                    <>
                      <MediaContainer
                        file={watch(apiConstants.coverPhoto)}
                        className="cover-pic"
                        type={watch(apiConstants.coverExtension)}
                      />
                      <img
                        className="add_cover_icon"
                        src={"/static/addProfileIcon.svg"}
                        alt=""
                      />
                    </>
                  ) : (
                    <div className="add_cover_icon_center">
                      <img
                        // onClick={(e) => {
                        //   e.stopPropagation();
                        //   handleUploadClick("cover");
                        // }}
                        src={"/static/addProfileIcon.svg"}
                        alt=""
                      />
                      <div className="cover-title">Add cover photo/video</div>
                    </div>
                  )}
                  <input
                    type="file"
                    accept="image/jpeg,image/jpg,image/png,video/mp4,video/quicktime"
                    ref={coverRef}
                    style={{ display: "none" }}
                    onInput={(e) => handleFileChange(e, "cover")}
                    onClick={(e: any) => (e.target.value = "")}
                  />
                </label>
              </div>
            </>
            <div className="profile-image-box">
              <div className="profile-image-wrap">
                <label
                  // htmlFor="add_photo"
                  onClick={() => handleUploadClick("profile")}
                >
                  {fileType?.includes("video") ? (
                    <div className="profile-pic video">
                      <video
                        autoPlay
                        playsInline
                        onClick={() => handleVideoClick("myVideo")}
                        id="myVideo"
                        loop
                        muted
                        src={""}
                      >
                        <source
                          src={watch(apiConstants.profileImage) || ""}
                          type="video/mp4"
                        />
                      </video>
                    </div>
                  ) : (
                    <img
                      src={uploadedImg || "/static/dummyimg.svg"}
                      alt=""
                      className="profile-pic"
                    />
                  )}
                  <img
                    className="add_image_icon"
                    src="/static/addProfileIcon.svg"
                    alt=""
                  />
                </label>
              </div>
              <input
                type={"file"}
                accept="image/*,video/*,.gif"
                onInput={(e) => {
                  handleFileChange(e, "photo");
                }}
                onClick={(e: any) => (e.target.value = "")}
                style={{ display: "none" }}
                className="user_img_input"
                ref={inputRef}
              />
            </div>
          </div>
        </div>

        <>
          <div className="details-box">
            <div className="detail-title">Personal Details</div>
            <InputField
              type="text"
              name={apiConstants.name}
              control={control}
              errors={errors}
              inputLabel={"Name*"}
              placeholder={"Add your preferred full name"}
              setValue={setValue}
              maxlength={250}
            />
            <div style={{ marginTop: "24px" }}>
              <InputField
                type="text"
                name={apiConstants.email}
                control={control}
                errors={errors}
                inputLabel={"Email Address *"}
                placeholder={"Add your email address"}
                setValue={setValue}
                disabled={isSocialLogin()}
              />
            </div>

            <div className="gender-box" style={{ marginTop: "24px" }}>
              <p className="title">Gender</p>
              <div className="gender_wrapper_new">
                {genderOption.map((item, index) => {
                  return (
                    <Fragment key={index}>
                      <div>
                        <label
                          htmlFor={item.value}
                          className={
                            watch(apiConstants.gender) === item.value
                              ? "gender_option active"
                              : "gender_option"
                          }
                        >
                          {watch(apiConstants.gender) === item.value ? (
                            <img src={"/static/radiocheck.svg"} alt="" />
                          ) : (
                            <img src={"/static/radiouncheck.svg"} alt="" />
                          )}

                          <InputField
                            key={index}
                            name={apiConstants.gender}
                            type="radio"
                            control={control}
                            id={item.value}
                            inputLabel={item.label}
                            setValue={setValue}
                          />
                        </label>
                      </div>
                      {index + 1 === genderOption.length && errors.gender ? (
                        <span className="text-danger mb-1 mt-1">
                          {errors.gender.message}
                        </span>
                      ) : (
                        ""
                      )}
                    </Fragment>
                  );
                })}
              </div>
            </div>

            <div style={{ marginBottom: "24px" }}>
              <DobField watch={watch} setValue={setValue} />
            </div>

            {/* <div style={{ marginBottom: "24px" }}>
              <p className="input_title_dob">Date Of Birth </p>
              <div className="dob_wrapper">
                <DateSelecter
                  name={apiConstants.dob}
                  errors={errors}
                  control={control}
                  dobError={dobError}
                />
              </div>
            </div> */}
          </div>
          <div>
            <InputField
              type={"textarea"}
              name={apiConstants.bio}
              control={control}
              errors={errors}
              inputLabel={"Bio"}
              className={"textarea-label"}
              placeholder={"Share your fitness passion"}
              maxlength="300"
              setValue={setValue}
              getValues={getValues}
            />

            <div
              className="continue_btn"
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
            >
              <Button
                style={{ height: "56px", maxWidth: "555px" }}
                btnTxt="Save"
                className={"backtologin"}
                onClick={handleNext}
                isLoading={isLoading}
              />
            </div>
          </div>
        </>
      </div>
      {showPasswordModal ? (
        <CommonModal
          onSubmit={(data: any) => updateprofile(data)}
          title={STRING_CONSTANTS.confirm_you}
          formData={tempValues}
          desc={STRING_CONSTANTS.password_desc}
          onClose={() => setShowPasswordModal(false)}
        />
      ) : (
        ""
      )}
      {cropperOpen ? (
        <div className="modal show">
          <div
            className="modal-section cropper_modal_wrap"
            style={{ padding: "0" }}
          >
            <ImageCropper
              getImage={imageFile}
              setCropedImage={setCroppedImage}
              setModal={setCropperOpen}
              isProfile={true}
              cropperAspect={1 / 1}
            />
          </div>
        </div>
      ) : null}
      {cropperCoverOpen ? (
        <div className="modal show">
          <div
            className="modal-section cropper_modal_wrap"
            style={{ padding: "0" }}
          >
            <ImageCropper
              getImage={imageCoverFile}
              setCropedImage={setCroppedCoverImage}
              setModal={setCropperCoverOpen}
              cropperAspect={4 / 1}
            />
          </div>
        </div>
      ) : null}
    </DashboardWrapper>
  );
};

export default PersonalDetailsNew;
