import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./assets/scss/main.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "./store/store";
import RouteStack from "./routes";
import { authSuccess, updateLoading } from "./store/auth/action";
import LoadingSplash from "./components/LoadingSplash";
import { makeGetCall, makePatchCall } from "./utils/Requests";
import apiConfig from "./utils/endpoints";
import LoadingLayer from "./components/ConnectUserProfile/LoaderLayer";
import { getGeolocation } from "./constants/helperConnectUser";
import { showToast } from "./utils";

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isUserLogin, setIsUserLogin] = useState(true);

  const auth = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const fm_token = localStorage.getItem("fm_token");
    if (fm_token) {
      makeGetCall({ url: apiConfig.profile })
        .then(async (res) => {
          dispatch(
            authSuccess({
              token: fm_token,
              moreUserDetails: res?.data?.userDetail,
              loggedIn: true,
            })
          );
          const locationData: any = await getGeolocation();
          if (
            locationData?.currentLng !== null &&
            locationData?.currentLat !== null
          ) {
            await makePatchCall({
              url: "auth/location/",
              apiPayload: {
                location: `${locationData?.currentLng},${locationData?.currentLat}`,
              },
            })
              .then((res) => {})
              .catch((err) => {
                showToast(err, "error");
              });
          }
        })
        .catch((err) => {
          console.log("err: ", err);
        });
      setIsUserLogin(false);
    } else {
      setIsUserLogin(false);
    }
  }, [dispatch]);

  const queryParams = new URLSearchParams(location.search);
  const attribute = queryParams.get("attribute");
  if (attribute) {
    localStorage.setItem("attribute", attribute);
    navigate(`/`);
  }

  return (
    <React.Fragment>
      {isUserLogin ? (
        <LoadingSplash />
      ) : (
        <>
          {auth?.loading && <LoadingLayer />}
          <RouteStack />
        </>
      )}
      <ToastContainer />
    </React.Fragment>
  );
};

export default App;
