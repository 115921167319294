import { useEffect, useState } from "react";
import { makeGetCall } from "../../../utils/Requests";
import { apiConstants } from "../../../utils/Constants";
import { showToast } from "../../../utils";
import ShortUserCard from "./ShortUserCard";
import { useNavigate } from "react-router-dom";
import { getGeolocation } from "../../../constants/helperConnectUser";

const Requests = () => {
  const navigate = useNavigate();
  const [connectionRequestList, setConnectionRequestList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  
  const getConnectionRequestList = async() => {
    const userLocationData:any = await getGeolocation();
    const params = {
      filter_by: "trainer",
      limit: 12,
    };
    if(userLocationData?.currentLat && userLocationData?.currentLng ){
      params["point"] = `${userLocationData?.currentLng},${userLocationData?.currentLat}` ;
    }
   await makeGetCall({
      url: apiConstants.get_pending_connections,
      params,
    })
      .then((res) => {
        if (res.status.code === 200) {
          setConnectionRequestList(res.results);
          setIsLoading((prev) => !prev);
        }
      })
      .catch((err: any) => {
        showToast(err, "error");
      });
  };
  useEffect(() => {
    getConnectionRequestList();
  }, []);
  
  return (
    <div>
      {connectionRequestList?.length > 0 && !isLoading && (
        <div
          style={{
            display: "flex",
            gap: "16px",
            flexDirection: "column",
            marginTop: "22px",
            backgroundColor: "white",
            borderRadius: "12px",
          }}
        >
          <div
            style={{
              fontSize: "20px",
              fontWeight: "600",
              color: "#25272D",
              marginLeft: "24px",
              marginTop: "27px",
            }}
          >
            Fitness mates requests
          </div>
          {connectionRequestList.slice(0,8).map((connection) => (
            <ShortUserCard connectionData={connection} mode="request" key={connection?.id} />
          ))}
          {connectionRequestList?.length > 8 && (
            <div className="see-all-btn">
              <div
                className="text-button"
                onClick={() => navigate("/connect/requests")}
              >
                See all
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Requests;
