// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination_container .pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
  align-items: center; }

.pagination_container .page-item {
  display: flex;
  justify-content: center;
  align-items: center; }
  .pagination_container .page-item.active {
    background: #EE7830;
    min-width: 30px;
    height: 30px;
    border-radius: 5px; }
  .pagination_container .page-item.disabled {
    background: none;
    cursor: not-allowed;
    pointer-events: auto; }

.pagination_container .prev_page_container,
.pagination_container .next_page_container {
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #25272D; }
  @media screen and (max-width: 600px) {
    .pagination_container .prev_page_container span,
    .pagination_container .next_page_container span {
      display: none; } }
  .pagination_container .prev_page_container.disabled,
  .pagination_container .next_page_container.disabled {
    cursor: not-allowed; }
`, "",{"version":3,"sources":["webpack://./src/components/ConnectUserProfile/Pagination/style.scss"],"names":[],"mappings":"AAAA;EAGQ,aAAa;EACb,eAAe;EACf,gBAAgB;EAChB,mBAAmB,EAAA;;AAN3B;EAUQ,aAAa;EACb,uBAAuB;EACvB,mBAAmB,EAAA;EAZ3B;IAeY,mBAAmB;IACnB,eAAe;IACf,YAAY;IACZ,kBAAkB,EAAA;EAlB9B;IAuBY,gBAAgB;IAChB,mBAAmB;IACnB,oBAAoB,EAAA;;AAzBhC;;EAgCQ,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,oBAAoB;EACpB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,cAAc,EAAA;EAEd;IAzCR;;MA2CgB,aAAa,EAAA,EAChB;EA5Cb;;IAgDY,mBAAmB,EAAA","sourcesContent":[".pagination_container {\n\n    .pagination {\n        display: flex;\n        padding-left: 0;\n        list-style: none;\n        align-items: center;\n    }\n\n    .page-item {\n        display: flex;\n        justify-content: center;\n        align-items: center;\n\n        &.active {\n            background: #EE7830;\n            min-width: 30px;\n            height: 30px;\n            border-radius: 5px;\n        }\n\n        &.disabled {\n\n            background: none;\n            cursor: not-allowed;\n            pointer-events: auto;\n\n        }\n    }\n\n    .prev_page_container,\n    .next_page_container {\n        display: flex;\n        align-items: center;\n        gap: 10px;\n        font-family: Poppins;\n        font-size: 16px;\n        font-weight: 400;\n        line-height: 24px;\n        color: #25272D;\n\n        @media screen and (max-width:600px) {\n            span {\n                display: none;\n            }\n        }\n\n        &.disabled {\n            cursor: not-allowed;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
