export const eventData = [
  {
    createdBy: {
      id: "406761e9-c55a-4786-b25e-6c4377934b3d",
      name: "Anil Create Linting",
      bio: null,
      tradingName: null,
      isAdmin: false,
      profilePicture: null,
      profilePictureExtension: null,
      thumbnail: null,
      userType: "Member",
    },
    id: "9c96734f-5c07-40df-8231-1347809692b3",
    listingType: "S",
    isActive: true,
    isPrivate: false,
    isDraft: false,
    createdAt: "2024-07-02T18:27:14.823658Z",
    difficultyLevel: ["B"],
    title: "Repeat Multi(3),Week Frequency Recurring Class",
    media: null,
    description: "Repeat Multi(3),Weeks Frequency Recurring Class",
    thumbnail: null,
    upcomingEventDate: "2024-07-28",
    interests: [
      {
        id: "92c2a1db-7647-4a74-aa9a-921c67b7c9a8",
        title: "Basketball",
        image:
          "https://fitness-mates.s3.amazonaws.com/interest/92c2a1db76474a74aa9a921c67b7c9a8/yR6KLrrn-1717664098278.png",
        icon: "https://fitness-mates.s3.amazonaws.com/interest/92c2a1db76474a74aa9a921c67b7c9a8/hlNT5J7S-1717664098419.png",
      },
    ],
    isAgeLimit: false,
    minAge: null,
    listingEventType: "P",
    maxAge: null,
    gender: ["F"],
    maxParticipants: 100,
    dynamicLink: "https://fitnessmates.page.link/ta4j",
    price: "23.00",
    scheduleDetails: {
      address: {
        city: "",
        state: "",
        address:
          "Karrinyup Shopping Centre 200 Karrinyup Rd, Karrinyup WA 6018, Australia",
        country: "",
        location: "POINT (115.7781686 -31.87734769999999)",
        postCode: "",
      },
      endDate: "2025-01-22",
      endTime: "23:30:00.332000",
      frequency: "W",
      weekDays: ["Sunday", "Monday"],
      startDate: "2024-07-10",
      startTime: "23:00:00.663000",
      isRecurring: true,
      recurringType: "R",
      frequencyNumber: "3",
      monthlyFrequency: "week",
      singleListingType: "R",
      monthlyFrequencyDesc: "",
    },
    teamSlotsAvailable: null,
    distance: 7760.724259355202,
    slotsAvailable: null,
    publishedAt: "2024-07-02T18:27:14.821977Z",
    events: [
      {
        id: "77b5d1e6-67eb-41c9-92a2-f3704a1a43ad",
        title: "Repeat Multi(3),Week Frequency Recurring Class",
        date: "2024-07-28",
        startTime: "23:00:00.663000",
        endTime: "23:30:00.332000",
        slotsAvailable: 100,
        address: {
          address:
            "Karrinyup Shopping Centre 200 Karrinyup Rd, Karrinyup WA 6018, Australia",
          city: "",
          location: "SRID=4326;POINT (115.7781686 -31.87734769999999)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -31.87734769999999,
          longitude: 115.7781686,
        },
      },
      {
        id: "3cbde174-d395-458b-9528-f0d14b776eb4",
        title: "Repeat Multi(3),Week Frequency Recurring Class",
        date: "2024-07-29",
        startTime: "23:00:00.663000",
        endTime: "23:30:00.332000",
        slotsAvailable: 100,
        address: {
          address:
            "Karrinyup Shopping Centre 200 Karrinyup Rd, Karrinyup WA 6018, Australia",
          city: "",
          location: "SRID=4326;POINT (115.7781686 -31.87734769999999)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -31.87734769999999,
          longitude: 115.7781686,
        },
      },
      {
        id: "1b4b5fad-7abd-4e64-8930-ce72d4131827",
        title: "Repeat Multi(3),Week Frequency Recurring Class",
        date: "2024-08-18",
        startTime: "23:00:00.663000",
        endTime: "23:30:00.332000",
        slotsAvailable: 100,
        address: {
          address:
            "Karrinyup Shopping Centre 200 Karrinyup Rd, Karrinyup WA 6018, Australia",
          city: "",
          location: "SRID=4326;POINT (115.7781686 -31.87734769999999)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -31.87734769999999,
          longitude: 115.7781686,
        },
      },
      {
        id: "1724aa9d-2fde-46d4-ae1a-4d33c01ace8f",
        title: "Repeat Multi(3),Week Frequency Recurring Class",
        date: "2024-08-19",
        startTime: "23:00:00.663000",
        endTime: "23:30:00.332000",
        slotsAvailable: 100,
        address: {
          address:
            "Karrinyup Shopping Centre 200 Karrinyup Rd, Karrinyup WA 6018, Australia",
          city: "",
          location: "SRID=4326;POINT (115.7781686 -31.87734769999999)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -31.87734769999999,
          longitude: 115.7781686,
        },
      },
      {
        id: "ba1b3c39-12ea-46b4-b028-d8067b48b6a3",
        title: "Repeat Multi(3),Week Frequency Recurring Class",
        date: "2024-09-08",
        startTime: "23:00:00.663000",
        endTime: "23:30:00.332000",
        slotsAvailable: 100,
        address: {
          address:
            "Karrinyup Shopping Centre 200 Karrinyup Rd, Karrinyup WA 6018, Australia",
          city: "",
          location: "SRID=4326;POINT (115.7781686 -31.87734769999999)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -31.87734769999999,
          longitude: 115.7781686,
        },
      },
      {
        id: "aedf860a-46d6-42b5-8637-564386ee2a06",
        title: "Repeat Multi(3),Week Frequency Recurring Class",
        date: "2024-09-09",
        startTime: "23:00:00.663000",
        endTime: "23:30:00.332000",
        slotsAvailable: 100,
        address: {
          address:
            "Karrinyup Shopping Centre 200 Karrinyup Rd, Karrinyup WA 6018, Australia",
          city: "",
          location: "SRID=4326;POINT (115.7781686 -31.87734769999999)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -31.87734769999999,
          longitude: 115.7781686,
        },
      },
      {
        id: "dccdedbd-4c7a-49b8-8f26-f5eeac91881f",
        title: "Repeat Multi(3),Week Frequency Recurring Class",
        date: "2024-09-29",
        startTime: "23:00:00.663000",
        endTime: "23:30:00.332000",
        slotsAvailable: 100,
        address: {
          address:
            "Karrinyup Shopping Centre 200 Karrinyup Rd, Karrinyup WA 6018, Australia",
          city: "",
          location: "SRID=4326;POINT (115.7781686 -31.87734769999999)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -31.87734769999999,
          longitude: 115.7781686,
        },
      },
      {
        id: "816c88be-fa6b-4717-bd62-e5d1615b66be",
        title: "Repeat Multi(3),Week Frequency Recurring Class",
        date: "2024-09-30",
        startTime: "23:00:00.663000",
        endTime: "23:30:00.332000",
        slotsAvailable: 100,
        address: {
          address:
            "Karrinyup Shopping Centre 200 Karrinyup Rd, Karrinyup WA 6018, Australia",
          city: "",
          location: "SRID=4326;POINT (115.7781686 -31.87734769999999)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -31.87734769999999,
          longitude: 115.7781686,
        },
      },
      {
        id: "d427f4b3-640b-4b55-a037-3942b49e98c4",
        title: "Repeat Multi(3),Week Frequency Recurring Class",
        date: "2024-10-20",
        startTime: "23:00:00.663000",
        endTime: "23:30:00.332000",
        slotsAvailable: 100,
        address: {
          address:
            "Karrinyup Shopping Centre 200 Karrinyup Rd, Karrinyup WA 6018, Australia",
          city: "",
          location: "SRID=4326;POINT (115.7781686 -31.87734769999999)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -31.87734769999999,
          longitude: 115.7781686,
        },
      },
      {
        id: "e8328256-7a83-4d19-b28e-741af085bbca",
        title: "Repeat Multi(3),Week Frequency Recurring Class",
        date: "2024-10-21",
        startTime: "23:00:00.663000",
        endTime: "23:30:00.332000",
        slotsAvailable: 100,
        address: {
          address:
            "Karrinyup Shopping Centre 200 Karrinyup Rd, Karrinyup WA 6018, Australia",
          city: "",
          location: "SRID=4326;POINT (115.7781686 -31.87734769999999)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -31.87734769999999,
          longitude: 115.7781686,
        },
      },
      {
        id: "21506fb4-7903-45c5-8ee9-7e08e0eb3e7f",
        title: "Repeat Multi(3),Week Frequency Recurring Class",
        date: "2024-11-10",
        startTime: "23:00:00.663000",
        endTime: "23:30:00.332000",
        slotsAvailable: 100,
        address: {
          address:
            "Karrinyup Shopping Centre 200 Karrinyup Rd, Karrinyup WA 6018, Australia",
          city: "",
          location: "SRID=4326;POINT (115.7781686 -31.87734769999999)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -31.87734769999999,
          longitude: 115.7781686,
        },
      },
      {
        id: "f02a05c4-856a-4439-a4d1-cd91cfb806b7",
        title: "Repeat Multi(3),Week Frequency Recurring Class",
        date: "2024-11-11",
        startTime: "23:00:00.663000",
        endTime: "23:30:00.332000",
        slotsAvailable: 100,
        address: {
          address:
            "Karrinyup Shopping Centre 200 Karrinyup Rd, Karrinyup WA 6018, Australia",
          city: "",
          location: "SRID=4326;POINT (115.7781686 -31.87734769999999)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -31.87734769999999,
          longitude: 115.7781686,
        },
      },
      {
        id: "16f6b414-9bf3-46f4-9369-119968569858",
        title: "Repeat Multi(3),Week Frequency Recurring Class",
        date: "2024-12-01",
        startTime: "23:00:00.663000",
        endTime: "23:30:00.332000",
        slotsAvailable: 100,
        address: {
          address:
            "Karrinyup Shopping Centre 200 Karrinyup Rd, Karrinyup WA 6018, Australia",
          city: "",
          location: "SRID=4326;POINT (115.7781686 -31.87734769999999)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -31.87734769999999,
          longitude: 115.7781686,
        },
      },
      {
        id: "92c739ca-b3d9-4647-830c-5c008f9fec33",
        title: "Repeat Multi(3),Week Frequency Recurring Class",
        date: "2024-12-02",
        startTime: "23:00:00.663000",
        endTime: "23:30:00.332000",
        slotsAvailable: 100,
        address: {
          address:
            "Karrinyup Shopping Centre 200 Karrinyup Rd, Karrinyup WA 6018, Australia",
          city: "",
          location: "SRID=4326;POINT (115.7781686 -31.87734769999999)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -31.87734769999999,
          longitude: 115.7781686,
        },
      },
      {
        id: "b43f7071-b123-4d16-9e6a-b382b9f5e5ad",
        title: "Repeat Multi(3),Week Frequency Recurring Class",
        date: "2024-12-22",
        startTime: "23:00:00.663000",
        endTime: "23:30:00.332000",
        slotsAvailable: 100,
        address: {
          address:
            "Karrinyup Shopping Centre 200 Karrinyup Rd, Karrinyup WA 6018, Australia",
          city: "",
          location: "SRID=4326;POINT (115.7781686 -31.87734769999999)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -31.87734769999999,
          longitude: 115.7781686,
        },
      },
      {
        id: "cca37337-1ebb-40c8-bde4-87d2a58e663e",
        title: "Repeat Multi(3),Week Frequency Recurring Class",
        date: "2024-12-23",
        startTime: "23:00:00.663000",
        endTime: "23:30:00.332000",
        slotsAvailable: 100,
        address: {
          address:
            "Karrinyup Shopping Centre 200 Karrinyup Rd, Karrinyup WA 6018, Australia",
          city: "",
          location: "SRID=4326;POINT (115.7781686 -31.87734769999999)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -31.87734769999999,
          longitude: 115.7781686,
        },
      },
      {
        id: "ba5e7743-089d-40a6-b5fb-ec08c7717cc6",
        title: "Repeat Multi(3),Week Frequency Recurring Class",
        date: "2025-01-12",
        startTime: "23:00:00.663000",
        endTime: "23:30:00.332000",
        slotsAvailable: 100,
        address: {
          address:
            "Karrinyup Shopping Centre 200 Karrinyup Rd, Karrinyup WA 6018, Australia",
          city: "",
          location: "SRID=4326;POINT (115.7781686 -31.87734769999999)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -31.87734769999999,
          longitude: 115.7781686,
        },
      },
      {
        id: "da00ebed-abb8-496c-b947-360a882b29a3",
        title: "Repeat Multi(3),Week Frequency Recurring Class",
        date: "2025-01-13",
        startTime: "23:00:00.663000",
        endTime: "23:30:00.332000",
        slotsAvailable: 100,
        address: {
          address:
            "Karrinyup Shopping Centre 200 Karrinyup Rd, Karrinyup WA 6018, Australia",
          city: "",
          location: "SRID=4326;POINT (115.7781686 -31.87734769999999)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -31.87734769999999,
          longitude: 115.7781686,
        },
      },
    ],
    photos: [
      {
        uploadDocument:
          "https://fitness-mates.s3.amazonaws.com/photos/9c96734f-5c07-40df-8231-1347809692b3/1398-920534-5efac5ad-d974-4c28-ad24-aefd549486a0.jpg?AWSAccessKeyId=AKIA5UHJQKEGZGW3J53I&Signature=wPSP5hSrvWwRGWcK30aFY6SHi1E%3D&Expires=1721642410",
        documentName: "pexels-minan1398-920534.jpg",
      },
    ],
    isFavourite: false,
  },
  {
    createdBy: {
      id: "406761e9-c55a-4786-b25e-6c4377934b3d",
      name: "Anil Create Linting",
      bio: null,
      tradingName: null,
      isAdmin: false,
      profilePicture: null,
      profilePictureExtension: null,
      thumbnail: null,
      userType: "Member",
    },
    id: "6764ab35-b778-4ec8-bfea-5acecbb47705",
    listingType: "S",
    isActive: true,
    isPrivate: false,
    isDraft: false,
    createdAt: "2024-07-02T18:15:15.826543Z",
    difficultyLevel: ["B"],
    title: "Custom Days Recurring Class",
    media: null,
    description:
      "Custom Days Recurring Class Custom Days Recurring Class Custom Days Recurring Class Custom Days Recurring Class",
    thumbnail: null,
    upcomingEventDate: "2024-08-14",
    interests: [
      {
        id: "ee4cffe2-7b40-4179-b85c-d34b3465aec3",
        title: "Dance",
        image: null,
        icon: "https://fitness-mates.s3.amazonaws.com/interest/ee4cffe27b404179b85cd34b3465aec3/tVOFt03x-1717664296607.png",
      },
    ],
    isAgeLimit: false,
    minAge: null,
    listingEventType: "P",
    maxAge: null,
    gender: ["F"],
    maxParticipants: 1000,
    dynamicLink: "https://fitnessmates.page.link/cBWC",
    price: "23.00",
    scheduleDetails: {
      dates: [
        "2024-07-10",
        "2024-07-11",
        "2024-07-12",
        "2024-07-13",
        "2024-07-14",
        "2024-08-14",
        "2024-08-15",
        "2024-08-16",
        "2024-08-17",
        "2024-08-19",
        "2024-08-20",
        "2024-08-21",
      ],
      address: {
        city: "",
        state: "",
        address: "Fremantle Fremantle WA 6160, Australia",
        country: "",
        location: "POINT (115.755096 -32.0517901)",
        postCode: "",
      },
      endTime: "22:30:00.727000",
      startTime: "21:00:00.678000",
      singleListingType: "C",
    },
    teamSlotsAvailable: null,
    distance: 7774.689659454453,
    slotsAvailable: null,
    publishedAt: "2024-07-02T18:15:15.824720Z",
    events: [
      {
        id: "7b793db3-a088-4c5c-950f-1b01782912a4",
        title: "Custom Days Recurring Class",
        date: "2024-07-10",
        startTime: "21:00:00.678000",
        endTime: "22:30:00.727000",
        slotsAvailable: 1000,
        address: {
          address: "Fremantle Fremantle WA 6160, Australia",
          city: "",
          location: "SRID=4326;POINT (115.755096 -32.0517901)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -32.0517901,
          longitude: 115.755096,
        },
      },
      {
        id: "8a8a7c99-05d2-4717-8178-edd0d493eb66",
        title: "Custom Days Recurring Class",
        date: "2024-07-11",
        startTime: "21:00:00.678000",
        endTime: "22:30:00.727000",
        slotsAvailable: 1000,
        address: {
          address: "Fremantle Fremantle WA 6160, Australia",
          city: "",
          location: "SRID=4326;POINT (115.755096 -32.0517901)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -32.0517901,
          longitude: 115.755096,
        },
      },
      {
        id: "e09a35ad-3d50-4815-94a9-3b79570729a0",
        title: "Custom Days Recurring Class",
        date: "2024-07-12",
        startTime: "21:00:00.678000",
        endTime: "22:30:00.727000",
        slotsAvailable: 1000,
        address: {
          address: "Fremantle Fremantle WA 6160, Australia",
          city: "",
          location: "SRID=4326;POINT (115.755096 -32.0517901)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -32.0517901,
          longitude: 115.755096,
        },
      },
      {
        id: "41e85611-1b2b-40d8-bc45-6799ebe2bb31",
        title: "Custom Days Recurring Class",
        date: "2024-07-13",
        startTime: "21:00:00.678000",
        endTime: "22:30:00.727000",
        slotsAvailable: 1000,
        address: {
          address: "Fremantle Fremantle WA 6160, Australia",
          city: "",
          location: "SRID=4326;POINT (115.755096 -32.0517901)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -32.0517901,
          longitude: 115.755096,
        },
      },
      {
        id: "443e6cd3-90bf-4a68-8bd1-9fceec64490a",
        title: "Custom Days Recurring Class",
        date: "2024-07-14",
        startTime: "21:00:00.678000",
        endTime: "22:30:00.727000",
        slotsAvailable: 1000,
        address: {
          address: "Fremantle Fremantle WA 6160, Australia",
          city: "",
          location: "SRID=4326;POINT (115.755096 -32.0517901)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -32.0517901,
          longitude: 115.755096,
        },
      },
      {
        id: "77c419c7-8375-45dd-81d8-0d473f85e71d",
        title: "Custom Days Recurring Class",
        date: "2024-08-14",
        startTime: "21:00:00.678000",
        endTime: "22:30:00.727000",
        slotsAvailable: 1000,
        address: {
          address: "Fremantle Fremantle WA 6160, Australia",
          city: "",
          location: "SRID=4326;POINT (115.755096 -32.0517901)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -32.0517901,
          longitude: 115.755096,
        },
      },
      {
        id: "2f8f8088-d74e-45cb-876e-078ee4936210",
        title: "Custom Days Recurring Class",
        date: "2024-08-15",
        startTime: "21:00:00.678000",
        endTime: "22:30:00.727000",
        slotsAvailable: 1000,
        address: {
          address: "Fremantle Fremantle WA 6160, Australia",
          city: "",
          location: "SRID=4326;POINT (115.755096 -32.0517901)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -32.0517901,
          longitude: 115.755096,
        },
      },
      {
        id: "4926ab8f-f3cd-4ea6-8b4c-ea3fbfc5a1b7",
        title: "Custom Days Recurring Class",
        date: "2024-08-16",
        startTime: "21:00:00.678000",
        endTime: "22:30:00.727000",
        slotsAvailable: 1000,
        address: {
          address: "Fremantle Fremantle WA 6160, Australia",
          city: "",
          location: "SRID=4326;POINT (115.755096 -32.0517901)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -32.0517901,
          longitude: 115.755096,
        },
      },
      {
        id: "507e8c07-542c-4ea9-b881-5d285d46dacb",
        title: "Custom Days Recurring Class",
        date: "2024-08-17",
        startTime: "21:00:00.678000",
        endTime: "22:30:00.727000",
        slotsAvailable: 1000,
        address: {
          address: "Fremantle Fremantle WA 6160, Australia",
          city: "",
          location: "SRID=4326;POINT (115.755096 -32.0517901)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -32.0517901,
          longitude: 115.755096,
        },
      },
      {
        id: "d12a1da2-c10b-4ac0-9972-05f9f1011bae",
        title: "Custom Days Recurring Class",
        date: "2024-08-19",
        startTime: "21:00:00.678000",
        endTime: "22:30:00.727000",
        slotsAvailable: 1000,
        address: {
          address: "Fremantle Fremantle WA 6160, Australia",
          city: "",
          location: "SRID=4326;POINT (115.755096 -32.0517901)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -32.0517901,
          longitude: 115.755096,
        },
      },
      {
        id: "1546aec7-892a-47bd-9a2c-e8d52e1e1bfe",
        title: "Custom Days Recurring Class",
        date: "2024-08-20",
        startTime: "21:00:00.678000",
        endTime: "22:30:00.727000",
        slotsAvailable: 1000,
        address: {
          address: "Fremantle Fremantle WA 6160, Australia",
          city: "",
          location: "SRID=4326;POINT (115.755096 -32.0517901)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -32.0517901,
          longitude: 115.755096,
        },
      },
      {
        id: "dd4e26ea-5367-4e7f-ad46-6dfd7d5a9657",
        title: "Custom Days Recurring Class",
        date: "2024-08-21",
        startTime: "21:00:00.678000",
        endTime: "22:30:00.727000",
        slotsAvailable: 1000,
        address: {
          address: "Fremantle Fremantle WA 6160, Australia",
          city: "",
          location: "SRID=4326;POINT (115.755096 -32.0517901)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -32.0517901,
          longitude: 115.755096,
        },
      },
    ],
    photos: [
      {
        uploadDocument:
          "https://fitness-mates.s3.amazonaws.com/photos/6764ab35-b778-4ec8-bfea-5acecbb47705/aik-1025469-a6175a82-065e-4e96-9219-0b35989a48ef.jpg?AWSAccessKeyId=AKIA5UHJQKEGZGW3J53I&Signature=00iWtF%2Foe0i0%2BIiRFVcl99ahtHI%3D&Expires=1721642410",
        documentName: "pexels-creationsbyfaik-1025469.jpg",
      },
    ],
    isFavourite: false,
  },
  {
    createdBy: {
      id: "406761e9-c55a-4786-b25e-6c4377934b3d",
      name: "Anil Create Linting",
      bio: null,
      tradingName: null,
      isAdmin: false,
      profilePicture: null,
      profilePictureExtension: null,
      thumbnail: null,
      userType: "Member",
    },
    id: "7d9b9ddb-4537-41aa-8604-cce08f67ee77",
    listingType: "S",
    isActive: true,
    isPrivate: false,
    isDraft: false,
    createdAt: "2024-07-02T18:34:11.087193Z",
    difficultyLevel: ["B"],
    title: "Repeat 1,Month on Day 12, Frequency Recurring Class",
    media: null,
    description: "Repeat Single,Month on Day 12, Frequency Recurring Class",
    thumbnail: null,
    upcomingEventDate: "2024-08-14",
    interests: [
      {
        id: "ee4cffe2-7b40-4179-b85c-d34b3465aec3",
        title: "Dance",
        image: null,
        icon: "https://fitness-mates.s3.amazonaws.com/interest/ee4cffe27b404179b85cd34b3465aec3/tVOFt03x-1717664296607.png",
      },
    ],
    isAgeLimit: false,
    minAge: null,
    listingEventType: "P",
    maxAge: null,
    gender: ["F"],
    maxParticipants: 123,
    dynamicLink: "https://fitnessmates.page.link/9Hhg",
    price: "34.00",
    scheduleDetails: {
      address: {
        city: "",
        state: "",
        address: "Mildura Mildura VIC 3500, Australia",
        country: "",
        location: "POINT (142.1367157 -34.2067651)",
        postCode: "",
      },
      endDate: "2024-11-27",
      endTime: "01:30:00.771000",
      frequency: "M",
      weekDays: [],
      startDate: "2024-07-14",
      startTime: "00:30:00.638000",
      isRecurring: true,
      recurringType: "R",
      frequencyNumber: "1",
      monthlyFrequency: "day",
      singleListingType: "R",
      monthlyFrequencyDesc: "Monthly on day 14",
    },
    teamSlotsAvailable: null,
    distance: 9723.433547358249,
    slotsAvailable: null,
    publishedAt: "2024-07-02T18:34:11.085533Z",
    events: [
      {
        id: "13795e0e-f68b-4395-8dfd-e8c7117ba95d",
        title: "Repeat 1,Month on Day 12, Frequency Recurring Class",
        date: "2024-07-14",
        startTime: "00:30:00.638000",
        endTime: "01:30:00.771000",
        slotsAvailable: 123,
        address: {
          address: "Mildura Mildura VIC 3500, Australia",
          city: "",
          location: "SRID=4326;POINT (142.1367157 -34.2067651)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -34.2067651,
          longitude: 142.1367157,
        },
      },
      {
        id: "e52e744b-075a-4bab-b3a1-6531be98088e",
        title: "Repeat 1,Month on Day 12, Frequency Recurring Class",
        date: "2024-08-14",
        startTime: "00:30:00.638000",
        endTime: "01:30:00.771000",
        slotsAvailable: 123,
        address: {
          address: "Mildura Mildura VIC 3500, Australia",
          city: "",
          location: "SRID=4326;POINT (142.1367157 -34.2067651)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -34.2067651,
          longitude: 142.1367157,
        },
      },
      {
        id: "d41a8977-fa5c-49b5-ae30-45e0edb5a936",
        title: "Repeat 1,Month on Day 12, Frequency Recurring Class",
        date: "2024-09-14",
        startTime: "00:30:00.638000",
        endTime: "01:30:00.771000",
        slotsAvailable: 123,
        address: {
          address: "Mildura Mildura VIC 3500, Australia",
          city: "",
          location: "SRID=4326;POINT (142.1367157 -34.2067651)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -34.2067651,
          longitude: 142.1367157,
        },
      },
      {
        id: "8894a74b-a04f-4e18-a270-dda5a1f75ca0",
        title: "Repeat 1,Month on Day 12, Frequency Recurring Class",
        date: "2024-10-14",
        startTime: "00:30:00.638000",
        endTime: "01:30:00.771000",
        slotsAvailable: 123,
        address: {
          address: "Mildura Mildura VIC 3500, Australia",
          city: "",
          location: "SRID=4326;POINT (142.1367157 -34.2067651)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -34.2067651,
          longitude: 142.1367157,
        },
      },
      {
        id: "b9beaf71-0382-46a3-acbf-e9d4dc9979b6",
        title: "Repeat 1,Month on Day 12, Frequency Recurring Class",
        date: "2024-11-14",
        startTime: "00:30:00.638000",
        endTime: "01:30:00.771000",
        slotsAvailable: 123,
        address: {
          address: "Mildura Mildura VIC 3500, Australia",
          city: "",
          location: "SRID=4326;POINT (142.1367157 -34.2067651)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -34.2067651,
          longitude: 142.1367157,
        },
      },
    ],
    photos: [
      {
        uploadDocument:
          "https://fitness-mates.s3.amazonaws.com/photos/7d9b9ddb-4537-41aa-8604-cce08f67ee77/kins-392779-d2af8a10-800d-4f48-9afc-fd6063ab2903.jpg?AWSAccessKeyId=AKIA5UHJQKEGZGW3J53I&Signature=o%2Br%2F1RZZgqCTIzvZu%2BzSplS98wE%3D&Expires=1721642410",
        documentName: "pexels-jeremyperkins-392779.jpg",
      },
    ],
    isFavourite: false,
  },
  {
    createdBy: {
      id: "406761e9-c55a-4786-b25e-6c4377934b3d",
      name: "Anil Create Linting",
      bio: null,
      tradingName: null,
      isAdmin: false,
      profilePicture: null,
      profilePictureExtension: null,
      thumbnail: null,
      userType: "Member",
    },
    id: "a08dd282-f352-480d-a208-c6b89a09511e",
    listingType: "S",
    isActive: true,
    isPrivate: false,
    isDraft: false,
    createdAt: "2024-07-02T18:35:46.100570Z",
    difficultyLevel: ["B"],
    title: "Repeat 1,Month on Day 2nd Sunday, Frequency Recurring Class",
    media: null,
    description: "Repeat 1,Month on Day 2nd Sunday, Frequency Recurring Class",
    thumbnail: null,
    upcomingEventDate: "2024-08-11",
    interests: [
      {
        id: "dfb79f8f-45f8-40d5-8874-4b3e40670fa3",
        title: "Walk",
        image:
          "https://fitness-mates.s3.amazonaws.com/interest/dfb79f8f45f840d588744b3e40670fa3/kHnzxQjg-1706933606841.png",
        icon: "https://fitness-mates.s3.amazonaws.com/interest/dfb79f8f45f840d588744b3e40670fa3/No2utC9b-1717664326998.png",
      },
    ],
    isAgeLimit: false,
    minAge: null,
    listingEventType: "P",
    maxAge: null,
    gender: ["F"],
    maxParticipants: 234,
    dynamicLink: "https://fitnessmates.page.link/Hkmv",
    price: "34.00",
    scheduleDetails: {
      address: {
        city: "",
        state: "",
        address: "Toowoomba Toowoomba QLD, Australia",
        country: "",
        location: "POINT (151.9506696 -27.5598212)",
        postCode: "",
      },
      endDate: "2025-01-27",
      endTime: "02:00:00.938000",
      frequency: "M",
      weekDays: [],
      startDate: "2024-07-14",
      startTime: "01:00:00.889000",
      isRecurring: true,
      recurringType: "R",
      frequencyNumber: "1",
      monthlyFrequency: "week",
      singleListingType: "R",
      monthlyFrequencyDesc: "Monthly on the  2nd  Sunday ",
    },
    teamSlotsAvailable: null,
    distance: 10123.912655312466,
    slotsAvailable: null,
    publishedAt: "2024-07-02T18:35:46.099082Z",
    events: [
      {
        id: "92908b86-83fc-4005-b206-a1a781b72926",
        title: "Repeat 1,Month on Day 2nd Sunday, Frequency Recurring Class",
        date: "2024-07-14",
        startTime: "01:00:00.889000",
        endTime: "02:00:00.938000",
        slotsAvailable: 234,
        address: {
          address: "Toowoomba Toowoomba QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (151.9506696 -27.5598212)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.5598212,
          longitude: 151.9506696,
        },
      },
      {
        id: "97b82fb7-48f4-4ea6-9fe5-1d82440dfbcf",
        title: "Repeat 1,Month on Day 2nd Sunday, Frequency Recurring Class",
        date: "2024-08-11",
        startTime: "01:00:00.889000",
        endTime: "02:00:00.938000",
        slotsAvailable: 234,
        address: {
          address: "Toowoomba Toowoomba QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (151.9506696 -27.5598212)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.5598212,
          longitude: 151.9506696,
        },
      },
      {
        id: "3aaa6146-33de-48d8-9d41-7a9dd59c35b3",
        title: "Repeat 1,Month on Day 2nd Sunday, Frequency Recurring Class",
        date: "2024-09-08",
        startTime: "01:00:00.889000",
        endTime: "02:00:00.938000",
        slotsAvailable: 234,
        address: {
          address: "Toowoomba Toowoomba QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (151.9506696 -27.5598212)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.5598212,
          longitude: 151.9506696,
        },
      },
      {
        id: "04b53ea0-771d-4e59-9b6c-190a0741f1f8",
        title: "Repeat 1,Month on Day 2nd Sunday, Frequency Recurring Class",
        date: "2024-10-13",
        startTime: "01:00:00.889000",
        endTime: "02:00:00.938000",
        slotsAvailable: 234,
        address: {
          address: "Toowoomba Toowoomba QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (151.9506696 -27.5598212)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.5598212,
          longitude: 151.9506696,
        },
      },
      {
        id: "832f08f8-1904-4522-99ec-83b226e70c27",
        title: "Repeat 1,Month on Day 2nd Sunday, Frequency Recurring Class",
        date: "2024-11-10",
        startTime: "01:00:00.889000",
        endTime: "02:00:00.938000",
        slotsAvailable: 234,
        address: {
          address: "Toowoomba Toowoomba QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (151.9506696 -27.5598212)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.5598212,
          longitude: 151.9506696,
        },
      },
      {
        id: "6fde63a3-73b2-477d-9820-6d89590dc215",
        title: "Repeat 1,Month on Day 2nd Sunday, Frequency Recurring Class",
        date: "2024-12-08",
        startTime: "01:00:00.889000",
        endTime: "02:00:00.938000",
        slotsAvailable: 234,
        address: {
          address: "Toowoomba Toowoomba QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (151.9506696 -27.5598212)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.5598212,
          longitude: 151.9506696,
        },
      },
      {
        id: "8fd01ebd-a659-45c2-bca6-b28513411f7c",
        title: "Repeat 1,Month on Day 2nd Sunday, Frequency Recurring Class",
        date: "2025-01-12",
        startTime: "01:00:00.889000",
        endTime: "02:00:00.938000",
        slotsAvailable: 234,
        address: {
          address: "Toowoomba Toowoomba QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (151.9506696 -27.5598212)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.5598212,
          longitude: 151.9506696,
        },
      },
    ],
    photos: [
      {
        uploadDocument:
          "https://fitness-mates.s3.amazonaws.com/photos/a08dd282-f352-480d-a208-c6b89a09511e/eta-2034892-a9967f5d-72bb-4f00-8260-68f191d0c05b.jpg?AWSAccessKeyId=AKIA5UHJQKEGZGW3J53I&Signature=b2zxESTJGayrsZPsPBnGu19MAiI%3D&Expires=1721642410",
        documentName: "pexels-lucaspezeta-2034892.jpg",
      },
    ],
    isFavourite: false,
  },
  {
    createdBy: {
      id: "406761e9-c55a-4786-b25e-6c4377934b3d",
      name: "Anil Create Linting",
      bio: null,
      tradingName: null,
      isAdmin: false,
      profilePicture: null,
      profilePictureExtension: null,
      thumbnail: null,
      userType: "Member",
    },
    id: "daa84ba6-f389-4c49-a1a1-c078203cd2da",
    listingType: "S",
    isActive: true,
    isPrivate: false,
    isDraft: false,
    createdAt: "2024-07-02T18:30:15.312498Z",
    difficultyLevel: ["B"],
    title: "Repeat Multi(3),Month on Day 10, Frequency Recurring Class",
    media: null,
    description:
      "Repeat Multi(3),Month on Day 10, Frequency Recurring Class Repeat Multi(3),Month Frequency Recurring Class",
    thumbnail: null,
    upcomingEventDate: "2024-12-10",
    interests: [
      {
        id: "458fcb39-12cb-4bea-adec-c55d5407268c",
        title: "Netball",
        image:
          "https://fitness-mates.s3.amazonaws.com/interest/458fcb3912cb4beaadecc55d5407268c/H6YXe7Ep-1706705349779.jpeg",
        icon: "https://fitness-mates.s3.amazonaws.com/interest/458fcb3912cb4beaadecc55d5407268c/RCSPNXpW-1717664482134.png",
      },
    ],
    isAgeLimit: false,
    minAge: null,
    listingEventType: "P",
    maxAge: null,
    gender: ["F"],
    maxParticipants: 200,
    dynamicLink: "https://fitnessmates.page.link/G2AB",
    price: "122.00",
    scheduleDetails: {
      address: {
        city: "",
        state: "",
        address:
          "Victoria Market Pharmacy 523 Elizabeth St, Melbourne VIC 3000, Australia",
        country: "",
        location: "POINT (144.9593671 -37.8066573)",
        postCode: "",
      },
      endDate: "2025-07-01",
      endTime: "22:00:00.502000",
      frequency: "M",
      weekDays: [],
      startDate: "2024-07-10",
      startTime: "21:00:00.065000",
      isRecurring: true,
      recurringType: "R",
      frequencyNumber: "5",
      monthlyFrequency: "day",
      singleListingType: "R",
      monthlyFrequencyDesc: "Monthly on day 10",
    },
    teamSlotsAvailable: null,
    distance: 10158.18173483423,
    slotsAvailable: null,
    publishedAt: "2024-07-02T18:30:15.311088Z",
    events: [
      {
        id: "d2cd0311-1b93-4ccd-bada-343435a79c72",
        title: "Repeat Multi(3),Month on Day 10, Frequency Recurring Class",
        date: "2024-07-10",
        startTime: "21:00:00.065000",
        endTime: "22:00:00.502000",
        slotsAvailable: 200,
        address: {
          address:
            "Victoria Market Pharmacy 523 Elizabeth St, Melbourne VIC 3000, Australia",
          city: "",
          location: "SRID=4326;POINT (144.9593671 -37.8066573)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -37.8066573,
          longitude: 144.9593671,
        },
      },
      {
        id: "018f71a0-7dd5-4cbb-bc10-88cdad1e33f9",
        title: "Repeat Multi(3),Month on Day 10, Frequency Recurring Class",
        date: "2024-12-10",
        startTime: "21:00:00.065000",
        endTime: "22:00:00.502000",
        slotsAvailable: 200,
        address: {
          address:
            "Victoria Market Pharmacy 523 Elizabeth St, Melbourne VIC 3000, Australia",
          city: "",
          location: "SRID=4326;POINT (144.9593671 -37.8066573)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -37.8066573,
          longitude: 144.9593671,
        },
      },
      {
        id: "57537103-ca2b-43c2-ae2a-02a67e473987",
        title: "Repeat Multi(3),Month on Day 10, Frequency Recurring Class",
        date: "2025-05-10",
        startTime: "21:00:00.065000",
        endTime: "22:00:00.502000",
        slotsAvailable: 200,
        address: {
          address:
            "Victoria Market Pharmacy 523 Elizabeth St, Melbourne VIC 3000, Australia",
          city: "",
          location: "SRID=4326;POINT (144.9593671 -37.8066573)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -37.8066573,
          longitude: 144.9593671,
        },
      },
    ],
    photos: [
      {
        uploadDocument:
          "https://fitness-mates.s3.amazonaws.com/photos/daa84ba6-f389-4c49-a1a1-c078203cd2da/xabay-41951-967e0da3-b003-4941-a1c2-901e8da2ceea.jpg?AWSAccessKeyId=AKIA5UHJQKEGZGW3J53I&Signature=kbMCmWAeR6aBkacfOJv17ACWuCc%3D&Expires=1721642410",
        documentName: "pexels-pixabay-41951.jpg",
      },
    ],
    isFavourite: false,
  },
  {
    createdBy: {
      id: "406761e9-c55a-4786-b25e-6c4377934b3d",
      name: "Anil Create Linting",
      bio: null,
      tradingName: null,
      isAdmin: false,
      profilePicture: null,
      profilePictureExtension: null,
      thumbnail: null,
      userType: "Member",
    },
    id: "740a6524-cdff-42e3-af51-5daf2b355e5b",
    listingType: "S",
    isActive: true,
    isPrivate: false,
    isDraft: false,
    createdAt: "2024-07-02T18:22:09.373162Z",
    difficultyLevel: ["B"],
    title: "Repeat Multi(3),Days Frequency Recurring Class",
    media: null,
    description:
      "Repeat Multi(3), Days Frequency Recurring Class Repeat 2,Days Frequency Recurring Class Repeat 2,Days Frequency Recurring Class Repeat 2,Days Frequency Recurring Class",
    thumbnail: null,
    upcomingEventDate: "2024-07-22",
    interests: [
      {
        id: "e2de1f21-c707-4dd3-8635-dba0fd5aff10",
        title: "Online",
        image: null,
        icon: "https://fitness-mates.s3.amazonaws.com/interest/e2de1f21c7074dd38635dba0fd5aff10/axTiunnS-1717664229211.png",
      },
    ],
    isAgeLimit: false,
    minAge: null,
    listingEventType: "P",
    maxAge: null,
    gender: ["F"],
    maxParticipants: 20,
    dynamicLink: "https://fitnessmates.page.link/sreR",
    price: "20.00",
    scheduleDetails: {
      address: {
        city: "",
        state: "",
        address: "Brisbane Brisbane QLD, Australia",
        country: "",
        location: "POINT (153.0260341 -27.4704528)",
        postCode: "",
      },
      endDate: "2024-12-30",
      endTime: "23:30:00.917000",
      frequency: "D",
      weekDays: [],
      startDate: "2024-07-10",
      startTime: "22:30:00.032000",
      isRecurring: true,
      recurringType: "R",
      frequencyNumber: "3",
      monthlyFrequency: "week",
      singleListingType: "R",
      monthlyFrequencyDesc: "",
    },
    teamSlotsAvailable: null,
    distance: 10211.46271640192,
    slotsAvailable: null,
    publishedAt: "2024-07-02T18:22:09.371781Z",
    events: [
      {
        id: "0576d5ba-962a-43df-a4bc-4ac36e5b6e92",
        title: "Repeat Multi(3),Days Frequency Recurring Class",
        date: "2024-07-10",
        startTime: "22:30:00.032000",
        endTime: "23:30:00.917000",
        slotsAvailable: 20,
        address: {
          address: "Brisbane Brisbane QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (153.0260341 -27.4704528)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.4704528,
          longitude: 153.0260341,
        },
      },
      {
        id: "cd4315ae-92ba-4cb9-8431-c422e96034db",
        title: "Repeat Multi(3),Days Frequency Recurring Class",
        date: "2024-07-13",
        startTime: "22:30:00.032000",
        endTime: "23:30:00.917000",
        slotsAvailable: 20,
        address: {
          address: "Brisbane Brisbane QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (153.0260341 -27.4704528)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.4704528,
          longitude: 153.0260341,
        },
      },
      {
        id: "964998ea-c1c0-4a82-8dab-6eb7f687f000",
        title: "Repeat Multi(3),Days Frequency Recurring Class",
        date: "2024-07-16",
        startTime: "22:30:00.032000",
        endTime: "23:30:00.917000",
        slotsAvailable: 20,
        address: {
          address: "Brisbane Brisbane QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (153.0260341 -27.4704528)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.4704528,
          longitude: 153.0260341,
        },
      },
      {
        id: "110440da-2e43-4f3c-9fbb-84c1a804b466",
        title: "Repeat Multi(3),Days Frequency Recurring Class",
        date: "2024-07-19",
        startTime: "22:30:00.032000",
        endTime: "23:30:00.917000",
        slotsAvailable: 20,
        address: {
          address: "Brisbane Brisbane QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (153.0260341 -27.4704528)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.4704528,
          longitude: 153.0260341,
        },
      },
      {
        id: "7f98b658-b3a8-4bdb-a5dd-5e4110a844e4",
        title: "Repeat Multi(3),Days Frequency Recurring Class",
        date: "2024-07-22",
        startTime: "22:30:00.032000",
        endTime: "23:30:00.917000",
        slotsAvailable: 20,
        address: {
          address: "Brisbane Brisbane QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (153.0260341 -27.4704528)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.4704528,
          longitude: 153.0260341,
        },
      },
      {
        id: "5b8e3782-ea19-4def-be7b-b6516ab22cc4",
        title: "Repeat Multi(3),Days Frequency Recurring Class",
        date: "2024-07-25",
        startTime: "22:30:00.032000",
        endTime: "23:30:00.917000",
        slotsAvailable: 20,
        address: {
          address: "Brisbane Brisbane QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (153.0260341 -27.4704528)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.4704528,
          longitude: 153.0260341,
        },
      },
      {
        id: "3a3e965b-a37d-4762-a1d4-494a3a54cff9",
        title: "Repeat Multi(3),Days Frequency Recurring Class",
        date: "2024-07-28",
        startTime: "22:30:00.032000",
        endTime: "23:30:00.917000",
        slotsAvailable: 20,
        address: {
          address: "Brisbane Brisbane QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (153.0260341 -27.4704528)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.4704528,
          longitude: 153.0260341,
        },
      },
      {
        id: "e5791b80-3ff8-4a36-be47-267fb512db89",
        title: "Repeat Multi(3),Days Frequency Recurring Class",
        date: "2024-07-31",
        startTime: "22:30:00.032000",
        endTime: "23:30:00.917000",
        slotsAvailable: 20,
        address: {
          address: "Brisbane Brisbane QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (153.0260341 -27.4704528)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.4704528,
          longitude: 153.0260341,
        },
      },
      {
        id: "8dba2295-d6d6-4d45-a6c4-395692b1bc85",
        title: "Repeat Multi(3),Days Frequency Recurring Class",
        date: "2024-08-03",
        startTime: "22:30:00.032000",
        endTime: "23:30:00.917000",
        slotsAvailable: 20,
        address: {
          address: "Brisbane Brisbane QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (153.0260341 -27.4704528)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.4704528,
          longitude: 153.0260341,
        },
      },
      {
        id: "d3e9f6ef-6c24-40c5-a219-bf84406a1568",
        title: "Repeat Multi(3),Days Frequency Recurring Class",
        date: "2024-08-06",
        startTime: "22:30:00.032000",
        endTime: "23:30:00.917000",
        slotsAvailable: 20,
        address: {
          address: "Brisbane Brisbane QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (153.0260341 -27.4704528)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.4704528,
          longitude: 153.0260341,
        },
      },
      {
        id: "c0aa587c-2408-4cc4-9ac5-94c2436c216c",
        title: "Repeat Multi(3),Days Frequency Recurring Class",
        date: "2024-08-09",
        startTime: "22:30:00.032000",
        endTime: "23:30:00.917000",
        slotsAvailable: 20,
        address: {
          address: "Brisbane Brisbane QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (153.0260341 -27.4704528)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.4704528,
          longitude: 153.0260341,
        },
      },
      {
        id: "193e02c3-8222-442c-93df-2c803d13c2e1",
        title: "Repeat Multi(3),Days Frequency Recurring Class",
        date: "2024-08-12",
        startTime: "22:30:00.032000",
        endTime: "23:30:00.917000",
        slotsAvailable: 20,
        address: {
          address: "Brisbane Brisbane QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (153.0260341 -27.4704528)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.4704528,
          longitude: 153.0260341,
        },
      },
      {
        id: "c80084d3-0e90-44e8-811e-358e9fe4290c",
        title: "Repeat Multi(3),Days Frequency Recurring Class",
        date: "2024-08-15",
        startTime: "22:30:00.032000",
        endTime: "23:30:00.917000",
        slotsAvailable: 20,
        address: {
          address: "Brisbane Brisbane QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (153.0260341 -27.4704528)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.4704528,
          longitude: 153.0260341,
        },
      },
      {
        id: "a0f8906c-76d9-4c26-ac8c-75e8e1371bf9",
        title: "Repeat Multi(3),Days Frequency Recurring Class",
        date: "2024-08-18",
        startTime: "22:30:00.032000",
        endTime: "23:30:00.917000",
        slotsAvailable: 20,
        address: {
          address: "Brisbane Brisbane QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (153.0260341 -27.4704528)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.4704528,
          longitude: 153.0260341,
        },
      },
      {
        id: "ff443c47-11fd-44c5-8f0f-e72d1381f802",
        title: "Repeat Multi(3),Days Frequency Recurring Class",
        date: "2024-08-21",
        startTime: "22:30:00.032000",
        endTime: "23:30:00.917000",
        slotsAvailable: 20,
        address: {
          address: "Brisbane Brisbane QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (153.0260341 -27.4704528)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.4704528,
          longitude: 153.0260341,
        },
      },
      {
        id: "1bfdbb42-7125-420d-931d-e45b41078b6b",
        title: "Repeat Multi(3),Days Frequency Recurring Class",
        date: "2024-08-24",
        startTime: "22:30:00.032000",
        endTime: "23:30:00.917000",
        slotsAvailable: 20,
        address: {
          address: "Brisbane Brisbane QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (153.0260341 -27.4704528)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.4704528,
          longitude: 153.0260341,
        },
      },
      {
        id: "0e79082d-d827-400b-8c83-77bc520149ad",
        title: "Repeat Multi(3),Days Frequency Recurring Class",
        date: "2024-08-27",
        startTime: "22:30:00.032000",
        endTime: "23:30:00.917000",
        slotsAvailable: 20,
        address: {
          address: "Brisbane Brisbane QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (153.0260341 -27.4704528)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.4704528,
          longitude: 153.0260341,
        },
      },
      {
        id: "894d9de3-fa32-4c92-b721-e29bb82de39b",
        title: "Repeat Multi(3),Days Frequency Recurring Class",
        date: "2024-08-30",
        startTime: "22:30:00.032000",
        endTime: "23:30:00.917000",
        slotsAvailable: 20,
        address: {
          address: "Brisbane Brisbane QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (153.0260341 -27.4704528)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.4704528,
          longitude: 153.0260341,
        },
      },
      {
        id: "18b3a1a4-efbe-40f0-ab9b-e3f8490b0a64",
        title: "Repeat Multi(3),Days Frequency Recurring Class",
        date: "2024-09-02",
        startTime: "22:30:00.032000",
        endTime: "23:30:00.917000",
        slotsAvailable: 20,
        address: {
          address: "Brisbane Brisbane QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (153.0260341 -27.4704528)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.4704528,
          longitude: 153.0260341,
        },
      },
      {
        id: "e2ea4666-faf9-4e51-9072-6011829b8cc5",
        title: "Repeat Multi(3),Days Frequency Recurring Class",
        date: "2024-09-05",
        startTime: "22:30:00.032000",
        endTime: "23:30:00.917000",
        slotsAvailable: 20,
        address: {
          address: "Brisbane Brisbane QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (153.0260341 -27.4704528)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.4704528,
          longitude: 153.0260341,
        },
      },
      {
        id: "4618585a-e9bc-4609-8f7c-06b8d30b57d8",
        title: "Repeat Multi(3),Days Frequency Recurring Class",
        date: "2024-09-08",
        startTime: "22:30:00.032000",
        endTime: "23:30:00.917000",
        slotsAvailable: 20,
        address: {
          address: "Brisbane Brisbane QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (153.0260341 -27.4704528)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.4704528,
          longitude: 153.0260341,
        },
      },
      {
        id: "ea49fb00-d16b-4640-b3cb-aabc020dee7a",
        title: "Repeat Multi(3),Days Frequency Recurring Class",
        date: "2024-09-11",
        startTime: "22:30:00.032000",
        endTime: "23:30:00.917000",
        slotsAvailable: 20,
        address: {
          address: "Brisbane Brisbane QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (153.0260341 -27.4704528)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.4704528,
          longitude: 153.0260341,
        },
      },
      {
        id: "4dbab427-71a3-4ae3-bed2-2a423fef42e4",
        title: "Repeat Multi(3),Days Frequency Recurring Class",
        date: "2024-09-14",
        startTime: "22:30:00.032000",
        endTime: "23:30:00.917000",
        slotsAvailable: 20,
        address: {
          address: "Brisbane Brisbane QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (153.0260341 -27.4704528)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.4704528,
          longitude: 153.0260341,
        },
      },
      {
        id: "2d157b4e-53a0-45b6-b91a-5cb3ad4ee991",
        title: "Repeat Multi(3),Days Frequency Recurring Class",
        date: "2024-09-17",
        startTime: "22:30:00.032000",
        endTime: "23:30:00.917000",
        slotsAvailable: 20,
        address: {
          address: "Brisbane Brisbane QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (153.0260341 -27.4704528)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.4704528,
          longitude: 153.0260341,
        },
      },
      {
        id: "91941ec1-3884-4ce8-a7fa-ff99d6d51187",
        title: "Repeat Multi(3),Days Frequency Recurring Class",
        date: "2024-09-20",
        startTime: "22:30:00.032000",
        endTime: "23:30:00.917000",
        slotsAvailable: 20,
        address: {
          address: "Brisbane Brisbane QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (153.0260341 -27.4704528)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.4704528,
          longitude: 153.0260341,
        },
      },
      {
        id: "8683b3aa-c597-44ec-b390-bfbefa74da73",
        title: "Repeat Multi(3),Days Frequency Recurring Class",
        date: "2024-09-23",
        startTime: "22:30:00.032000",
        endTime: "23:30:00.917000",
        slotsAvailable: 20,
        address: {
          address: "Brisbane Brisbane QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (153.0260341 -27.4704528)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.4704528,
          longitude: 153.0260341,
        },
      },
      {
        id: "4077837b-9f07-4998-91a0-4d103bafaeb9",
        title: "Repeat Multi(3),Days Frequency Recurring Class",
        date: "2024-09-26",
        startTime: "22:30:00.032000",
        endTime: "23:30:00.917000",
        slotsAvailable: 20,
        address: {
          address: "Brisbane Brisbane QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (153.0260341 -27.4704528)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.4704528,
          longitude: 153.0260341,
        },
      },
      {
        id: "4811a4c9-80df-4f95-b057-ce3a23c0d877",
        title: "Repeat Multi(3),Days Frequency Recurring Class",
        date: "2024-09-29",
        startTime: "22:30:00.032000",
        endTime: "23:30:00.917000",
        slotsAvailable: 20,
        address: {
          address: "Brisbane Brisbane QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (153.0260341 -27.4704528)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.4704528,
          longitude: 153.0260341,
        },
      },
      {
        id: "83829234-4d51-4053-a30b-d778822a5e49",
        title: "Repeat Multi(3),Days Frequency Recurring Class",
        date: "2024-10-02",
        startTime: "22:30:00.032000",
        endTime: "23:30:00.917000",
        slotsAvailable: 20,
        address: {
          address: "Brisbane Brisbane QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (153.0260341 -27.4704528)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.4704528,
          longitude: 153.0260341,
        },
      },
      {
        id: "a7a34728-c5c2-4bd2-b441-10ddb9c07fe4",
        title: "Repeat Multi(3),Days Frequency Recurring Class",
        date: "2024-10-05",
        startTime: "22:30:00.032000",
        endTime: "23:30:00.917000",
        slotsAvailable: 20,
        address: {
          address: "Brisbane Brisbane QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (153.0260341 -27.4704528)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.4704528,
          longitude: 153.0260341,
        },
      },
      {
        id: "76cf5d6e-4299-4d3f-86b8-7f7a681afe48",
        title: "Repeat Multi(3),Days Frequency Recurring Class",
        date: "2024-10-08",
        startTime: "22:30:00.032000",
        endTime: "23:30:00.917000",
        slotsAvailable: 20,
        address: {
          address: "Brisbane Brisbane QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (153.0260341 -27.4704528)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.4704528,
          longitude: 153.0260341,
        },
      },
      {
        id: "3fee21d2-e865-4ff3-953e-7035fed66fa0",
        title: "Repeat Multi(3),Days Frequency Recurring Class",
        date: "2024-10-11",
        startTime: "22:30:00.032000",
        endTime: "23:30:00.917000",
        slotsAvailable: 20,
        address: {
          address: "Brisbane Brisbane QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (153.0260341 -27.4704528)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.4704528,
          longitude: 153.0260341,
        },
      },
      {
        id: "00672dce-9eae-44dc-81cc-9ab9fdf45be0",
        title: "Repeat Multi(3),Days Frequency Recurring Class",
        date: "2024-10-14",
        startTime: "22:30:00.032000",
        endTime: "23:30:00.917000",
        slotsAvailable: 20,
        address: {
          address: "Brisbane Brisbane QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (153.0260341 -27.4704528)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.4704528,
          longitude: 153.0260341,
        },
      },
      {
        id: "8871ef25-4f78-46ec-833e-015101144254",
        title: "Repeat Multi(3),Days Frequency Recurring Class",
        date: "2024-10-17",
        startTime: "22:30:00.032000",
        endTime: "23:30:00.917000",
        slotsAvailable: 20,
        address: {
          address: "Brisbane Brisbane QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (153.0260341 -27.4704528)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.4704528,
          longitude: 153.0260341,
        },
      },
      {
        id: "43169974-8a2c-46ea-b673-572158582c33",
        title: "Repeat Multi(3),Days Frequency Recurring Class",
        date: "2024-10-20",
        startTime: "22:30:00.032000",
        endTime: "23:30:00.917000",
        slotsAvailable: 20,
        address: {
          address: "Brisbane Brisbane QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (153.0260341 -27.4704528)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.4704528,
          longitude: 153.0260341,
        },
      },
      {
        id: "01495b99-36ee-46fc-9da4-de0bf82d3f42",
        title: "Repeat Multi(3),Days Frequency Recurring Class",
        date: "2024-10-23",
        startTime: "22:30:00.032000",
        endTime: "23:30:00.917000",
        slotsAvailable: 20,
        address: {
          address: "Brisbane Brisbane QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (153.0260341 -27.4704528)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.4704528,
          longitude: 153.0260341,
        },
      },
      {
        id: "5dd7d6d9-2d03-42a1-bee3-e529dd8b444d",
        title: "Repeat Multi(3),Days Frequency Recurring Class",
        date: "2024-10-26",
        startTime: "22:30:00.032000",
        endTime: "23:30:00.917000",
        slotsAvailable: 20,
        address: {
          address: "Brisbane Brisbane QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (153.0260341 -27.4704528)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.4704528,
          longitude: 153.0260341,
        },
      },
      {
        id: "bde939c3-9696-457b-a79a-a509f066e21d",
        title: "Repeat Multi(3),Days Frequency Recurring Class",
        date: "2024-10-29",
        startTime: "22:30:00.032000",
        endTime: "23:30:00.917000",
        slotsAvailable: 20,
        address: {
          address: "Brisbane Brisbane QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (153.0260341 -27.4704528)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.4704528,
          longitude: 153.0260341,
        },
      },
      {
        id: "678ca0b8-b01c-4664-a756-4e41af8f3875",
        title: "Repeat Multi(3),Days Frequency Recurring Class",
        date: "2024-11-01",
        startTime: "22:30:00.032000",
        endTime: "23:30:00.917000",
        slotsAvailable: 20,
        address: {
          address: "Brisbane Brisbane QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (153.0260341 -27.4704528)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.4704528,
          longitude: 153.0260341,
        },
      },
      {
        id: "73550a23-63fd-471b-abfd-f168ca4f1e3b",
        title: "Repeat Multi(3),Days Frequency Recurring Class",
        date: "2024-11-04",
        startTime: "22:30:00.032000",
        endTime: "23:30:00.917000",
        slotsAvailable: 20,
        address: {
          address: "Brisbane Brisbane QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (153.0260341 -27.4704528)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.4704528,
          longitude: 153.0260341,
        },
      },
      {
        id: "12708361-c8f9-4428-beaa-fc4de6505ccd",
        title: "Repeat Multi(3),Days Frequency Recurring Class",
        date: "2024-11-07",
        startTime: "22:30:00.032000",
        endTime: "23:30:00.917000",
        slotsAvailable: 20,
        address: {
          address: "Brisbane Brisbane QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (153.0260341 -27.4704528)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.4704528,
          longitude: 153.0260341,
        },
      },
      {
        id: "505b2990-4d78-4926-884f-59a31c0b1bc0",
        title: "Repeat Multi(3),Days Frequency Recurring Class",
        date: "2024-11-10",
        startTime: "22:30:00.032000",
        endTime: "23:30:00.917000",
        slotsAvailable: 20,
        address: {
          address: "Brisbane Brisbane QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (153.0260341 -27.4704528)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.4704528,
          longitude: 153.0260341,
        },
      },
      {
        id: "78028749-dfac-4071-917b-3382609042fe",
        title: "Repeat Multi(3),Days Frequency Recurring Class",
        date: "2024-11-13",
        startTime: "22:30:00.032000",
        endTime: "23:30:00.917000",
        slotsAvailable: 20,
        address: {
          address: "Brisbane Brisbane QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (153.0260341 -27.4704528)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.4704528,
          longitude: 153.0260341,
        },
      },
      {
        id: "fa8b3261-f7fd-4efb-aee2-dfb0828c9cde",
        title: "Repeat Multi(3),Days Frequency Recurring Class",
        date: "2024-11-16",
        startTime: "22:30:00.032000",
        endTime: "23:30:00.917000",
        slotsAvailable: 20,
        address: {
          address: "Brisbane Brisbane QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (153.0260341 -27.4704528)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.4704528,
          longitude: 153.0260341,
        },
      },
      {
        id: "349d739b-3977-461e-a433-4cc69403e497",
        title: "Repeat Multi(3),Days Frequency Recurring Class",
        date: "2024-11-19",
        startTime: "22:30:00.032000",
        endTime: "23:30:00.917000",
        slotsAvailable: 20,
        address: {
          address: "Brisbane Brisbane QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (153.0260341 -27.4704528)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.4704528,
          longitude: 153.0260341,
        },
      },
      {
        id: "307350d5-811c-4e1b-992c-f8f9c6a277f2",
        title: "Repeat Multi(3),Days Frequency Recurring Class",
        date: "2024-11-22",
        startTime: "22:30:00.032000",
        endTime: "23:30:00.917000",
        slotsAvailable: 20,
        address: {
          address: "Brisbane Brisbane QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (153.0260341 -27.4704528)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.4704528,
          longitude: 153.0260341,
        },
      },
      {
        id: "ffcb3fcd-9cfc-4525-a680-231c2a30f434",
        title: "Repeat Multi(3),Days Frequency Recurring Class",
        date: "2024-11-25",
        startTime: "22:30:00.032000",
        endTime: "23:30:00.917000",
        slotsAvailable: 20,
        address: {
          address: "Brisbane Brisbane QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (153.0260341 -27.4704528)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.4704528,
          longitude: 153.0260341,
        },
      },
      {
        id: "f3a3f51f-49c0-4a03-9203-cb7acc38e4e9",
        title: "Repeat Multi(3),Days Frequency Recurring Class",
        date: "2024-11-28",
        startTime: "22:30:00.032000",
        endTime: "23:30:00.917000",
        slotsAvailable: 20,
        address: {
          address: "Brisbane Brisbane QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (153.0260341 -27.4704528)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.4704528,
          longitude: 153.0260341,
        },
      },
      {
        id: "1e84c315-bf07-4d0e-9d92-bee2144670bb",
        title: "Repeat Multi(3),Days Frequency Recurring Class",
        date: "2024-12-01",
        startTime: "22:30:00.032000",
        endTime: "23:30:00.917000",
        slotsAvailable: 20,
        address: {
          address: "Brisbane Brisbane QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (153.0260341 -27.4704528)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.4704528,
          longitude: 153.0260341,
        },
      },
      {
        id: "22778ae7-f0a4-41c2-a913-4e6abf709a65",
        title: "Repeat Multi(3),Days Frequency Recurring Class",
        date: "2024-12-04",
        startTime: "22:30:00.032000",
        endTime: "23:30:00.917000",
        slotsAvailable: 20,
        address: {
          address: "Brisbane Brisbane QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (153.0260341 -27.4704528)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.4704528,
          longitude: 153.0260341,
        },
      },
      {
        id: "7b44d34d-b7fc-433b-9c65-9eb9903aa263",
        title: "Repeat Multi(3),Days Frequency Recurring Class",
        date: "2024-12-07",
        startTime: "22:30:00.032000",
        endTime: "23:30:00.917000",
        slotsAvailable: 20,
        address: {
          address: "Brisbane Brisbane QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (153.0260341 -27.4704528)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.4704528,
          longitude: 153.0260341,
        },
      },
      {
        id: "83521a1d-41b9-4d78-8558-d16fb3e5f6ea",
        title: "Repeat Multi(3),Days Frequency Recurring Class",
        date: "2024-12-10",
        startTime: "22:30:00.032000",
        endTime: "23:30:00.917000",
        slotsAvailable: 20,
        address: {
          address: "Brisbane Brisbane QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (153.0260341 -27.4704528)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.4704528,
          longitude: 153.0260341,
        },
      },
      {
        id: "7a18f083-a322-411e-8978-d46aff162c36",
        title: "Repeat Multi(3),Days Frequency Recurring Class",
        date: "2024-12-13",
        startTime: "22:30:00.032000",
        endTime: "23:30:00.917000",
        slotsAvailable: 20,
        address: {
          address: "Brisbane Brisbane QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (153.0260341 -27.4704528)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.4704528,
          longitude: 153.0260341,
        },
      },
      {
        id: "fbf85c74-2faf-4813-ab4d-ea7ee4d84068",
        title: "Repeat Multi(3),Days Frequency Recurring Class",
        date: "2024-12-16",
        startTime: "22:30:00.032000",
        endTime: "23:30:00.917000",
        slotsAvailable: 20,
        address: {
          address: "Brisbane Brisbane QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (153.0260341 -27.4704528)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.4704528,
          longitude: 153.0260341,
        },
      },
      {
        id: "fac8eafc-4b21-49d8-9bd3-4559f8f15721",
        title: "Repeat Multi(3),Days Frequency Recurring Class",
        date: "2024-12-19",
        startTime: "22:30:00.032000",
        endTime: "23:30:00.917000",
        slotsAvailable: 20,
        address: {
          address: "Brisbane Brisbane QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (153.0260341 -27.4704528)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.4704528,
          longitude: 153.0260341,
        },
      },
      {
        id: "dc84e8d7-36ab-4633-aa7c-71bbccbedd1f",
        title: "Repeat Multi(3),Days Frequency Recurring Class",
        date: "2024-12-22",
        startTime: "22:30:00.032000",
        endTime: "23:30:00.917000",
        slotsAvailable: 20,
        address: {
          address: "Brisbane Brisbane QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (153.0260341 -27.4704528)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.4704528,
          longitude: 153.0260341,
        },
      },
      {
        id: "4ce6efc1-b93e-4343-a64f-bb50b58192e0",
        title: "Repeat Multi(3),Days Frequency Recurring Class",
        date: "2024-12-25",
        startTime: "22:30:00.032000",
        endTime: "23:30:00.917000",
        slotsAvailable: 20,
        address: {
          address: "Brisbane Brisbane QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (153.0260341 -27.4704528)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.4704528,
          longitude: 153.0260341,
        },
      },
      {
        id: "7b10eeb7-5b03-4719-b541-419370b0480f",
        title: "Repeat Multi(3),Days Frequency Recurring Class",
        date: "2024-12-28",
        startTime: "22:30:00.032000",
        endTime: "23:30:00.917000",
        slotsAvailable: 20,
        address: {
          address: "Brisbane Brisbane QLD, Australia",
          city: "",
          location: "SRID=4326;POINT (153.0260341 -27.4704528)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -27.4704528,
          longitude: 153.0260341,
        },
      },
    ],
    photos: [
      {
        uploadDocument:
          "https://fitness-mates.s3.amazonaws.com/photos/740a6524-cdff-42e3-af51-5daf2b355e5b/ixabay-2150-4612ef6a-d985-49bf-bf37-8974a500c97a.jpg?AWSAccessKeyId=AKIA5UHJQKEGZGW3J53I&Signature=TaS8zNFReQEvOMvP%2BO2uxrWcz%2Bc%3D&Expires=1721642410",
        documentName: "pexels-pixabay-2150.jpg",
      },
    ],
    isFavourite: false,
  },
  {
    createdBy: {
      id: "406761e9-c55a-4786-b25e-6c4377934b3d",
      name: "Anil Create Linting",
      bio: null,
      tradingName: null,
      isAdmin: false,
      profilePicture: null,
      profilePictureExtension: null,
      thumbnail: null,
      userType: "Member",
    },
    id: "90f47e18-0367-4401-a3cf-b3cbd812cebf",
    listingType: "S",
    isActive: true,
    isPrivate: false,
    isDraft: false,
    createdAt: "2024-07-02T18:32:19.742188Z",
    difficultyLevel: ["B"],
    title: "Repeat Multi(3),Month on Day Tuesday, Frequency Recurring Class",
    media: null,
    description:
      "Repeat Multi(3), Month on Day Tuesday, Frequency Recurring Class",
    thumbnail: null,
    upcomingEventDate: "2024-10-15",
    interests: [
      {
        id: "dfb79f8f-45f8-40d5-8874-4b3e40670fa3",
        title: "Walk",
        image:
          "https://fitness-mates.s3.amazonaws.com/interest/dfb79f8f45f840d588744b3e40670fa3/kHnzxQjg-1706933606841.png",
        icon: "https://fitness-mates.s3.amazonaws.com/interest/dfb79f8f45f840d588744b3e40670fa3/No2utC9b-1717664326998.png",
      },
    ],
    isAgeLimit: false,
    minAge: null,
    listingEventType: "P",
    maxAge: null,
    gender: ["F"],
    maxParticipants: 345,
    dynamicLink: "https://fitnessmates.page.link/PkUS",
    price: "2.00",
    scheduleDetails: {
      address: {
        city: "",
        state: "",
        address: "Byron Bay Byron Bay NSW 2481, Australia",
        country: "",
        location: "POINT (153.6105168 -28.6419122)",
        postCode: "",
      },
      endDate: "2025-02-25",
      endTime: "01:30:00.500000",
      frequency: "M",
      weekDays: [],
      startDate: "2024-07-16",
      startTime: "01:00:00.254000",
      isRecurring: true,
      recurringType: "R",
      frequencyNumber: "3",
      monthlyFrequency: "week",
      singleListingType: "R",
      monthlyFrequencyDesc: "Monthly on the  3rd  Tuesday ",
    },
    teamSlotsAvailable: null,
    distance: 10324.879075877461,
    slotsAvailable: null,
    publishedAt: "2024-07-02T18:32:19.740228Z",
    events: [
      {
        id: "e575ad28-d7bc-42cf-9bd1-44ba75e82105",
        title:
          "Repeat Multi(3),Month on Day Tuesday, Frequency Recurring Class",
        date: "2024-07-16",
        startTime: "01:00:00.254000",
        endTime: "01:30:00.500000",
        slotsAvailable: 345,
        address: {
          address: "Byron Bay Byron Bay NSW 2481, Australia",
          city: "",
          location: "SRID=4326;POINT (153.6105168 -28.6419122)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -28.6419122,
          longitude: 153.6105168,
        },
      },
      {
        id: "07a53cd2-4bbf-405f-b692-47be0129470b",
        title:
          "Repeat Multi(3),Month on Day Tuesday, Frequency Recurring Class",
        date: "2024-10-15",
        startTime: "01:00:00.254000",
        endTime: "01:30:00.500000",
        slotsAvailable: 240,
        address: {
          address: "Byron Bay Byron Bay NSW 2481, Australia",
          city: "",
          location: "SRID=4326;POINT (153.6105168 -28.6419122)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -28.6419122,
          longitude: 153.6105168,
        },
      },
      {
        id: "dd2a37d6-331b-4b12-af19-c18034172b6b",
        title:
          "Repeat Multi(3),Month on Day Tuesday, Frequency Recurring Class",
        date: "2025-01-21",
        startTime: "01:00:00.254000",
        endTime: "01:30:00.500000",
        slotsAvailable: 345,
        address: {
          address: "Byron Bay Byron Bay NSW 2481, Australia",
          city: "",
          location: "SRID=4326;POINT (153.6105168 -28.6419122)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -28.6419122,
          longitude: 153.6105168,
        },
      },
    ],
    photos: [
      {
        uploadDocument:
          "https://fitness-mates.s3.amazonaws.com/photos/90f47e18-0367-4401-a3cf-b3cbd812cebf/0259-731574-6d90adfa-dcc3-4982-9ca4-d2968d08a9a9.jpg?AWSAccessKeyId=AKIA5UHJQKEGZGW3J53I&Signature=2sx1w3bqWl0ZmJrw2xw9Grfx8%2BY%3D&Expires=1721642410",
        documentName: "pexels-romain-kamin-10259-731574.jpg",
      },
    ],
    isFavourite: false,
  },
  {
    createdBy: {
      id: "247ca369-cad2-441c-8eb8-7342fd98183f",
      name: "Ajay Palsaniya",
      bio: "dev member account dsfsd sdfsdfsdf sefewrf ewrwerwe werwer werwere werwerew ewrwerew as",
      tradingName: "zcdsdsasdas",
      isAdmin: false,
      profilePicture:
        "https://fitness-mates.s3.amazonaws.com/users/247ca369cad2441c8eb87342fd98183f/qZtfk2oK-1721364870177.jpeg",
      profilePictureExtension: "image/jpeg",
      thumbnail: null,
      userType: "Member",
    },
    id: "94ddebd5-8b94-4767-a4bb-058256b03d99",
    listingType: "S",
    isActive: true,
    isPrivate: false,
    isDraft: false,
    createdAt: "2024-07-05T06:53:13.111843Z",
    difficultyLevel: ["B"],
    title: "reaccuring liss",
    media: null,
    description: "dfsdf",
    thumbnail: null,
    upcomingEventDate: "2024-07-22",
    interests: [
      {
        id: "92c2a1db-7647-4a74-aa9a-921c67b7c9a8",
        title: "Basketball",
        image:
          "https://fitness-mates.s3.amazonaws.com/interest/92c2a1db76474a74aa9a921c67b7c9a8/yR6KLrrn-1717664098278.png",
        icon: "https://fitness-mates.s3.amazonaws.com/interest/92c2a1db76474a74aa9a921c67b7c9a8/hlNT5J7S-1717664098419.png",
      },
    ],
    isAgeLimit: false,
    minAge: null,
    listingEventType: "P",
    maxAge: null,
    gender: ["M", "F", "N"],
    maxParticipants: 10,
    dynamicLink: "https://fitnessmates.page.link/4cKZ",
    price: "10.00",
    scheduleDetails: {
      address: {
        city: "",
        state: "",
        address: "Liverpool Liverpool NSW 2170, Australia",
        country: "",
        location: "POINT (150.9179595 -33.9285567)",
        postCode: "",
      },
      endDate: "2024-10-25",
      endTime: "15:00:00.880000",
      frequency: "D",
      weekDays: [],
      startDate: "2024-07-17",
      startTime: "13:30:00.056000",
      isRecurring: true,
      recurringType: "R",
      frequencyNumber: "1",
      monthlyFrequency: "week",
      singleListingType: "R",
      monthlyFrequencyDesc: "",
    },
    teamSlotsAvailable: null,
    distance: 10390.59789641257,
    slotsAvailable: null,
    publishedAt: "2024-07-05T06:53:13.109263Z",
    events: [
      {
        id: "661ac1ef-b895-439c-a9ce-8bb615145aa6",
        title: "reaccuring liss",
        date: "2024-07-17",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 9,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "d34555e0-7aa1-4a4b-acd6-fac1dd0091fb",
        title: "reaccuring liss",
        date: "2024-07-18",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "cf24bfe9-e801-46c4-962d-7845b150c07a",
        title: "reaccuring liss",
        date: "2024-07-19",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 9,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "dcfb567c-1406-4bbf-be57-890ae2455f0d",
        title: "reaccuring liss",
        date: "2024-07-20",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "6fe9004f-9193-4397-b0f4-e11bb32eeeb0",
        title: "reaccuring liss",
        date: "2024-07-21",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 9,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "e3578cda-b7f3-4990-a243-0f2a2b783382",
        title: "reaccuring liss",
        date: "2024-07-22",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "35241eb2-03c7-4f51-89fa-4366fe80d8ec",
        title: "reaccuring liss",
        date: "2024-07-23",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 9,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "8796215a-c2a3-43c3-8d53-63c830e894ec",
        title: "reaccuring liss",
        date: "2024-07-24",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 9,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "9c841496-2575-4d7b-bb54-7460ad61ef4d",
        title: "reaccuring liss",
        date: "2024-07-25",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "84034441-fe2e-475a-b22e-44bceafdc8c8",
        title: "reaccuring liss",
        date: "2024-07-26",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "380065ff-2e2c-4cec-bd76-627f254e327f",
        title: "reaccuring liss",
        date: "2024-07-27",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "a1a0d4e0-d541-46f6-9485-d8ecc171f247",
        title: "reaccuring liss",
        date: "2024-07-28",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "8dc478d4-91ba-411c-8e46-0489d772ab9b",
        title: "reaccuring liss",
        date: "2024-07-29",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "2298d7e4-59ee-44c2-bd23-527d6c6978d5",
        title: "reaccuring liss",
        date: "2024-07-30",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "1b5c385b-2dea-403c-8c36-e6ab24273411",
        title: "reaccuring liss",
        date: "2024-07-31",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "ef28a378-2a48-4550-9647-f3abc25b2509",
        title: "reaccuring liss",
        date: "2024-08-01",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "2d9379cd-805f-4f3d-9cfb-758860b2c925",
        title: "reaccuring liss",
        date: "2024-08-02",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "36703dae-c8fb-45b4-879e-4a05cb672c5f",
        title: "reaccuring liss",
        date: "2024-08-03",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "f83e8f96-a036-4bbe-a100-e0b8069e996f",
        title: "reaccuring liss",
        date: "2024-08-04",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "d4d00344-b7b2-4ad0-ae30-014de3c186d5",
        title: "reaccuring liss",
        date: "2024-08-05",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "6f7d3919-c472-49c6-b460-6a426b4db395",
        title: "reaccuring liss",
        date: "2024-08-06",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "7437ce17-1911-45f8-a3c1-3d5d654472cd",
        title: "reaccuring liss",
        date: "2024-08-07",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "860c0a9a-5c29-4986-adfe-3cf1d52e203c",
        title: "reaccuring liss",
        date: "2024-08-08",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "a07ddedb-30d2-401f-9bdc-d5e8301013ae",
        title: "reaccuring liss",
        date: "2024-08-09",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "d7867f38-0e58-47d1-b942-11b3458b84aa",
        title: "reaccuring liss",
        date: "2024-08-10",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "064c7306-637f-4b6a-9a41-69e1b68e0d49",
        title: "reaccuring liss",
        date: "2024-08-11",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "19554549-2f93-42a3-8526-3dbb1b247898",
        title: "reaccuring liss",
        date: "2024-08-12",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "9abc4cec-0ef8-44d6-bf67-e99d250ef53f",
        title: "reaccuring liss",
        date: "2024-08-13",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "fc9fc126-4357-4f16-a361-ebc4b2945c6d",
        title: "reaccuring liss",
        date: "2024-08-14",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "ee1fe434-0b06-4e8f-96c2-b50df7a15892",
        title: "reaccuring liss",
        date: "2024-08-15",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "d8c0263b-ef26-460f-9b7a-20b212f3e84c",
        title: "reaccuring liss",
        date: "2024-08-16",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "91b516ce-48c5-4f6e-a589-a8968571d41a",
        title: "reaccuring liss",
        date: "2024-08-17",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "e072bb1c-51f2-4764-8548-21100a2840ec",
        title: "reaccuring liss",
        date: "2024-08-18",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "830cc1f6-7a3c-4f26-b7db-87698ba08309",
        title: "reaccuring liss",
        date: "2024-08-19",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "dff9345b-ebbf-48a5-949b-4a9d2bebe53b",
        title: "reaccuring liss",
        date: "2024-08-20",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "89732569-94e8-4707-bc35-c993a97309c9",
        title: "reaccuring liss",
        date: "2024-08-21",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "d1a7b852-f09f-4445-906f-15e016888c86",
        title: "reaccuring liss",
        date: "2024-08-22",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "5b413f94-7a8b-41c6-bb30-5f80f41f773f",
        title: "reaccuring liss",
        date: "2024-08-23",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "cf259999-3990-45cf-9827-b7df5539e72a",
        title: "reaccuring liss",
        date: "2024-08-24",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "2f434ccc-f7ad-4392-9543-1fcade118cc1",
        title: "reaccuring liss",
        date: "2024-08-25",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "25a7c8db-b0b8-4617-ac76-4e44386cba48",
        title: "reaccuring liss",
        date: "2024-08-26",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "97042df9-1d6f-4c69-9ca8-c2de69096a43",
        title: "reaccuring liss",
        date: "2024-08-27",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "6e0d640c-63fd-47d0-af43-239897ba522e",
        title: "reaccuring liss",
        date: "2024-08-28",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "b0cf665c-44b7-4012-a48c-4e474706f862",
        title: "reaccuring liss",
        date: "2024-08-29",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "e851a04a-fcdb-45e8-853b-3d425aaf5a51",
        title: "reaccuring liss",
        date: "2024-08-30",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "7e0ae949-c41d-4381-9d20-3afffe3964fe",
        title: "reaccuring liss",
        date: "2024-08-31",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "788ca09f-a378-4474-865f-337d4531db20",
        title: "reaccuring liss",
        date: "2024-09-01",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "880c786e-bac4-45f5-a53c-906d8f987c36",
        title: "reaccuring liss",
        date: "2024-09-02",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "9e5b556a-15cd-47b9-b46c-d1e80c25a678",
        title: "reaccuring liss",
        date: "2024-09-03",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "c5be01ac-0df3-4c3e-aa5e-8c0eff6441d2",
        title: "reaccuring liss",
        date: "2024-09-04",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "917d4d6e-d495-4bfd-8ff6-cc0986d36916",
        title: "reaccuring liss",
        date: "2024-09-05",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "a409c528-f827-4ae8-8aae-7c27963e61e0",
        title: "reaccuring liss",
        date: "2024-09-06",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "3ef18605-e3e2-4004-8939-e7d61a3894dc",
        title: "reaccuring liss",
        date: "2024-09-07",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "dbaaab6c-b706-46a0-bfe0-eab816e23b30",
        title: "reaccuring liss",
        date: "2024-09-08",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "f4ceb564-bfd3-4d8c-a76f-21bc9ff76978",
        title: "reaccuring liss",
        date: "2024-09-09",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "325ddbf4-6a05-4f57-972a-39a6af30201d",
        title: "reaccuring liss",
        date: "2024-09-10",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "85148e72-baa5-4417-89fb-9bbb05813b35",
        title: "reaccuring liss",
        date: "2024-09-11",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "1f7a9c8a-b7e5-4ce1-b53a-ee5ca4645184",
        title: "reaccuring liss",
        date: "2024-09-12",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "a5347ae0-80b9-4eb7-9b27-2b2d182fccc5",
        title: "reaccuring liss",
        date: "2024-09-13",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "058e5795-47e7-4d5e-ac71-37e5ff256bea",
        title: "reaccuring liss",
        date: "2024-09-14",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "0503ee64-a3de-4c3b-8100-5e19b7720ae6",
        title: "reaccuring liss",
        date: "2024-09-15",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "116ed332-cc89-4b98-a07d-d43e7e9ea454",
        title: "reaccuring liss",
        date: "2024-09-16",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "d468d86e-83e0-4843-823f-ea3e9ed64aaf",
        title: "reaccuring liss",
        date: "2024-09-17",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "8bd4a7d5-16cd-49b9-afd5-517926957fcd",
        title: "reaccuring liss",
        date: "2024-09-18",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "453805fc-a224-44e3-a7b7-fcce205fa78a",
        title: "reaccuring liss",
        date: "2024-09-19",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "ade0cd03-5005-4e50-8a59-8c5ef78ab75a",
        title: "reaccuring liss",
        date: "2024-09-20",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "08d5ff8c-1db0-4d4c-bf9e-9426ffc7522c",
        title: "reaccuring liss",
        date: "2024-09-21",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "d5ea4239-24a1-47b0-8de6-98f6aa199305",
        title: "reaccuring liss",
        date: "2024-09-22",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "b7b5aadd-9acd-490e-b05a-89d40d316a20",
        title: "reaccuring liss",
        date: "2024-09-23",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "ddabba2c-9e02-44d8-a3e1-78e24ab40102",
        title: "reaccuring liss",
        date: "2024-09-24",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "6a73088c-f0d0-4428-9393-a3d381088e3d",
        title: "reaccuring liss",
        date: "2024-09-25",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "7f57a0d4-057e-40a3-a832-6257f5c131a2",
        title: "reaccuring liss",
        date: "2024-09-26",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "8d3c453d-676e-4f0b-b6b0-4d8d138b73d8",
        title: "reaccuring liss",
        date: "2024-09-27",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "49adae22-4b24-471f-a223-eee2b57fd367",
        title: "reaccuring liss",
        date: "2024-09-28",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "d7e29599-2cc9-45f6-a269-92b57db5d4da",
        title: "reaccuring liss",
        date: "2024-09-29",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "8d05783c-bee7-40f3-9653-b720ecb64937",
        title: "reaccuring liss",
        date: "2024-09-30",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "bd544ca8-d0e2-4537-92e7-75087da62b02",
        title: "reaccuring liss",
        date: "2024-10-01",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "76ef06d1-853c-43e0-9386-c3aec3b4f80f",
        title: "reaccuring liss",
        date: "2024-10-02",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "48f88564-f658-44a1-989b-a3f1222e0923",
        title: "reaccuring liss",
        date: "2024-10-03",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "c9166c35-da49-400a-91e4-fc4e262fcbe8",
        title: "reaccuring liss",
        date: "2024-10-04",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "a778adfe-9196-4d7f-b944-c5586607d9cf",
        title: "reaccuring liss",
        date: "2024-10-05",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "f3f7faa9-b554-451d-b80f-cab44483eb54",
        title: "reaccuring liss",
        date: "2024-10-06",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "d3329985-55c9-49be-8824-20bbdd65412f",
        title: "reaccuring liss",
        date: "2024-10-07",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "e1a175a2-d3fc-42e6-9591-54beadf4953a",
        title: "reaccuring liss",
        date: "2024-10-08",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "54412c5f-74b5-4d9c-8991-31529359e101",
        title: "reaccuring liss",
        date: "2024-10-09",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "712159ed-c04a-469d-b8ca-c331c5c42c69",
        title: "reaccuring liss",
        date: "2024-10-10",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "e02dc094-e42d-497f-8fc3-62fde068e0b6",
        title: "reaccuring liss",
        date: "2024-10-11",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "578cdd02-4e92-43a3-aa08-d0e380200014",
        title: "reaccuring liss",
        date: "2024-10-12",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "57612607-e8d8-4459-8522-64da91505d78",
        title: "reaccuring liss",
        date: "2024-10-13",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "3a9a8216-f76b-4fff-9102-1b39a05efa69",
        title: "reaccuring liss",
        date: "2024-10-14",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "eb9adf82-cbdf-4b98-95d1-f095d7183144",
        title: "reaccuring liss",
        date: "2024-10-15",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "c60a6a4f-9923-4063-99e1-fde9b856e40b",
        title: "reaccuring liss",
        date: "2024-10-16",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "29278acc-1062-40d6-8d34-3de34913966f",
        title: "reaccuring liss",
        date: "2024-10-17",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "0ca0ce1a-9f2c-4a8e-ba37-ddddbe37a20b",
        title: "reaccuring liss",
        date: "2024-10-18",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "441ed657-686a-41e4-8d78-243441851e21",
        title: "reaccuring liss",
        date: "2024-10-19",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "e169070f-86b9-4992-ad8f-c00a80233809",
        title: "reaccuring liss",
        date: "2024-10-20",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "24a00a2c-20d9-4d46-8e93-1cc5010d9fe8",
        title: "reaccuring liss",
        date: "2024-10-21",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "43b8b4c4-bf72-4f36-b4d4-33fe7f64c731",
        title: "reaccuring liss",
        date: "2024-10-22",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "3de48a19-4f47-41b0-a477-9d2e96469fcf",
        title: "reaccuring liss",
        date: "2024-10-23",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "3bbc880e-421b-449e-b89c-5652e17b832d",
        title: "reaccuring liss",
        date: "2024-10-24",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
      {
        id: "24db1a5b-cfb7-44c8-9df8-58cf4c121490",
        title: "reaccuring liss",
        date: "2024-10-25",
        startTime: "13:30:00.056000",
        endTime: "15:00:00.880000",
        slotsAvailable: 10,
        address: {
          address: "Liverpool Liverpool NSW 2170, Australia",
          city: "",
          location: "SRID=4326;POINT (150.9179595 -33.9285567)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.9285567,
          longitude: 150.9179595,
        },
      },
    ],
    photos: [
      {
        uploadDocument:
          "https://fitness-mates.s3.amazonaws.com/photos/94ddebd5-8b94-4767-a4bb-058256b03d99/ooknowpopup-96230577-9bd3-45fd-88e8-46661b8bae3b.png?AWSAccessKeyId=AKIA5UHJQKEGZGW3J53I&Signature=IUY1TGpTZg9Im%2FNhOhDQ8HBzaRI%3D&Expires=1721642410",
        documentName: "booknowpopup.png",
      },
    ],
    isFavourite: false,
  },
  {
    createdBy: {
      id: "406761e9-c55a-4786-b25e-6c4377934b3d",
      name: "Anil Create Linting",
      bio: null,
      tradingName: null,
      isAdmin: false,
      profilePicture: null,
      profilePictureExtension: null,
      thumbnail: null,
      userType: "Member",
    },
    id: "e52428b7-aa12-41d7-8a9e-852708d8b9f4",
    listingType: "S",
    isActive: true,
    isPrivate: false,
    isDraft: false,
    createdAt: "2024-07-02T18:45:09.201772Z",
    difficultyLevel: ["B"],
    title: "Recuring Days Multi(2)",
    media: null,
    description: "Recuring Days Multi(2)",
    thumbnail: null,
    upcomingEventDate: "2024-07-22",
    interests: [
      {
        id: "dfb79f8f-45f8-40d5-8874-4b3e40670fa3",
        title: "Walk",
        image:
          "https://fitness-mates.s3.amazonaws.com/interest/dfb79f8f45f840d588744b3e40670fa3/kHnzxQjg-1706933606841.png",
        icon: "https://fitness-mates.s3.amazonaws.com/interest/dfb79f8f45f840d588744b3e40670fa3/No2utC9b-1717664326998.png",
      },
    ],
    isAgeLimit: false,
    minAge: null,
    listingEventType: "P",
    maxAge: null,
    gender: ["F"],
    maxParticipants: 200,
    dynamicLink: "https://fitnessmates.page.link/7yw8",
    price: "22.00",
    scheduleDetails: {
      address: {
        city: "",
        state: "",
        address: "Sydney Airport Mascot NSW 2020, Australia",
        country: "",
        location: "POINT (151.1816944 -33.95003140000001)",
        postCode: "",
      },
      endDate: "2024-09-25",
      endTime: "02:00:00.425000",
      frequency: "D",
      weekDays: [],
      startDate: "2024-07-16",
      startTime: "01:30:00.574000",
      isRecurring: true,
      recurringType: "R",
      frequencyNumber: "2",
      monthlyFrequency: "week",
      singleListingType: "R",
      monthlyFrequencyDesc: "",
    },
    teamSlotsAvailable: null,
    distance: 10412.906142381244,
    slotsAvailable: null,
    publishedAt: "2024-07-02T18:45:09.200468Z",
    events: [
      {
        id: "b28111cc-e5e8-4d29-b9b2-a3df96ec39aa",
        title: "Recuring Days Multi(2)",
        date: "2024-07-16",
        startTime: "01:30:00.574000",
        endTime: "02:00:00.425000",
        slotsAvailable: 200,
        address: {
          address: "Sydney Airport Mascot NSW 2020, Australia",
          city: "",
          location: "SRID=4326;POINT (151.1816944 -33.95003140000001)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.95003140000001,
          longitude: 151.1816944,
        },
      },
      {
        id: "a7689fb5-e134-40fb-9299-fd914c4f29b0",
        title: "Recuring Days Multi(2)",
        date: "2024-07-18",
        startTime: "01:30:00.574000",
        endTime: "02:00:00.425000",
        slotsAvailable: 200,
        address: {
          address: "Sydney Airport Mascot NSW 2020, Australia",
          city: "",
          location: "SRID=4326;POINT (151.1816944 -33.95003140000001)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.95003140000001,
          longitude: 151.1816944,
        },
      },
      {
        id: "2912655f-6734-4a9a-9956-1dadde39ef96",
        title: "Recuring Days Multi(2)",
        date: "2024-07-20",
        startTime: "01:30:00.574000",
        endTime: "02:00:00.425000",
        slotsAvailable: 200,
        address: {
          address: "Sydney Airport Mascot NSW 2020, Australia",
          city: "",
          location: "SRID=4326;POINT (151.1816944 -33.95003140000001)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.95003140000001,
          longitude: 151.1816944,
        },
      },
      {
        id: "0eed49af-755f-4a17-90ed-e220197d74e3",
        title: "Recuring Days Multi(2)",
        date: "2024-07-22",
        startTime: "01:30:00.574000",
        endTime: "02:00:00.425000",
        slotsAvailable: 200,
        address: {
          address: "Sydney Airport Mascot NSW 2020, Australia",
          city: "",
          location: "SRID=4326;POINT (151.1816944 -33.95003140000001)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.95003140000001,
          longitude: 151.1816944,
        },
      },
      {
        id: "35ba8590-1a66-4957-a1ed-f77d994dbe85",
        title: "Recuring Days Multi(2)",
        date: "2024-07-24",
        startTime: "01:30:00.574000",
        endTime: "02:00:00.425000",
        slotsAvailable: 200,
        address: {
          address: "Sydney Airport Mascot NSW 2020, Australia",
          city: "",
          location: "SRID=4326;POINT (151.1816944 -33.95003140000001)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.95003140000001,
          longitude: 151.1816944,
        },
      },
      {
        id: "f73663e3-7acc-46e0-8344-18681c4a39a5",
        title: "Recuring Days Multi(2)",
        date: "2024-07-26",
        startTime: "01:30:00.574000",
        endTime: "02:00:00.425000",
        slotsAvailable: 200,
        address: {
          address: "Sydney Airport Mascot NSW 2020, Australia",
          city: "",
          location: "SRID=4326;POINT (151.1816944 -33.95003140000001)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.95003140000001,
          longitude: 151.1816944,
        },
      },
      {
        id: "0f8dec48-e858-44fc-8037-5c5f68248ccc",
        title: "Recuring Days Multi(2)",
        date: "2024-07-28",
        startTime: "01:30:00.574000",
        endTime: "02:00:00.425000",
        slotsAvailable: 200,
        address: {
          address: "Sydney Airport Mascot NSW 2020, Australia",
          city: "",
          location: "SRID=4326;POINT (151.1816944 -33.95003140000001)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.95003140000001,
          longitude: 151.1816944,
        },
      },
      {
        id: "4a5e3261-9904-4345-806b-33723dbac6bb",
        title: "Recuring Days Multi(2)",
        date: "2024-07-30",
        startTime: "01:30:00.574000",
        endTime: "02:00:00.425000",
        slotsAvailable: 200,
        address: {
          address: "Sydney Airport Mascot NSW 2020, Australia",
          city: "",
          location: "SRID=4326;POINT (151.1816944 -33.95003140000001)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.95003140000001,
          longitude: 151.1816944,
        },
      },
      {
        id: "41fac2ef-676e-4a9f-b3fb-e51cee94b220",
        title: "Recuring Days Multi(2)",
        date: "2024-08-01",
        startTime: "01:30:00.574000",
        endTime: "02:00:00.425000",
        slotsAvailable: 200,
        address: {
          address: "Sydney Airport Mascot NSW 2020, Australia",
          city: "",
          location: "SRID=4326;POINT (151.1816944 -33.95003140000001)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.95003140000001,
          longitude: 151.1816944,
        },
      },
      {
        id: "13314c0f-5da2-4cd4-84ff-4a367edeca21",
        title: "Recuring Days Multi(2)",
        date: "2024-08-03",
        startTime: "01:30:00.574000",
        endTime: "02:00:00.425000",
        slotsAvailable: 200,
        address: {
          address: "Sydney Airport Mascot NSW 2020, Australia",
          city: "",
          location: "SRID=4326;POINT (151.1816944 -33.95003140000001)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.95003140000001,
          longitude: 151.1816944,
        },
      },
      {
        id: "55e2bdb2-8cd7-4dea-8d90-bd576ca16c51",
        title: "Recuring Days Multi(2)",
        date: "2024-08-05",
        startTime: "01:30:00.574000",
        endTime: "02:00:00.425000",
        slotsAvailable: 200,
        address: {
          address: "Sydney Airport Mascot NSW 2020, Australia",
          city: "",
          location: "SRID=4326;POINT (151.1816944 -33.95003140000001)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.95003140000001,
          longitude: 151.1816944,
        },
      },
      {
        id: "e3bedcfd-df1b-45ec-aeaa-bd4e90a29e7a",
        title: "Recuring Days Multi(2)",
        date: "2024-08-07",
        startTime: "01:30:00.574000",
        endTime: "02:00:00.425000",
        slotsAvailable: 200,
        address: {
          address: "Sydney Airport Mascot NSW 2020, Australia",
          city: "",
          location: "SRID=4326;POINT (151.1816944 -33.95003140000001)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.95003140000001,
          longitude: 151.1816944,
        },
      },
      {
        id: "02ddff83-2990-43f9-8653-8cf1de267d95",
        title: "Recuring Days Multi(2)",
        date: "2024-08-09",
        startTime: "01:30:00.574000",
        endTime: "02:00:00.425000",
        slotsAvailable: 200,
        address: {
          address: "Sydney Airport Mascot NSW 2020, Australia",
          city: "",
          location: "SRID=4326;POINT (151.1816944 -33.95003140000001)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.95003140000001,
          longitude: 151.1816944,
        },
      },
      {
        id: "540b3e31-5de3-40bd-ab9b-a44baeb218aa",
        title: "Recuring Days Multi(2)",
        date: "2024-08-11",
        startTime: "01:30:00.574000",
        endTime: "02:00:00.425000",
        slotsAvailable: 200,
        address: {
          address: "Sydney Airport Mascot NSW 2020, Australia",
          city: "",
          location: "SRID=4326;POINT (151.1816944 -33.95003140000001)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.95003140000001,
          longitude: 151.1816944,
        },
      },
      {
        id: "d78a487d-29f5-42ab-8051-4f81f68e1c87",
        title: "Recuring Days Multi(2)",
        date: "2024-08-13",
        startTime: "01:30:00.574000",
        endTime: "02:00:00.425000",
        slotsAvailable: 200,
        address: {
          address: "Sydney Airport Mascot NSW 2020, Australia",
          city: "",
          location: "SRID=4326;POINT (151.1816944 -33.95003140000001)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.95003140000001,
          longitude: 151.1816944,
        },
      },
      {
        id: "5b8e048d-f9b5-4a9b-b978-75e8dc3fbe98",
        title: "Recuring Days Multi(2)",
        date: "2024-08-15",
        startTime: "01:30:00.574000",
        endTime: "02:00:00.425000",
        slotsAvailable: 200,
        address: {
          address: "Sydney Airport Mascot NSW 2020, Australia",
          city: "",
          location: "SRID=4326;POINT (151.1816944 -33.95003140000001)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.95003140000001,
          longitude: 151.1816944,
        },
      },
      {
        id: "a9fc0397-613e-459d-91de-18953a27ce19",
        title: "Recuring Days Multi(2)",
        date: "2024-08-17",
        startTime: "01:30:00.574000",
        endTime: "02:00:00.425000",
        slotsAvailable: 200,
        address: {
          address: "Sydney Airport Mascot NSW 2020, Australia",
          city: "",
          location: "SRID=4326;POINT (151.1816944 -33.95003140000001)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.95003140000001,
          longitude: 151.1816944,
        },
      },
      {
        id: "d74d4dc6-ccd2-4317-9990-b3383d1e8a54",
        title: "Recuring Days Multi(2)",
        date: "2024-08-19",
        startTime: "01:30:00.574000",
        endTime: "02:00:00.425000",
        slotsAvailable: 200,
        address: {
          address: "Sydney Airport Mascot NSW 2020, Australia",
          city: "",
          location: "SRID=4326;POINT (151.1816944 -33.95003140000001)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.95003140000001,
          longitude: 151.1816944,
        },
      },
      {
        id: "43d817f1-9ebe-49ed-8f55-f141e2992ec3",
        title: "Recuring Days Multi(2)",
        date: "2024-08-21",
        startTime: "01:30:00.574000",
        endTime: "02:00:00.425000",
        slotsAvailable: 200,
        address: {
          address: "Sydney Airport Mascot NSW 2020, Australia",
          city: "",
          location: "SRID=4326;POINT (151.1816944 -33.95003140000001)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.95003140000001,
          longitude: 151.1816944,
        },
      },
      {
        id: "d74b6031-5f1d-49b0-8579-3e3b895d4f66",
        title: "Recuring Days Multi(2)",
        date: "2024-08-23",
        startTime: "01:30:00.574000",
        endTime: "02:00:00.425000",
        slotsAvailable: 200,
        address: {
          address: "Sydney Airport Mascot NSW 2020, Australia",
          city: "",
          location: "SRID=4326;POINT (151.1816944 -33.95003140000001)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.95003140000001,
          longitude: 151.1816944,
        },
      },
      {
        id: "3aa04493-09dc-4843-ab5f-9599fd836f8e",
        title: "Recuring Days Multi(2)",
        date: "2024-08-25",
        startTime: "01:30:00.574000",
        endTime: "02:00:00.425000",
        slotsAvailable: 200,
        address: {
          address: "Sydney Airport Mascot NSW 2020, Australia",
          city: "",
          location: "SRID=4326;POINT (151.1816944 -33.95003140000001)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.95003140000001,
          longitude: 151.1816944,
        },
      },
      {
        id: "7c813e25-5858-44c2-afc8-80ea783a939b",
        title: "Recuring Days Multi(2)",
        date: "2024-08-27",
        startTime: "01:30:00.574000",
        endTime: "02:00:00.425000",
        slotsAvailable: 200,
        address: {
          address: "Sydney Airport Mascot NSW 2020, Australia",
          city: "",
          location: "SRID=4326;POINT (151.1816944 -33.95003140000001)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.95003140000001,
          longitude: 151.1816944,
        },
      },
      {
        id: "53dc3e9d-167a-49ab-831c-6f90d6bd7357",
        title: "Recuring Days Multi(2)",
        date: "2024-08-29",
        startTime: "01:30:00.574000",
        endTime: "02:00:00.425000",
        slotsAvailable: 200,
        address: {
          address: "Sydney Airport Mascot NSW 2020, Australia",
          city: "",
          location: "SRID=4326;POINT (151.1816944 -33.95003140000001)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.95003140000001,
          longitude: 151.1816944,
        },
      },
      {
        id: "0c1eeddd-50fb-446d-97ba-f2fa776b3f3d",
        title: "Recuring Days Multi(2)",
        date: "2024-08-31",
        startTime: "01:30:00.574000",
        endTime: "02:00:00.425000",
        slotsAvailable: 200,
        address: {
          address: "Sydney Airport Mascot NSW 2020, Australia",
          city: "",
          location: "SRID=4326;POINT (151.1816944 -33.95003140000001)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.95003140000001,
          longitude: 151.1816944,
        },
      },
      {
        id: "c0dfe655-5cfd-4d25-9d2f-8ae73ae5b5eb",
        title: "Recuring Days Multi(2)",
        date: "2024-09-02",
        startTime: "01:30:00.574000",
        endTime: "02:00:00.425000",
        slotsAvailable: 200,
        address: {
          address: "Sydney Airport Mascot NSW 2020, Australia",
          city: "",
          location: "SRID=4326;POINT (151.1816944 -33.95003140000001)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.95003140000001,
          longitude: 151.1816944,
        },
      },
      {
        id: "3dea09da-9afe-42a2-b8c0-8667c181c59d",
        title: "Recuring Days Multi(2)",
        date: "2024-09-04",
        startTime: "01:30:00.574000",
        endTime: "02:00:00.425000",
        slotsAvailable: 200,
        address: {
          address: "Sydney Airport Mascot NSW 2020, Australia",
          city: "",
          location: "SRID=4326;POINT (151.1816944 -33.95003140000001)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.95003140000001,
          longitude: 151.1816944,
        },
      },
      {
        id: "964e7e88-d9ca-43f5-b0e7-2a8f779b10e7",
        title: "Recuring Days Multi(2)",
        date: "2024-09-06",
        startTime: "01:30:00.574000",
        endTime: "02:00:00.425000",
        slotsAvailable: 200,
        address: {
          address: "Sydney Airport Mascot NSW 2020, Australia",
          city: "",
          location: "SRID=4326;POINT (151.1816944 -33.95003140000001)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.95003140000001,
          longitude: 151.1816944,
        },
      },
      {
        id: "d187851f-1837-4a11-9bb7-b93898556eee",
        title: "Recuring Days Multi(2)",
        date: "2024-09-08",
        startTime: "01:30:00.574000",
        endTime: "02:00:00.425000",
        slotsAvailable: 200,
        address: {
          address: "Sydney Airport Mascot NSW 2020, Australia",
          city: "",
          location: "SRID=4326;POINT (151.1816944 -33.95003140000001)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.95003140000001,
          longitude: 151.1816944,
        },
      },
      {
        id: "9e93c34a-3789-49d3-9065-f19ead4722af",
        title: "Recuring Days Multi(2)",
        date: "2024-09-10",
        startTime: "01:30:00.574000",
        endTime: "02:00:00.425000",
        slotsAvailable: 200,
        address: {
          address: "Sydney Airport Mascot NSW 2020, Australia",
          city: "",
          location: "SRID=4326;POINT (151.1816944 -33.95003140000001)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.95003140000001,
          longitude: 151.1816944,
        },
      },
      {
        id: "5c8e9d2f-24d7-4838-a787-99cd76b3a62e",
        title: "Recuring Days Multi(2)",
        date: "2024-09-12",
        startTime: "01:30:00.574000",
        endTime: "02:00:00.425000",
        slotsAvailable: 200,
        address: {
          address: "Sydney Airport Mascot NSW 2020, Australia",
          city: "",
          location: "SRID=4326;POINT (151.1816944 -33.95003140000001)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.95003140000001,
          longitude: 151.1816944,
        },
      },
      {
        id: "4040c45b-bfaa-48e7-8d32-ce9eec67af90",
        title: "Recuring Days Multi(2)",
        date: "2024-09-14",
        startTime: "01:30:00.574000",
        endTime: "02:00:00.425000",
        slotsAvailable: 200,
        address: {
          address: "Sydney Airport Mascot NSW 2020, Australia",
          city: "",
          location: "SRID=4326;POINT (151.1816944 -33.95003140000001)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.95003140000001,
          longitude: 151.1816944,
        },
      },
      {
        id: "8a5bb56b-18cb-4fee-802b-739a81909655",
        title: "Recuring Days Multi(2)",
        date: "2024-09-16",
        startTime: "01:30:00.574000",
        endTime: "02:00:00.425000",
        slotsAvailable: 200,
        address: {
          address: "Sydney Airport Mascot NSW 2020, Australia",
          city: "",
          location: "SRID=4326;POINT (151.1816944 -33.95003140000001)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.95003140000001,
          longitude: 151.1816944,
        },
      },
      {
        id: "6d796961-6ca2-4832-a0f7-8287374021cf",
        title: "Recuring Days Multi(2)",
        date: "2024-09-18",
        startTime: "01:30:00.574000",
        endTime: "02:00:00.425000",
        slotsAvailable: 200,
        address: {
          address: "Sydney Airport Mascot NSW 2020, Australia",
          city: "",
          location: "SRID=4326;POINT (151.1816944 -33.95003140000001)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.95003140000001,
          longitude: 151.1816944,
        },
      },
      {
        id: "08136fde-2c9a-4a84-bef8-f5b6c9932e4d",
        title: "Recuring Days Multi(2)",
        date: "2024-09-20",
        startTime: "01:30:00.574000",
        endTime: "02:00:00.425000",
        slotsAvailable: 200,
        address: {
          address: "Sydney Airport Mascot NSW 2020, Australia",
          city: "",
          location: "SRID=4326;POINT (151.1816944 -33.95003140000001)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.95003140000001,
          longitude: 151.1816944,
        },
      },
      {
        id: "c915c851-ffac-4666-89f5-678d3fe5059f",
        title: "Recuring Days Multi(2)",
        date: "2024-09-22",
        startTime: "01:30:00.574000",
        endTime: "02:00:00.425000",
        slotsAvailable: 200,
        address: {
          address: "Sydney Airport Mascot NSW 2020, Australia",
          city: "",
          location: "SRID=4326;POINT (151.1816944 -33.95003140000001)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.95003140000001,
          longitude: 151.1816944,
        },
      },
      {
        id: "bd7788c7-e723-4024-8ff7-b5181368a2b7",
        title: "Recuring Days Multi(2)",
        date: "2024-09-24",
        startTime: "01:30:00.574000",
        endTime: "02:00:00.425000",
        slotsAvailable: 200,
        address: {
          address: "Sydney Airport Mascot NSW 2020, Australia",
          city: "",
          location: "SRID=4326;POINT (151.1816944 -33.95003140000001)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.95003140000001,
          longitude: 151.1816944,
        },
      },
    ],
    photos: [
      {
        uploadDocument:
          "https://fitness-mates.s3.amazonaws.com/photos/e52428b7-aa12-41d7-8a9e-852708d8b9f4/eta-2034892-4f8bf4f5-1ddb-42cb-98cb-dc8d57e87170.jpg?AWSAccessKeyId=AKIA5UHJQKEGZGW3J53I&Signature=q3TmMtNiCvhqItasXVoYORXVc40%3D&Expires=1721642410",
        documentName: "pexels-lucaspezeta-2034892.jpg",
      },
    ],
    isFavourite: false,
  },
  {
    createdBy: {
      id: "406761e9-c55a-4786-b25e-6c4377934b3d",
      name: "Anil Create Linting",
      bio: null,
      tradingName: null,
      isAdmin: false,
      profilePicture: null,
      profilePictureExtension: null,
      thumbnail: null,
      userType: "Member",
    },
    id: "867d6a49-9741-48ba-b9d7-510e1aa9f488",
    listingType: "S",
    isActive: true,
    isPrivate: false,
    isDraft: false,
    createdAt: "2024-07-02T18:18:59.483811Z",
    difficultyLevel: ["B"],
    title: "Repeated Frequency",
    media: null,
    description: "Repeat 1, Frequency Recurring Class, Frequency type: days",
    thumbnail: null,
    upcomingEventDate: "2024-07-22",
    interests: [
      {
        id: "aa389d29-54e2-408f-bf8d-7446face75b2",
        title: "Yoga",
        image:
          "https://fitness-mates.s3.amazonaws.com/interest/aa389d2954e2408fbf8d7446face75b2/RTeJ1cij-1706933725631.png",
        icon: "https://fitness-mates.s3.amazonaws.com/interest/aa389d2954e2408fbf8d7446face75b2/Qyrf1ByZ-1717664428783.jpg",
      },
    ],
    isAgeLimit: false,
    minAge: null,
    listingEventType: "P",
    maxAge: null,
    gender: ["F"],
    maxParticipants: 100,
    dynamicLink: "https://fitnessmates.page.link/drfV",
    price: "12.00",
    scheduleDetails: {
      address: {
        city: "",
        state: "",
        address: "Bondi Beach Bondi Beach NSW 2026, Australia",
        country: "",
        location: "POINT (151.2703991 -33.8891201)",
        postCode: "",
      },
      endDate: "2024-07-30",
      endTime: "23:00:00.869000",
      frequency: "D",
      weekDays: [],
      startDate: "2024-07-09",
      startTime: "21:30:00.912000",
      isRecurring: true,
      recurringType: "R",
      frequencyNumber: "1",
      monthlyFrequency: "week",
      singleListingType: "R",
      monthlyFrequencyDesc: "",
    },
    teamSlotsAvailable: null,
    distance: 10416.649197268536,
    slotsAvailable: null,
    publishedAt: "2024-07-02T18:18:59.480963Z",
    events: [
      {
        id: "d5ff6ce8-daf4-47e4-b3af-6d185114453a",
        title: "Repeated Frequency",
        date: "2024-07-09",
        startTime: "21:30:00.912000",
        endTime: "23:00:00.869000",
        slotsAvailable: 100,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "ec320506-841e-4713-8ce2-c422fec9d24c",
        title: "Repeated Frequency",
        date: "2024-07-10",
        startTime: "21:30:00.912000",
        endTime: "23:00:00.869000",
        slotsAvailable: 100,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "2844c730-d543-40f5-976c-30215b5e37b2",
        title: "Repeated Frequency",
        date: "2024-07-11",
        startTime: "21:30:00.912000",
        endTime: "23:00:00.869000",
        slotsAvailable: 100,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "c931cfb8-f38b-4b1b-8015-a0d68beed9ec",
        title: "Repeated Frequency",
        date: "2024-07-12",
        startTime: "21:30:00.912000",
        endTime: "23:00:00.869000",
        slotsAvailable: 100,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "84992271-733d-4a44-a6ab-baaada5494fe",
        title: "Repeated Frequency",
        date: "2024-07-13",
        startTime: "21:30:00.912000",
        endTime: "23:00:00.869000",
        slotsAvailable: 100,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "e25c80ec-f845-4f06-8e0c-8e424ec0dd09",
        title: "Repeated Frequency",
        date: "2024-07-14",
        startTime: "21:30:00.912000",
        endTime: "23:00:00.869000",
        slotsAvailable: 100,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "f83ff801-e87c-4291-ba5c-c4c031fac31d",
        title: "Repeated Frequency",
        date: "2024-07-15",
        startTime: "21:30:00.912000",
        endTime: "23:00:00.869000",
        slotsAvailable: 100,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "61404405-ddcd-4661-aae6-405a085410a1",
        title: "Repeated Frequency",
        date: "2024-07-16",
        startTime: "21:30:00.912000",
        endTime: "23:00:00.869000",
        slotsAvailable: 100,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "65f3f94c-ac2c-499f-901a-3af0c4849db8",
        title: "Repeated Frequency",
        date: "2024-07-17",
        startTime: "21:30:00.912000",
        endTime: "23:00:00.869000",
        slotsAvailable: 100,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "65ababdc-9ab4-44f6-9405-b7c37f149e3c",
        title: "Repeated Frequency",
        date: "2024-07-18",
        startTime: "21:30:00.912000",
        endTime: "23:00:00.869000",
        slotsAvailable: 100,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "0a6b4462-25ec-48b1-a8c6-0d5b22afd6f8",
        title: "Repeated Frequency",
        date: "2024-07-19",
        startTime: "21:30:00.912000",
        endTime: "23:00:00.869000",
        slotsAvailable: 100,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "dbe0b3c6-c13f-419e-9608-965dfb3d4dc4",
        title: "Repeated Frequency",
        date: "2024-07-20",
        startTime: "21:30:00.912000",
        endTime: "23:00:00.869000",
        slotsAvailable: 100,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "4f5f53b2-5985-48d7-a751-17aae7d11bc2",
        title: "Repeated Frequency",
        date: "2024-07-21",
        startTime: "21:30:00.912000",
        endTime: "23:00:00.869000",
        slotsAvailable: 100,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "2701559b-5999-42a6-a4c9-7b3587439467",
        title: "Repeated Frequency",
        date: "2024-07-22",
        startTime: "21:30:00.912000",
        endTime: "23:00:00.869000",
        slotsAvailable: 100,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "46e15ad9-c2ec-4a56-837d-755650f04230",
        title: "Repeated Frequency",
        date: "2024-07-23",
        startTime: "21:30:00.912000",
        endTime: "23:00:00.869000",
        slotsAvailable: 100,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "7fe73ed6-9e02-4a48-932a-be2026e21e5f",
        title: "Repeated Frequency",
        date: "2024-07-24",
        startTime: "21:30:00.912000",
        endTime: "23:00:00.869000",
        slotsAvailable: 100,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "7e7429e3-7e5e-46d5-8f32-b50a630c4464",
        title: "Repeated Frequency",
        date: "2024-07-25",
        startTime: "21:30:00.912000",
        endTime: "23:00:00.869000",
        slotsAvailable: 100,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "94fa22d8-6ced-4015-8b8f-f9292c0d4476",
        title: "Repeated Frequency",
        date: "2024-07-26",
        startTime: "21:30:00.912000",
        endTime: "23:00:00.869000",
        slotsAvailable: 100,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "8fda1b0d-c406-4c64-83c7-3519e83f4cc1",
        title: "Repeated Frequency",
        date: "2024-07-27",
        startTime: "21:30:00.912000",
        endTime: "23:00:00.869000",
        slotsAvailable: 100,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "b6efa7f7-d8d8-4cf8-a0fe-f7fb0ceb676d",
        title: "Repeated Frequency",
        date: "2024-07-28",
        startTime: "21:30:00.912000",
        endTime: "23:00:00.869000",
        slotsAvailable: 100,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "342f66d2-ee5f-4068-a3ae-8cf3d072a536",
        title: "Repeated Frequency",
        date: "2024-07-29",
        startTime: "21:30:00.912000",
        endTime: "23:00:00.869000",
        slotsAvailable: 100,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "02d775b4-b2ea-4423-8a95-dadcc2bef48a",
        title: "Repeated Frequency",
        date: "2024-07-30",
        startTime: "21:30:00.912000",
        endTime: "23:00:00.869000",
        slotsAvailable: 100,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
    ],
    photos: [
      {
        uploadDocument:
          "https://fitness-mates.s3.amazonaws.com/photos/867d6a49-9741-48ba-b9d7-510e1aa9f488/eta-2034892-a8c19a4a-cae5-47f2-8961-23375027f3f1.jpg?AWSAccessKeyId=AKIA5UHJQKEGZGW3J53I&Signature=KpfIM6KcMGOHUAgQgswbkVp9iVM%3D&Expires=1721642410",
        documentName: "pexels-lucaspezeta-2034892.jpg",
      },
    ],
    isFavourite: false,
  },
  {
    createdBy: {
      id: "406761e9-c55a-4786-b25e-6c4377934b3d",
      name: "Anil Create Linting",
      bio: null,
      tradingName: null,
      isAdmin: false,
      profilePicture: null,
      profilePictureExtension: null,
      thumbnail: null,
      userType: "Member",
    },
    id: "b1956e43-b51f-4a7c-856b-ceb404fc3432",
    listingType: "S",
    isActive: true,
    isPrivate: false,
    isDraft: false,
    createdAt: "2024-07-02T18:25:25.239327Z",
    difficultyLevel: ["B"],
    title: "Repeat 1,Week Frequency Recurring Class",
    media: null,
    description: "Repeat 1,Week Frequency Recurring Class",
    thumbnail: null,
    upcomingEventDate: "2024-07-22",
    interests: [
      {
        id: "dfb79f8f-45f8-40d5-8874-4b3e40670fa3",
        title: "Walk",
        image:
          "https://fitness-mates.s3.amazonaws.com/interest/dfb79f8f45f840d588744b3e40670fa3/kHnzxQjg-1706933606841.png",
        icon: "https://fitness-mates.s3.amazonaws.com/interest/dfb79f8f45f840d588744b3e40670fa3/No2utC9b-1717664326998.png",
      },
    ],
    isAgeLimit: false,
    minAge: null,
    listingEventType: "P",
    maxAge: null,
    gender: ["F"],
    maxParticipants: 344,
    dynamicLink: "https://fitnessmates.page.link/QTVe",
    price: "24.00",
    scheduleDetails: {
      address: {
        city: "",
        state: "",
        address: "Bondi Beach Bondi Beach NSW 2026, Australia",
        country: "",
        location: "POINT (151.2703991 -33.8891201)",
        postCode: "",
      },
      endDate: "2024-12-24",
      endTime: "23:30:00.801000",
      frequency: "W",
      weekDays: ["Monday", "Tuesday", "Wednesday"],
      startDate: "2024-07-09",
      startTime: "22:30:00.685000",
      isRecurring: true,
      recurringType: "R",
      frequencyNumber: "1",
      monthlyFrequency: "week",
      singleListingType: "R",
      monthlyFrequencyDesc: "",
    },
    teamSlotsAvailable: null,
    distance: 10416.649197268536,
    slotsAvailable: null,
    publishedAt: "2024-07-02T18:25:25.237328Z",
    events: [
      {
        id: "7cc4af47-9547-4461-ab19-f80a94baa3f8",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-07-09",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "1fb091bb-dbbe-4013-83c5-d36eff3612c9",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-07-10",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "ba016a64-50e8-4708-92ce-623c89dd32ba",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-07-15",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "d8e789e3-bc38-4600-b85a-3ab3dbfece43",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-07-16",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "a2a334c6-c909-4569-883e-e760d89daa9b",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-07-17",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "be8f2bc2-d05b-45e9-8d00-c55b87a11bd6",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-07-22",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "a496f93a-a6c9-4f49-b8e0-90782bf2bbb3",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-07-23",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "744a90ee-956e-4948-93f7-380386476a57",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-07-24",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "48564445-1dd0-4e27-84e1-2fc79f3cffa2",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-07-29",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "ece51928-9d9a-4b84-afb1-0c38d04268c1",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-07-30",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "17a1ee44-44d8-4ce7-bb3d-eb1ecf747621",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-07-31",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "2f04d4df-2ad6-4d21-9175-2775c5b0d770",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-08-05",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "364f52e8-1de4-4c58-8c39-2206f444b243",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-08-06",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "b48b02bf-cf3b-46f1-8574-41a3d8872cfb",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-08-07",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "c44897f5-e08e-40d2-9863-ab6eee75dc2c",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-08-12",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "93435299-f505-4bd9-bf2d-aae7de69ee45",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-08-13",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "3345c05f-5eaa-48f9-93e5-aefb8320800a",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-08-14",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "81dd042a-02c1-48d8-bc63-fab6f86767cd",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-08-19",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "569210b4-1be5-44a8-8e2a-8e292686da74",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-08-20",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "dd6d7259-1ff0-44de-ae01-c72848293564",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-08-21",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "b6c59302-08fc-4502-95ee-d31f5f2099a3",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-08-26",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "7361bf24-54d5-4e0c-917d-81f02ae1974a",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-08-27",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "45b1b803-02c5-4ed0-aef7-73f6e76eb19a",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-08-28",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "3150b085-25f2-460b-8e5d-159154cc24d7",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-09-02",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "849b946c-554f-4da1-b6fa-7de030144fa4",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-09-03",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "17d97dc1-2b7b-4d08-9701-1751c2ef70a4",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-09-04",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "846df46a-ca29-41c4-8e78-b917d6f7812f",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-09-09",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "461863b7-010d-409b-b582-ab88e0b099aa",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-09-10",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "8a90eb8b-43ad-41ba-b23a-09a2b85e455b",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-09-11",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "57cd4d3f-d96d-4bf1-9116-7d32932dfa3c",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-09-16",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "a168abca-f7d0-4b2e-a1f7-c30d85bc9968",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-09-17",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "a61508be-7103-4c79-b342-af8007908441",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-09-18",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "16a77e30-e229-4f73-90e3-c97b6e8fada8",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-09-23",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "df1edc53-2e76-45ba-8261-e56e3b1ab219",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-09-24",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "7ef89bc6-7373-4244-8b35-9e5ccb177342",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-09-25",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "651e276d-1519-491e-8c93-8c764d1b8b5e",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-09-30",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "9be15b9b-865b-444d-92b0-4782141a8839",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-10-01",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "715ba48a-4be8-4572-9901-80a2534e4c5a",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-10-02",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "3bb33c97-75af-422d-9cdf-748fa2cd1e65",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-10-07",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "15a6eb5d-7652-4486-a8bf-e59ee68f8303",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-10-08",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "d0313a8a-83ae-4c72-a7e4-ca86f44e3cde",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-10-09",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "72bab75d-6f35-4512-8945-0fcfdfd58354",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-10-14",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "25614c26-b625-4195-b73b-5740487fce50",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-10-15",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "2d9d781b-9f73-49c9-b639-edee1f9cf8ef",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-10-16",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "15106eab-7faa-4b8f-b608-1277c59c9249",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-10-21",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "81e60b64-2049-4a89-ab89-d106eeb5119c",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-10-22",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "3283bb11-1532-4cf4-8e2a-53349c1a54f2",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-10-23",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "f7abe137-0d9f-4307-b1e4-656d2e97d959",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-10-28",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "b20d7d3e-4162-4c06-bdee-cb98e7fe60e8",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-10-29",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "fb583ed9-6eab-45cc-875d-33ee1585d8ec",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-10-30",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "2ce58b5f-cc7e-499a-a950-53963d8a772e",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-11-04",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "47b1c449-d465-4ba2-a6fb-ee90dd4a3aaf",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-11-05",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "0184fd14-1252-403b-80cf-dc98efb7f52a",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-11-06",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "e75335c6-6180-440d-b1be-0fe571ea083c",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-11-11",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "3ba319c4-392c-435d-a8a4-5a3e73355e3b",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-11-12",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "0fd19a17-d926-43b5-af4a-5e15fac74985",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-11-13",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "dd51cdb2-39df-4224-8db1-ee61605af9f8",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-11-18",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "b026f5e0-d840-447a-8263-81d33644a9ff",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-11-19",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "c7cd52b2-f0c3-4ef6-9bd6-85af5f9fec78",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-11-20",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "c1f12d1a-ec19-4bc3-adfc-88f26f1822e9",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-11-25",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "6e432dae-7b9f-4887-9967-d1f9c798dd3f",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-11-26",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "b5784ffe-ce4b-4f41-95e6-166e61beca04",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-11-27",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "457a53c1-2121-49ab-878d-24fb79f05126",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-12-02",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "8ab0d1ce-feeb-42d2-a35f-9b3cb9b5e73a",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-12-03",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "dd5c443f-70fe-4a13-9a51-5d85e4f469a5",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-12-04",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "12a68e15-b395-4b8b-886b-52d6e339a5c6",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-12-09",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "16e83026-8969-47c7-88ce-177359c2181d",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-12-10",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "a5f0a9fb-e1bb-4381-b0f1-8d38b56d1bc9",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-12-11",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "ba0b9e11-9a18-4147-bc0c-da0272ba6baf",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-12-16",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "188e3d19-e3bb-4118-878a-1233a1ea2f31",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-12-17",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "4f403596-a09c-4157-893e-bb16e7ed33b8",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-12-18",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "6083694a-bc4a-4c3b-905b-d98c9ff6f92e",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-12-23",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
      {
        id: "374f4f7a-ea48-4472-a084-c8b845c3f489",
        title: "Repeat 1,Week Frequency Recurring Class",
        date: "2024-12-24",
        startTime: "22:30:00.685000",
        endTime: "23:30:00.801000",
        slotsAvailable: 344,
        address: {
          address: "Bondi Beach Bondi Beach NSW 2026, Australia",
          city: "",
          location: "SRID=4326;POINT (151.2703991 -33.8891201)",
          state: null,
          country: "",
          postCode: "",
          suburb: null,
          latitude: -33.8891201,
          longitude: 151.2703991,
        },
      },
    ],
    photos: [
      {
        uploadDocument:
          "https://fitness-mates.s3.amazonaws.com/photos/b1956e43-b51f-4a7c-856b-ceb404fc3432/kins-392779-df853480-65c1-4fa7-875a-6c4ba5a7d941.jpg?AWSAccessKeyId=AKIA5UHJQKEGZGW3J53I&Signature=Mm%2Fr0htAMvVf8%2BnfRXzg3kX2xLc%3D&Expires=1721642410",
        documentName: "pexels-jeremyperkins-392779.jpg",
      },
    ],
    isFavourite: false,
  },
  {
    createdBy: {
      id: "724f48d8-6a52-43dc-b4d4-2b298dc376b7",
      name: "sweta",
      bio: "xcgfcgh",
      tradingName: "ty",
      isAdmin: false,
      profilePicture:
        "https://fitness-mates.s3.amazonaws.com/users/724f48d86a5243dcb4d42b298dc376b7/a9ce3b4t-1721210452658.jpg",
      profilePictureExtension: "image/jpeg",
      thumbnail: null,
      userType: "Guest",
    },
    id: "30102b2b-7a06-4899-a0aa-b72d8cbddd63",
    listingType: "S",
    isActive: true,
    isPrivate: false,
    isDraft: false,
    createdAt: "2024-07-20T10:20:44.940026Z",
    difficultyLevel: ["B"],
    title: "Gender Testing",
    media: null,
    description: "asfsdg",
    thumbnail: null,
    upcomingEventDate: "2024-07-26",
    interests: [
      {
        id: "d97bcf7a-7f3f-4de8-a652-1e50a5df6205",
        title: "Crossfit",
        image:
          "https://fitness-mates.s3.amazonaws.com/interest/d97bcf7a7f3f4de8a6521e50a5df6205/2S8de7Fo-1717664339214.png",
        icon: "https://fitness-mates.s3.amazonaws.com/interest/d97bcf7a7f3f4de8a6521e50a5df6205/RCii0pYY-1717664362244.png",
      },
    ],
    isAgeLimit: false,
    minAge: null,
    listingEventType: "P",
    maxAge: null,
    gender: ["F", "M"],
    maxParticipants: 4,
    dynamicLink: "https://fitnessmates.page.link/qi65",
    price: "0.00",
    scheduleDetails: {
      date: "2024-07-26",
      address: {
        city: "",
        state: "",
        suburb: "",
        address: "200 Geary St, San Francisco, CA 94102, USA",
        country: "",
        location: "POINT(-122.4063558 37.7858186)",
        postCode: "",
      },
      endTime: "17:00:00.000000",
      startTime: "10:00:00.000000",
      singleListingType: "O",
    },
    teamSlotsAvailable: null,
    distance: 12602.667027963515,
    slotsAvailable: null,
    publishedAt: "2024-07-20T10:20:44.938510Z",
    events: [
      {
        id: "6048108d-5e21-4461-90db-c39d935ba89e",
        title: "Gender Testing",
        date: "2024-07-26",
        startTime: "10:00:00",
        endTime: "17:00:00",
        slotsAvailable: 4,
        address: {
          address: "200 Geary St, San Francisco, CA 94102, USA",
          city: "",
          location: "SRID=4326;POINT (-122.4063558 37.7858186)",
          state: null,
          country: "",
          postCode: "",
          suburb: "",
          latitude: 37.7858186,
          longitude: -122.4063558,
        },
      },
    ],
    photos: [
      {
        uploadDocument:
          "https://fitness-mates.s3.amazonaws.com/photos/30102b2b-7a06-4899-a0aa-b72d8cbddd63/IMG_0001-745126b1-6cef-400c-8b22-10cc9a4eb239.JPG?AWSAccessKeyId=AKIA5UHJQKEGZGW3J53I&Signature=OWPlmhbGKzwcxeECie%2BjN6YPY3k%3D&Expires=1721642410",
        documentName: "IMG_0001.JPG",
      },
    ],
    isFavourite: false,
  },
];
