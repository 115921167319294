/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import ApiLoader from "../../global/ApiLoader";
import Button from "../../global/Button";
import {
  daysToYearsMonths,
  fetchMoreIntrestList,
  getIntrestList,
  setLocalStorage,
  showToast,
} from "../../utils";
import endpoints from "../../utils/endpoints";
import { makeGetCall, makePatchCall } from "../../utils/Requests";
import { ROUTE_CONSTANTS } from "../../utils/RouteConstants";
import { STRING_CONSTANTS } from "../../utils/StringConstant";
import ExpCard from "../Dashboard/ExpCard";
import GlobalHeader from "../Dashboard/GlobalHeader";
import moment from "moment";

const ManageExpertises = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [interestList, setInterestList] = useState<any>([]);
  const [nextUrl, setNextUrl] = useState(null);
  const [userData, setUserData] = useState<any>(null);
  const [isBtnLoading, setIsBtnLoading] = useState(false);

  useEffect(() => {
    getIntrestList({}, setInterestList, setNextUrl, setIsLoading);
  }, []);

  let userAge = null;
  if (userData?.userDetail?.dob) {
    userAge = moment().diff(
      moment(userData?.userDetail?.dob, "YYYY-MM-DD"),
      "years"
    );
  }

  function convertToDays(years: any, months: any) {
    const daysInYear = 365;
    const daysInMonth = 30;
    const totalDays = years * daysInYear + months * daysInMonth;
    return totalDays;
  }
  useEffect(() => {
    setIsLoading(true);
    makeGetCall({ url: endpoints.profile })
      .then((res) => setUserData(res.data))
      .catch((err) => {
        showToast(err, "error");
      });
    setIsLoading(false);
  }, []);

  const handleNext = () => {
    if (interestList.length > 0) {
      const updatedExperience = interestList.map((interestObj: any) => {
        const matchingPayload =
          userData.trainerData[0]?.trainerSpecializationExperience?.find(
            (payload: any) => payload?.id === interestObj?.id
          );
        if (matchingPayload) {
          return {
            id: matchingPayload?.id,
            experience:
              convertToDays(interestObj.years, interestObj.months) || 0,
            interestInformation: interestObj?.interestInformation,
          };
        } else {
          return {
            interestId: interestObj?.id,
            experience:
              convertToDays(interestObj.years, interestObj.months) || 0,
            interestInformation: interestObj?.interestInformation,
          };
        }
      });

      setIsBtnLoading(true);
      let newObj = updatedExperience.filter(
        (obj2: any) => obj2.experience !== null && obj2.experience !== 0
      );
      console.log(newObj);
      if (newObj) {
        makePatchCall({
          url: endpoints.interest_experience,
          apiPayload: { updateExperience: newObj },
        })
          .then((res) => {
            if (res.status.code === 200) {
              showToast(res.status.message, "success");
              setLocalStorage("expData", res?.data);
              navigate(ROUTE_CONSTANTS.upload_document);
            } else {
              showToast(STRING_CONSTANTS.wrong_data_message, "error");
            }
          })
          .catch((err) => {
            showToast(err, "error");
          })
          .finally(() => {
            setIsBtnLoading(true);
          });
      } else {
        navigate(ROUTE_CONSTANTS.upload_document);
      }
    }
  };

  useEffect(() => {
    setIsLoading(true);
    if (userData) {
      let newArray = interestList?.map((obj: any) => {
        let matchingPayload =
          userData.trainerData[0]?.trainerSpecializationExperience?.find(
            (payload: any) => payload?.interestId === obj?.id
          );
        if (matchingPayload) {
          return {
            ...obj,
            id: matchingPayload?.id,
            years: daysToYearsMonths(matchingPayload.experience).years,
            months: daysToYearsMonths(matchingPayload.experience).months,
            interestInformation: matchingPayload?.interestInformation,
          };
        } else {
          return {
            ...obj,
          };
        }
      });
      setIsLoading(false);
      setInterestList(newArray);
    }
  }, [userData, nextUrl]);

  const updateExpById = ({ id, value, type }: any) => {
    const objectToUpdate = interestList.find((obj: any) => obj?.id === id);
    if (objectToUpdate) {
      if (type === "year") {
        objectToUpdate.years = value;
      } else if (type === "month") {
        objectToUpdate.months = value;
      } else if (type === "interestInformation") {
        objectToUpdate.interestInformation = value;
      }
    }
    setInterestList([...interestList]);
  };

  return (
    <>
      <GlobalHeader />
      <div className="manage_profile expertise_wrapper">
        <div style={{ marginTop: "94px" }}>
          <div className="profile_box mt-3 mt-md-0">
            <div className="title">
              Make your journey count
              <img
                src="/static/backarrow.svg"
                alt=""
                className="back_arrow"
                onClick={() => navigate(ROUTE_CONSTANTS.business_intrest)}
              />
            </div>
            <div
              className="sub_title"
              style={{ maxWidth: "473px", margin: "0 auto" }}
            >
              {STRING_CONSTANTS.expertise_desc}
            </div>
            <div className="progressbar">
              <span style={{ maxWidth: "75%" }}></span>
            </div>

            <ul className="business_intrest pt-0 ">
              {isLoading ? (
                <ApiLoader />
              ) : interestList.length ? (
                <InfiniteScroll
                  className="expertise_web pt-0"
                  dataLength={interestList?.length}
                  next={() =>
                    fetchMoreIntrestList(
                      nextUrl,
                      interestList,
                      setNextUrl,
                      setInterestList,
                      setIsLoading
                    )
                  }
                  hasMore={nextUrl ? true : false}
                  loader={<ApiLoader />}
                >
                  {interestList.map((item: any, index: number) => {
                    return (
                      <li key={index}>
                        <ExpCard
                          updateExpById={updateExpById}
                          item={item}
                          userAge={userAge}
                        />
                      </li>
                    );
                  })}
                </InfiniteScroll>
              ) : null}
            </ul>

            <div className="continue_btn ">
              <Button
                btnTxt="Continue"
                onClick={handleNext}
                isLoading={isBtnLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageExpertises;
