import { Link } from "react-router-dom";
import Button from "../../global/Button";
import { isUserAuthenticated } from "../../utils";
import { ROUTE_CONSTANTS } from "../../utils/RouteConstants";

import GlobalHeader from "./GlobalHeader";

const Dashboard = () => {
  return (
    <>
      <div className=" empty-container ">
        <GlobalHeader />
        <div className="coming_soon">
          <div className="empty-info">
            Good things coming soon!
            <br />
            <br />
            <br />
            <br />
            <Link
              to={
                isUserAuthenticated()
                  ? ROUTE_CONSTANTS.create_listing
                  : ROUTE_CONSTANTS.login
              }
            >
              <Button btnTxt="Create listing" />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
