import React from "react";
import RouteStack from "./routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./assets/scss/main.scss";
import { useLocation, useNavigate } from "react-router-dom";

const App = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const attribute = queryParams.get("attribute");
  if (attribute) {
    localStorage.setItem("attribute", attribute);
    navigate(`/`);
  }
  // if (!localStorage.getItem('isFirstTimeUser')) {
  //   localStorage.setItem('isFirstTimeUser', 'yes');
  // }
  return (
    <React.Fragment>
      <RouteStack />
      <ToastContainer />
    </React.Fragment>
  );
};

export default App;
