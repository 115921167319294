import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

const BorderlessTable = ({ data }) => {
  return (
    <TableContainer component={Paper} style={{ boxShadow: "none" }}>
      <Table>
        <TableHead
          sx={{
            marginBottom: "15px",
          }}
        >
          <TableRow>
            <TableCell
              sx={{
                border: "none",
                fontSize: "12px",
                fontWeight: "500",
                lineHeight: "22px",
                color: "#6C6D6F",
              }}
            >
              Name
            </TableCell>
            <TableCell
              sx={{
                border: "none",
                fontSize: "12px",
                fontWeight: "500",
                lineHeight: "22px",
                color: "#6C6D6F",
              }}
            >
              Email
            </TableCell>
            <TableCell
              sx={{
                border: "none",
                fontSize: "12px",
                fontWeight: "500",
                lineHeight: "22px",
                color: "#6C6D6F",
              }}
            >
              Phone Number
            </TableCell>
            <TableCell
              sx={{
                border: "none",
                fontSize: "12px",
                fontWeight: "500",
                lineHeight: "22px",
                color: "#6C6D6F",
              }}
            >
              Gender
            </TableCell>
            <TableCell
              sx={{
                border: "none",
                fontSize: "12px",
                fontWeight: "500",
                lineHeight: "22px",
                color: "#6C6D6F",
              }}
            >
              Age
            </TableCell>
            <TableCell
              sx={{
                border: "none",
                fontSize: "12px",
                fontWeight: "500",
                lineHeight: "22px",
                color: "#6C6D6F",
              }}
            >
              Description
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.length > 0 ? (
            data?.map((row:any, index:any) => (
              <TableRow key={row.id} style={{ borderBottom: "none" }}>
                <TableCell
                  style={{
                    border: "none",
                    fontSize: "16px",
                    fontWeight: "500",
                    lineHeight: "22px",
                    color: "#000000",
                  }}
                >{`${index + 1}. ${row?.name}`}</TableCell>
                <TableCell
                  style={{
                    border: "none",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "22px",
                    color: "#000000",
                  }}
                >
                  {row?.email ? row?.email  : "--"}
                </TableCell>
                <TableCell
                  style={{
                    border: "none",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "22px",
                    color: "#000000",
                  }}
                >
                  { row?.contactNumber ? `+ ${row?.contactNumber}`  : "--" }
                </TableCell>
                <TableCell
                  style={{
                    border: "none",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "22px",
                    color: "#000000",
                  }}
                >
                  {row.gender === "F"
                    ? "Female"
                    : row.gender === "M"
                    ? "Male"
                    : "Prefer not to say"}
                </TableCell>
                <TableCell
                  style={{
                    border: "none",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "22px",
                    color: "#000000",
                  }}
                >
                  {row.age || "--"}
                </TableCell>
                <TableCell
                  style={{
                    border: "none",
                    width: "299px",
                    fontSize: "12px",
                    fontWeight: "400",
                    lineHeight: "22px",
                    color: "#000000",
                  }}
                >
                  {row.description || "--"}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell
                colSpan={6}
                style={{ textAlign: "center", border: "none" }}
              >
                No Request Found
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BorderlessTable;
