import React, { useEffect } from "react";
import "./style.scss";
import Button from "../../../global/Button";
import MeetingLinkInput from "../MeetingLinkInput/MeetingLinkInput";
import { apiConstants, meetingLinkRegex } from "../../../utils/Constants";
import { showToast } from "../../../utils";
import { FREQUENCY_TYPE, RECURRING_TYPE, SCHEDULE_TYPE } from "../../../types";
import moment from "moment";

type Props = {
  [key: string]: any;
};

const ScheduleListingMeeting = (props: Props) => {
  const {
    watch,
    handleSetValue,
    setScheduleLisiting,
    data = [],
    setScheduleData,
  } = props;

  const getDateFormat = (date) => {
    return moment(new Date(date)).format("YYYY-MM-DD");
  };
  const getTimeFormat = (time) => {
    return moment(time).format("HH:mm:ss.SSSSSS");
  };

  const getCustomDateFormat = (dates) => {
    return dates?.map((i) => moment(new Date(i)).format("YYYY-MM-DD"));
  };

  const handleSave = () => {
    const filterVal = watch(apiConstants.virtual_links_multiple)?.filter(
      (val) => !val?.virtual_link
    );
    if (!filterVal || filterVal?.length > 0) {
      showToast(
        "Please fill all the meeting links with respective dates",
        "error"
      );
    } else {
      const httpsUrl = watch(apiConstants.virtual_links_multiple)?.filter(
        (val) => {
          let regex = /^(https?:\/\/)/;
          return val?.virtual_link && !regex.test(val?.virtual_link);
        }
      );
      if (!httpsUrl || httpsUrl?.length > 0) {
        showToast(
          "Please use the prefix before the URL i.e., “https://”",
          "error"
        );
      } else {
        const filterVal = watch(apiConstants.virtual_links_multiple)?.filter(
          (val) => {
            let regex =
              /^(https:\/\/meet\.google\.com(\/[a-zA-Z0-9-?&=]+)?|https:\/\/([a-z0-9]+\.|)zoom\.us\/j\/[a-zA-Z0-9?&=]+|https:\/\/teams\.microsoft\.com\/l\/meetup-join\/[a-zA-Z0-9-?&=]+)$/;
            return !regex.test(val?.virtual_link);
          }
        );
        if (!filterVal || filterVal?.length > 0) {
          showToast(
            "Please include only web URLs for Google Meet, Zoom, and Microsoft Teams here.",
            "error"
          );
        } else {
          if (
            watch(apiConstants.schedule_type) === SCHEDULE_TYPE.O &&
            watch(apiConstants.event_date) &&
            watch(apiConstants.event_start_time) &&
            watch(apiConstants.event_end_time)
          ) {
            setScheduleData({
              type: watch(apiConstants.schedule_type) || SCHEDULE_TYPE.O,
              oneTimeDate: getDateFormat(watch(apiConstants.event_date)),
              startTime: getTimeFormat(watch(apiConstants.event_start_time)),
              endTime: getTimeFormat(watch(apiConstants.event_end_time)),
              virtualLinksMultiple:
                watch(apiConstants?.virtual_links_multiple) || [],
            });
          } else if (watch(apiConstants.schedule_type) === SCHEDULE_TYPE.R) {
            if (
              watch(apiConstants.recurring_type) === RECURRING_TYPE.C &&
              watch(apiConstants.event_start_time) &&
              watch(apiConstants.event_end_time)
            ) {
              setScheduleData({
                type: watch(apiConstants.schedule_type),
                recurringType: watch(apiConstants.recurring_type),
                customDates: getCustomDateFormat(
                  watch(apiConstants.event_custom_date)
                ),
                startTime: getTimeFormat(watch(apiConstants.event_start_time)),
                endTime: getTimeFormat(watch(apiConstants.event_end_time)),
                virtualLinksMultiple:
                  watch(apiConstants?.virtual_links_multiple) || [],
              });
            } else {
              if (watch(apiConstants.recur_frequency) === FREQUENCY_TYPE.D) {
                setScheduleData({
                  type: watch(apiConstants.schedule_type),
                  recurringType: watch(apiConstants.recurring_type),
                  startTime: getTimeFormat(
                    watch(apiConstants.event_start_time)
                  ),
                  endTime: getTimeFormat(watch(apiConstants.event_end_time)),
                  startDate: getDateFormat(
                    watch(apiConstants.event_start_date)
                  ),
                  endDate: getDateFormat(watch(apiConstants.event_end_date)),
                  repeatNumber: watch(apiConstants.repeat_every) || 1,
                  recurringRepeatType: watch(apiConstants.recur_frequency),
                  virtualLinksMultiple:
                    watch(apiConstants?.virtual_links_multiple) || [],
                });
              } else if (
                watch(apiConstants.recur_frequency) === FREQUENCY_TYPE.M
              ) {
                setScheduleData({
                  type: watch(apiConstants.schedule_type),
                  recurringType: watch(apiConstants.recurring_type),
                  startTime: getTimeFormat(
                    watch(apiConstants.event_start_time)
                  ),
                  endTime: getTimeFormat(watch(apiConstants.event_end_time)),
                  startDate: getDateFormat(
                    watch(apiConstants.event_start_date)
                  ),
                  endDate: getDateFormat(watch(apiConstants.event_end_date)),
                  repeatNumber: watch(apiConstants.repeat_every) || 1,
                  recurringRepeatType: watch(apiConstants.recur_frequency),
                  monthlyRepeatType: /on\s+Day/i.test(
                    watch(apiConstants.monthly_frequency)
                  )
                    ? "day"
                    : "week",
                  monthlyFrequencyData: watch(apiConstants.monthly_frequency),
                  virtualLinksMultiple:
                    watch(apiConstants?.virtual_links_multiple) || [],
                });
              } else if (
                watch(apiConstants.recur_frequency) === FREQUENCY_TYPE.W
              ) {
                setScheduleData({
                  type: watch(apiConstants.schedule_type),
                  recurringType: watch(apiConstants.recurring_type),
                  startTime: getTimeFormat(
                    watch(apiConstants.event_start_time)
                  ),
                  endTime: getTimeFormat(watch(apiConstants.event_end_time)),
                  startDate: getDateFormat(
                    watch(apiConstants.event_start_date)
                  ),
                  endDate: getDateFormat(watch(apiConstants.event_end_date)),
                  repeatNumber: watch(apiConstants.repeat_every) || 1,
                  recurringRepeatType: watch(apiConstants.recur_frequency),
                  weekDays: watch(apiConstants.selected_days),
                  virtualLinksMultiple:
                    watch(apiConstants?.virtual_links_multiple) || [],
                });
              }
            }
          }
          handleSetValue(apiConstants.has_schedule_date, true);
          setScheduleLisiting(false);
        }
      }
    }
  };
  return (
    <div className="schedule_meetings_wrap">
      <div className="meeting_header">
        <div className="heading">Add meeting links</div>
        <div className="sub_heading">
          Please provide your meeting links here. Make sure to include only web
          URLs for Google Meet, Zoom, and Microsoft Teams.
        </div>
      </div>
      <div className="meeting_body">
        <div className="links_container">
          {data &&
            data?.map((item, index) => (
              <MeetingLinkInput
                key={item?.date || index}
                data={item}
                index={index}
                handleSetValue={handleSetValue}
                watch={watch}
              />
            ))}
        </div>
      </div>
      <div className="meeting_footer">
        <Button
          btnTxt="Cancel"
          className="simple"
          onClick={() => setScheduleLisiting(false)}
        />
        <Button btnTxt="Save" className="filled" onClick={handleSave} />
      </div>
    </div>
  );
};

export default ScheduleListingMeeting;
