const LocationDeniedPopup = (props: any) => {
  const { onClose } = props;

  return (
    <div className={"modal show"}>
      <div className="modal-section draft_popup">
        <div className="modal-top-part">
          <button className="close">
            <img
              src="/static/dismiss-cross-round-black.svg"
              alt=""
              onClick={onClose}
            />
          </button>
          <p>
            We kindly ask for your location to enhance your app experience;
            however, we regret to inform you that without this information, we
            are unable to proceed further.
          </p>
        </div>
      </div>
    </div>
  );
};

export default LocationDeniedPopup;
