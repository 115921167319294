import LocationIcon from "../../assets/img/icons/LocationIcon";
import TimeIcon from "../../assets/img/icons/TimeIcon";
import IconTeam from "../../assets/img/createListingIcon/icon-team.svg";
import IconPackage from "../../assets/img/createListingIcon/icon-package.svg";
import IconPhysicalGray from "../../assets/img/createListingIcon/physicalListingType.svg";
import IconVirtualGray from "../../assets/img/createListingIcon/virtualListingType.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import IconSession from "../../assets/img/createListingIcon/icon-session.png";
import moment from "moment";
import { Link } from "react-router-dom";

const CancelledBookingCard = ({ item, setSideList, setMapCenter }) => {
  /* const handligSideAndCenter = () => {
    setSideList(true);
    setMapCenter({ lat: Number(item?.latitude), lng: Number(item?.longitude) });
  }; */

  return (
    <>
      {item && (
        <div className="my-schedule-wrap" style={{ paddingRight: "20px" }}>
          <div className="left-content" style={{ alignItems: "start" }}>
            <div style={{ minWidth: "50px" }}>
              <div className="day">
                {item.date === moment().format("YYYY-MM-DD") ? (
                  <span>Today</span>
                ) : (
                  moment(item.date).format("dddd").substring(0, 3)
                )}
              </div>
              <div className="date me-3">{item.date.split("-")[2]}</div>
            </div>
            <div className="vertical-divider" />
            <div>
              <div
                style={{ display: "flex", gap: "16px", alignItems: "center" }}
              >
                <Link
                  to={`/listing-two/${item.event.listing.id}`}
                  style={{ textDecoration: "none" }}
                >
                  <div className="title">{item.title}</div>
                </Link>
                <div
                  className="d-none d-md-flex"
                  style={{
                    backgroundColor:
                      item?.listingType === "P" ? "#F3F3F3" : "#EE7830",
                    padding: "2px 16px 2px 12px",
                    borderRadius: "8px",
                    color: item?.listingType === "P" ? "#EE7830" : "#FFFFFF",
                    fontSize: "14px",
                    fontWeight: "500",
                    fontFamily: "Poppins",
                    display: "flex",
                    gap: "4px",
                  }}
                >
                  {item?.listingType === "S" ||
                  item?.listingType === "SPECIAL" ? (
                    <img src={IconSession} alt="session" />
                  ) : item?.listingType === "P" ? (
                    <img src={IconPackage} alt="package" />
                  ) : (
                    <img src={IconTeam} alt="team" />
                  )}
                  {item?.listingType === "S" ||
                  item?.listingType === "SPECIAL" ? (
                    <span>Session</span>
                  ) : item?.listingType === "P" ? (
                    <span>Package</span>
                  ) : (
                    <span>Team</span>
                  )}
                </div>
              </div>
              <div className="d-md-none sub-content-wrap">
                <div
                  className="d-flex d-md-none"
                  style={{
                    backgroundColor:
                      item?.listingType === "P" ? "#F3F3F3" : "#EE7830",
                    padding: "2px 8px 2px 8px",
                    borderRadius: "8px",
                    color: item?.listingType === "P" ? "#EE7830" : "#FFFFFF",
                    fontSize: "12px",
                    fontWeight: "500",
                    lineHeight: "16px",
                    fontFamily: "Poppins",
                    display: "flex",
                    gap: "4px",
                    alignItems: "center",
                  }}
                >
                  {item?.listingType === "S" ||
                  item?.listingType === "SPECIAL" ? (
                    <img
                      src={IconSession}
                      alt="session"
                      height="13px"
                      width="13px"
                    />
                  ) : item?.listingType === "P" ? (
                    <img
                      src={IconPackage}
                      alt="package"
                      height="13px"
                      width="13px"
                    />
                  ) : (
                    <img src={IconTeam} alt="team" height="13px" width="13px" />
                  )}
                  {item?.listingType === "S" ||
                  item?.listingType === "SPECIAL" ? (
                    <span>Session</span>
                  ) : item?.listingType === "P" ? (
                    <span>Package</span>
                  ) : (
                    <span>Team</span>
                  )}
                </div>
                <div
                  style={{
                    borderRadius: "12px",
                    padding: "4px 10px",
                    backgroundColor: "#F3F3F3",
                    fontFamily: "Poppins",
                    fontSize: "12px",
                    fontWeight: "400",
                  }}
                >
                  <img
                    src={
                      item.listingEventType === "V"
                        ? IconVirtualGray
                        : IconPhysicalGray
                    }
                    alt="icon"
                  />
                  <span style={{ marginLeft: "6px" }}>
                    {item.listingEventType === "V" ? "Virtual" : "Physical"}{" "}
                  </span>
                </div>
                <div className="content-items">
                  <span>
                    <TimeIcon />
                  </span>
                  <span className="sub-title" style={{ color: "#25272D" }}>
                    {moment(item?.startTime?.substring(0, 5), ["HH:mm"]).format(
                      "hh:mmA"
                    )}{" "}
                    -{" "}
                    {moment(item?.endTime?.substring(0, 5), ["HH:mm"]).format(
                      "hh:mmA"
                    )}
                  </span>
                </div>
                {item.listingEventType !== "V" ? (
                  <div className="content-items" style={{ color: "#6C6D6F" }}>
                    <span>
                      <LocationIcon />
                    </span>
                    <div className="t-sub-title-box">
                      <span className="sub-title">{item.address}</span>
                    </div>
                    <span className="map-link" style={{ color: "#6C6D6F" }}>
                      Open maps
                    </span>
                  </div>
                ) : (
                  <div
                    className="content-items"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div
                      className="open-maps-box-booking-detail"
                      style={{ cursor: "pointer" }}
                    >
                      <img alt="" src="/static/meet.svg" />
                      <span
                        className="map-link"
                        style={{
                          color: "#25272D",
                          marginLeft: "4px",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          window.open(item.virtualLink);
                        }}
                      >
                        Meet Link
                      </span>
                      <CopyToClipboard
                        // onClick={(e) => e.stopPropagation()}
                        // onCopy={() => showToast("Copied!!", "success")}
                        text={item.virtualLink}
                        style={{ marginLeft: "7px" }}
                      >
                        <img
                          alt=""
                          src="/static/copyblue.svg"
                          className="copy-btn"
                        />
                      </CopyToClipboard>
                    </div>
                  </div>
                )}
              </div>
              <div className="d-none d-md-flex sub-content-wrap">
                <div className="d-flex">
                  <span className="sub-title" style={{ color: "#25272D" }}>
                    Event Type:
                  </span>
                  <div
                    className="map-link"
                    style={{
                      textDecoration: "none",
                      marginLeft: "4px",
                      color: "#6C6D6F",
                    }}
                  >
                    <span>
                      {item.listingEventType === "V" ? "Virtual" : "Physical"}
                    </span>
                    <img
                      style={{
                        marginLeft: "4px",
                        height: "16px",
                        width: "16px",
                      }}
                      src={
                        item.listingEventType === "V"
                          ? IconVirtualGray
                          : IconPhysicalGray
                      }
                    />
                  </div>
                </div>

                {item.listingEventType !== "V" ? (
                  <div className="content-items" style={{ color: "#6C6D6F" }}>
                    <span>
                      <LocationIcon />
                    </span>
                    <div className="t-sub-title-box">
                      <span className="sub-title">{item.address}</span>
                    </div>
                    <span className="map-link" style={{ color: "#6C6D6F" }}>
                      Open maps
                    </span>
                  </div>
                ) : (
                  <div
                    className="content-items"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div
                      className="open-maps-box-booking-detail"
                      style={{ cursor: "pointer" }}
                    >
                      <img alt="" src="/static/meet.svg" />
                      <span
                        className="map-link"
                        style={{
                          color: "#25272D",
                          marginLeft: "4px",
                        }}
                        // onClick={(e) => {
                        //   e.stopPropagation();
                        //   window.open(item.virtualLink);
                        // }}
                      >
                        Meet Link
                      </span>
                      <CopyToClipboard
                        // onClick={(e) => e.stopPropagation()}
                        // onCopy={() => showToast("Copied!!", "success")}
                        text={item.virtualLink}
                        style={{ marginLeft: "7px" }}
                      >
                        <img
                          alt=""
                          src="/static/copyblue.svg"
                          className="copy-btn"
                        />
                      </CopyToClipboard>
                    </div>
                  </div>
                )}
                <div className="content-items">
                  <span>
                    <TimeIcon />
                  </span>
                  <span className="sub-title" style={{ color: "#6C6D6F" }}>
                    {moment(item?.startTime?.substring(0, 5), ["HH:mm"]).format(
                      "hh:mmA"
                    )}{" "}
                    -{" "}
                    {moment(item?.endTime?.substring(0, 5), ["HH:mm"]).format(
                      "hh:mmA"
                    )}
                  </span>
                </div>
              </div>
              <div
                className="content-items d-none d-md-flex"
                style={{ marginTop: "14px" }}
              >
                <span className="sub-title">
                  Cancelled on {moment(item?.cancelDate).format("ddd D MMM")}
                </span>
              </div>
            </div>
          </div>
          <div
            className="d-none d-md-flex"
            style={{
              height: "105px",
              minWidth: "105px",
              border:
                item?.status === "RF"
                  ? "1px solid #29CC6A"
                  : "1px solid #EE7830",
              borderRadius: "8px",
              padding: "8px 13.5px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              className="title"
              style={{ fontSize: "14px", color: "#8F939A" }}
            >
              Refund:
            </div>
            <div
              className="status"
              style={{
                fontSize: "14px",
                color: `${item?.status === "RF" ? "#29CC6A" : "#EE7830"}`,
              }}
            >
              {item?.status === "RF" ? "Done" : "In Progress"}
            </div>
            <div
              style={{
                fontSize: "20px",
                color: "#25272D",
                fontWeight: "500",
                lineHeight: "28px",
              }}
            >{`$${item?.refundedTotalAmount}`}</div>
          </div>
          <div
            className="d-flex d-md-none"
            style={{ gap: "9px", marginTop: "10px" }}
          >
            <div className="content-items" style={{ marginTop: "12px" }}>
              <div className="sub-title">Cancelled on</div>
              <div className="sub-title">
                {moment(item?.cancelDate).format("ddd D MMM")}
              </div>
            </div>
            <div
              style={{
                minWidth: "176px",
                border:
                  item?.status === "RF"
                    ? "1px solid #29CC6A"
                    : "1px solid #EE7830",
                borderRadius: "8px",
                padding: "8px 13.5px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div className="d-flex align-items-center">
                <div
                  className="title"
                  style={{ fontSize: "14px", color: "#8F939A" }}
                >
                  Refund:
                </div>
                <div
                  style={{
                    fontSize: "20px",
                    color: "#25272D",
                    fontWeight: "500",
                    lineHeight: "28px",
                    marginLeft: "4px",
                  }}
                >{`$${item?.refundedTotalAmount}`}</div>
              </div>
              <div
                className="status"
                style={{
                  fontSize: "14px",
                  color: `${item?.status === "RF" ? "#29CC6A" : "#EE7830"}`,
                }}
              >
                {item?.status === "RF" ? "Done" : "In Progress"}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CancelledBookingCard;
