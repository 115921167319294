import { Fragment } from "react";
import moment from "moment";
import { FREQUENCY_TYPE, RECURRING_TYPE, SCHEDULE_TYPE } from "../types";
import { dateFormatter } from "../utils";
import { STRING_CONSTANTS } from "../utils/StringConstant";
import { apiConstants } from "../utils/Constants";

export const initialScheduleData = {
  scheduleType: SCHEDULE_TYPE.O, // O/R
  recurringDetails: {
    recurringType: "customday", // customday/recurring
    customDaysDetails: {
      customRecurDates: "",
      startTime: "",
      endTime: "",
    },
    repeatDetails: {
      repeatEvery: 1,
      frequencyType: "", // "Day", "Week", "Month"
      weekFrequency: "", // for week type
      monthFrequency: "",
      startDate: "",
      endDate: "",
      startTime: "",
      endTime: "",
    },
  },
  oneTimeDetails: {
    eventDate: "",
    startTime: "",
    endTime: "",
  },
  virtualLinksMultiple: [],
};

export const handleShowScheduleDate = (scheduleData) => {
  if (
    scheduleData?.type === SCHEDULE_TYPE.O &&
    scheduleData?.oneTimeDate &&
    scheduleData?.startTime &&
    scheduleData?.endTime
  ) {
    return (
      <>
        <p>{dateFormatter(scheduleData?.oneTimeDate, "MMMM Do, YYYY")}</p>
        <small>
          {`${moment(scheduleData?.startTime, "HH:mm").format(
            "hh:mm A"
          )} - ${moment(scheduleData?.endTime, "HH:mm").format("hh:mm A")}`}
        </small>
      </>
    );
  } else if (
    scheduleData?.type === SCHEDULE_TYPE.R &&
    scheduleData?.recurringType === RECURRING_TYPE.C
  ) {
    return (
      <>
        <p>
          {scheduleData?.customDates?.length > 1
            ? `${scheduleData?.customDates?.length} days selected`
            : scheduleData?.customDates?.length === 1
            ? `${scheduleData?.customDates?.length} day selected`
            : ""}
        </p>
        <small>
          {`${moment(scheduleData?.startTime, "HH:mm").format(
            "hh:mm A"
          )} - ${moment(scheduleData?.endTime, "HH:mm").format("hh:mm A")}`}
        </small>
      </>
    );
  } else if (
    scheduleData?.type === SCHEDULE_TYPE.R &&
    scheduleData?.recurringType === SCHEDULE_TYPE.R &&
    scheduleData?.recurringRepeatType === FREQUENCY_TYPE.W
  ) {
    return (
      <>
        <p>
          Every {scheduleData?.repeatNumber} {scheduleData?.recurringRepeatType}{" "}
        </p>
        <small>
          {scheduleData?.weekDays?.length > 0
            ? scheduleData?.weekDays?.map((item: any, index: number) => {
                return (
                  <Fragment key={index}> {item.slice(0, 3) + " "}</Fragment>
                );
              })
            : null}
          <small>
            {`${moment(scheduleData?.startTime, "HH:mm").format(
              "hh:mm A"
            )} - ${moment(scheduleData?.endTime, "HH:mm").format("hh:mm A")}`}
          </small>
        </small>
      </>
    );
  } else if (
    scheduleData?.type === SCHEDULE_TYPE.R &&
    scheduleData?.recurringType === SCHEDULE_TYPE.R &&
    scheduleData?.recurringRepeatType === FREQUENCY_TYPE.D
  ) {
    return (
      <>
        <p>
          Every {scheduleData?.repeatNumber} {scheduleData?.recurringRepeatType}{" "}
        </p>
        <small>
          {`${moment(scheduleData?.startTime, "HH:mm").format(
            "hh:mm A"
          )} - ${moment(scheduleData?.endTime, "HH:mm").format("hh:mm A")}`}
        </small>
      </>
    );
  } else if (
    scheduleData?.type === SCHEDULE_TYPE.R &&
    scheduleData?.recurringType === RECURRING_TYPE.R &&
    scheduleData?.recurringRepeatType === FREQUENCY_TYPE.M
  ) {
    return (
      <>
        <p>
          Every {scheduleData?.repeatNumber} {scheduleData?.recurringRepeatType}{" "}
          {scheduleData?.monthlyFrequencyData?.split(" ")?.splice(1)?.join(" ")}
        </p>
        <small>
          {`${moment(scheduleData?.startTime, "HH:mm").format(
            "hh:mm A"
          )} - ${moment(scheduleData?.endTime, "HH:mm").format("hh:mm A")}`}
        </small>
      </>
    );
  } else {
    return (
      <p style={{ color: "#8F939A" }}>
        {STRING_CONSTANTS.schedule_date_placeholder}
      </p>
    );
  }
};

const getDateFormat = (date) => {
  return new Date(date);
};
const getTimeFormat = (time) => {
  return new Date(time);
};

const getCustomDateFormat = (dates) => {
  return dates?.map((i) => new Date(i));
};

// reset schedule data

export const resetScheduleData = (scheduleData, handleSetValue) => {
  handleSetValue(
    apiConstants.schedule_type,
    scheduleData?.type || SCHEDULE_TYPE.O
  );
  if (
    scheduleData?.scheduleType === SCHEDULE_TYPE.O &&
    scheduleData?.oneTimeDate &&
    scheduleData?.startTime &&
    scheduleData?.endTime
  ) {
    handleSetValue(
      apiConstants.event_date,
      getDateFormat(scheduleData?.oneTimeDate)
    );
    handleSetValue(
      apiConstants.event_start_time,
      getTimeFormat(scheduleData?.oneTimeDate + " " + scheduleData?.startTime)
    );
    handleSetValue(
      apiConstants.event_end_time,
      getTimeFormat(scheduleData?.oneTimeDate + " " + scheduleData?.endTime)
    );
  } else {
    handleSetValue(
      apiConstants.recurring_type,
      scheduleData?.recurringType || "customday"
    );

    if (
      scheduleData?.recurringType === "customday" &&
      scheduleData?.startTime &&
      scheduleData?.endTime &&
      scheduleData?.customDates?.length
    ) {
      handleSetValue(apiConstants.event_custom_date, scheduleData?.customDates);
      handleSetValue(
        apiConstants.event_start_time,
        getTimeFormat(
          scheduleData?.customDates[0] + " " + scheduleData?.startTime
        )
      );
      handleSetValue(
        apiConstants.event_end_time,
        getTimeFormat(
          scheduleData?.customDates[0] + " " + scheduleData?.endTime
        )
      );
    } else if (scheduleData?.recurringType === "recurring") {
      handleSetValue(
        apiConstants.repeat_every,
        scheduleData?.repeatNumber || 1
      );

      handleSetValue(
        apiConstants.recur_frequency,
        scheduleData?.recurringRepeatType
      );

      if (scheduleData?.recurringRepeatType === "Week") {
        handleSetValue(
          apiConstants.selected_days,
          scheduleData?.weekDays || []
        );
      }
      if (scheduleData?.recurringRepeatType === "Month") {
        handleSetValue(
          apiConstants.monthly_frequency,
          scheduleData?.monthlyFrequencyData
        );
      }

      handleSetValue(
        apiConstants.event_start_date,
        getDateFormat(scheduleData?.startDate)
      );
      handleSetValue(
        apiConstants.event_end_date,
        getDateFormat(scheduleData?.endDate)
      );

      handleSetValue(
        apiConstants.event_start_time,
        getTimeFormat(scheduleData?.startDate + " " + scheduleData?.startTime)
      );
      handleSetValue(
        apiConstants.event_end_time,
        getTimeFormat(scheduleData?.startDate + " " + scheduleData?.endTime)
      );
    }
  }
};
