// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.read_more_container {
  transition: all 0.3s ease-in-out; }
  .read_more_container .read_more_btn {
    color: #25272D;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.3s ease-in-out; }
    .read_more_container .read_more_btn:hover {
      color: #EE7830; }
`, "",{"version":3,"sources":["webpack://./src/components/ConnectUserProfile/ReadMore/style.scss"],"names":[],"mappings":"AAAA;EACI,gCAAgC,EAAA;EADpC;IAIQ,cAAc;IACd,gBAAgB;IAChB,eAAe;IACf,gCAAgC,EAAA;IAPxC;MAUY,cAAc,EAAA","sourcesContent":[".read_more_container {\n    transition: all 0.3s ease-in-out;\n\n    .read_more_btn {\n        color: #25272D;\n        font-weight: 500;\n        cursor: pointer;\n        transition: all 0.3s ease-in-out;\n\n        &:hover {\n            color: #EE7830;\n        }\n    }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
