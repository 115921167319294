import React from "react";

const ApiLoaderNew = () => {
    return (
        <div
            className="lds-ring"
            style={{ margin: "0 auto", display: "flex", justifyContent: "center", position: 'absolute', top: '30%', left: '50%' }}
        >
            <img style={{ width: "50px" }} src="/static/spinner.svg" alt="loader" />
        </div>
    );
};

export default ApiLoaderNew;
