import { useEffect } from "react";
import Events from "../components/Dashboard/Event/Events";
import Events2 from "../components/Dashboard/Event/Events2";

const EventsPage = () => {

  return (
    <>
      <Events2 />
    </>
  );
};

export default EventsPage;
