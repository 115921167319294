import { Navigate, Outlet } from "react-router-dom";
import { getItemFromStore } from "../utils";
import { ROUTE_CONSTANTS } from "../utils/RouteConstants";
import IntermediateRoutes from "./IntermediateRoutes";

const UnAuthRoutes = () => {
  const isAuthenticated = getItemFromStore("access_token") ? true : false;
  const isProfileComplete = getItemFromStore("isProfileComplete")
    ? true
    : false;

  const isAuthAndProfileComplete = isAuthenticated && isProfileComplete;

  return !isAuthenticated ? (
    <Outlet />
  ) : isAuthAndProfileComplete ? (
    <Navigate to={ROUTE_CONSTANTS.dashboard} />
  ) : (
    <IntermediateRoutes />
  );
};

export default UnAuthRoutes;
