import { STRING_CONSTANTS } from "../utils/StringConstant";
import Button from "./Button";
import CommonOutlineButton from "./CommonOutlineButton";
import { makeDeleteCall } from "../utils/Requests";
import endpoints from "../utils/endpoints";
import { showToast } from "../utils";

interface RemoveConnection {
  onOk: any;
  onCancel: any;
  userProfile: any;
  setShowRemoveConnection: any;
}
const RemoveConnectionModal = (props: RemoveConnection) => {
  const {
    onCancel,
    userProfile,
    setShowRemoveConnection,
    onOk = () => null,
  } = props;

  const removeConnection = () => {
    makeDeleteCall({
      url: `${endpoints.remove_connection}/${userProfile?.connectionStatus?.connectionId}`,
    }).then((res) => {
      showToast("Connection Removed", "success");
      setShowRemoveConnection(false);
      onOk();
    });
  };

  return (
    <div className={"modal show"}>
      <div
        className="modal-section log_out_modal"
        style={{
          padding: "27px 20px",
          maxWidth: "390px",
          margin: 0,
        }}
      >
        <div className="modal-top-part" style={{ position: "relative" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "20px",
            }}
          >
            <div
              className="profile-image"
              style={{
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "contain",
                borderRadius: "100%",
                height: "75px",
                width: "75px",
                top: -50.5,
                outline: "5px solid #FFFFFF",
                boxShadow: "0px 3px 20px rgba(0, 0, 0, 0.5)",
                backgroundImage: `url(${
                  userProfile?.profilePicture
                    ? userProfile?.profilePicture
                    : "/static/dummyimg.svg"
                })`,
              }}
            ></div>
          </div>
          <div
            style={{
              fontSize: "20px",
              fontFamily: "Poppins",
              fontWeight: 700,
              lineHeight: "24px",
              textAlign: "center",
              color: "#25272D",
            }}
          >
            {STRING_CONSTANTS.remove_connection}
          </div>
          <div
            style={{
              fontFamily: "Poppins",
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "24px",
              textAlign: "center",
              color: "#6C6D6F",
              marginTop: "16px",
              marginBottom: "24px",
            }}
          >
            Are you sure you want to remove Kristin from your connection?
          </div>
        </div>
        <div className="button_option">
          <Button
            btnTxt="Confirm"
            className={"backtologin"}
            onClick={() => removeConnection()}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              fontSize: "18px",
            }}
          >
            <CommonOutlineButton
              onClick={onCancel}
              image=""
              text="Cancel"
              className={"common_btn_grey"}
              width="100%"
              height="fit-content"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RemoveConnectionModal;
