import { useForm } from "react-hook-form";
import Button from "../../global/Button";
import InputField from "../../global/InputField";
import { makePostCall } from "../../utils/Requests";
import apiConfig from "../../utils/endpoints";
import { useState } from "react";
import { showToast } from "../../utils";
// import { yupResolver } from "@hookform/resolvers/yup";
// import { BasicDetailsValidationGuest } from "./ProfileValidations";
interface DraftPopUpProps {
  onClose?: any;
  callBack?: any;
}
const AddInterestPopup = (props: DraftPopUpProps) => {
  const { onClose, callBack } = props;
  const [isLoading, setIsLoading] = useState(false);

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    getValues,
    formState: { errors, dirtyFields },
    reset,
  } = useForm({
    // resolver: yupResolver(BasicDetailsValidationGuest),
    mode: "onSubmit",
  });

  const onSubmit = (data: any) => {
    setIsLoading(true);
    makePostCall({
      url: apiConfig.add_interest,
      apiPayload: data,
    })
      .then((res) => {
        setIsLoading(false);
        onClose();
        callBack();
        showToast(res.status.message, "success");
      })
      .catch((err) => {
        console.error(err, "error");
        setIsLoading(false);
      });
  };

  const handleSubmitForm = () => {
    handleSubmit(onSubmit)();
  };

  return (
    <div className={"modal show"}>
      <div className="stripe_acc_pop">
        <div className="modal-top-part">
          <div className="header_container">
            <img src="/static/closeBorder.svg" alt="" onClick={onClose} />
          </div>

          <div className="modal_stripe_heading">
            Can't find Interest?
            <br /> Send us the Request
          </div>

          <div className="mt-3">
            <InputField
              type="text"
              name={"title"}
              control={control}
              errors={errors}
              inputLabel={"Title*"}
              placeholder={"Type interest here"}
              setValue={setValue}
              maxlength={250}
            />
          </div>
          <div>
            <InputField
              type="text"
              name={"description"}
              control={control}
              errors={errors}
              inputLabel={"Description*"}
              placeholder={"Type the description here"}
              setValue={setValue}
              maxlength={250}
            />
          </div>
        </div>
        <Button
          btnTxt="Send Interest"
          className={"stripe_existing"}
          disabled={
            !watch("title")?.trim("") || !watch("description")?.trim("")
          }
          onClick={handleSubmitForm}
        />
        <Button btnTxt="Cancel" className={"stripe_new"} onClick={onClose} />
      </div>
    </div>
  );
};

export default AddInterestPopup;
