// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.file_preview_container .modal-content {
  background: rgba(37, 39, 45, 0.4); }

.file_preview_container .modal-body {
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 !important;
  padding: 0 !important; }

.file_preview_container .file_preview_wrap .file_preview_close {
  position: absolute;
  right: 24px;
  top: 24px;
  z-index: 2;
  width: 40px;
  height: 40px; }

.file_preview_container .file_preview_wrap .custom_file_preview {
  width: 100%;
  height: auto;
  max-height: 100%;
  max-width: 100%;
  object-fit: cover; }
`, "",{"version":3,"sources":["webpack://./src/components/ConnectUserProfile/FilePreview/style.scss"],"names":[],"mappings":"AAAA;EAGQ,iCAAiC,EAAA;;AAHzC;EAOQ,gBAAgB;EAChB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,oBAAoB;EACpB,qBAAqB,EAAA;;AAZ7B;EAiBY,kBAAkB;EAClB,WAAW;EACX,SAAS;EACT,UAAU;EACV,WAAW;EACX,YAAY,EAAA;;AAtBxB;EA0BY,WAAW;EACX,YAAY;EACZ,gBAAgB;EAChB,eAAe;EACf,iBAAiB,EAAA","sourcesContent":[".file_preview_container {\n    .modal-content {\n\n        background: rgba(37, 39, 45, 0.4);\n    }\n\n    .modal-body {\n        overflow: hidden;\n        display: flex;\n        justify-content: center;\n        align-items: center;\n        margin: 0 !important;\n        padding: 0 !important;\n    }\n\n    .file_preview_wrap {\n        .file_preview_close {\n            position: absolute;\n            right: 24px;\n            top: 24px;\n            z-index: 2;\n            width: 40px;\n            height: 40px;\n        }\n\n        .custom_file_preview {\n            width: 100%;\n            height: auto;\n            max-height: 100%;\n            max-width: 100%;\n            object-fit: cover;\n        }\n\n\n\n\n\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
