// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payment_layout {
  height: 100dvh;
  width: 100vw;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  font-family: Poppins;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: #25272D; }
  .payment_layout .logo_container_pay {
    position: absolute;
    top: 16px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 12px; }
`, "",{"version":3,"sources":["webpack://./src/pages/PaymentClose/style.scss"],"names":[],"mappings":"AAAA;EACI,cAAc;EACd,YAAY;EACZ,gBAAgB;EAChB,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,sBAAsB;EACtB,SAAS;EACT,oBAAoB;EACpB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,cAAc,EAAA;EAdlB;IAiBQ,kBAAkB;IAClB,SAAS;IACT,SAAS;IACT,2BAA2B;IAC3B,aAAa;IACb,SAAS,EAAA","sourcesContent":[".payment_layout {\n    height: 100dvh;\n    width: 100vw;\n    overflow: hidden;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    gap: 20px;\n    font-family: Poppins;\n    font-size: 18px;\n    font-weight: 500;\n    line-height: 24px;\n    text-align: center;\n    color: #25272D;\n\n    .logo_container_pay {\n        position: absolute;\n        top: 16px;\n        left: 50%;\n        transform: translateX(-50%);\n        display: flex;\n        gap: 12px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
