import { Navigate, Outlet } from "react-router-dom";
import { getItemFromStore } from "../utils";
import { ROUTE_CONSTANTS } from "../utils/RouteConstants";
import IntermediateRoutes from "./IntermediateRoutes";

const AuthRoutes = () => {
  const isAuthenticated = getItemFromStore("access_token") ? true : false;
  const isProfileComplete = getItemFromStore("isProfileComplete")
    ? true
    : false;
  const isAuthAndProfileComplete = isAuthenticated && isProfileComplete;
  const isAuthAndNotProfileComplete = isAuthenticated && !isProfileComplete;

  return isAuthAndProfileComplete ? (
    <>
      <Outlet />
    </>
  ) : isAuthAndNotProfileComplete ? (
    <>
      <IntermediateRoutes />
    </>
  ) : (
    <>
      {/* {removeAllItemFromStore()} */}
      <Navigate to={ROUTE_CONSTANTS.login} />
    </>
  );
};
export default AuthRoutes;
