import { useState } from "react";
import { Modal } from "react-bootstrap";
import Button from "../../global/Button";
import moment from "moment";
import { makePostCall } from "../../utils/Requests";
import endpoints from "../../utils/endpoints";
import { useNavigate, useParams } from "react-router-dom";
import { getItemFromStore, showToast } from "../../utils";
import {
  CalendarIcon,
  LeftArrowStickIcon,
  LocationGreyIcon,
  RightArrowIcon,
  SpClockIcon,
} from "../../utils/Icons/svgs";
import EventDateCard from "./Popups/EventDateCard";
import WaitlistPopup from "./Popups/WaitlistPopup";
import { LISTING_TYPE } from "../../types";
import EventArchivedCard from "./Popups/EventArchivedCard";

const EventDateModal = (props) => {
  const navigate = useNavigate();
  const { selDates, detailsData, setEventCenter } = props;
  const [showWaitlistPopup, setWaitlistPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [active, setActive] = useState<any>("upcoming");

  let bookingarr = [];
  const { id } = useParams();
  const event_id = id;
  const handleBooking = () => {
    if (bookingarr.length < 1) {
      showToast("please select atleast one event", "error");
      return;
    }

    const data = {
      booking_data: bookingarr,
    };
    setIsLoading(true);
    makePostCall({ url: endpoints.listings_intermediate, apiPayload: data })
      .then((res) => {
        setIsLoading(false);
        const booking_id = res?.data?.id;
        navigate(`/booking/${event_id}/${booking_id}`);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  ////PACKAGE BOOKING

  const handlePackageBooking = () => {
    // const data = {
    //   booking_data: { event_id: event_id },
    // };

    // makePostCall({ url: endpoints.listings_intermediate, apiPayload: data })
    //   .then((res) => {
    //     if (res.status.code === 200) {
    //       const booking_id = res?.data?.id;
    //       navigate(`/booking/${event_id}/${booking_id}`);
    //     }
    //   })
    //   .catch((err) => console.error(err));
    navigate(`/events-booking/${event_id}`);
  };

  const handleModal = () => {
    props.onHide();
    props.setShowScheduleModal(true);
    let datesArray = [];

    for (let i = 0; i < props.detailsData?.events?.length; i++) {
      for (let k = 0; k < bookingarr?.length; k++) {
        if (props.detailsData?.events?.[i]?.id === bookingarr[k].event) {
          datesArray.push(props.detailsData?.events?.[i]?.date);
        }
      }
    }
    props.setSelDates(datesArray);
  };

  const handleWaitlist = (eventId) => {
    const listing_type =
      detailsData?.listingType === LISTING_TYPE.S ||
      detailsData?.listingType === LISTING_TYPE.SPECIAL
        ? LISTING_TYPE.S
        : detailsData?.listingType === LISTING_TYPE.M
        ? LISTING_TYPE.M
        : "";
    let waitlistData: any = {};
    if (listing_type === LISTING_TYPE.M) {
      waitlistData.listing_type = listing_type;
      waitlistData.listing = eventId;
    } else if (listing_type === LISTING_TYPE.S) {
      waitlistData.listing_type = listing_type;
      waitlistData.event = eventId;
    }

    setIsLoading(true);
    makePostCall({ url: endpoints.listings_waitlist, apiPayload: waitlistData })
      .then((res) => {
        if (res.status.code === 200) {
          setIsLoading(false);
          setWaitlistPopup(true);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        showToast(err, "error");
      });
  };

  const showNoArchiveData = props?.eventsArray
    ?.slice(0, 8)
    ?.filter((item, i) => {
      return moment(item?.date).diff(moment(), "minutes") < 0;
    });

  return (
    <Modal {...props} centered dialogClassName="width-734">
      <Modal.Body style={{ height: "fit-content" }}>
        {(detailsData?.listingType === LISTING_TYPE.S ||
          detailsData?.listingType === LISTING_TYPE.SPECIAL) && (
          <div className="emodal_wrapper">
            <div className="eheading position-relative d-flex justify-content-center align-items-center">
              <div className="">
                <p className="e_head_txt">Available Dates</p>
                <p className="e_subhead_txt">
                  Please select the event dates that suits you.
                </p>
              </div>
              <span
                className="position-absolute eclose_btn"
                onClick={props.onHide}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.2097 4.3871L4.29289 4.29289C4.65338 3.93241 5.22061 3.90468 5.6129 4.2097L5.70711 4.29289L12 10.585L18.2929 4.29289C18.6834 3.90237 19.3166 3.90237 19.7071 4.29289C20.0976 4.68342 20.0976 5.31658 19.7071 5.70711L13.415 12L19.7071 18.2929C20.0676 18.6534 20.0953 19.2206 19.7903 19.6129L19.7071 19.7071C19.3466 20.0676 18.7794 20.0953 18.3871 19.7903L18.2929 19.7071L12 13.415L5.70711 19.7071C5.31658 20.0976 4.68342 20.0976 4.29289 19.7071C3.90237 19.3166 3.90237 18.6834 4.29289 18.2929L10.585 12L4.29289 5.70711C3.93241 5.34662 3.90468 4.77939 4.2097 4.3871L4.29289 4.29289L4.2097 4.3871Z"
                    fill="#25272D"
                  />
                </svg>
              </span>
            </div>
            <div className="edates_wrapper">
              <p className="em_title">Select a new date</p>
              <div className="e_all_dates_container row gx-0 gap-3">
                <div className="active-inactive-box">
                  <div
                    className="item cursor-pointer"
                    onClick={() => setActive("upcoming")}
                  >
                    <div className={active === "upcoming" ? "active" : ""}>
                      Upcoming
                    </div>
                  </div>
                  <div
                    className="item cursor-pointer"
                    onClick={() => setActive("archived")}
                  >
                    <div className={active === "archived" ? "active" : ""}>
                      Archived
                    </div>
                  </div>
                </div>
                {active === "upcoming" ? (
                  <>
                    {props.second
                      ? props?.selectedEvents?.map((item, i) =>
                          moment(item?.date).diff(moment(), "minutes") > 0 ? (
                            <EventDateCard
                              item={item}
                              i={i}
                              maxParticipants={props.maxParticipants}
                              key={i}
                              bookingarr={bookingarr}
                              second={true}
                              clickWaitlist={(eventId) =>
                                handleWaitlist(eventId)
                              }
                            />
                          ) : null
                        )
                      : props?.eventsArray
                          ?.slice(0, 8)
                          ?.map((item, i) =>
                            moment(item?.date).diff(moment(), "minutes") > 0 ? (
                              <EventDateCard
                                item={item}
                                i={i}
                                maxParticipants={props.maxParticipants}
                                key={i}
                                bookingarr={bookingarr}
                                setSelDates={props.setSelDates}
                                selDates={selDates}
                                clickWaitlist={(eventId) =>
                                  handleWaitlist(eventId)
                                }
                              />
                            ) : null
                          )}
                  </>
                ) : (
                  <>
                    {props?.eventsArray
                      ?.slice(0, 8)
                      ?.map(
                        (item, i) =>
                          moment(item?.date).diff(moment(), "minutes") < 0 && (
                            <EventArchivedCard
                              item={item}
                              i={i}
                              maxParticipants={props.maxParticipants}
                              key={i}
                              bookingarr={bookingarr}
                              setSelDates={props.setSelDates}
                              selDates={selDates}
                              clickWaitlist={(eventId) =>
                                handleWaitlist(eventId)
                              }
                            />
                          )
                      )}
                    {showNoArchiveData.length === 0 && (
                      <div style={{ margin: "16px auto", textAlign: "center" }}>
                        <img alt="" src="/static/archived.svg" />
                        <div
                          style={{
                            marginTop: "16px",
                            fontSize: "14px",
                            fontWeight: "500",
                            color: "#8F939A",
                          }}
                        >
                          No archived date to show
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
              {props?.eventsArray?.length > 8 ? (
                <div
                  className="schedule_event_dates my-3 cursor-pointer"
                  onClick={() => {
                    handleModal();
                  }}
                >
                  <div className="d-flex align-items-center py-4 px-2 gap-1">
                    <span>
                      <CalendarIcon />
                    </span>
                    {detailsData?.listingType === "S" ? (
                      <p className="mb-0 sch_txt">Schedule Sessions Dates</p>
                    ) : detailsData?.listingType === "P" ? (
                      <p className="mb-0 sch_txt">Schedule Package Dates</p>
                    ) : (
                      <p className="mb-0 sch_txt">
                        Schedule Team Listing Dates
                      </p>
                    )}
                  </div>
                  <div>
                    <RightArrowIcon />
                  </div>
                </div>
              ) : (
                ""
              )}
              <Button
                btnTxt="Confirm Date"
                className={"backtologin mt-4"}
                isLoading={isLoading}
                disabled={
                  detailsData?.createdBy?.id ===
                  getItemFromStore("userProfile")?.id
                }
                onClick={() => {
                  handleBooking();
                }}
              />
            </div>
          </div>
        )}

        {detailsData?.listingType === LISTING_TYPE.M && (
          <div className="session_p_modal position-relative">
            <div
              className="d-md-none position-relative cursor-pointer my-2"
              onClick={() => props.onHide()}
            >
              <LeftArrowStickIcon />
            </div>
            <div className="sp_c_icon mb-3">
              <span>
                <CalendarIcon width="48" height="48" />
              </span>
            </div>
            <div className="sp_txt mb-4">
              <p className="sp_title mb-0">session</p>
              <p className="sp_subtitle mb-0">
                Please check all the available events
              </p>
            </div>
            <div className="mx-auto  ">
              {detailsData?.events?.map((item, i) => {
                return (
                  <div className="sp_date_container mx-auto mb-3">
                    <div className="sp_only_date">
                      <p className="sp_date_month mb-0">
                        {moment(item?.date).format("MMMM")}
                      </p>
                      <p className="sp_date_date mb-0">
                        {moment(item?.date).date()}
                      </p>
                      <p className="sp_date_day mb-0">
                        {moment(item?.date).format("ddd")}
                      </p>
                    </div>
                    {/* <div></div> */}
                    <hr />
                    <div className="sp_info">
                      <div className="sp_title">{item.title} </div>
                      <div className="sp_loc">
                        <div className="d-flex">
                          <LocationGreyIcon />
                        </div>
                        <div className="loc">{item?.address?.address}</div>
                        <div
                          className="sp_map"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            props.onHide();
                            props.setOpenMap(true);
                            setEventCenter(item?.address?.location);
                          }}
                        >
                          open maps
                        </div>
                      </div>
                      <div className="sp_time">
                        <SpClockIcon />{" "}
                        {moment(item?.startTime?.substring(0, 5), [
                          "HH:mm",
                        ]).format("hh:mmA")}
                        -{" "}
                        {moment(item?.endTime?.substring(0, 5), [
                          "HH:mm",
                        ]).format("hh:mmA")}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="d-flex justify-content-center package_wrapper">
              <Button
                btnTxt={`${props.isDisabled() ? "Booking Full" : "Book"}`}
                className={"backtologin "}
                style={{ width: "350px" }}
                isLoading={isLoading}
                onClick={() => {
                  handlePackageBooking();
                }}
                disabled={
                  props.isDisabled() ||
                  detailsData?.createdBy?.id ===
                    getItemFromStore("userProfile")?.id
                }
              />
            </div>
            {props.isDisabled() && (
              <div className="d-flex justify-content-center package_wrapper mt-3">
                <Button
                  btnTxt="Add to waitlist"
                  className={"backtologin "}
                  style={{ width: "350px" }}
                  onClick={() => {
                    handleWaitlist(event_id);
                  }}
                />
              </div>
            )}
          </div>
        )}
        <WaitlistPopup
          show={showWaitlistPopup}
          onHide={() => setWaitlistPopup(false)}
        />
      </Modal.Body>
    </Modal>
  );
};
export default EventDateModal;
