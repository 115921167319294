/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import Button from "../../../global/Button";
import InputField from "../../../global/InputField";
import { FREQUENCY_TYPE, RECURRING_TYPE, SCHEDULE_TYPE } from "../../../types";
import {
  dateFormatter,
  findDatesInNextThreeMonths,
  showToast,
} from "../../../utils";
import {
  apiConstants,
  recurFrequency,
  WeekDays,
} from "../../../utils/Constants";
import { STRING_CONSTANTS } from "../../../utils/StringConstant";
import EventDateSelector from "./EventDateSelector";
import EventTimeSelector from "./EventTimeSelector";
import MultiDateSelector from "./MultiDateSelector";
import ScheduleListingMeeting from "../../ListingComponents/ScheduleListingMeeting/ScheduleListingMeeting";
import {
  generateDateRangeEventsWithSkip,
  generateMonthlyEventsWithSkip,
  generateWeekdayEventsWithSkip,
} from "../../../utils/datesRange";
import SingleDatePicker from "../../DatePicker/SingleDatePicker/index.tsx";
import MultiDatePicker from "../../DatePicker/MultiDatePicker/index.tsx";
import SingleTimePicker from "../../DatePicker/SingleTimePicker/index.tsx";
import dayjs from "dayjs";
import {
  compareDayjsDate,
  compareDayjsTime,
  dateDayjsFormat,
  timeDayjsFormat,
} from "../../../constants/helperCreateListing";
interface scheduleListingProps {
  control: any;
  watch?: any;
  handleSetValue?: any;
  getValues?: any;
  setScheduleLisiting?: any;
  trigger: any;
  errors: any;
  setScheduleDate: any;
  dirtyFields: any;
  setScheduleData?: any;
  scheduleData?: any;
}
const ScheduleListingModal = (props: scheduleListingProps) => {
  const {
    control,
    watch,
    handleSetValue,
    getValues,
    setScheduleLisiting,
    trigger,
    errors,
    setScheduleDate,
    dirtyFields,
    setScheduleData,
    scheduleData,
  } = props;
  const recurringType = watch(apiConstants.recurring_type);
  const scheduleType = watch(apiConstants.schedule_type);

  const [showRecFrequency, setShowRecFrequency] = useState(false);
  const [showMonthFrequency, setShowMonthFrequency] = useState(false);
  const [availableDates, setAvailableDates] = useState<any>([]);
  const [isMeetingLink, setIsMeetingLink] = useState<any>(false);

  const handleReset = (step = 1) => {
    switch (step) {
      case 1: // onetime
        handleSetValue(apiConstants.event_date, undefined);
        // handleSetValue(apiConstants.event_start_time, undefined);
        // handleSetValue(apiConstants.event_end_time, undefined);
        break;

      case 2: // recurring-all
        // handleSetValue(apiConstants.event_start_time, undefined);
        // handleSetValue(apiConstants.event_end_time, undefined);
        // handleSetValue(apiConstants.event_start_date, undefined);
        // handleSetValue(apiConstants.event_end_date, undefined);
        handleSetValue(apiConstants.repeat_every, 1);
        handleSetValue(apiConstants.recur_frequency, undefined);
        handleSetValue(apiConstants.selected_days, undefined);
        handleSetValue(apiConstants.monthly_frequency, undefined);
        break;
      case 4: // month
        handleSetValue(apiConstants.monthly_frequency, undefined);
        handleSetValue(apiConstants.selected_month_dates_options, undefined);
        handleSetValue(apiConstants.on_the_basis, undefined);
        break;
      case 5: // week
        handleSetValue(apiConstants.selected_days, undefined);
        break;
      case 6: // recurring-custom
        handleSetValue(apiConstants.event_custom_date, undefined);
        // handleSetValue(apiConstants.event_start_time, undefined);
        // handleSetValue(apiConstants.event_end_time, undefined);
        break;
      default: // onetime
        handleSetValue(apiConstants.event_date, undefined);
        // handleSetValue(apiConstants.event_start_time, undefined);
        // handleSetValue(apiConstants.event_end_time, undefined);
        break;
    }
  };

  const getDatesBetween = (
    startDate: any,
    endDate: any,
    frequency: number,
    type: string,
    recurrenceDays?: any,
    onthebasis?: any
  ) => {
    const dates = [];

    let currentDate = moment(startDate);
    const end = moment(endDate);

    while (currentDate <= end) {
      if (type === "Week") {
        const currentWeek = currentDate.clone();

        for (let i = 0; i < 7; i++) {
          if (
            currentWeek.startOf("day") <= end.startOf("day") &&
            recurrenceDays?.includes(currentWeek.format("dddd"))
          ) {
            dates.push({
              start:
                currentWeek.format("YYYY-MM-DD") +
                " " +
                moment(getValues(apiConstants.event_start_time)).format(
                  "hh:mm A"
                ),
              end:
                currentWeek.format("YYYY-MM-DD") +
                " " +
                moment(getValues(apiConstants.event_end_time)).format(
                  "hh:mm A"
                ),
            });
          }
          currentWeek.add(1, "day");
        }

        currentDate = currentDate.add(frequency, "weeks");
      } else if (type === "Day") {
        dates.push({
          start:
            currentDate.format("YYYY-MM-DD") +
            " " +
            moment(getValues(apiConstants.event_start_time)).format("hh:mm A"),
          end:
            currentDate.format("YYYY-MM-DD") +
            " " +
            moment(getValues(apiConstants.event_end_time)).format("hh:mm A"),
        });
        currentDate.add(frequency, "days");
      } else if (type === "Month") {
        const weekdayRegex =
          /(Monday|Tuesday|Wednesday|Thursday|Friday|Saturday|Sunday)/i;

        const containsWeekday = weekdayRegex.test(onthebasis);
        const result = containsWeekday ? "weekday" : "date";

        if (result === "weekday") {
          // Process month type based on weekday

          let MonthdaysBasedOnWeek: any = findDatesInNextThreeMonths(
            dateFormatter(currentDate, "YYYY-MM-DD"),
            end.diff(currentDate, "months"),
            dateFormatter(end, "YYYY-MM-DD")
          );

          for (
            let i = 0;
            i < MonthdaysBasedOnWeek?.length;
            i += Number(frequency)
          ) {
            if (i >= MonthdaysBasedOnWeek?.length) {
              break;
            }

            dates.push({
              start:
                moment(MonthdaysBasedOnWeek[i]).format("YYYY-MM-DD") +
                " " +
                moment(getValues(apiConstants.event_start_time)).format(
                  "hh:mm A"
                ),
              end:
                moment(MonthdaysBasedOnWeek[i]).format("YYYY-MM-DD") +
                " " +
                moment(getValues(apiConstants.event_end_time)).format(
                  "hh:mm A"
                ),
            });
          }
          break;
        } else if (result === "date") {
          // Process month type based on date
          const targetDate = moment(currentDate).date(currentDate.date()); // 6th of the month

          dates.push({
            start:
              targetDate.format("YYYY-MM-DD") +
              " " +
              moment(getValues(apiConstants.event_start_time)).format(
                "hh:mm A"
              ),
            end:
              targetDate.format("YYYY-MM-DD") +
              " " +
              moment(getValues(apiConstants.event_end_time)).format("hh:mm A"),
          });

          currentDate.add(frequency, "months");
        }
      } else {
        return;
      }
    }

    return dates;
  };

  useEffect(() => {
    //Remove leading zeros
    if (watch(apiConstants.repeat_every)) {
    }
    if (watch(apiConstants.repeat_every)) {
      handleSetValue(
        apiConstants.repeat_every,
        Number(watch(apiConstants?.repeat_every)).toString()
      );
    }
  }, [watch(apiConstants.repeat_every)]);

  const handleWeekDays = (item: any) => {
    const tempDays = watch(apiConstants.selected_days);
    const isSelected = tempDays?.includes(item);
    if (isSelected) {
      handleSetValue(
        apiConstants.selected_days,
        tempDays.filter((day: any) => day !== item)
      );
    } else {
      handleSetValue(apiConstants.selected_days, [...tempDays, item]);
    }
  };
  const handleScheduleListing = async () => {
    switch (watch(apiConstants.schedule_type)) {
      // schedule: onetime
      case SCHEDULE_TYPE.O:
        let startDate = watch(apiConstants.event_date);
        let endDate = watch(apiConstants.event_date);
        handleSetValue(
          apiConstants.event_start_date,
          dateDayjsFormat(startDate)
        );
        handleSetValue(apiConstants.event_end_date, dateDayjsFormat(endDate));

        if (!watch(apiConstants.event_date)) {
          showToast("An event date is required to proceed.", "error");
          return;
        } else if (
          !watch(apiConstants.event_end_time) ||
          !watch(apiConstants.event_start_time)
        ) {
          showToast(STRING_CONSTANTS.start_and_end_time_error, "error");
          return;
        } else if (
          !compareDayjsTime(
            watch(apiConstants.event_start_time),
            watch(apiConstants.event_end_time)
          )
        ) {
          showToast(STRING_CONSTANTS.start_and_end_date_error_eql, "error");
          return;
        } else {
          const virtual_links_temp = [
            {
              date: dateDayjsFormat(watch(apiConstants.event_start_date)),
              event_start_time: dayjs(
                watch(apiConstants.event_start_time)
              ).format("YYYY-MM-DD hh:mm A"),
              event_end_time: dayjs(watch(apiConstants.event_end_time)).format(
                "YYYY-MM-DD hh:mm A"
              ),

              virtual_link: "",
            },
          ];

          const newModifiedLinks = virtual_links_temp.map((item) => {
            const isDate = scheduleData?.virtualLinksMultiple?.filter((val) => {
              if (
                dayjs(val.date).format("YYYY-MM-DD") ===
                dayjs(item.date).format("YYYY-MM-DD")
              ) {
                return val;
              }
            });

            if (isDate?.length > 0) {
              return {
                ...item,
                virtual_link: isDate[0]?.virtual_link,
              };
            } else {
              return item;
            }
          });

          await handleSetValue(
            apiConstants.virtual_links_multiple,
            newModifiedLinks
          );
          await handleContinueClick(
            getValues(apiConstants.virtual_links_multiple)
          );
        }

        break;
      // schedule:recurring
      case SCHEDULE_TYPE.R:
        switch (watch(apiConstants.recurring_type)) {
          // recurring:custom dates
          case RECURRING_TYPE.C:
            let startDate;
            let endDate;

            if (watch(apiConstants.event_custom_date).length >= 1) {
              startDate = watch(apiConstants.event_custom_date)[0];

              endDate = watch(apiConstants.event_custom_date)?.[
                watch(apiConstants.event_custom_date)?.length - 1
              ];

              handleSetValue(apiConstants.event_start_date, startDate);
              handleSetValue(apiConstants.event_end_date, endDate);
            }
            if (
              !watch(apiConstants.event_end_time) ||
              !watch(apiConstants.event_start_time)
            ) {
              showToast(STRING_CONSTANTS.start_and_end_time_error, "error");
              return;
            } else if (
              !compareDayjsTime(
                watch(apiConstants.event_start_time),
                watch(apiConstants.event_end_time)
              )
            ) {
              showToast(STRING_CONSTANTS.start_and_end_date_error_eql, "error");
              return;
            } else if (watch(apiConstants.event_custom_date)?.length < 2) {
              showToast(
                `For custom case, please select at least two "dates".`,
                "error"
              );
            } else {
              const virtual_links = getValues(
                apiConstants.event_custom_date
              )?.map((event) => {
                return {
                  date: event,
                  event_start_time: timeDayjsFormat(
                    watch(apiConstants.event_start_time)
                  ),
                  event_end_time: timeDayjsFormat(
                    watch(apiConstants.event_end_time)
                  ),
                  virtual_link: "",
                };
              });

              const newModifiedLinks = virtual_links.map((item) => {
                const isDate = scheduleData?.virtualLinksMultiple?.filter(
                  (val) => {
                    if (
                      dayjs(val.date).format("YYYY-MM-DD") ===
                      dayjs(item.date).format("YYYY-MM-DD")
                    ) {
                      return val;
                    }
                  }
                );

                if (isDate?.length > 0) {
                  return {
                    ...item,
                    virtual_link: isDate[0]?.virtual_link,
                  };
                } else {
                  return item;
                }
              });

              await handleSetValue(
                apiConstants.virtual_links_multiple,
                newModifiedLinks
              );
              await handleContinueClick(
                getValues(apiConstants.virtual_links_multiple)
              );
            }
            break;
          // recurring:repeat frequency
          case RECURRING_TYPE.R:
            if (
              !watch(apiConstants.event_end_time) ||
              !watch(apiConstants.event_start_time)
            ) {
              showToast(STRING_CONSTANTS.start_and_end_time_error, "error");
              return;
            } else if (
              !compareDayjsTime(
                watch(apiConstants.event_start_time),
                watch(apiConstants.event_end_time)
              )
            ) {
              showToast(STRING_CONSTANTS.start_and_end_date_error_eql, "error");
              return;
            } else if (
              !compareDayjsDate(
                watch(apiConstants.event_start_date),
                watch(apiConstants.event_end_date)
              )
            ) {
              showToast(
                "The start date can’t be same or after end date",
                "error"
              );

              setAvailableDates([]);
              return;
            } else {
              if (watch(apiConstants.recur_frequency) === "Day") {
                const virtual_links = generateDateRangeEventsWithSkip(
                  dayjs(getValues(apiConstants.event_start_date)).format(
                    "YYYY-MM-DD"
                  ),
                  dayjs(getValues(apiConstants.event_end_date)).format(
                    "YYYY-MM-DD"
                  ),
                  dayjs(getValues(apiConstants.event_start_time)).format(
                    "YYYY-MM-DD hh:mm A"
                  ),
                  dayjs(getValues(apiConstants.event_end_time)).format(
                    "YYYY-MM-DD hh:mm A"
                  ),
                  getValues(apiConstants.repeat_every) - 1
                );
                const newModifiedLinks = virtual_links.map((item) => {
                  const isDate = scheduleData?.virtualLinksMultiple?.filter(
                    (val) => {
                      if (
                        dayjs(val.date).format("YYYY-MM-DD") ===
                        dayjs(item.date).format("YYYY-MM-DD")
                      ) {
                        return val;
                      }
                    }
                  );

                  if (isDate?.length > 0) {
                    return {
                      ...item,
                      virtual_link: isDate[0]?.virtual_link,
                    };
                  } else {
                    return item;
                  }
                });

                await handleSetValue(
                  apiConstants.virtual_links_multiple,
                  newModifiedLinks
                );
              } else if (watch(apiConstants.recur_frequency) === "Week") {
                const virtual_links = await generateWeekdayEventsWithSkip(
                  dayjs(getValues(apiConstants.event_start_date)).format(
                    "YYYY-MM-DD"
                  ),
                  dayjs(getValues(apiConstants.event_end_date)).format(
                    "YYYY-MM-DD"
                  ),
                  dayjs(getValues(apiConstants.event_start_time)).format(
                    "YYYY-MM-DD hh:mm A"
                  ),
                  dayjs(getValues(apiConstants.event_end_time)).format(
                    "YYYY-MM-DD hh:mm A"
                  ),
                  getValues(apiConstants.selected_days),
                  getValues(apiConstants.repeat_every) - 1
                );
                const newModifiedLinks = virtual_links.map((item) => {
                  const isDate = scheduleData?.virtualLinksMultiple?.filter(
                    (val) => {
                      if (
                        dayjs(val.date).format("YYYY-MM-DD") ===
                        dayjs(item.date).format("YYYY-MM-DD")
                      ) {
                        return val;
                      }
                    }
                  );

                  if (isDate?.length > 0) {
                    return {
                      ...item,
                      virtual_link: isDate[0]?.virtual_link,
                    };
                  } else {
                    return item;
                  }
                });

                await handleSetValue(
                  apiConstants.virtual_links_multiple,
                  newModifiedLinks
                );
              } else if (watch(apiConstants.recur_frequency) === "Month") {
                const virtual_links = await generateMonthlyEventsWithSkip(
                  dayjs(getValues(apiConstants.event_start_date)).format(
                    "YYYY-MM-DD"
                  ),
                  dayjs(getValues(apiConstants.event_end_date)).format(
                    "YYYY-MM-DD"
                  ),
                  dayjs(getValues(apiConstants.event_start_time)).format(
                    "YYYY-MM-DD hh:mm A"
                  ),
                  dayjs(getValues(apiConstants.event_end_time)).format(
                    "YYYY-MM-DD hh:mm A"
                  ),
                  getValues(apiConstants.on_the_basis) === "weekday"
                    ? "date"
                    : "day",
                  getValues(apiConstants.repeat_every) - 1
                );
                const newModifiedLinks = virtual_links.map((item) => {
                  const isDate = scheduleData?.virtualLinksMultiple?.filter(
                    (val) => {
                      if (
                        dayjs(val.date).format("YYYY-MM-DD") ===
                        dayjs(item.date).format("YYYY-MM-DD")
                      ) {
                        return val;
                      }
                    }
                  );

                  if (isDate?.length > 0) {
                    return {
                      ...item,
                      virtual_link: isDate[0]?.virtual_link,
                    };
                  } else {
                    return item;
                  }
                });

                await handleSetValue(
                  apiConstants.virtual_links_multiple,
                  newModifiedLinks
                );
              }
              handleContinueClick(
                getValues(apiConstants.virtual_links_multiple)
              );
            }
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  };

  // useEffect(() => {
  //   if (watch(apiConstants.event_custom_date)) {
  //     const normalDates = getValues(apiConstants.event_custom_date)?.map(
  //       (date: any) => moment(new Date(date)).format("YYYY-MM-DD")
  //     );
  //     let dates = normalDates.map((item: any, index: number) => {
  //       let customDates = getValues(apiConstants.custom_dates);
  //       const observer = customDates?.filter((val) =>
  //         val?.start?.includes(item)
  //       );
  //       if (observer?.length > 0) {
  //         return {
  //           start:
  //             item +
  //             " " +
  //             moment(getValues(apiConstants.event_start_time))?.format(
  //               "hh:mm A"
  //             ),
  //           end:
  //             item +
  //             " " +
  //             moment(getValues(apiConstants.event_end_time))?.format("hh:mm A"),
  //           virtual_link: observer[0].virtual_link || "",
  //         };
  //       } else {
  //         return {
  //           start:
  //             item +
  //             " " +
  //             moment(getValues(apiConstants.event_start_time))?.format(
  //               "hh:mm A"
  //             ),
  //           end:
  //             item +
  //             " " +
  //             moment(getValues(apiConstants.event_end_time))?.format("hh:mm A"),
  //           virtual_link: "",
  //         };
  //       }
  //     });

  //     // Define a custom comparison function
  //     const compareDates = (a: any, b: any) => {
  //       const dateA: any = moment(a.start.replace(/ Invalid date$/, ""));
  //       const dateB: any = moment(b.start.replace(/ Invalid date$/, ""));
  //       return dateA - dateB;
  //     };

  //     // Sort the array based on the start date
  //     dates.sort(compareDates);

  //     handleSetValue(apiConstants.custom_dates, dates);
  //   }
  // }, [
  //   watch(apiConstants.event_custom_date),
  //   watch(apiConstants.event_start_time),
  //   watch(apiConstants.event_end_time),
  // ]);

  useEffect(() => {
    if (watch(apiConstants.recur_frequency === "Month")) {
      const dayOfWeek = dateFormatter(
        watch(apiConstants.event_start_date),
        "dddd"
      ); // Get the full name of the day of the week

      // Math.ceil(moment(dateString).date() / 7);
      const occurrence = Math.ceil(
        moment(watch(apiConstants.event_start_date)).date() / 7
      );

      // Calculate the occurrence of the day within the month

      if (dirtyFields.event_start_date) {
        handleSetValue(apiConstants.monthly_frequency, "");
      }
      handleSetValue(apiConstants.selected_month_dates_options, {
        day: dateFormatter(watch(apiConstants.event_start_date), "DD"),
        weakday: ` ${`${
          occurrence === 5
            ? "last"
            : occurrence === 1
            ? "first"
            : occurrence === 2
            ? "second"
            : occurrence === 3
            ? "third"
            : "fourth"
        } ${dayOfWeek}`} `,
      });
    }
  }, [watch(apiConstants.event_start_date)]);

  const getWeekNumber = (date) => {
    const weekDay = dayjs(date).format("dddd");
    const currentDay = dayjs(date, "YYYY-MM-DD");
    const weekOfMonth = Math.ceil(currentDay.date() / 7);

    if (weekOfMonth === 1) {
      return `Monthly On The First ${weekDay}`;
    } else if (weekOfMonth === 2) {
      return `Monthly On The Second ${weekDay}`;
    } else if (weekOfMonth === 3) {
      return `Monthly On The Third ${weekDay}`;
    } else if (weekOfMonth === 4) {
      return `Monthly On The Fourth ${weekDay}`;
    } else if (weekOfMonth === 5) {
      return `Monthly On The Last ${weekDay}`;
    }
  };

  useEffect(() => {
    if (
      watch(apiConstants.event_start_date) &&
      watch(apiConstants.event_end_date) &&
      watch(apiConstants.repeat_every) &&
      watch(apiConstants.recur_frequency)
    ) {
      if (
        watch(apiConstants.recur_frequency) === "Month" &&
        !watch(apiConstants.monthly_frequency)
      ) {
        setAvailableDates([]);
        return;
      } else {
        let preferedDates = getDatesBetween(
          watch(apiConstants.event_start_date),
          watch(apiConstants.event_end_date),
          watch(apiConstants.repeat_every),
          watch(apiConstants.recur_frequency),
          watch(apiConstants.selected_days),
          watch(apiConstants.monthly_frequency)
        );

        handleSetValue(apiConstants.custom_dates, preferedDates);

        setAvailableDates(
          preferedDates?.map((item: any) => {
            return new Date(item.start?.split(" ")?.[0]);
          })
        );
      }
    }
  }, [
    watch(apiConstants.event_start_date),
    watch(apiConstants.event_end_date),
    watch(apiConstants.repeat_every),
    watch(apiConstants.recur_frequency),
    watch(apiConstants.monthly_frequency),
    watch(apiConstants.selected_days),
  ]);

  const getInputValue = () => {
    if (watch(apiConstants.event_custom_date)?.length > 1) {
      return `${watch(apiConstants.event_custom_date)?.length} days selected`;
    } else if (watch(apiConstants.event_custom_date)?.length === 1) {
      return "1 day selected";
    } else {
      return "";
    }
  };
  useEffect(() => {
    getInputValue();
  }, [watch(apiConstants.event_custom_date)]);
  const customDayClassNames = [
    {
      "add-tick": availableDates,
    },
  ];

  const handleContinueClick = (props) => {
    if (watch(apiConstants.multiple_virtual_links_allowed) === false) {
      setIsMeetingLink(true);
    } else {
      if (
        watch(apiConstants.schedule_type) === SCHEDULE_TYPE.O &&
        watch(apiConstants.event_date) &&
        watch(apiConstants.event_start_time) &&
        watch(apiConstants.event_end_time)
      ) {
        setScheduleData({
          type: watch(apiConstants.schedule_type) || SCHEDULE_TYPE.O,
          oneTimeDate: dateDayjsFormat(watch(apiConstants.event_date)),
          startTime: timeDayjsFormat(watch(apiConstants.event_start_time)),
          endTime: timeDayjsFormat(watch(apiConstants.event_end_time)),
        });
      } else if (watch(apiConstants.schedule_type) === SCHEDULE_TYPE.R) {
        if (
          watch(apiConstants.recurring_type) === RECURRING_TYPE.C &&
          watch(apiConstants.event_start_time) &&
          watch(apiConstants.event_end_time)
        ) {
          setScheduleData({
            type: watch(apiConstants.schedule_type),
            recurringType: watch(apiConstants.recurring_type),
            customDates: watch(apiConstants.event_custom_date),
            startTime: timeDayjsFormat(watch(apiConstants.event_start_time)),
            endTime: timeDayjsFormat(watch(apiConstants.event_end_time)),
          });
        } else {
          if (watch(apiConstants.recur_frequency) === FREQUENCY_TYPE.D) {
            setScheduleData({
              type: watch(apiConstants.schedule_type),
              recurringType: watch(apiConstants.recurring_type),
              startTime: timeDayjsFormat(watch(apiConstants.event_start_time)),
              endTime: timeDayjsFormat(watch(apiConstants.event_end_time)),
              startDate: dateDayjsFormat(watch(apiConstants.event_start_date)),
              endDate: dateDayjsFormat(watch(apiConstants.event_end_date)),
              repeatNumber: watch(apiConstants.repeat_every) || 1,
              recurringRepeatType: watch(apiConstants.recur_frequency),
            });
          } else if (watch(apiConstants.recur_frequency) === FREQUENCY_TYPE.M) {
            setScheduleData({
              type: watch(apiConstants.schedule_type),
              recurringType: watch(apiConstants.recurring_type),
              startTime: timeDayjsFormat(watch(apiConstants.event_start_time)),
              endTime: timeDayjsFormat(watch(apiConstants.event_end_time)),
              startDate: dateDayjsFormat(watch(apiConstants.event_start_date)),
              endDate: dateDayjsFormat(watch(apiConstants.event_end_date)),
              repeatNumber: watch(apiConstants.repeat_every) || 1,
              recurringRepeatType: watch(apiConstants.recur_frequency),
              monthlyRepeatType: /on\s+Day/i.test(
                watch(apiConstants.monthly_frequency)
              )
                ? "day"
                : "week",
              monthlyFrequencyData: watch(apiConstants.monthly_frequency),
            });
          } else if (watch(apiConstants.recur_frequency) === FREQUENCY_TYPE.W) {
            setScheduleData({
              type: watch(apiConstants.schedule_type),
              recurringType: watch(apiConstants.recurring_type),
              startTime: timeDayjsFormat(watch(apiConstants.event_start_time)),
              endTime: timeDayjsFormat(watch(apiConstants.event_end_time)),
              startDate: dateDayjsFormat(watch(apiConstants.event_start_date)),
              endDate: dateDayjsFormat(watch(apiConstants.event_end_date)),
              repeatNumber: watch(apiConstants.repeat_every) || 1,
              recurringRepeatType: watch(apiConstants.recur_frequency),
              weekDays: watch(apiConstants.selected_days),
            });
          }
        }
      }

      handleSetValue(apiConstants.has_schedule_date, true);
      setScheduleLisiting(false);
    }
  };
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowRecFrequency(false);
      setShowMonthFrequency(false);
    }
  };

  useEffect(() => {
    if (showRecFrequency || showMonthFrequency) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showRecFrequency, showMonthFrequency]);

  return (
    <div className={"modal show"}>
      <div
        className="modal-section v-modal new-schedule_modal handle overflow_web_schedule"
        style={{ gap: "30px", maxWidth: isMeetingLink ? "998px" : "586px" }}
      >
        {isMeetingLink ? (
          <ScheduleListingMeeting
            setScheduleData={setScheduleData}
            setScheduleLisiting={setScheduleLisiting}
            watch={watch}
            handleSetValue={handleSetValue}
            data={watch(apiConstants?.virtual_links_multiple) || []}
          />
        ) : (
          <>
            <div
              className="modal-top-part"
              style={{ overflow: "unset", maxHeight: "fit-content" }}
            >
              <h4>Schedule listing</h4>
              <ul className="mytabs">
                <li
                  className={scheduleType === SCHEDULE_TYPE.O ? "active" : ""}
                  onClick={() => {
                    handleSetValue(apiConstants.schedule_type, SCHEDULE_TYPE.O);
                  }}
                >
                  <img src="/static/content-view.svg" alt="" /> One-time event
                </li>
                <li
                  className={scheduleType === SCHEDULE_TYPE.R ? "active" : ""}
                  onClick={() => {
                    handleSetValue(apiConstants.schedule_type, SCHEDULE_TYPE.R);
                  }}
                >
                  <img src="/static/arrow-repeat-all.svg" alt="" /> Recurring
                  class
                </li>
              </ul>
              {scheduleType === SCHEDULE_TYPE.R && (
                <>
                  <ul className="recurring_type">
                    <li
                      onClick={() => {
                        handleSetValue(
                          apiConstants.recurring_type,
                          "customday"
                        );
                        handleReset(2);
                      }}
                      className={recurringType === "customday" ? "active" : ""}
                    >
                      <img src="/static/customday.svg" alt="" />
                      Select custom days
                    </li>
                    <li
                      onClick={() => {
                        handleSetValue(
                          apiConstants.recurring_type,
                          "recurring"
                        );
                        handleReset(6);
                      }}
                      className={recurringType === "recurring" ? "active" : ""}
                    >
                      <img src="/static/recurring.svg" alt="" />
                      Repeated frequency
                    </li>
                  </ul>
                </>
              )}
              {recurringType === RECURRING_TYPE.R &&
              scheduleType === SCHEDULE_TYPE.R ? (
                <div className="form_row">
                  <InputField
                    value={watch(apiConstants.repeat_every === "") || 6}
                    name={apiConstants.repeat_every}
                    type={"number"}
                    control={control}
                    inputLabel={"Repeat Every:"}
                    placeholder={"0"}
                    errors={errors}
                    className={"repeat_every"}
                    pattern={"[1-9][0-9]*"}
                  />
                  <div className="select_frequency">
                    <div className="form_group">
                      <div className="input_box" style={{ zIndex: 4 }}>
                        <span className="input_label">
                          {"Select Recur Frequency:"}
                        </span>
                        <input
                          type="text"
                          value={getValues(apiConstants.recur_frequency) || ""}
                          onClick={() => {
                            setShowRecFrequency(!showRecFrequency);
                            handleSetValue(apiConstants.selected_days, []);
                          }}
                          name={apiConstants.recur_frequency}
                        />
                        {showRecFrequency ? (
                          <ul
                            className="frequency_option selection"
                            ref={dropdownRef}
                          >
                            {recurFrequency.map((item, index) => {
                              return (
                                <li
                                  key={index}
                                  className={
                                    watch(apiConstants.recur_frequency) === item
                                      ? "active"
                                      : ""
                                  }
                                  onClick={() => {
                                    handleSetValue(
                                      apiConstants.recur_frequency,
                                      item
                                    );
                                    if (item === "Week") {
                                      handleReset(4);
                                    } else if (item === "Month") {
                                      handleReset(5);
                                    }
                                    setShowRecFrequency(!showRecFrequency);
                                  }}
                                >
                                  {item}
                                </li>
                              );
                            })}
                          </ul>
                        ) : null}
                        {errors[apiConstants.recur_frequency] ? (
                          <span className="text-danger">{`${
                            errors[apiConstants.recur_frequency].message
                          }`}</span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="all-mid-part p-0 p-md-3">
                {scheduleType === SCHEDULE_TYPE.O && (
                  <>
                    <SingleDatePicker
                      name={apiConstants.event_date}
                      watch={watch}
                      handleSetValue={handleSetValue}
                      label={"Event Date"}
                    />
                    {/* <div className="form_group">
                      <div className={"input_box"}>
                        <span className="input_label">Event Date</span>
                        <div className="one-time">
                          <MultiDateSelector
                            name={apiConstants.event_date}
                            control={control}
                            minDate={new Date()}
                            watch={watch}
                            errors={errors}
                          />
                        </div>
                      </div>
                    </div> */}
                  </>
                )}

                {scheduleType === SCHEDULE_TYPE.R &&
                  recurringType === RECURRING_TYPE.C && (
                    <MultiDatePicker
                      name={apiConstants.event_custom_date}
                      watch={watch}
                      handleSetValue={handleSetValue}
                      label={"Select Recur Frequency"}
                    />
                    // <div className="form_group custom_day">
                    //   <div className={"input_box"}>
                    //     <span className="input_label">
                    //       Select Recur Frequency:
                    //     </span>
                    //     <div className="data-length-wrapper">
                    //       <div className="outer-container">
                    //         <MultiDateSelector
                    //           name={apiConstants.event_custom_date}
                    //           control={control}
                    //           minDate={new Date()}
                    //           multiple={true}
                    //           watch={watch}
                    //           errors={errors}
                    //         />
                    //         <div className="content-box">{getInputValue()}</div>
                    //       </div>
                    //     </div>
                    //   </div>
                    // </div>
                  )}
                {scheduleType === SCHEDULE_TYPE.R &&
                  recurringType === RECURRING_TYPE.R &&
                  getValues(apiConstants.recur_frequency) === "Week" && (
                    <>
                      <h5>Select recurrence days</h5>
                      <div
                        className="position-relative week_days"
                        style={{
                          marginBottom: errors[apiConstants.selected_days]
                            ? "20px"
                            : "",
                        }}
                      >
                        <ul
                          className="weeklayset"
                          style={{
                            paddingBottom: "12px",
                          }}
                        >
                          {WeekDays.map((item: any, index: number) => {
                            return (
                              <li
                                className={
                                  watch(apiConstants.selected_days)?.includes(
                                    item.value
                                  )
                                    ? "active"
                                    : ""
                                }
                                key={index}
                                onClick={() => handleWeekDays(item.value)}
                              >
                                {item.label}
                              </li>
                            );
                          })}
                        </ul>
                        {errors[apiConstants.selected_days] ? (
                          <span className="text-danger">
                            {"Please select atleast one recurrence day"}
                          </span>
                        ) : null}
                      </div>
                    </>
                  )}

                <h5
                  style={{
                    marginTop: "24px",
                    marginBottom: "16px",
                  }}
                >
                  Select listing time
                </h5>
                <div className="two-row-input">
                  <SingleTimePicker
                    label={"Start Time"}
                    name={apiConstants.event_start_time}
                    watch={watch}
                    handleSetValue={handleSetValue}
                  />
                  <SingleTimePicker
                    label={"End Time"}
                    name={apiConstants.event_end_time}
                    watch={watch}
                    handleSetValue={handleSetValue}
                  />
                  {/* <div className="form_group position-relative">
                    <div className="input_box position-initial">
                      <span className="input_label">Start Time:</span>
                      <EventTimeSelector
                        name={apiConstants.event_start_time}
                        control={control}
                        errors={errors}
                      />
                    </div>
                  </div> */}
                  {/* <div className="form_group position-relative">
                    <div className="input_box position-initial">
                      <span className="input_label">End Time:</span>
                      <EventTimeSelector
                        name={apiConstants.event_end_time}
                        control={control}
                        errors={errors}
                      />
                    </div>
                  </div> */}
                </div>
                {scheduleType === SCHEDULE_TYPE.R &&
                recurringType === RECURRING_TYPE.R ? (
                  <>
                    <h5
                      style={{
                        marginTop: "24px",
                        marginBottom: "16px",
                      }}
                    >
                      Select dates to run this listing
                    </h5>
                    <div className="two-row-input">
                      <SingleDatePicker
                        name={apiConstants.event_start_date}
                        watch={watch}
                        handleSetValue={handleSetValue}
                        label={"Start Date"}
                      />
                      <SingleDatePicker
                        name={apiConstants.event_end_date}
                        watch={watch}
                        handleSetValue={handleSetValue}
                        label={"End Date"}
                      />

                      {/* <div className="form_group">
                        <div className={"input_box"}>
                          <span className="input_label">Start Date:</span>
                          <div
                            className="start-date-recurence"
                            style={{ zIndex: 3 }}
                          >
                            <EventDateSelector
                              name={apiConstants.event_start_date}
                              control={control}
                              minDate={new Date()}
                              selectsStart
                              startDate={watch(apiConstants.event_start_date)}
                              shouldCloseOnSelect={true}
                              getValues={getValues}
                              watch={watch}
                              errors={errors}
                              onCustomChange={() => handleReset(4)}
                            />
                          </div>
                        </div>
                      </div> */}
                      {/* <div className="form_group">
                        <div className="input_box">
                          <span className="input_label">End Date:</span>
                          <div className="end-date-recurence">
                            <EventDateSelector
                              name={apiConstants.event_end_date}
                              control={control}
                              selectsEnd
                              minDate={watch(apiConstants.event_start_date)}
                              startDate={getValues(
                                apiConstants.event_start_date
                              )}
                              endDate={watch(apiConstants.event_end_date)}
                              getValues={getValues}
                              watch={watch}
                              errors={errors}
                              shouldCloseOnSelect={true}
                              highlightDates={
                                watch(apiConstants.event_end_date)
                                  ? customDayClassNames
                                  : ""
                              }
                              disabled={watch(apiConstants.repeat_every) < 1}
                            />
                          </div>
                        </div>
                      </div> */}
                    </div>
                  </>
                ) : null}
                {watch(apiConstants.recur_frequency) === "Month" &&
                  scheduleType === SCHEDULE_TYPE.R &&
                  recurringType === RECURRING_TYPE.R &&
                  watch(apiConstants.event_start_date) && (
                    <div
                      className="form_row"
                      style={{ marginTop: "24px", padding: "0" }}
                    >
                      <div className="select_frequency month_frequency">
                        <div className="form_group">
                          <div className="input_box">
                            <span className="input_label">
                              {"Monthly Frequency:"}
                            </span>
                            <input
                              type="text"
                              value={
                                watch(apiConstants.monthly_frequency) || ""
                              }
                              onClick={() => {
                                setShowMonthFrequency(!showMonthFrequency);
                              }}
                            />
                            {errors[apiConstants.monthly_frequency] ? (
                              <span className="text-danger">{`${
                                errors[apiConstants.monthly_frequency].message
                              }`}</span>
                            ) : null}
                            {showMonthFrequency ? (
                              <>
                                <ul
                                  className="frequency_option"
                                  ref={dropdownRef}
                                >
                                  {dayjs(
                                    watch(apiConstants.event_start_date)
                                  ).date() < 29 && (
                                    <li
                                      className={
                                        watch(apiConstants.on_the_basis) ===
                                        "weekday"
                                          ? "active"
                                          : ""
                                      }
                                      onClick={() => {
                                        handleSetValue(
                                          apiConstants.on_the_basis,
                                          "weekday"
                                        );
                                        setShowMonthFrequency(
                                          !showMonthFrequency
                                        );

                                        handleSetValue(
                                          apiConstants.monthly_frequency,
                                          `Monthly on day ${dayjs(
                                            watch(apiConstants.event_start_date)
                                          ).date()}`
                                        );
                                      }}
                                    >
                                      Monthly on day{" "}
                                      {dayjs(
                                        watch(apiConstants.event_start_date)
                                      ).date()}
                                    </li>
                                  )}
                                  <li
                                    className={
                                      watch(apiConstants.on_the_basis) ===
                                      "date"
                                        ? "active"
                                        : ""
                                    }
                                    onClick={() => {
                                      handleSetValue(
                                        apiConstants.on_the_basis,
                                        "date"
                                      );
                                      setShowMonthFrequency(
                                        !showMonthFrequency
                                      );
                                      handleSetValue(
                                        apiConstants.monthly_frequency,
                                        getWeekNumber(
                                          watch(apiConstants.event_start_date)
                                        )
                                      );
                                    }}
                                  >
                                    {getWeekNumber(
                                      watch(apiConstants.event_start_date)
                                    )}
                                  </li>
                                </ul>
                              </>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            </div>
            <div className="all-button">
              <div
                className="d-md-none d-flex"
                style={{
                  fontSize: "14px",
                  textAlign: "center",
                  color: "#6C6D6F",
                  margin: "0px auto",
                  maxWidth: "310px",
                  paddingBottom: "12px",
                }}
              >
                <p>By cancelling, your schedule progress won’t be saved</p>
              </div>
              <div className="all-button-top">
                <Button
                  btnTxt="Cancel"
                  className="first-parts"
                  onClick={() => {
                    setScheduleLisiting(false);
                  }}
                />
                <Button
                  btnTxt="Add and continue"
                  className={"backtologin"}
                  onClick={handleScheduleListing}
                />
              </div>
              <div
                className="d-none d-md-flex"
                style={{
                  fontSize: "14px",
                  textAlign: "center",
                  color: "#6C6D6F",
                  margin: "0px auto",
                }}
              >
                <p
                  style={{
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  By cancelling, your schedule progress won’t be saved
                </p>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ScheduleListingModal;
