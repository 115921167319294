import React, { useEffect, useState } from "react";
import GlobalHeader from "../Dashboard/GlobalHeader";
import ListingCard from "./ListingCard";
import UserProfile from "./UserProfile";
import RemoveConnectionModal from "../../global/RemoveConnectionModal";
import UserFlaggedModal from "../../global/UserFlaggedModal";
import FlagUserModal from "../../global/FlagUserModal";
import { showToast } from "../../utils";
import { makeGetCall } from "../../utils/Requests";
import endpoints from "../../utils/endpoints";
import { useNavigate, useParams } from "react-router-dom";
import CommonLoader from "../../global/CommonLoader";
import { ROUTE_CONSTANTS } from "../../utils/RouteConstants";

const Profile = () => {
  const params = useParams();
  const [showFlagUser, setShowFlagUser] = useState(false);
  const [showUserFlaggedModal, setShowUserFlaggedModal] = useState(false);
  const [showRemoveConnection, setShowRemoveConnection] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [listings, setListings] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [showLess, setShowLess] = useState(false);
  const [userProfile, setUserProfile] = useState({ name: "" });
  const [, setCurrentLat] = useState<any>(null);
  const [, setCurrentLng] = useState<any>(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (params.id) {
      navigate('/our-mates/'+params.id)
      // fetchListings(params?.id);
      // fetchUserProfile();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);

  useEffect(() => {
    // Check if Geolocation API is available in the browser
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCurrentLat(position.coords.latitude);
          setCurrentLng(position.coords.longitude);
        },
        (err) => {
          showToast(
            "We kindly ask for your location to enhance your app experience; however, we regret to inform you that without this information, we are unable to proceed further.",
            "error"
          );
        }
      );
    } else {
      showToast("Geolocation is not available in your browser.", "error");
    }
  }, []);

  const fetchListings = (userId: any) => {
    setIsLoading(true);
    makeGetCall({ url: `${endpoints.listings}/${userId}` }).then((res) => {
      setListings(res?.results);
      if (res.next) {
        setShowMore(true);
      } else if (res.previous) {
        setShowLess(true);
      } else {
        setShowMore(false);
        setShowLess(false);
      }
      setIsLoading(false);
    });
  };
  const fetchUserProfile = () => {
    setIsLoading(true);
    makeGetCall({ url: `${endpoints.user_by_id}/${params?.id}` }).then(
      (res) => {
        setUserProfile(res.data[0]);
        setIsLoading(false);
      }
    );
  };
  const fetchUserProfileAction = () => {
    makeGetCall({ url: `${endpoints.user_by_id}/${params?.id}` }).then(
      (res) => {
        setUserProfile(res.data[0]);
      }
    );
  };

  return (
    <>
      <GlobalHeader />
      {isLoading ? (
        <CommonLoader />
      ) : (
        <div className="user-profile-layout">
          <div className="user-profile-container">
            <UserProfile
              userProfile={userProfile}
              setShowFlagUser={setShowFlagUser}
              setShowRemoveConnection={setShowRemoveConnection}
              fetchUserProfile={fetchUserProfileAction}
            />
            <div className="profile-right-container">
              <div className="listing-container">
                <div className="listing-heading">Created Listings</div>
                <div className="listing-info">
                  <img src="/static/InfoIconGray.svg" alt="" />
                  {!userProfile.isFlagged ? (
                    <div className="listing-info-text">
                      These listings are created by{" "}
                      <span>{userProfile?.name}</span>. Please feel free to
                      proceed with booking if they align with your preferences.
                    </div>
                  ) : (
                    "You cannot see the listings created by this user because you have flagged them."
                  )}
                </div>
                <div className="listing">
                  {!userProfile.isFlagged ? (
                    listings?.length > 0 ? (
                      <div className="listing">
                        {listings?.map((listing, i) => {
                          return (
                            <ListingCard
                              key={listing?.id}
                              active={listing?.isActive}
                              listing={listing}
                            />
                          );
                        })}
                      </div>
                    ) : (
                      <div className="no-listing-found-container ">
                        <div className="no-listing-found">
                          <img src="/static/no-lisitng.svg" alt="" />
                          <p className="mt-4">No listings available</p>
                        </div>
                      </div>
                    )
                  ) : null}
                </div>
                <div className="flex-row" style={{ justifyContent: "end" }}>
                  {showLess && <div className="more-option">See less</div>}
                  {showMore && !userProfile.isFlagged && (
                    <div className="more-option" style={{ marginLeft: 20 }}>
                      See more
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {showFlagUser && (
        <FlagUserModal
          userProfile={userProfile}
          setShowFlagUser={setShowFlagUser}
          onOk={() => {}}
          onCancel={() => setShowFlagUser(false)}
          setShowUserFlaggedModal={setShowUserFlaggedModal}
          fetchUserProfile={fetchUserProfileAction}
        />
      )}
      {showRemoveConnection && (
        <RemoveConnectionModal
          setShowRemoveConnection={setShowRemoveConnection}
          userProfile={userProfile}
          onOk={() => {}}
          onCancel={() => setShowRemoveConnection(false)}
        />
      )}
      {showUserFlaggedModal && (
        <UserFlaggedModal
          onOk={() => {}}
          onCancel={() => setShowUserFlaggedModal(false)}
        />
      )}
    </>
  );
};

export default Profile;
