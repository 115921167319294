// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown-container {
  position: relative;
  display: inline-block; }
  .dropdown-container .dropdown-content {
    display: flex;
    align-items: center;
    padding: 7px 19.5px;
    border: 1px solid #ddd;
    border-radius: 8px;
    cursor: pointer;
    background: #F3F3F3; }
  .dropdown-container .dropdown-text {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin-right: 6px;
    /* Space between text and icon */ }
  .dropdown-container .dropdown-icon {
    display: flex;
    align-items: center; }
  .dropdown-container .dropdown-icon svg {
    fill: #25272d; }
  .dropdown-container .dropdown-menu {
    position: absolute;
    left: 0;
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: gray;
    color: #25272d;
    z-index: 1000; }
    .dropdown-container .dropdown-menu .dropdown-item {
      padding: 8px 21px;
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      cursor: pointer; }
    .dropdown-container .dropdown-menu .dropdown-item:hover {
      background: #f0f0f0; }
`, "",{"version":3,"sources":["webpack://./src/assets/scss/dropdown.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,qBAAqB,EAAA;EAFvB;IAKI,aAAa;IACb,mBAAmB;IACnB,mBAAmB;IACnB,sBAAsB;IACtB,kBAAkB;IAClB,eAAe;IACf,mBAAmB,EAAA;EAXvB;IAeI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,iBAAiB;IAAE,gCAAA,EAAiC;EAlBxD;IAsBI,aAAa;IACb,mBAAmB,EAAA;EAvBvB;IA2BI,aAAa,EAAA;EA3BjB;IA+BI,kBAAkB;IAElB,OAAO;IACP,WAAW;IACX,sBAAsB;IACtB,kBAAkB;IAClB,gBAAgB;IAChB,cAAc;IACd,aAAa,EAAA;IAvCjB;MA0CM,iBAAiB;MACjB,eAAe;MACf,gBAAgB;MAChB,iBAAiB;MACjB,eAAe,EAAA;IA9CrB;MAkDM,mBAAmB,EAAA","sourcesContent":[".dropdown-container {\n  position: relative;\n  display: inline-block;\n\n  .dropdown-content {\n    display: flex;\n    align-items: center;\n    padding: 7px 19.5px;\n    border: 1px solid #ddd;\n    border-radius: 8px;\n    cursor: pointer;\n    background: #F3F3F3;\n  }\n\n  .dropdown-text {\n    font-size: 16px;\n    font-weight: 500;\n    line-height: 24px;\n    margin-right: 6px; /* Space between text and icon */\n  }\n\n  .dropdown-icon {\n    display: flex;\n    align-items: center;\n  }\n\n  .dropdown-icon svg {\n    fill: #25272d;\n  }\n\n  .dropdown-menu {\n    position: absolute;\n    // top: 100%;\n    left: 0;\n    width: 100%;\n    border: 1px solid #ddd;\n    border-radius: 4px;\n    background: gray;\n    color: #25272d;\n    z-index: 1000;\n\n    .dropdown-item {\n      padding: 8px 21px;\n      font-size: 16px;\n      font-weight: 500;\n      line-height: 24px;\n      cursor: pointer;\n    }\n\n    .dropdown-item:hover {\n      background: #f0f0f0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
