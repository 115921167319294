import { useEffect, useState } from "react";
import SearchFilter from "../ConnectComponents/SearchFilter";
import { scrollToTop, showToast } from "../../../utils";
import { makeGetCall, makeNextPageCall } from "../../../utils/Requests";
import { apiConstants } from "../../../utils/Constants";
import ConnectUserCard from "../ConnectComponents/ConnectUserCard";
import {
  CONNECTION_TYPE,
  getFilterParams,
  SUGGESTIONS_TYPES,
} from "../ConnectConstants";
import ReactPaginate from "react-paginate";
import ConnectMapContainer from "../ConnectComponents/ConnectMapContainer";
import NoMatchedDataCard from "../ConnectComponents/NoMatchedDataCard";
import useCheckDeviceScreen from "../../../hooks/useCheckScreen";
import IconSeeAll from "../../../assets/img/connectIcons/see-all.svg";
import NoSearchedFilter from "../ConnectComponents/NoSearchedFilter";
import { getGeolocation } from "../../../constants/helperConnectUser";

const ConnectSuggestions = () => {
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState({
    interestBased: true,
    nearBy: true,
  });
  const [userCurrentLocation, setUserCurrentLocation] = useState({
    lat: 0,
    long: 0,
  });
  const [users, setUsers] = useState<any>([]);
  const [showMap, setShowMap] = useState(false);
  const [isFilterOn, setIsFilterOn] = useState(false);
  const [filterParameters, setFilterParameters] = useState({});
  const [serachData, setSearchData] = useState<any>();
  const [isSearchMode, setIsSearchMode] = useState(false);
  const [count, setCount] = useState(0);

  const [connectid, setConnectid] = useState<any>(null);
  const [suggestionData, setSuggestionData] = useState({
    interestBased: [],
    nearBy: [],
  });
  const [suggestedConnectionData, setSuggestedConnectionData] = useState([]);
  const [limit] = useState<number>(12);
  const [seeAllDetails, setSeeAllDetails] = useState({
    isClicked: false,
    filter: "interest",
  });
  const [apiDetails, setApiDetails] = useState({
    prevUrl: "",
    nextUrl: "",
    pageCount: 1,
  });
  const [currentPage, setCurrentPage] = useState(0);
  const [offset, setOffset] = useState<number>(0);
  const {isMobile } = useCheckDeviceScreen();
  const handleClearClick = () => {
    setSearch("");
    setSeeAllDetails((prev) => ({ ...prev, isClicked: false }));
    setSuggestedConnectionData([]);
    setSuggestionData(() => ({ interestBased: [], nearBy: [] }));
  };
  const handleSuggestionSearch = (value: any) => {
    setSearch(value);
  };

  const handleFilterApply = (filterData: any) => {
    setIsFilterOn(true);
    setFilterParameters(filterData);
    getSuggestionsList(SUGGESTIONS_TYPES.INTEREST_BASED, 6, true, filterData);
    getSuggestionsList(SUGGESTIONS_TYPES.NEAR_BY, 6, true, filterData);
  };
  const handleSearchedSeeAllClick = () => {
    setIsSearchMode(true);
    /* getSuggestionsList(
      SUGGESTIONS_TYPES.INTEREST_BASED,
      6,
      false,
      filterParameters
    );
    getSuggestionsList(SUGGESTIONS_TYPES.NEAR_BY, 6, false, filterParameters); */
  };
  const handleViewToggle = () => {
    setShowMap((prev) => !prev);
  };
  const handlePageClick = (event: any) => {
    setCurrentPage(event.selected);
    setOffset(event.selected * limit);
    if (apiDetails.nextUrl) {
      makeNextPageCall({ url: apiDetails.nextUrl })
        .then((res) => {
          if (res.next || res.previous) {
            setApiDetails({
              prevUrl: res.previous ?? "",
              nextUrl: res.next ?? "",
              pageCount: res.count ?? 1,
            });
          }
          if(isSearchMode){
            setSearchData(res.results);
          }else{
            setSuggestedConnectionData(res.results);
          }
          
        })
        .catch((err) => {
          showToast(err, "error");
        });
    } else if (apiDetails.prevUrl) {
      makeNextPageCall({ url: apiDetails.prevUrl })
        .then((res) => {
          if (res.next || res.previous) {
            setApiDetails({
              prevUrl: res.previous ?? "",
              nextUrl: res.next ?? "",
              pageCount: res.count ?? 1,
            });
          }
          if(isSearchMode){
            setSearchData(res.results);
          }else{
            setSuggestedConnectionData(res.results);
          }
        })
        .catch((err) => {
          showToast(err, "error");
        });
    }
    scrollToTop();
  };
  const handleSeeAllClick = (filterBy: string) => {
    setSeeAllDetails({ isClicked: true, filter: filterBy });
    if (filterBy === SUGGESTIONS_TYPES.INTEREST_BASED) {
      getSuggestionsList(
        SUGGESTIONS_TYPES.INTEREST_BASED,
        12,
        false,
        filterParameters
      );
    } else {
      getSuggestionsList(
        SUGGESTIONS_TYPES.NEAR_BY,
        12,
        false,
        filterParameters
      );
    }
  };
  const getSuggestionsList = async (
    filterBy = SUGGESTIONS_TYPES.INTEREST_BASED,
    limit = 6,
    isFilterApplied = false,
    filterParams: any
  ) => {
    const userLocationData:any = await getGeolocation();
    
    if (filterBy === SUGGESTIONS_TYPES.INTEREST_BASED) {
      setIsLoading((prev: any) => ({ ...prev, interestBased: true }));
    } else {
      setIsLoading((prev: any) => ({ ...prev, nearBy: true }));
    }

    const params = {
      filter_by: filterBy,
      // point:
      //   userCurrentLocation.lat && userCurrentLocation.long
      //     ? `${userCurrentLocation.long},${userCurrentLocation.lat}`
      //     : "77.7952727,26.8611005",
      limit: limit,
    };

    if(userLocationData?.currentLat && userLocationData?.currentLng ){
      params["point"] = `${userLocationData?.currentLng},${userLocationData?.currentLat}` ;
    }

    await makeGetCall({
      url: apiConstants.get_connect_suggestions,
      params: search
        ? { ...params, search: search }
        : isFilterApplied
        ? { ...params, ...getFilterParams(filterParams) }
        : params,
    })
      .then((res) => {
        if (res.status.code === 200) {
          if (limit === 12) {
            const data = res.results;
            if (data) {
              setSuggestedConnectionData(data);
            }
            if (res.next || res.previous) {
              setApiDetails({
                prevUrl: res.previous ?? "",
                nextUrl: res.next ?? "",
                pageCount: res.count ?? 1,
              });
            }
          } else {
            if (filterBy === SUGGESTIONS_TYPES.INTEREST_BASED) {
              setSuggestionData((prev) => ({
                ...prev,
                interestBased: res.results,
              }));
              setIsLoading((prev: any) => ({ ...prev, interestBased: false }));
            } else {
              setSuggestionData((prev) => ({
                ...prev,
                nearBy: res.results,
              }));
              setIsLoading((prev: any) => ({ ...prev, nearBy: false }));
            }
          }
          if (isFilterOn) {
            setIsFilterOn(false);
          }
        }
      })
      .catch((err: any) => {
        showToast(err, "error");
      });
  };
  // const getSearchedSuggestion = (
  //   filterBy = SUGGESTIONS_TYPES.INTEREST_BASED,
  //   limit = 8
  // ) => {
  //   const params = {
  //     filter_by: filterBy,
  //     point:
  //       userCurrentLocation.lat && userCurrentLocation.long
  //         ? `${userCurrentLocation.lat},${userCurrentLocation.long}`
  //         : "77.7952727,26.8611005",
  //     limit: limit,
  //     search: search,
  //   };
  //   makeGetCall({
  //     url: apiConstants.get_connect_suggestions,
  //     params,
  //   })
  //     .then((res) => {
  //       if (res.status.code === 200) {
  //         const data = res.results;

  //         if (res.next || res.previous) {
  //           setApiDetails({
  //             prevUrl: res.previous ?? "",
  //             nextUrl: res.next ?? "",
  //             pageCount: res.count ?? 1,
  //           });
  //         }
  //         setSearchData(data);
  //       }
  //     })
  //     .catch((err: any) => {
  //       showToast(err, "error");
  //     });
  // };

  const getSearchedSuggestion = () => {
    const params = {
      tab:"Users",
      search: search,
      point:`${userCurrentLocation.long},${userCurrentLocation.lat}`,
      limit:12,
    };
    makeGetCall({
      url: apiConstants.get_all_users,
      params
    })
      .then((res) => {
        if (res.status.code === 200) {
          const data = res.results;

          if (res.next || res.previous) {
            setApiDetails({
              prevUrl: res.previous ?? "",
              nextUrl: res.next ?? "",
              pageCount: res.count ?? 1,
            });
          }
          setCount(res?.count)
          setSearchData(data);
        }
      })
      .catch((err: any) => {
        showToast(err, "error");
      });
  };

  useEffect(() => {
    // Check if Geolocation API is available in the browser
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserCurrentLocation({
            lat: position.coords.latitude,
            long: position.coords.longitude,
          });
          getSuggestionsList(
            SUGGESTIONS_TYPES.INTEREST_BASED,
            6,
            false,
            filterParameters
          );
          getSuggestionsList(
            SUGGESTIONS_TYPES.NEAR_BY,
            6,
            false,
            filterParameters
          );
        },
        (err) => {
          showToast(
            "We kindly ask for your location to enhance your app experience; however, we regret to inform you that without this information, we are unable to proceed further.",
            "error"
          );
        }
      );
    } else {
      showToast("Geolocation is not available in your browser.", "error");
    }
  }, []);

  useEffect(() => {
    if (search !== "") {
      setIsSearchMode(true);
      getSearchedSuggestion();
    }
    if (search === "") {
      setIsSearchMode(false);
      getSuggestionsList(
        SUGGESTIONS_TYPES.INTEREST_BASED,
        6,
        false,
        filterParameters
      );
      getSuggestionsList(SUGGESTIONS_TYPES.NEAR_BY, 6, false, filterParameters);
    }
  }, [search]);
  return (
    <div>
     {!isMobile && <div className="connect-page-heading">Suggestions</div> }
      <SearchFilter
        showMap={showMap}
        handleViewToggle={handleViewToggle}
        handleSearchChange={handleSuggestionSearch}
        serachData={serachData}
        handleSeeAll={handleSearchedSeeAllClick}
        isFilterSupported={false}
        handleFilterApply={handleFilterApply}
        handleClearSearch={handleClearClick}
      />

      {!showMap ? (
        !isSearchMode ? (

        !(seeAllDetails.isClicked && suggestedConnectionData.length > 0) ? (
          <div className="suggestion-wrapper">
            <div>
              <div className="suggestion-heading">
              Suggestions based on interest
              </div>
              <div className="suggestion-content">
                {isLoading.interestBased ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      marginTop: "200px",
                    }}
                  >
                    <div className="lds-ring">
                      <div style={{ textAlign: "center" }}>
                        <img
                          style={{ width: "50px" }}
                          src="/static/spinner.svg"
                          alt="loader"
                        />
                      </div>

                      <div className="text__container">
                        <span>Please wait... Loading</span>
                      </div>
                    </div>
                  </div>
                ) : suggestionData.interestBased.length === 0 ? (
                  <NoMatchedDataCard />
                ) : (
                  suggestionData.interestBased.map((suggestion) => (
                    <ConnectUserCard
                      connectionData={suggestion}
                      key={suggestion.id}
                      mode={CONNECTION_TYPE.SUGGESTIONS}
                      isScrollable={false}
                    />
                  ))
                )}{" "}
              </div>
              <div className="see-all-btn">
                <div
                  className="text-button"
                  onClick={() => {
                    handleSeeAllClick(SUGGESTIONS_TYPES.INTEREST_BASED);
                  }}
                >
                  <img src={IconSeeAll} height="30px" width="67px" />
                </div>
              </div>
            </div>
            <div>
              <div className="suggestion-heading">Suggestions nearby you</div>
              <div className="suggestion-content">
                {" "}
                {isLoading.nearBy ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                      marginTop: "200px",
                    }}
                  >
                    <div className="lds-ring">
                      <div style={{ textAlign: "center" }}>
                        <img
                          style={{ width: "50px" }}
                          src="/static/spinner.svg"
                          alt="loader"
                        />
                      </div>

                      <div className="text__container">
                        <span>Please wait... Loading</span>
                      </div>
                    </div>
                  </div>
                ) : suggestionData.nearBy.length === 0 ? (
                  <NoMatchedDataCard />
                ) : (
                  suggestionData.nearBy.map((suggestion) => (
                    <ConnectUserCard
                      connectionData={suggestion}
                      key={suggestion.id}
                      mode={CONNECTION_TYPE.SUGGESTIONS}
                      isScrollable={false}
                    />
                  ))
                )}
              </div>
              <div className="see-all-btn">
                <div
                  className="text-button"
                  onClick={() => {
                    handleSeeAllClick(SUGGESTIONS_TYPES.NEAR_BY);
                  }}
                >
                  <img src={IconSeeAll} height="30px" width="67px" />
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="content-title">{`Suggestions based on ${
              seeAllDetails.filter === "interest" ? "interest" : "near by"
            }`}</div>
            <div
              className="content-style"
              // style={{ display: "flex", flexWrap: "wrap", gap: "24px" }}
            >
              {suggestedConnectionData?.map((connection) => (
                <ConnectUserCard
                  connectionData={connection}
                  key={connection.id}
                  mode={CONNECTION_TYPE.SUGGESTIONS}
                  isScrollable={false}
                />
              ))}
            </div>
            <div className="paginate-style">
              <div className="pages-inner-container">
                <div className="pagination-box">
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel={
                      <>
                        <div
                          className={
                            Math.ceil(apiDetails.pageCount / limit) ===
                            currentPage + 1
                              ? "previous-button disabled"
                              : "previous-button"
                          }
                        >
                          
                          <img src="/static/u_angle-left-b(1).svg" alt="" />
                          {!isMobile ? "Next " : ""}
                        </div>
                      </>
                    }
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={!isMobile ? 5 : 2}
                    forcePage={currentPage}
                    pageCount={Math.ceil(apiDetails.pageCount / limit)}
                    previousLabel={
                      <>
                        <div className="previous-button">
                        {!isMobile ? "Previous " : ""}
                          <img src="/static/u_angle-left-b.svg" alt="" />
                        </div>
                      </>
                    }
                    renderOnZeroPageCount={null}
                    pageClassName="page-item"
                    pageLinkClassName="page-link"
                    previousClassName="page-item"
                    previousLinkClassName="page-link"
                    nextClassName="page-item"
                    nextLinkClassName="page-link"
                    breakClassName="page-item"
                    breakLinkClassName="page-link"
                    containerClassName="pagination"
                    activeClassName="active"
                  />
                </div>
              </div>
            </div>
          </div>
        )
      ) : (
        <div>
        <div style={{fontSize:"14px", fontFamily:"Poppins", lineHeight:"24px", fontWeight:"500", margin:"21px 0px 12px 0px"}}>{`Search results (${count})`}</div>
        {  serachData?.length > 0 ? <div
          className="content-style"
          // style={{ display: "flex", flexWrap: "wrap", gap: "24px" }}
        >
          {serachData?.map((connection) => (
            <ConnectUserCard
              connectionData={connection}
              key={connection.id}
              mode={CONNECTION_TYPE.SUGGESTIONS}
              isScrollable={false}
            />
          ))}
        </div> : <NoSearchedFilter searchTerm={search}/>}
        {count > 12 && <div className="paginate-style">
          <div className="pages-inner-container">
            <div className="pagination-box">
              <ReactPaginate
                breakLabel="..."
                nextLabel={
                  <>
                    <div
                      className={
                        Math.ceil(apiDetails.pageCount / limit) ===
                        currentPage + 1
                          ? "previous-button disabled"
                          : "previous-button"
                      }
                    >
                      
                      <img src="/static/u_angle-left-b(1).svg" alt="" />
                      {!isMobile ? "Next " : ""}
                    </div>
                  </>
                }
                onPageChange={handlePageClick}
                pageRangeDisplayed={!isMobile ? 5 : 2}
                forcePage={currentPage}
                pageCount={Math.ceil(apiDetails.pageCount / limit)}
                previousLabel={
                  <>
                    <div className="previous-button">
                    {!isMobile ? "Previous " : ""}
                      <img src="/static/u_angle-left-b.svg" alt="" />
                    </div>
                  </>
                }
                renderOnZeroPageCount={null}
                pageClassName="page-item"
                pageLinkClassName="page-link"
                previousClassName="page-item"
                previousLinkClassName="page-link"
                nextClassName="page-item"
                nextLinkClassName="page-link"
                breakClassName="page-item"
                breakLinkClassName="page-link"
                containerClassName="pagination"
                activeClassName="active"
              />
            </div>
          </div>
        </div>
        }
      </div>
      )
      ) : (
        <div className="inner-contet">
          <div className="content-title"></div>
         {isSearchMode && <div style={{fontSize:"14px", fontFamily:"Poppins", lineHeight:"24px", fontWeight:"500", margin:"21px 0px 12px 0px"}}>{`Search results (${count})`}</div>}
          <ConnectMapContainer
            eventMap={ isSearchMode ? serachData :
              seeAllDetails.isClicked
                ? suggestedConnectionData
                : [...suggestionData.interestBased, ...suggestionData.nearBy]
            }
            latitude={userCurrentLocation.lat}
            longitude={userCurrentLocation.long}
            mode={CONNECTION_TYPE.SUGGESTIONS}
          />
        </div>
      )}
    </div>
  );
};

export default ConnectSuggestions;
