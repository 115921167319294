import React from "react";
import { apiConstants } from "../../../utils/Constants";
import "./style.scss";
import InputField from "../../../global/InputField";
import InfoCard from "../InfoCard/InfoCard";
import HorizontalLine from "../HorizontalLine/HorizontalLine";
import moment from "moment";

type Props = {
  [key: string]: any;
};

const MeetingLinkInput = (props: Props) => {
  const { handleSetValue, index, data, watch } = props;
  return (
    <div className="meeting_link_form">
      <div className="date_stamp_wrap">
        <div className="order">{index + 1}</div>
        <div className="time_date">
          <div className="date_stamp">
            <img src="/static/createListingIcon/calenderGrey.svg" alt="" />
            <span>{data?.date}</span>
          </div>
          <div className="time_stamp">
            <img src="/static/createListingIcon/clockGrey.svg" alt="" />
            <span>
              {moment(data?.event_start_time)?.format("HH:mm A")} -{" "}
              {moment(data?.event_end_time)?.format("HH:mm A")}
            </span>
          </div>
        </div>
      </div>
      <div className="vertical_line" />
      <div className="link_box">
        <div className="link_box_wrap">
          <img
            className="start_icon"
            src="/static/createListingIcon/linkGrey.svg"
            alt=""
          />
          <div className="input_container">
            <input
              className="input_item"
              value={data?.virtual_link}
              onChange={(e) =>
                handleSetValue(
                  `virtual_links_multiple[${index}].virtual_link`,
                  e.target.value
                )
              }
              placeholder="Add meeting link here"
            />
            {watch(`virtual_links_multiple[${index}].virtual_link`) && (
              <img
                className="clear_icon"
                src="/static/createListingIcon/clearGrey.svg"
                alt=""
                onClick={() =>
                  handleSetValue(
                    `virtual_links_multiple[${index}].virtual_link`,
                    ""
                  )
                }
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MeetingLinkInput;
