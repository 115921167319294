import { useEffect, useState } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import moment from "moment";
import Button from "../../../global/Button";
import {
  showToast,
  scrollToTop,
  formatNumber,
  handleVideoClick,
  getItemFromStore,
} from "../../../utils";

import {
  makeDeleteCall,
  makeGetCall,
  makeNextPageCall,
  makePatchCall,
  makePostCall,
} from "../../../utils/Requests";
import apiConfig from "../../../utils/endpoints";
import endpoints from "../../../utils/endpoints";
import DashboardWrapper from "../../Dashboard/DashboardWrapper";
import { apiConstants } from "../../../utils/Constants";
import ConfirmStripePopup from "../../Dashboard/CreateListing.tsx/ConfirmStripePopup";
import AddBankAccountPopup from "../../Dashboard/CreateListing.tsx/AddBankAccountPopup";
import WaitingStripeNewAccountPopup from "../../Dashboard/CreateListing.tsx/WaitingStripeNewAccountPopup";
import ReactSimplyCarousel from "react-simply-carousel";
import { LISTING_TYPE, USER_TYPE } from "../../../types";
import img from "../../../assets/img/fitness.png";
import img2 from "../../../assets/img/star.png";
import { Modal } from "react-bootstrap";
import ApiLoader from "../../../global/ApiLoader";
import ReactPaginate from "react-paginate";
import { STRING_CONSTANTS } from "../../../utils/StringConstant";
import LoadingLayer from "../../ConnectUserProfile/LoaderLayer";

interface ListingData {
  [key: string]: any;
}

const FavouriteNew = () => {
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<any>("Events");
  const tabs = ["Events", "People"];
  const [favListEvents, setFavListEvents] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showShare, setShowShare] = useState(false);
  const [detailsData, setDetailsData] = useState(null);
  const [favList, setFavList] = useState<any>([]);
  const [nextUrl, setNextUrl] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [pageCount, setPageCount] = useState<number>(1);
  const [limit] = useState<number>(10);
  const [offset, setOffset] = useState<number>(0);
  const [nextUrlPeople, setNextUrlpeople] = useState<any>(null);
  const [isLocationAccessible, setIslocationAccessible] = useState<any>(null);
  const [currentLat, setCurrentLat] = useState<any>(null);
  const [currentLng, setCurrentLng] = useState<any>(null);

  useEffect(() => {
    // Check if Geolocation API is available in the browser
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setCurrentLat(position.coords.latitude);
          setCurrentLng(position.coords.longitude);
          setIslocationAccessible(true);
        },
        (err) => {
          setIslocationAccessible(false);
        }
      );
    } else {
      setIslocationAccessible(false);
      showToast("Geolocation is not available in your browser.", "error");
    }
  }, []);

  const getData = (page = 0) => {
    setIsLoading(true);
    let params = {
      limit: limit,
      offset: page * limit,
    };
    if (currentLng && currentLat) {
      params["dist"] = 200000;
      params["point"] = `${currentLng},${currentLat}`;
    }
    makeGetCall({
      url: `${endpoints.favourite_listing}`,
      params: params,
    }).then((res) => {
      setIsLoading(false);
      setFavListEvents(res?.results);
      setPageCount(res?.count);
      setNextUrl(res?.next || null);
    });
  };

  const getDataPeople = (page = 0) => {
    setIsLoading(true);
    let params = {
      limit: limit,
      offset: page * limit,
    };
    if (currentLng && currentLat) {
      params["dist"] = 200000;
      params["point"] = `${currentLng},${currentLat}`;
    }
    makeGetCall({
      url: `${endpoints.favourite}`,
      params: params,
    }).then((res) => {
      setIsLoading(false);
      setFavList(res?.results);
      setPageCount(res?.count);
      setNextUrlpeople(res?.next || null);
    });
  };

  useEffect(() => {
    if (activeTab === "Events") {
      getData();
    } else {
      getDataPeople();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    setCurrentPage(0);
    setPageCount(1);
  }, [activeTab, currentLat]);

  function EventImageCarousel({ item }: any) {
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);

    return (
      <div className="carousal-item-custom">
        {item?.photos?.length > 1 ? (
          <ReactSimplyCarousel
            activeSlideIndex={activeSlideIndex}
            onRequestChange={setActiveSlideIndex}
            itemsToShow={1}
            itemsToScroll={1}
            dotsNav={{
              show: true,
              containerProps: {
                className: "dots-nav-container",
                style: {
                  position: "absolute",
                  bottom: "10%",
                  gap: "6px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                },
              },
              itemBtnProps: {
                style: {
                  height: 6,
                  width: 6,
                  borderRadius: "50%",
                  border: 0,
                  background: "rgba(255, 255, 255, 0.4)",
                },
              },
              activeItemBtnProps: {
                style: {
                  height: 6,
                  width: 6,
                  borderRadius: "50%",
                  border: 0,
                  background: "white",
                },
              },
            }}
            forwardBtnProps={{
              //here you can also pass className, or any other button element attributes
              className: "image-carousel-button",

              style: {
                background: "rgba(255, 255, 255, 0.4)",
                borderRadius: "50%",
                border: "none",
                cursor: "pointer",
                height: 24,
                width: 24,
                position: "absolute",
                zIndex: "0",
                top: "50%",
                right: "16px",
                translate: "0 -50%",
                display: "none",
                justifyContent: "center",
                alignItems: "center",
              },
              children: (
                <img
                  style={{ width: "16px", height: "16px" }}
                  alt=""
                  src="/static/chevron-black-right.svg"
                />
              ),
            }}
            backwardBtnProps={{
              //here you can also pass className, or any other button element attributes
              className: "image-carousel-button",
              style: {
                background: "rgba(255, 255, 255, 0.4)",
                borderRadius: "50%",
                border: "none",
                cursor: "pointer",
                height: 24,
                width: 24,
                position: "absolute",
                zIndex: "99",
                top: "50%",
                left: "16px",
                translate: "0 -50%",
                display: "none",
                justifyContent: "center",
                alignItems: "center",
              },
              children: (
                <img
                  style={{ width: "16px", height: "16px" }}
                  alt=""
                  src="/static/chevron-black-left.svg"
                />
              ),
            }}
            speed={400}
            easing="linear"
          >
            {/* here you can also pass any other element attributes. Also, you can use your custom components as slides */}

            {item?.photos.map((ph) =>
              /\.(mp4|mov|avi)$/.test(ph?.documentName?.toLowerCase()) ? (
                <>
                  <video
                    src={ph?.uploadDocument}
                    className="event_img"
                    //preload="none"
                    //autoPlay
                    controls
                    controlsList="nodownload"
                    // playsInline
                    // loop
                    muted
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      e.preventDefault();
                      scrollToTop();
                      navigate(`/listing-two/${item?.listingData?.id}`);
                    }}
                  >
                    <source src={ph?.uploadDocument} />
                  </video>
                </>
              ) : (
                <img
                  src={ph?.uploadDocument || "/static/expertise2.png"}
                  className="event_img"
                  alt=""
                  loading="lazy"
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToTop();
                    navigate(`/listing-two/${item?.listingData?.id}`);
                  }}
                />
              )
            )}
          </ReactSimplyCarousel>
        ) : item?.photos?.length == 1 ? (
          /\.(mp4|mov|avi)$/.test(
            item?.photos[0].documentName?.toLowerCase()
          ) ? (
            <>
              <div className="individual-image-video-block">
                <div className="individual-image-video-block-2">
                  <video
                    src={item?.photos[0].uploadDocument}
                    className="event_img"
                    controls
                    controlsList="nodownload"
                    muted
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      e.preventDefault();
                      scrollToTop();
                      navigate(`/listing-two/${item?.listingData?.id}`);
                    }}
                  >
                    <source src={item?.photos[0].uploadDocument} />
                  </video>
                </div>
              </div>
            </>
          ) : (
            <div className="individual-image-video-block">
              <div className="individual-image-video-block-2">
                <img
                  src={
                    item?.photos[0]?.uploadDocument || "/static/expertise2.png"
                  }
                  className="event_img"
                  alt=""
                  loading="lazy"
                  onClick={(e) => {
                    e.preventDefault();
                    scrollToTop();
                    navigate(`/listing-two/${item?.listingData?.id}`);
                  }}
                />
              </div>
            </div>
          )
        ) : /\.(mp4|mov|avi)$/.test(item?.media?.toLowerCase()) ? (
          <>
            <video
              src={item?.media}
              className="event_img"
              controls
              controlsList="nodownload"
              muted
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                e.preventDefault();
                scrollToTop();
                navigate(`/listing-two/${item?.listingData?.id}`);
              }}
            >
              <source src={item?.media} />
            </video>
          </>
        ) : (
          <img
            src={item?.media || "/static/expertise2.png"}
            className="event_img"
            alt=""
            loading="lazy"
            onClick={(e) => {
              e.preventDefault();
              scrollToTop();
              navigate(`/listing-two/${item?.listingData?.id}`);
            }}
          />
        )}
      </div>
    );
  }

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(detailsData?.dynamicLink);

      showToast("Copied!!", "success");
    } catch (err) {
      showToast("Failed to copy text.", "error");
    }
  };
  const encodedMessage = encodeURIComponent("Check out this link!");
  const encodedLink = encodeURIComponent(detailsData?.dynamicLink);

  const handleWhatsAppShare = () => {
    const whatsappUrl = `https://api.whatsapp.com/send?text=${encodedMessage}%20${encodedLink}`;
    window.open(whatsappUrl, "_blank");
  };

  const handleSmsShare = () => {
    const smsUrl = `sms:?body=${encodedMessage}%20${encodedLink}`;
    window.open(smsUrl, "_blank");
  };

  const handleMessengerShare = () => {
    const messengerUrl = `fb-messenger://share?link=${encodedLink}`;
    window.open(messengerUrl, "_blank");
  };

  const handleTwitterShare = () => {
    const twitterUrl = `https://twitter.com/intent/tweet?text=${encodedMessage}%20${encodedLink}`;
    window.open(twitterUrl, "_blank");
  };

  const handleFacebookShare = () => {
    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodedLink}`;
    window.open(facebookUrl, "_blank");
  };

  const handleEmailShare = () => {
    const emailUrl = `mailto:?subject=${encodedMessage}&body=${encodedMessage}%20${encodedLink}`;
    window.open(emailUrl, "_blank");
  };

  const handleNotShowShare = () => {
    setShowShare(false);
  };

  const handleDislike = (id) => {
    const apiPayload = {
      listing: id,
    };
    makeDeleteCall({
      url: endpoints.favourite_listing,
      apiPayload,
      content_type: "multipart/form-data",
    }).then((res) => {
      showToast(res.status.message, "success");
      getData();
    });
  };

  const FavouriteEvents = ({ favListEvents }) => {
    return (
      <>
        <div className="favourite-results-wrapper">
          {favListEvents?.map((item) => (
            <div className="favourite-card-wrapper">
              <div className="event-link-title">
                <div className="listing_box" key={item.id}>
                  <div className="crousel-container">
                    <div className="span_box">
                      <span
                        className="share"
                        onClick={() => {
                          setDetailsData(item?.listingData);
                          setShowShare(true);
                        }}
                      >
                        <img src="/static/Card-Share-Icon.svg" alt="" />
                      </span>

                      <div className="span_box">
                        <span
                          className="heart"
                          onClick={() => handleDislike(item.listingData.id)}
                        >
                          <img src="/static/heartred.svg" alt="" />
                        </span>
                      </div>
                    </div>
                    <div className="span_box">
                      {item.listingData.listingEventType !== "V" ? (
                        <>
                          <img
                            className="event_type"
                            src="/static/event-type2.svg"
                            alt=""
                          />
                        </>
                      ) : (
                        <>
                          <span className="event_type">
                            <img src="/static/event-type.svg" alt="" />
                          </span>
                        </>
                      )}
                    </div>
                    {item.listingData.listingEventType !== "V" ? (
                      <div className="span_box">
                        {item?.listingData?.distance ? (
                          <span className="distance">
                            {formatNumber(
                              item?.listingData?.distance?.toFixed(2)
                            )}{" "}
                            km's away...
                          </span>
                        ) : null}
                      </div>
                    ) : (
                      ""
                    )}

                    <EventImageCarousel key={item.id} item={item.listingData} />
                    {item?.listingType === LISTING_TYPE.SPECIAL && (
                      <div className="special-box">
                        <img
                          src={img2}
                          alt=""
                          style={{
                            height: "30px",
                            width: "30px",
                            marginRight: "auto",
                          }}
                        />
                        <div className="exc-text">Exclusive for Members</div>
                      </div>
                    )}

                    <div className="span_box">
                      {item?.listingData?.listingType === LISTING_TYPE.C ? (
                        <span className="price">Join</span>
                      ) : item?.listingData?.price ||
                        item?.listingData?.monthlyCost ? (
                        <span className="price">
                          {item?.listingData?.price == "0.00" ? (
                            "Free"
                          ) : (
                            <>
                              {" "}
                              AUD{" "}
                              {item?.listingData?.price ||
                                item?.listingData?.monthlyCost}
                            </>
                          )}
                        </span>
                      ) : (
                        <span className="price">Free</span>
                      )}
                    </div>
                  </div>

                  <div
                    className="limited-event-info"
                    onClick={(e) => {
                      e.preventDefault();
                      scrollToTop();
                      navigate(`/listing-two/${item?.listingData?.id}`);
                    }}
                  >
                    <div className="title item">
                      <Link
                        className="event-link-title"
                        to={`/listing-two/${item?.listingData?.id}`}
                      >
                        {item?.listingData?.title}
                      </Link>
                    </div>

                    <div
                      className="item"
                      style={{ fontSize: "12px", fontWeight: "400" }}
                    >
                      {item?.listingData?.listingEventType === "P" ? (
                        <>
                          <img src="/static/ion_location-outline.svg" alt="" />
                          <span style={{ paddingLeft: "4px" }}>
                            {
                              item?.listingData?.scheduleDetails?.address
                                ?.address
                            }
                          </span>
                        </>
                      ) : (
                        <div
                          style={{
                            width: "100%",
                            height: "16px",
                          }}
                        ></div>
                      )}
                    </div>
                    {item?.listingType === LISTING_TYPE.C ? null : (
                      <div
                        className="item"
                        style={{ fontSize: "12px", fontWeight: "400" }}
                      >
                        <img src="/static/calendar-2.svg" alt="" />
                        <span style={{ paddingLeft: "4px" }}>
                          {`${moment(item?.events?.[0]?.date).format(
                            "DD MMM, YYYY"
                          )} • ${moment(
                            item?.events?.[0]?.startTime?.substring(0, 5),
                            ["HH:mm"]
                          ).format("hh:mm A")}
                                      - 
                                      ${moment(
                                        item?.events?.[0]?.endTime?.substring(
                                          0,
                                          5
                                        ),
                                        ["HH:mm"]
                                      ).format("hh:mm A")}`}
                        </span>
                      </div>
                    )}
                  </div>

                  {item?.listingType === LISTING_TYPE.SPECIAL ? (
                    <div className="name name-special">
                      <img
                        src={img}
                        alt=""
                        style={{
                          height: "100%",
                          width: "65%",
                          marginRight: "auto",
                        }}
                      />
                    </div>
                  ) : (
                    <div
                      className="name"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        navigate(
                          `/our-mates/${item?.listingData.createdBy?.id}`
                        );
                      }}
                    >
                      <Link
                        className="event-link-title"
                        to={`/listing-two/${item?.listingData?.id}`}
                      >
                        {["mp4", "mov", "avi"].includes(
                          item?.listingData.createdBy?.profilePicture
                            ?.split(".")
                            .splice(-1)[0]
                            ?.toLowerCase()
                        ) ? (
                          <video
                            src={item?.listingData.createdBy?.profilePicture}
                            //preload="none"
                            className="event_img"
                            autoPlay
                            //controls
                            // playsInline
                            // loop
                            muted
                          >
                            <source
                              src={item?.listingData?.createdBy?.profilePicture}
                            />
                          </video>
                        ) : (
                          <img
                            src={
                              item?.listingData?.createdBy?.profilePicture ||
                              "/static/dummyimg.svg"
                            }
                            alt=""
                            loading="lazy"
                          />
                        )}
                      </Link>
                      <span style={{ fontSize: "12px" }}>
                        Event by{" "}
                        <strong>
                          {item?.listingData?.createdBy?.tradingName
                            ? item?.listingData?.createdBy?.tradingName
                            : item?.listingData?.createdBy?.name}
                        </strong>
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </>
    );
  };

  const FavouritePeople = () => {
    return (
      <>
        <div className="favourite-results-wrapper">
          {favList?.map((item: any, index: number) => {
            return (
              <div className="favourite-people-card-wrap">
                <div
                  className="cover-photo-wrap"
                  onClick={() => navigate(`/our-mates/${item.user.id}`)}
                >
                  <div
                    className="profile-image-big"
                    style={{
                      height: 150,
                      width: "100%",
                      borderRadius: "12px 12px 0px 0px",
                      backgroundImage: item?.user?.coverPhotoVideo
                        ? ["mp4", "mov", "avi"].includes(
                            item?.profilePicture
                              ?.split(".")
                              .splice(-1)[0]
                              .toLowerCase()
                          )
                          ? `url(${item?.user?.coverPhotoVideo})`
                          : `url(${item?.user?.coverPhotoVideo})`
                        : "url(/static/expertise2.png)",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                    }}
                  ></div>
                </div>
                <div
                  className="profile-image-user-type-box"
                  onClick={() => navigate(`/our-mates/${item.user.id}`)}
                >
                  {["mp4", "mov", "avi"].includes(
                    item?.user?.profilePicture
                      ?.split(".")
                      .splice(-1)[0]
                      .toLowerCase()
                  ) ? (
                    <>
                      <div
                        style={{
                          position: "absolute",
                          top: "-70px",
                          left: "16px",
                        }}
                      >
                        <video
                          src={
                            item?.user?.profilePicture ||
                            "/static/expertise2.png"
                          }
                          autoPlay
                          playsInline
                          loop
                          muted
                          style={{
                            cursor: "pointer",
                            width: "136px",
                            height: "136px",
                            objectFit: "cover",
                            verticalAlign: "middle",
                            borderRadius: "100%",
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <source src={item?.user?.profilePicture} />
                        </video>
                      </div>
                    </>
                  ) : (
                    <div
                      style={{
                        position: "absolute",
                        top: "-70px",
                        left: "16px",
                      }}
                    >
                      <img
                        style={{
                          height: 136,
                          width: 136,
                          top: -70,
                          border: "4px solid #FFFFFF",
                          borderRadius: "50%",
                        }}
                        src={
                          item?.user?.profilePicture || "/static/expertise2.png"
                        }
                        alt=""
                      />
                    </div>
                  )}
                  <div className="trainer-chip-outer">
                    {item.user?.isTrainer ? (
                      <span className="trainer-chip">Trainer</span>
                    ) : null}
                  </div>
                </div>

                <div className="details-box">
                  <div
                    className="username"
                    onClick={() => navigate(`/our-mates/${item.user.id}`)}
                  >
                    {item?.user?.name},{" "}
                    {item?.user?.age ? (
                      <span className="age">{item?.user?.age} years</span>
                    ) : null}
                  </div>
                  {item?.user?.distance ? (
                    <div className="location">
                      <span>
                        <img alt="" src={"/static/locationsmallorange.svg"} />
                      </span>
                      <span>
                        {formatNumber(item?.user?.distance?.toFixed(2))} km’s
                        away...
                      </span>
                    </div>
                  ) : null}
                  <div className="interest-tag-section">
                    {item?.user?.interests?.slice(0, 3)?.map((p: any) => {
                      return (
                        <div key={p} className="interest-tag">
                          {p.title}
                        </div>
                      );
                    })}
                  </div>
                  <div className="action-buttons">
                    <span
                      className="like-people"
                      onClick={() =>
                        removeFromFavList(item.user.id, item.markedBy)
                      }
                    >
                      <img alt="" src="/static/likepeople.svg" />
                    </span>
                    <Button
                      disabled={item.connectionStatus.status === "Pending"}
                      btnTxt={
                        item.connectionStatus.status === "Pending"
                          ? "Request sent"
                          : "Connect"
                      }
                      className={
                        item.connectionStatus.status === "Pending"
                          ? "disabled-connect"
                          : "connect"
                      }
                      onClick={() => connectUser(item.user.id)}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </>
    );
  };

  const removeFromFavList = (userId: string, markedBy: string) => {
    let apiPayload = {
      user: userId,
      markedBy: markedBy,
    };
    makeDeleteCall({
      url: endpoints.favourite,
      apiPayload,
    })
      .then((res) => {
        if (res.status.code === 200) {
          showToast(res.status?.message, "success");
          getDataPeople();
        } else {
          showToast(res.status?.message, "error");
        }
      })
      .catch((err) => {
        showToast(err, "error");
      });
  };

  const connectUser = (connectid) => {
    makePostCall({
      url: `auth/${connectid}/connections/`,
    })
      .then((res) => {
        if (res.status.code === 200) {
          showToast(res.status.message, "success");
        } else {
          showToast(res.status.message || res.error.message, "error");
        }
      })
      .catch((err) => {
        showToast(err, "error");
      });
  };

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
    scrollToTop();

    getData(event.selected);
    // setOffset(event.selected * limit);

    // if (nextUrl) {
    //   makeNextPageCall({ url: nextUrl })
    //     .then((res) => {
    //       console.log("res: ", res);
    //       setNextUrl(res?.next);
    //       setPageCount(res?.count);
    //       setFavListEvents(res.results);
    //     })
    //     .catch((err) => {
    //       showToast(err, "error");
    //     });
    //   scrollToTop();
    // }
  };
  const handlePageClickPeople = (event) => {
    setCurrentPage(event.selected);
    scrollToTop();
    getDataPeople(event.selected);
    // setCurrentPage(event.selected);
    // setOffset(event.selected * limit);
    // if (nextUrlPeople) {
    //   makeNextPageCall({ url: nextUrlPeople })
    //     .then((res) => {
    //       const tempData = res.results;
    //       setNextUrl(res?.next);
    //       setPageCount(res?.count);
    //       setFavList(tempData as never);
    //     })
    //     .catch((err) => {
    //       showToast(err, "error");
    //     });
    //   scrollToTop();
    // }
  };

  return (
    <DashboardWrapper showTab={true}>
      {isLoading && <LoadingLayer loading={isLoading} />}
      <div className="personal-details-wrapper">
        <div className="favourite-new-contenet">
          <div className="page-title">Favourites</div>
          <div className="tabs-container-wrap">
            <div className="tabs-container">
              {tabs.map((tab) => (
                <button
                  key={tab}
                  className={`tab-button ${activeTab === tab ? "active" : ""}`}
                  onClick={() => setActiveTab(tab)}
                >
                  {tab.toUpperCase()}
                </button>
              ))}
            </div>
            {activeTab === "Events" ? (
              <>
                {favListEvents.length ? (
                  <FavouriteEvents favListEvents={favListEvents} />
                ) : (
                  <div className="no-event-outer-new">
                    <div className="no_fav_list">
                      <img src="/static/bigheart.svg" alt="" />
                      <h3>{STRING_CONSTANTS.no_fav_yet}</h3>
                      <p>{STRING_CONSTANTS.no_fav_list_desc}</p>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <>
                {favList?.length ? (
                  <FavouritePeople />
                ) : (
                  <div className="no-event-outer-new">
                    <div className="no_fav_list">
                      <img src="/static/bigheart.svg" alt="" />
                      <h3>{STRING_CONSTANTS.no_fav_yet}</h3>
                      <p>{STRING_CONSTANTS.no_fav_list_desc}</p>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        {/* {nextUrl || nextUrlPeople ? ( */}
        <div className="paination-container">
          <ReactPaginate
            breakLabel="..."
            nextLabel={
              <>
                <div className="previous-button">
                  <img src="/static/u_angle-left-b(1).svg" alt="" />
                  Next
                </div>
              </>
            }
            onPageChange={(e) =>
              activeTab === "Events"
                ? handlePageClick(e)
                : handlePageClickPeople(e)
            }
            pageRangeDisplayed={5}
            forcePage={currentPage}
            pageCount={pageCount / limit}
            previousLabel={
              <>
                <div className="previous-button">
                  Previous <img src="/static/u_angle-left-b.svg" alt="" />
                </div>
              </>
            }
            renderOnZeroPageCount={null}
            pageClassName="page-item"
            pageLinkClassName="page-link"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            breakClassName="page-item"
            breakLinkClassName="page-link"
            containerClassName="pagination"
            activeClassName="active"
          />
        </div>
        {/* ) : null} */}
      </div>
      {detailsData ? (
        <Modal
          show={showShare}
          onHide={handleNotShowShare}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <div className="share_modal_layout">
              <img
                src="/static/close.svg"
                alt="Close"
                style={{ cursor: "pointer" }}
                onClick={handleNotShowShare}
                className="share_modal_close_btn"
              />
              <h4>Share Listing</h4>
              <div className="share_modal_details">
                {detailsData?.photos?.length > 0 ? (
                  /\.(mp4|mov|avi)$/.test(
                    detailsData?.photos[0].documentName?.toLowerCase()
                  ) ? (
                    <>
                      <video
                        style={{
                          width: "110px",
                          height: "86px",
                          borderRadius: "12px",
                        }}
                        src={detailsData?.photos[0].uploadDocument}
                        className="event_img"
                        //preload="none"
                        // autoPlay
                        controls
                        controlsList="nodownload"
                        // playsInline
                        // loop
                        muted
                        onClick={(e) => {
                          e.preventDefault();
                          scrollToTop();
                          navigate(`/listing-two/${detailsData.id}`);
                        }}
                      >
                        <source src={detailsData?.photos[0].uploadDocument} />
                      </video>
                    </>
                  ) : (
                    <img
                      src={
                        detailsData?.photos[0].uploadDocument ||
                        "/static/expertise2.png"
                      }
                      className="event_img"
                      alt=""
                      style={{
                        width: "110px",
                        height: "86px",
                        borderRadius: "12px",
                      }}
                      loading="lazy"
                      onClick={(e) => {
                        e.preventDefault();
                        scrollToTop();
                        navigate(`/listing-two/${detailsData.id}`);
                      }}
                    />
                  )
                ) : (
                  <img
                    style={{
                      width: "110px",
                      height: "86px",
                      borderRadius: "12px",
                    }}
                    src="/static/expertise2.png"
                    alt=""
                  />
                )}

                <div className="title">
                  {detailsData.title && <h5>{detailsData.title}</h5>}
                  <p className="sub-title">
                    Address: {detailsData?.scheduleDetails?.address?.address}
                  </p>
                </div>
                <div>
                  {detailsData?.listingType === "S" ||
                  detailsData?.listingType === "SPECIAL" ? (
                    <button
                      className="session_btn_popup"
                      style={{ padding: "10px" }}
                    >
                      <img src="/static/Sport-white.svg" alt="Sport Balls" />
                      Session
                    </button>
                  ) : detailsData?.listingType === "P" ? (
                    <button
                      className="package_btn_popup"
                      style={{ padding: "10px" }}
                    >
                      <img src="/static/box-white.svg" alt="Sport Balls" />
                      Package
                    </button>
                  ) : (
                    <button
                      className="team_btn_popup"
                      style={{ padding: "10px" }}
                    >
                      <img src="/static/people-white.svg" alt="Sport Balls" />
                      Team
                    </button>
                  )}
                </div>
              </div>
              <div className="share_options">
                <span onClick={() => handleCopy()}>
                  <img src="/static/copy.svg" alt="Copy icon" />
                  Copy
                </span>
                <span onClick={handleEmailShare}>
                  <img src="/static/mail.svg" alt="Mail icon" />
                  Email
                </span>
                <span
                  className="d-block d-sm-none d-flex"
                  onClick={handleSmsShare}
                >
                  <img src="/static/message.svg" alt="Message icon" />
                  Message
                </span>
                <span onClick={handleWhatsAppShare}>
                  <img src="/static/whatsapp.svg" alt="WhatsApp icon" />
                  WhatsApp
                </span>
                <span onClick={handleMessengerShare}>
                  <img src="/static/messenger.svg" alt="Messenger icon" />
                  Messenger
                </span>
                <span onClick={handleFacebookShare}>
                  <img src="/static/facebook.svg" alt="Facebook icon" />
                  Facebook
                </span>
                <span onClick={handleTwitterShare}>
                  <img src="/static/twitter.svg" alt="Twitter icon" />
                  Twitter
                </span>
              </div>
              <button className="mt-5 d-lg-none" onClick={handleNotShowShare}>
                Back
              </button>
            </div>
          </Modal.Body>
        </Modal>
      ) : null}
    </DashboardWrapper>
  );
};

export default FavouriteNew;
