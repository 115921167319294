import { Link } from "react-router-dom";
import { ROUTE_CONSTANTS } from "../../utils/RouteConstants";

const LogoHeader = () => {
  return (
    <div
      style={{
        position: "sticky",
        top: 0,
        zIndex: 999,
        backgroundColor: "#ffffff",
      }}
    >
      <div className="top-bar">
        <div className="profile-managment-top">
          <div className="'left-logo d-none d-md-block">
            <Link to={ROUTE_CONSTANTS.dashboard}>
              <img src="/static/orange-logo.svg" alt="" />
            </Link>
          </div>
          <div className=" d-md-none">
            <Link to={ROUTE_CONSTANTS.dashboard}>
              <img src="/static/logo_small.svg" alt="" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogoHeader;
